import gql from 'graphql-tag';

export const ProfileContactGraphqlFields = `
    active
    contactType
    contactUsage
    contactValue
    createdAt
    createdBy{
        id
        fullName
    }
    deleted
    deletedAt
    id
    preference
    profile{
        id
        uid
        user{
            id
        }
    }
    uid
    updatedAt
    verified
`;

export const GET_PROFILE_CONTACTS_BY_LOGGED_USER = gql`
    query listProfileContactByUser{
        listProfileContactByUser{
            ${ProfileContactGraphqlFields}
        }
    }
`;

export const SAVE_PROFILE_CONTACT = gql`
    mutation saveProfileContact($profileContactDto:ProfileContactDtoInput){
        saveProfileContact(profileContactDto:$profileContactDto){
            data{
                ${ProfileContactGraphqlFields}
            }
            status
            errorDescription
            code
        }
    }
`;

export const DELETE_PROFILE_CONTACT = gql`
    mutation deleteProfileContact($uid:String){
        deleteProfileContact(uid:$uid){
            data{
                ${ProfileContactGraphqlFields}
            }
            status
            errorDescription
            code
        }
    }
`;



