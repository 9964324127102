import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {Reconciliation} from './reconciliation.model';

export const loadReconciliations = createAction(
    '[Reconciliation/API] Load Reconciliations',
    props<{ reconciliations: Reconciliation[] }>()
);

export const addReconciliation = createAction(
    '[Reconciliation/API] Add Reconciliation',
    props<{ reconciliation: Reconciliation }>()
);

export const upsertReconciliation = createAction(
    '[Reconciliation/API] Upsert Reconciliation',
    props<{ reconciliation: Reconciliation }>()
);

export const addReconciliations = createAction(
    '[Reconciliation/API] Add Reconciliations',
    props<{ reconciliations: Reconciliation[] }>()
);

export const upsertReconciliations = createAction(
    '[Reconciliation/API] Upsert Reconciliations',
    props<{ reconciliations: Reconciliation[] }>()
);

export const updateReconciliation = createAction(
    '[Reconciliation/API] Update Reconciliation',
    props<{ reconciliation: Update<Reconciliation> }>()
);

export const updateReconciliations = createAction(
    '[Reconciliation/API] Update Reconciliations',
    props<{ reconciliations: Update<Reconciliation>[] }>()
);

export const deleteReconciliation = createAction(
    '[Reconciliation/API] Delete Reconciliation',
    props<{ id: string }>()
);

export const deleteReconciliations = createAction(
    '[Reconciliation/API] Delete Reconciliations',
    props<{ ids: string[] }>()
);

export const clearReconciliations = createAction(
    '[Reconciliation/API] Clear Reconciliations'
);
export const settlePayment = createAction(
    '[Reconciliation/API] Settle Payment',
    props<{ id: number }>()
);
