import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {SettingsService} from '../../../../services/settings/settings.service';
import {ApplicationDocument} from '../../../../store/entities/advocate/application-document/application-document.model';

@Component({
    selector: 'app-view-attachment',
    templateUrl: './view-attachment.component.html',
    styleUrls: ['./view-attachment.component.scss']
})
export class ViewAttachmentComponent implements OnInit {

    attachment$: Observable<string>;
    title: string;
    applicationDocument: ApplicationDocument;

    constructor(
        private dialogRef: MatDialogRef<ViewAttachmentComponent>,
        private settingService: SettingsService,
        @Inject(MAT_DIALOG_DATA) data,
    ) {
        this.applicationDocument = data;
    }

    ngOnInit(): void {
        this.title = 'YOU ARE VIEWING: ' + this.applicationDocument?.attachmentType?.name?.toUpperCase();

        this.attachment$ = this.settingService.getAttachment(this.applicationDocument?.uid);
    }

    closeModal(close: boolean) {
        if (close) {
            this.dialogRef.close();
        }
    }
}
