<!-- Footer -->
<footer>
    <div class="footer-top">
        <div class="pt-exebar">
            <div class="container">
                <div class="d-flex align-items-stretch">
                    <div class="pt-logo mr-auto">
                        {{'public.footer-title' | translate}} - e-WAKILI
                    </div>
                    <div class="pt-social-link">
                        <ul class="list-inline m-a0">
                            <li><a href="#" class="btn-link"><i class="ik ik-facebook"></i></a></li>
                            <li><a href="#" class="btn-link"><i class="ik ik-twitter"></i></a></li>
                            <li><a href="#" class="btn-link"><i class="ik ik-linkedin"></i></a></li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-12">
                    <div class="widget footer_widget custom-widget">
                        <h5 class="footer-title">{{'navigation.contact' | translate}}</h5>
                        <ul>
                            <li>{{'footer.address-org-name' | translate}},</li>
                            <li>{{'footer.address-org-locaion' | translate}},</li>
                            <li>{{'footer.address-org-post' | translate}},</li>
                            <li>Dar es Salaam.</li>
                            <li>{{'footer.address-org-phone' | translate}}: +255739303038</li>
                            <li>{{'footer.address-org-mail' | translate}}: rhc@judiciary.go.tz</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12">
                    <div class="widget footer_widget custom-widget">
                        <h5 class="footer-title">{{'footer.menu' | translate}}</h5>
                        <ul>
                            <li><a routerLink="/ewakili/guide">{{'navigation.user-guide' | translate}} </a></li>
                            <li><a routerLink="/ewakili/about">{{'navigation.about-ewakili' | translate}}</a></li>
                            <!-- <li><a routerLink="/tams-public/temp-admission">Temporary Admission</a></li> -->
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12">
                    <div class="widget footer_widget custom-widget">
                        <h5 class="footer-title">{{'navigation.e-service' | translate}}</h5>
                        <ul>
                            <li><a target="_blank" href="https://jsds.judiciary.go.tz/">{{'navigation.case-management' | translate}} </a></li>
                            <li><a href="#">{{'navigation.court-broker' | translate}}</a></li>
                            <!-- <li><a href="#">Judiciary Portal</a></li> -->
                            <li><a href="#">{{'navigation.court-mapping' | translate}}</a></li>
                            <li><a href="#">{{'navigation.e-library' | translate}}</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12">
                    <div class="widget footer_widget custom-widget">
                        <h5 class="footer-title">{{'footer.links' | translate}}</h5>
                        <ul>
                            <li><a target="_blank" href="https://judiciary.go.tz/">{{'footer.organization' | translate}}</a></li>
                            <li><a target="_blank" href="https://www.sheria.go.tz/">{{'footer.link-ministry' | translate}}</a></li>
                            <li><a target="_blank" href="https://tls.or.tz/">{{'footer.link-tls' | translate}}</a></li>
                            <li><a target="_blank" href="https://legalaidportal.sheria.go.tz/">{{'footer.link-legal-aid' | translate}}</a></li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 text-center"><span
                        style="color: white;">{{'footer.copyright' | translate}} &copy; 2018 - {{currentYear}}. {{'footer.organization' | translate}}.</span>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer -->
