import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {AppState} from '../../../reducers';
import {ResponseCode} from '../../../../shared/enums/http-status-codes.enum';
import {User} from './user.model';
import {GET_LOGGED_IN_USER, SAVE_STAFF, SAVE_USER_QUALIFICATIONS} from './user.graphql';
import {map, switchMap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {
    changePassword,
    changePasswordCompleted,
    getLoggedInUser, resendPasswordResetMail,
    saveStaff,
    saveUserQualifications,
    upsertUser
} from './user.actions';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {AuthService} from '../../../../services/auth/auth.service';
import {fetchAllAttachmentTypesByUser} from '../attachment-type/attachment-type.actions';
import {CHANGE_PASSWORD, RESEND_PASSWORD_RESET_MAIL} from '../../auth-user/auth-user.graphql';


@Injectable()
export class UserEffects {

    getLoggedInUser$ = createEffect(() => this.actions$.pipe(
        ofType(getLoggedInUser),
        switchMap((action) => {
            return this.apollo.query({
                query: GET_LOGGED_IN_USER,
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        this.store.dispatch(upsertUser({user: Object.values(data)[0] as User}));
                    }
                })
            );
        })
    ), {dispatch: false});

    saveUserQualifications$ = createEffect(() => this.actions$.pipe(
        ofType(saveUserQualifications),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: SAVE_USER_QUALIFICATIONS,
                variables: {
                    qualifications: action.userQualification
                }
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        if (data.saveUserQualification.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(getLoggedInUser());
                            this.store.dispatch(fetchAllAttachmentTypesByUser());
                            return this.notificationService.successMessage('Your qualifications saved successfully');
                        } else {
                            return this.responseCodesService.errorMessageByCode(data.saveUserQualification.code);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});

    /* allUsers$ = createEffect(() => this.actions$.pipe(
        ofType(fetchAllUsers),
        switchMap( (action) => {
          return this.apollo.query({
            query: LIST_USERS,
            fetchPolicy: 'network-only'
          }).pipe(
              this.notificationService.catchError(),
              map( ({data}: any) => {
                if (data) {
                  this.store.dispatch(upsertUsers({users:
                        Object.values(data)[0] as User[]}));
                }
              })
          );
        })
    ), {dispatch: false});

    getOneUsers$ = createEffect(() => this.actions$.pipe(
        ofType(fetchOneUser),
        switchMap( (action) => {
          return this.apollo.query({
            query: GET_ONE_USER,
            fetchPolicy: 'network-only',
              variables: {uid: action.uid}
          }).pipe(
              this.notificationService.catchError(),
              map( ({data}: any) => {
                if (data) {
                  this.store.dispatch(upsertUser({user:
                        Object.values(data)[0] as User}));
                }
              })
          );
        })
    ), {dispatch: false});

    newUser$ = createEffect(() => this.actions$.pipe(
        ofType(newUser),
        switchMap(( action) => {
          return this.apollo.mutate({
            mutation: CREATE_USER,
            variables: {
              user: action.user
            }
          }).pipe(
              this.notificationService.catchError(),
              map(({data}: any) => {
                if (data) {
                  if (data.createUser.code === HttpStatusCode.SUCCESS) {
                    this.store.dispatch(upsertUser({user: data.createUser.data}));
                    return this.notificationService.successMessage('User saved successfully');
                  } else {
                    return this.responseCodesService.errorMessageByCode(data.createUser.code);
                  }
                }
              })
          );
        })
    ), {dispatch: false});

    editUser$ = createEffect(() => this.actions$.pipe(
        ofType(editUser),
        switchMap((action) => {
          return this.apollo.mutate({
            mutation: EDIT_USER,
            variables: {
              user: action.user
            }
          }).pipe(
              this.notificationService.catchError(),
              map(({data}: any) => {
                if (data.updateUser.code === HttpStatusCode.SUCCESS) {
                  this.store.dispatch(upsertUser({user: data.updateUser.data}));
                  return this.notificationService.successMessage('User updated successfully');
                } else {
                  return this.responseCodesService.errorMessageByCode(data.updateUser.code);
                }
              })
          );
        })
    ), {dispatch: false});

    enableUser$ = createEffect(() => this.actions$.pipe(
        ofType(activateUser),
        switchMap((action) => {
          return this.apollo.mutate({
            mutation: ACTIVATE_USER,
            variables: {
              uid: action.uid
            }
          }).pipe(
              this.notificationService.catchError(),
              map(({data}: any) => {
                if (data.activateUser.code === HttpStatusCode.SUCCESS) {
                  this.store.dispatch(upsertUser({user: data.activateUser.data}));
                  return this.notificationService.successMessage('User active status changed successfully');
                } else {
                  return this.responseCodesService.errorMessageByCode(data.activateUser.code);
                }
              })
          );
        })
    ), {dispatch: false});

    */


    changePassword$ = createEffect(() => this.actions$.pipe(
        ofType(changePassword),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: CHANGE_PASSWORD,
                variables: {
                    changePassword: action.passwords
                }
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data.changePassword.code === ResponseCode.SUCCESS) {
                        this.store.dispatch(changePasswordCompleted());
                        this.notificationService.successMessage(data.changePassword.data);
                        this.auth.logout('Password changed successfully. Please login with your newly created password.');
                    } else {
                        return this.responseCodesService.errorMessageByMessage(data.changePassword);
                    }
                })
            );
        })
    ), {dispatch: false});


    resendPasswordResetMail$ = createEffect(() => this.actions$.pipe(
        ofType(resendPasswordResetMail),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: RESEND_PASSWORD_RESET_MAIL
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data.resendPasswordResetMail.code === ResponseCode.SUCCESS) {
                        this.auth.logout('Password reset mail sent to your email.');
                    } else {
                        return this.responseCodesService.errorMessageByMessage(data.changePassword);
                    }
                })
            );
        })
    ), {dispatch: false});



    /*
  delUser$ = createEffect(() => this.actions$.pipe(
      ofType(removeUser),
      switchMap((action) => {
        return this.apollo.mutate({
          mutation: DELETE_USER,
          variables: {
            uid: action.uid
          }
        }).pipe(
            this.notificationService.catchError(),
            map(({data}: any) => {
              if (data.removeUser.code === HttpStatusCode.SUCCESS) {
                this.store.dispatch(deleteUser({id: action.id + ''}));
                return this.notificationService.successMessage('User deleted successfully');
              } else {
                return this.responseCodesService.errorMessageByCode(data.removeUser.code);
              }
            })
        );
      })
  ), {dispatch: false});



  newPublicUser$ = createEffect(() => this.actions$.pipe(
    ofType(newPublicUser),
    switchMap(( action) => {
      return this.apollo.mutate({
        mutation: CREATE_PUBLIC_USER,
        variables: {
          saveUserInput: action.publicUser
        }
      }).pipe(
          this.notificationService.catchError(),
          map(({data}: any) => {
            if (data) {
              if (data.saveUser.code === HttpStatusCode.SUCCESS) {
                // this.store.dispatch(upsertUser({user: data.createUser.data}));
                return this.notificationService.successMessage('You have successfully being registered');
              } else {
                return this.responseCodesService.errorMessageByCode(data.saveUser.code);
              }
            }
          })
      );
    })
), {dispatch: false});

getMarriageRegisterList$ = createEffect(() => this.actions$.pipe(
  ofType(getMarriageRegisterList),
  switchMap( (action) => {
    return this.apollo.query({
      query: LIST_MARRIAGE_REGISTER,
      fetchPolicy: 'network-only'
    }).pipe(
        this.notificationService.catchError(),
        map( ({data}: any) => {
          if (data) {
            this.store.dispatch(loadUsers({users:
                  Object.values(data)[0] as User[]}));
          }
        })
    );
  })
), {dispatch: false});

  getUsersByCategory$ = createEffect(() => this.actions$.pipe(
        ofType(fetchAllUsersByCategory),
        switchMap( (action) => {
            return this.apollo.query({
                query: LIST_USERS_BY_CATEGORY,
                fetchPolicy: 'network-only',
                variables: {
                    userCategory: action.userCategory
                }
            }).pipe(
                this.notificationService.catchError(),
                map( ({data}: any) => {
                    if (data) {
                        this.store.dispatch(loadUsers({users:
                                Object.values(data)[0] as User[]}));
                    }
                })
            );
        })
    ), {dispatch: false});
    */

    saveStaff = createEffect(() => this.actions$.pipe(
        ofType(saveStaff),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: SAVE_STAFF,
                variables: {
                    staff: action.staff
                }
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        if (data.saveStaff.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(upsertUser({user: data.saveStaff.data}));
                            return this.notificationService.successMessage('Staff saved successfully');
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.saveStaff);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});

    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService,
        private auth: AuthService,
    ) {
    }

}
