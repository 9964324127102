import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {FirmBranch} from './firm-branch.model';

export const loadFirmBranchs = createAction(
    '[FirmBranch/API] Load FirmBranchs',
    props<{ firmBranchs: FirmBranch[] }>()
);

export const addFirmBranch = createAction(
    '[FirmBranch/API] Add FirmBranch',
    props<{ firmBranch: FirmBranch }>()
);

export const upsertFirmBranch = createAction(
    '[FirmBranch/API] Upsert FirmBranch',
    props<{ firmBranch: FirmBranch }>()
);

export const addFirmBranchs = createAction(
    '[FirmBranch/API] Add FirmBranchs',
    props<{ firmBranchs: FirmBranch[] }>()
);

export const upsertFirmBranchs = createAction(
    '[FirmBranch/API] Upsert FirmBranchs',
    props<{ firmBranchs: FirmBranch[] }>()
);

export const updateFirmBranch = createAction(
    '[FirmBranch/API] Update FirmBranch',
    props<{ firmBranch: Update<FirmBranch> }>()
);

export const updateFirmBranchs = createAction(
    '[FirmBranch/API] Update FirmBranchs',
    props<{ firmBranchs: Update<FirmBranch>[] }>()
);

export const deleteFirmBranch = createAction(
    '[FirmBranch/API] Delete FirmBranch',
    props<{ id: string }>()
);

export const deleteFirmBranchs = createAction(
    '[FirmBranch/API] Delete FirmBranchs',
    props<{ ids: string[] }>()
);

export const clearFirmBranchs = createAction(
    '[FirmBranch/API] Clear FirmBranchs'
);

export const getUserFirmBranch = createAction(
    '[FirmBranch/API] Get User FirmBranchs',
);

export const saveFirmBranch = createAction(
    '[FirmBranch/API] Save  FirmBranchs',
    props<{ firmBranch: any }>()
);


export const applyForFirm = createAction(
    '[FirmBranch/API] Apply For  FirmBranchs',
    props<{ firmUid: string, firmBranchUid: string }>()
);
