import gql from 'graphql-tag';
import {countryGqlFields} from '../../settings/country/country.graphql';
import {UserGqlFields} from '../../settings/user/user.graphql';
import {petitionEducationGqlFields} from '../petition-education/petition-education.graphql';
import {ProfileAddressGraphqlFields} from '../profile-address/profile-address.graphql';
import {ProfileContactGraphqlFields} from '../profile-contact/profile-contact.graphql';
import {WorkExperienceGraphqlFields} from '../work-experience/work-experience.graphql';
import {applicationDocumentGqlFields} from '../application-document/application-document.graphql';
import {FirmMemberGraphqlFields} from '../firm-member/firm-member.graphql';

export const profileGqlFields = `
    id
    uid
    country{
        ${countryGqlFields}
    }
    active
    createdAt
    updatedAt
    createdBy{
        ${UserGqlFields}
    }
    dob
    fullname
    idNumber
    user{
        ${UserGqlFields}
    }
    idType
    photo
    picture
    title
    gender
`;

export const fullProfileDetailsQqlFields = `
    id
    workExperience{
        id
        uid
        beginYear
        createdAt
        endYear
        place
        title
        updatedAt
    }
    application{
      id
      uid
      active
      applicationType
      approveStatus
      unReviewed
      resubmission
      submissionAt
      updatedAt
      createdAt
      lastUpdatedAt
      approvalList{
        id
        uid
        actionUserType{
          id
          name
          displayName
        }
        decision
        comment
        createdAt
        active
      }
      createdBy{
        id
        uid
        fullName
      }
      currentStage{
        id
        uid
        name
        displayName
      }
      workflowProcess{
        id
        uid
        displayName
        name
      }
      profile{
        id
        uid
        country{
            ${countryGqlFields}
        }
        active
        createdAt
        updatedAt
        createdBy{
            ${UserGqlFields}
        }
        dob
        fullname
        idNumber
        user{
            ${UserGqlFields}
        }
        idType
        picture
        title
        gender
      }
    }
    firmMember{
        ${FirmMemberGraphqlFields}
    }
    petition{
      id
      uid
      active
      admitAs
      lstRegno
      petitionNo
      priority
      qualifications
      seniority
      seniorpriority
      createdAt
      updatedAt
      lstExemptionApprovalStatus
      exemptionDate
      legalProfessionalViewList{
          id
          active
          uid
          comment
          createdAt
          updatedAt
          user{
            id
            fullName
            uid
          }
          approveStatus
          submissionAt
          createdBy{
            id
            uid
            fullName
          }
          workflowProcess{
            id
            name
            displayName
          }
      }
    }
    qualification
    petitionEducations{
        ${petitionEducationGqlFields}
    }
    profile{
        ${profileGqlFields}
    }
    profileAddresses{
        ${ProfileAddressGraphqlFields}
    }
    profileContact{
        ${ProfileContactGraphqlFields}
    }
    workExperience{
        ${WorkExperienceGraphqlFields}
    }
    petitionAttachments{
        ${applicationDocumentGqlFields}
    }
    advocate{
        id
        uid
        rollNo
        advocateCategory
    }
`;

export const fullAdvocateProfileDetailsQqlFields = `
    id
    applications{
      id
      uid
      active
      applicationType
      approveStatus
      unReviewed
      resubmission
      submissionAt
      updatedAt
      createdAt
      lastUpdatedAt
      applicationDocuments{
        filename
        id
        uid
        attachmentType {
            id
            uid
            name
        }
      }
      approvalList{
        id
        uid
        actionUserType{
          id
          name
          displayName
        }
        application{
            id
            uid
            currentStage{
                id
                uid
                name
            }
        }
        decision
        comment
        createdAt
        active
        resubmission
        user{
          id
          uid
        }
      }
      createdBy{
        id
        uid
        fullName
      }
      currentStage{
        id
        uid
        name
        displayName
      }
      workflowProcess{
        id
        uid
        displayName
        name
      }
      profile{
        id
        uid
        country{
            ${countryGqlFields}
        }
        active
        createdAt
        updatedAt
        createdBy{
            ${UserGqlFields}
        }
        dob
        fullname
        idNumber
        user{
            ${UserGqlFields}
        }
        idType
        picture
        title
        gender
      }
    }
    qualification
    firmMember{
        ${FirmMemberGraphqlFields}
    }
    petition{
      id
      uid
      active
      admitAs
      lstRegno
      petitionNo
      priority
      qualifications
      seniority
      seniorpriority
      createdAt
      updatedAt
      lstExemptionApprovalStatus
      exemptionDate
      appearance{
        id
        uid
        appearDate
        reportingTime
      }
      appearanceVenue{
        id
        uid
        name
      }
    }
    petitionEducations{
        ${petitionEducationGqlFields}
    }
    profile{
        ${profileGqlFields}
    }
    profileAddresses{
        ${ProfileAddressGraphqlFields}
    }
    profileContact{
        ${ProfileContactGraphqlFields}
    }
    workExperience{
        ${WorkExperienceGraphqlFields}
    }
    advocate{
        id
        uid
        rollNo
        advocateCategory
    }
`;


export const SAVE_ADVOCATE_PROFILE = gql`
    mutation saveProfile($profile: ProfileDtoInput){
        saveProfile(profileDto: $profile){
            code
            data{
                ${profileGqlFields}
            }
            status
            errorDescription
        }
    }
`;

export const EDIT_ADVOCATE_PROFILE = gql`
    mutation editProfile($profile: ProfileDtoInput, $uid: String){
        updateProfile(profileDto: $profile, uid: $uid){
            code
            data{
                ${profileGqlFields}
            }
            status
            errorDescription
        }
    }
`;

export const GET_ADVOCATE_PROFILE_BY_USER = gql`
    query getProfileByUser{
        getProfileByUser{
            code
            data{
                ${profileGqlFields}
            }
            errorDescription
            status
        }
    }
`;

export const GET_ADVOCATE_PROFILE_BY_USER_PHOTO = gql`
    query getProfileByUser{
        getProfileByUser{
            code
            data{
                id
                uid
                photo
                title
            }
        }
    }
`;

export const GET_FULL_PROFILE_DETAILS_BY_UID = gql`
    query getFullProfileDetails($uid: String) {
        getProfileDetails(applicationUid: $uid) {
            ${fullProfileDetailsQqlFields}
        }
    }
`;

export const GET_FULL_PROFILE_DETAILS_BY_USER = gql`
    query gefFullAdvocateProfile($userUid: String){
        getUserProfileDetails(profileUid: $userUid){
            ${fullAdvocateProfileDetailsQqlFields}
        }
    }
`;

export const GET_APPLICATION_SUBMISSION_STATUS = gql`
    query submitPetitionStatus{
        submitPetitionStatus
    }
`;

export const ENROLL_PETITIONERS = gql`
    query enrollPetitioners{
        enrollPetitioners{
            code
            data
            errorDescription
            status
        }
    }
`;

export const CHANGE_PETITIONER_FULLNAME = gql`
    mutation updatePetitionName($uuid: String, $fullname: String){
        updatePetitionerFullname(uid: $uuid, fullname: $fullname){
            code
            data{
                ${profileGqlFields}
            }
            errorDescription
            status
        }
    }
`;
