import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Signature, SignatureDto } from './signature.model';

export const loadSignatures = createAction(
  '[Signature/API] Load Signatures', 
  props<{ signatures: Signature[] }>()
);

export const addSignature = createAction(
  '[Signature/API] Add Signature',
  props<{ signature: Signature }>()
);

export const upsertSignature = createAction(
  '[Signature/API] Upsert Signature',
  props<{ signature: Signature }>()
);

export const addSignatures = createAction(
  '[Signature/API] Add Signatures',
  props<{ signatures: Signature[] }>()
);

export const upsertSignatures = createAction(
  '[Signature/API] Upsert Signatures',
  props<{ signatures: Signature[] }>()
);

export const updateSignature = createAction(
  '[Signature/API] Update Signature',
  props<{ signature: Update<Signature> }>()
);

export const updateSignatures = createAction(
  '[Signature/API] Update Signatures',
  props<{ signatures: Update<Signature>[] }>()
);

export const deleteSignature = createAction(
  '[Signature/API] Delete Signature',
  props<{ id: string }>()
);

export const deleteSignatures = createAction(
  '[Signature/API] Delete Signatures',
  props<{ ids: string[] }>()
);

export const clearSignatures = createAction(
  '[Signature/API] Clear Signatures'
);


/*
*  Effect actions
* */

export const fetchAllSignature = createAction(
  '[Signature/API] fetch all signature from the server'
);

export const saveNewSignature = createAction(
  '[Signature/API] save new signature to the server',
  props<{ signatureDto: SignatureDto }>()
);

export const editExistingSignature = createAction(
  '[Signature/API] update exiting signature to the server',
  props<{ signatureDto: SignatureDto, uid: string }>()
);

export const removerSignature = createAction(
  '[Signature/API] remove existing signature from server',
  props<{ uid: string }>()
);

