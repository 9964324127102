import gql from 'graphql-tag';
import {cleMembersGqlFields} from '../../settings/cle-member/cle-member.graphql';
import {profileGqlFields} from '../../advocate/advocate-profile/advocate-profile.graphql';

export const LIST_ALL_PETITIONERS_BY_CORAM = gql`
    query getPetitionersByCoram($coramUid: String){
        getPetitionersByCoram(coramUid: $coramUid){
            cleMemberList{
                ${cleMembersGqlFields}
                candidate
            }
            petitioners{
                id
                uid
                active
                admitAs
                lstRegno
                petitionNo
                priority
                qualifications
                seniority
                seniorpriority
                createdAt
                updatedAt
                profile{
                    ${profileGqlFields}
                }
            }
        }
    }
`;
