import gql from 'graphql-tag';

export const ActionUserTypeGqlFields = `
    id
    uid
    name
    displayName
`;

export const LIST_ALL_ACTION_USER_TYPES = gql`
    query listActionUserTypes{
        listActionUserTypes{
            ${ActionUserTypeGqlFields}
        }
    }
`;
