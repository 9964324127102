<div [formGroup]="form">
    <mat-form-field appearance="outline" class="material-form">
        <mat-label>{{ label ? label : 'Value'}}</mat-label>
        <input matInput *ngIf="inputType === typeOfInputEnum?.TEXT" [formControlName]="control" />

        <input *ngIf="inputType === typeOfInputEnum?.DATE" matInput [matDatepicker]="controlDate"
            [formControlName]="control">
        <mat-datepicker-toggle *ngIf="inputType === typeOfInputEnum?.DATE" matSuffix [for]="controlDate">
        </mat-datepicker-toggle>
        <mat-datepicker *ngIf="inputType === typeOfInputEnum?.DATE" #controlDate></mat-datepicker>

        <mat-select
            *ngIf="inputType === typeOfInputEnum?.SELECT && typeOfSelectEnum[selectType] === typeOfSelectEnum?.MARITAL_STATUS"
            [formControlName]="control">
            <mat-option *ngFor="let status of maritalStatusList" [value]="status?.name">
                {{status?.displayName}}
            </mat-option>
        </mat-select>

        <mat-select
            *ngIf="inputType === typeOfInputEnum?.SELECT && typeOfSelectEnum[selectType] === typeOfSelectEnum?.MARRIAGE_FORM"
            [formControlName]="control">
            <mat-option *ngFor="let form of marriageFormsList" [value]="form?.name">
                {{form?.label}}
            </mat-option>
        </mat-select>
        <mat-select
            *ngIf="inputType === typeOfInputEnum?.SELECT && typeOfSelectEnum[selectType] === typeOfSelectEnum?.MARRIAGE_TYPE"
            [formControlName]="control">
            <mat-option *ngFor="let type of marriageTypesList" [value]="type?.name">
                {{type?.label}}
            </mat-option>
        </mat-select>


        <mat-error>
            <span *ngIf="!form[control]?.valid && form[control]?.touched">
                Please fill {{ label ? label : 'value'}}
            </span>
        </mat-error>
    </mat-form-field>
</div>