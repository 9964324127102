import {AppState} from '../../store/reducers';
import {environment} from '../../../environments/environment';
import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {NgxPermissionsService} from 'ngx-permissions';
import {Store} from '@ngrx/store';
import {addAuthUser, logoutAction} from 'src/app/store/entities/auth-user/auth-user.actions';
import {AuthUser} from 'src/app/store/entities/auth-user/auth-user.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthTokenModel} from '../../common/interfaces/data.interfaces';
import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {NotificationService} from "../notifications/notification.service";
import {selectLoggedInUser} from "../../store/entities/auth-user/auth-user.selector";


@Injectable({
    providedIn: 'root'
})
export class AuthService implements OnDestroy {

    authority: any;
    objectPerms: any;
    perms: any;
    private errorCode: any;
    subscriptions: Subscription = new Subscription();
    authUser: AuthUser;

    constructor(
        private http: HttpClient,
        private router: Router,
        private snackbar: MatSnackBar,
        private permissionsService: NgxPermissionsService,
        private store: Store<AppState>,
        private route: Router,
        private notificationService: NotificationService
    ) {
    }

    async login(formData: { username: string, password: string }): Promise<AuthTokenModel> {
        const body = `username=${encodeURIComponent(
            formData.username
        )}&password=${encodeURIComponent(formData.password)}&grant_type=password`;

        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json',
            Authorization: 'Basic ' + btoa(environment.CLIENT_ID + ':' + environment.CLIENT_SECRET),
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': '*'
        }).set('ngLoader', 'false');
        return await this.http
            .post<AuthTokenModel>(environment.SERVER_URL + `/oauth/token`, body, {headers})
            .pipe(
                map(tokenDetails => {
                    if (tokenDetails && tokenDetails.access_token) {
                        localStorage.setItem('currentClient', JSON.stringify(tokenDetails.access_token));
                        localStorage.setItem('refreshToken', JSON.stringify(tokenDetails.refresh_token));
                        localStorage.setItem('expireTime', JSON.stringify(tokenDetails.expires_in));
                        if (!localStorage.getItem('design-mode')) {
                            localStorage.setItem('design-mode', 'light');
                        }
                    }
                    return tokenDetails;
                })
            )
            .toPromise();
    }

    forgotPassword(username: string): Observable<any> {
        return this.http
            .post<any>(environment.SERVER_URL + `/anonymous/forgot-password`, username);
    }

    async authRole(forLogin = false) {
        this.perms = await localStorage.getItem('currentClient');
        if (this.perms) {
            // await this.subscriptions.add(
            await this.http.get(environment.SERVER_URL + '/auth/user').toPromise().then(userData => {
                    this.authority = userData;
                    // console.log(userData);
                    this.objectPerms = this.authority.authorities;
                    const finalArray = this.objectPerms.map((obj) => {
                        return obj.authority;
                    });
                    const userInfo = this.authority.principal;
                    userInfo.permissions = finalArray;

                    this.authUser = userInfo;
                    const authUser: AuthUser = {
                        id: userInfo.id,
                        firstname: userInfo.firstName,
                        profile: userInfo.profile,
                        middlename: userInfo.middleName,
                        lastname: userInfo.lastName,
                        fullName: userInfo.fullName,
                        isInternalUser: userInfo.isInternalUser,
                        isExternalUser: userInfo.isExternalUser,
                        resetPassword: userInfo.resetPassword
                    };

                    localStorage.setItem('srp', JSON.stringify(userInfo.resetPassword));

                    // console.log(finalArray);
                    this.store.dispatch(addAuthUser({authUser}));
                    this.permissionsService.loadPermissions(finalArray);
                    if (forLogin) {
                        if (localStorage.getItem('redirectUrl')) {
                            const redirectUrl = localStorage.getItem('redirectUrl');
                            localStorage.removeItem('redirectUrl');
                            return this.route.navigateByUrl(redirectUrl);
                        } else {
                            return this.route.navigate(['', 'management']);
                        }
                    }
                },
                (error) => this.collectFailedRequest(error));

        }
    }

    async loggedUser() {
        return this.store.select(selectLoggedInUser).pipe(map(loggedUser => loggedUser)).toPromise();
    }

    /*logout(): any {
        // remove user from local storage to log user out
        const token = JSON.stringify(localStorage.getItem('currentClient'));
        const refreshToken = JSON.stringify(localStorage.getItem('refreshToken'));
        localStorage.removeItem('encryptedWorkingOrganizationId');
        // this.permissionsService.flushPermissions();

        const body = `token=${encodeURIComponent(token)}&refresh-token=${encodeURIComponent(refreshToken)}&grant_type=password`;

        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json',
            Authorization:
                'Basic ' + btoa(environment.CLIENT_ID + ':' + environment.CLIENT_SECRET),
            notoken: 'notoken'
        });

        this.store.dispatch(logoutAction());
        localStorage.removeItem('currentClient');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('expireTime');

        this.subscriptions.add(
            this.http
                .post<any>(environment.SERVER_URL + `/users/logout`, body, {headers})
                .subscribe(data => {
                        this.openSnackbar('Logout Successfully');
                    }
                    , error => this.collectFailedRequest(error))
        );
    }*/


    logout(message = 'Logout successfully'): any {
        // remove user from local storage to log user out
        const token = JSON.parse(localStorage.getItem('currentClient'));
        if (!token) {
            return this.router.navigate(['', 'login']);
        }
        const refreshToken = JSON.parse(localStorage.getItem('refreshToken'));
        const body = `token=${encodeURIComponent(token)}&refresh-token=${encodeURIComponent(refreshToken)}`;
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Bearer ' + encodeURIComponent(token),
        });

        this.subscriptions.add(
            this.http
                .post<any>(environment.SERVER_URL + `/auth/users/logout`, body, {headers})
                .subscribe(data => {
                        localStorage.removeItem('currentClient');
                        localStorage.removeItem('refreshToken');
                        localStorage.removeItem('expireTime');
                        this.store.dispatch(logoutAction());
                        this.permissionsService.flushPermissions();
                        this.openSnackbar(message);
                        return this.router.navigate(['', 'login']);
                    }
                    , error => this.collectFailedRequest(error))
        );
    }

    passwordReset(formData: any) {
        this.subscriptions.add(
            this.http.post(environment.SERVER_URL + '/eboard/anonymous/password-reset', formData)
                .subscribe(res => {
                        // console.log('success', res);
                        this.openSnackbar('Password Changed Successfully');
                        this.router.navigate(['', 'login']);
                    }, error => {
                        // console.log('error', error);
                        this.collectFailedRequest(error);
                    }
                )
        );
    }

    alreadyLoggedIn(): boolean {
        return !!localStorage.getItem('currentClient');
    }

    public collectFailedRequest(err): void {
        this.errorCode = err.status;

        // 400 Bad Request
        if (this.errorCode === 400) {
            this.openSnackbar('Wrong Credentials');
        }

        if (this.errorCode === 401) {
            localStorage.removeItem('currentClient');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('expireTime');
            window.sessionStorage.clear();
            this.router.navigateByUrl('/login');
            this.openSnackbar('Unauthorized');
            // console.log(err);
            // this.openSnackbar(err.error_description);
        }
        if (this.errorCode === 404) {
            const message = 'Service Temporarily Unavailable';
            const action = 'Dismiss';
            this.snackbar.open(message, action, {
                duration: 5000
            });
        }
        if (this.errorCode === 500) {
            const message = 'Something went wrong in server sided.';
            const action = 'Dismiss';
            localStorage.removeItem('currentClient');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('expireTime');
            this.snackbar.open(message, action, {
                duration: 5000
            });
        }
        if (this.errorCode === 504) {
            const message = 'Service Temporarily Unavailable';
            const action = 'Dismiss';
            this.snackbar.open(message, action, {
                duration: 5000
            });
        }
    }

    openSnackbar(message, closeText = 'Dismiss', duration = 5000, verticalPosition: any = 'top', horizontalPosition: any = 'center') {
        this.snackbar.open(message, closeText, {
            duration,
            verticalPosition,
            horizontalPosition
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    createPassword(formData, token): Observable<any> {
        return this.http
            .post<any>(environment.SERVER_URL + `/anonymous/create-password/${token}`, formData);
    }

    register(formData): Observable<any> {
        return this.http
            .post<any>(environment.SERVER_URL + `/anonymous/register`, formData);
    }

    passwordValidity(token): Observable<any> {
        return this.http
            .get<any>(environment.SERVER_URL + `/anonymous/check-token-validity/` + token);
    }
}
