import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {Application, Approval, ResubmitApplicationDto} from './application.model';
import {AdvocateStatusEnum} from '../../advocate/advocate/advocate.model';

export const loadApplications = createAction(
    '[Application/API] Load Applications',
    props<{ applications: Application[] }>()
);

export const addApplication = createAction(
    '[Application/API] Add Application',
    props<{ application: Application }>()
);

export const upsertApplication = createAction(
    '[Application/API] Upsert Application',
    props<{ application: Application }>()
);

export const addApplications = createAction(
    '[Application/API] Add Applications',
    props<{ applications: Application[] }>()
);

export const upsertApplications = createAction(
    '[Application/API] Upsert Applications',
    props<{ applications: Application[] }>()
);

export const updateApplication = createAction(
    '[Application/API] Update Application',
    props<{ application: Update<Application> }>()
);

export const updateApplications = createAction(
    '[Application/API] Update Applications',
    props<{ applications: Update<Application>[] }>()
);

export const deleteApplication = createAction(
    '[Application/API] Delete Application',
    props<{ id: string }>()
);

export const deleteApplications = createAction(
    '[Application/API] Delete Applications',
    props<{ ids: string[] }>()
);

export const clearApplications = createAction(
    '[Application/API] Clear Applications'
);


/*
*  Effect actions
* */

export const submitPetitionApplication = createAction(
    '[Application/API] submit petition application for processing'
);

export const approvePetitionApplication = createAction(
    '[Application/API] approve petition application for processing',
    props<{ approve: Approval }>()
);

export const getApplicationByUid = createAction(
    '[Application/API] get single application by uid',
    props<{ uid: string }>()
);

export const savePermitApplication = createAction(
    '[Application/API] Save Permit Application',
    props<{ permitApplication: any }>()
);

export const updatePetitionAdmitAs = createAction(
    '[Application/API] update petition admitted as',
    props<{ admit: AdvocateStatusEnum, applicationUid: string }>()
);

export const saveRenewalApplication = createAction(
    '[Application/API] Save Renewal Applications',
    props<{ renewalApplication: any }>()
);

export const reSubmitApplication = createAction(
    '[Application/API] Re submit other Applications',
    props<{ applicationUid: string,  resubmitApplication: ResubmitApplicationDto}>()
);

