import gql from 'graphql-tag';

export const RegionGraphqlFields = `
    id
    uid
    name
    districts{
       id
       uid
       name
    }
`;

export const GET_ALL_REGIONS = gql`
    query getAllRegion{
        getAllRegion{
           ${RegionGraphqlFields}
        }
    }
`;



