<!--<div class="login-page">
  <div class="login-wrapper">
    
    <div class="login-wrapper-2">
      <div class="login-top ">
        <div class="logo-box position-relative">
          <img src="./assets/images/rita-logo.png" alt="RITA LOGO">
          <h3 class="system-title mat-h3">
            &lt;!&ndash;MARRIAGE AND DIVORCE MANAGEMENT SYSTEM - <span>MDMS</span>&ndash;&gt;
            {{'main.app-title' | translate}}
          </h3>
          <div  class="position-absolute">
            <p  matTooltip="Switch to English/ Badilisha kwenda Kingereza" *ngIf="lang === 'sw'" matRipple (click)="switchLang('en')" class="lang"> <mat-icon mat-list-icon svgIcon="uk-flag"></mat-icon>EN</p>
            <p matTooltip="Switch to Swahili/ Badilisha kwenda Kiswahili" *ngIf="lang === 'en'" matRipple (click)="switchLang('sw')" class="lang"> <mat-icon mat-list-icon svgIcon="tz-flag"></mat-icon>SW</p>
          </div>
        </div>
        
       
      </div>
      <div class="login-container">
        <button routerLink="/rita-public/user-registration" class="mb-3" mat-stroked-button color="primary">
          {{'main.register-link' | translate}}
        </button>




      </div>
    </div>
    
    <h4 class="footer-copy">&copy; 2021 RITA - MDMS</h4>
  </div>
  
</div>-->

<ng-container *ngTemplateOutlet="AuthContent"></ng-container>
