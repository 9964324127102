import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { ActionUserType } from './action-user-type.model';

export const loadActionUserTypes = createAction(
  '[ActionUserType/API] Load ActionUserTypes',
  props<{ actionUserTypes: ActionUserType[] }>()
);

export const addActionUserType = createAction(
  '[ActionUserType/API] Add ActionUserType',
  props<{ actionUserType: ActionUserType }>()
);

export const upsertActionUserType = createAction(
  '[ActionUserType/API] Upsert ActionUserType',
  props<{ actionUserType: ActionUserType }>()
);

export const addActionUserTypes = createAction(
  '[ActionUserType/API] Add ActionUserTypes',
  props<{ actionUserTypes: ActionUserType[] }>()
);

export const upsertActionUserTypes = createAction(
  '[ActionUserType/API] Upsert ActionUserTypes',
  props<{ actionUserTypes: ActionUserType[] }>()
);

export const updateActionUserType = createAction(
  '[ActionUserType/API] Update ActionUserType',
  props<{ actionUserType: Update<ActionUserType> }>()
);

export const updateActionUserTypes = createAction(
  '[ActionUserType/API] Update ActionUserTypes',
  props<{ actionUserTypes: Update<ActionUserType>[] }>()
);

export const deleteActionUserType = createAction(
  '[ActionUserType/API] Delete ActionUserType',
  props<{ id: string }>()
);

export const deleteActionUserTypes = createAction(
  '[ActionUserType/API] Delete ActionUserTypes',
  props<{ ids: string[] }>()
);

export const clearActionUserTypes = createAction(
  '[ActionUserType/API] Clear ActionUserTypes'
);

export const listAllActionUserTypes = createAction(
    '[ActionUserType/API] List All Action User Types'
);

