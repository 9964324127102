import gql from 'graphql-tag';

export const ReconciliationGraphqlFields = `
    CCy
    billCtrNum
    ctrAccNum
    dptCellNum
    dptEmailAddr
    dptName
    id
    paidAmt
    payRefId
    pspCode
    pspName
    pspTrxId
    reconcRsv1
    reconcRsv2
    reconcRsv3
    reconcilliationBatch{
        id
    }
    remarks
    spBillId
    trxDtTm
    usdPayChnl
`;


export const SETTLE_PAYMENT = gql`
    query settlePayment($id:Long!){
        settlePayment(id:$id){
            data{
                ${ReconciliationGraphqlFields}
            }
            code
            status
            errorDescription
        }
    }
`;

export const GET_RECONCILIATION_LIST = gql`
    query getReconciliationList( $dtInput: DataTablesInputInput, $reconBatchId: Long ){
        getReconciliationList(dtInput:$dtInput,reconBatchId:$reconBatchId){
            data{
                ${ReconciliationGraphqlFields}
            }
            draw
            recordsTotal
            recordsFiltered
        }
    }
`;


