import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {listPermissionGroups, loadPermissionGroups} from './permission-group.actions';
import {map, switchMap} from 'rxjs/operators';
import {LIST_PERMISSION_GROUP_FOR_COMPANY} from './permission-group.graphql';
import {Apollo} from 'apollo-angular';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {NotificationService} from '../../../../services/notifications/notification.service';


@Injectable()
export class PermissionGroupEffects {

    listPermissionsGroupForCompany$ = createEffect(() => this.actions$.pipe(
        ofType(listPermissionGroups),
        switchMap((action) => {
            return this.apollo.watchQuery({
                query: LIST_PERMISSION_GROUP_FOR_COMPANY,
                fetchPolicy: 'network-only',
            }).valueChanges.pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        if (data.listPermissionGroups.code === 'SUCCESS') {
                            this.store.dispatch(loadPermissionGroups({permissionGroups: data.listPermissionGroups.dataList}));
                        }
                    }
                })
            );
        })
    ), {dispatch: false});

    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService,
        private notificationService: NotificationService,
    ) {
    }

}
