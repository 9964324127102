export const personConsentRelationships = [
    { displayName: "Father", name: "FATHER" },
    { displayName: "Mother", name: "MOTHER" },
    { displayName: "Guardian", name: "GUARDIAN" },
  ];

 export const applicationStatuses: any[] = [
    {name: 'PENDING', label: 'Pending Applications'},
    {name: 'ACCEPTED', label: 'Accepted Applications'},
    {name: 'REJECTED', label: 'Rejected Applications'},
    {name: 'RE_SUBMISSION', label: 'Re Submitted Applications'},
  ];
  export const applicationStatusActions = [
    {name: 'ACCEPT', label: 'Accept this application'},
    {name: 'REJECT', label: 'Reject this application'},
    {name: 'RE_SUBMIT', label: 'Resubmit this application'},
  ];

export const appliedAsList = [
  {
    name: 'NO_IMPEDIMENT',
    displayName: 'No Impediment'
  },
  {
    name: 'SINGLE_STATUS',
    displayName: 'Single Status'
  }
]

export const marriageForms = [
  {name: 'MONOGAMY', label: 'Monogamy'},
  {name: 'POLGAMY', label: 'Polygamy'},
  {name: 'POSIBLILY_POLGAMY', label: 'Possibly polygamy'},
];
export const marriageTypes = [
  {name: 'BUDHISM', label: 'Budhism Marriage'},
  {name: 'CHRISTIAN', label: 'Christian Marriage'},
  {name: 'CIVIL_MARRIAGE', label: 'Civil Marriage'},
  {name: 'CUSTOMARY', label: 'Customary Marriage'},
  {name: 'HINDU', label: 'Hindu Marriage'},
  {name: 'ISLAMIC_MARRIAGE', label: 'Islamic Marriage'},
];