import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CoramPetition } from './coram-petition.model';
import * as CoramPetitionActions from './coram-petition.actions';

export const coramPetitionsFeatureKey = 'coramPetitions';

export interface State extends EntityState<CoramPetition> {
  // additional entities state properties
}

export const adapter: EntityAdapter<CoramPetition> = createEntityAdapter<CoramPetition>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(CoramPetitionActions.addCoramPetition,
    (state, action) => adapter.addOne(action.coramPetition, state)
  ),
  on(CoramPetitionActions.upsertCoramPetition,
    (state, action) => adapter.upsertOne(action.coramPetition, state)
  ),
  on(CoramPetitionActions.addCoramPetitions,
    (state, action) => adapter.addMany(action.coramPetitions, state)
  ),
  on(CoramPetitionActions.upsertCoramPetitions,
    (state, action) => adapter.upsertMany(action.coramPetitions, state)
  ),
  on(CoramPetitionActions.updateCoramPetition,
    (state, action) => adapter.updateOne(action.coramPetition, state)
  ),
  on(CoramPetitionActions.updateCoramPetitions,
    (state, action) => adapter.updateMany(action.coramPetitions, state)
  ),
  on(CoramPetitionActions.deleteCoramPetition,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(CoramPetitionActions.deleteCoramPetitions,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(CoramPetitionActions.loadCoramPetition,
    (state, action) => adapter.setOne(action.coramPetition, state)
  ),
  on(CoramPetitionActions.loadCoramPetitions,
    (state, action) => adapter.setAll(action.coramPetitions, state)
  ),
  on(CoramPetitionActions.clearCoramPetitions,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
