<header class="header rs-nav">
    <div class="top-bar">
        <div class="container">
            <div class="row d-flex align-items-center justify-content-between">
                <div class="topbar-left">
                    <ul>
                        <li><i class="ik ik-phone"></i>+255739303038</li>
                        <li><i class="ik ik-mail"></i>rhc@judiciary.go.tz</li>
                    </ul>
                </div>
                <div class="topbar-right">
                    <ul>
                        <li matTooltip="Switch to English/ Badilisha kwenda Kingereza" *ngIf="lang === 'sw'" matRipple (click)="switchLang('en')" class="lang cursor-pointer">
                             <span><mat-icon mat-list-icon svgIcon="uk-flag"></mat-icon>EN</span></li>
                        <li matTooltip="Switch to Swahili/ Badilisha kwenda Kiswahili" *ngIf="lang === 'en'" matRipple (click)="switchLang('sw')" class="lang cursor-pointer">
                             <span><mat-icon mat-list-icon svgIcon="tz-flag"></mat-icon>SW</span></li>
                        <li class="lang cursor-pointer" routerLink="/login" style="color: #fff;"><span><mat-icon>lock</mat-icon> {{'public.login' | translate}}</span></li>
                    </ul>
<!--                    <ul>
                        <li><a routerLink="/login" style="color: #fff;"><i class="ik ik-flag"></i> En</a></li>
                        <li><a routerLink="/login" style="color: #fff;"><i class="ik ik-lock"></i> Login</a></li>
                    </ul>-->
                </div>
            </div>
        </div>
    </div>
    <div class="sticky-header navbar-expand-lg">
        <div class="menu-bar clearfix">
            <div class="container clearfix">
                <!-- Header Logo ==== -->
                <div class="menu-logo">
                    <a href="index.html"><img src="assets/images/Judiciary-Logo.png" style="height: auto!important;" alt=""></a>
                </div>
                <!-- Mobile Nav Button ==== -->
                <button class="navbar-toggler collapsed menuicon justify-content-end" type="button"
                        data-toggle="collapse" data-target="#menuDropdown" aria-controls="menuDropdown"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <!-- Author Nav ==== -->
                <div class="secondary-menu">
                    <div class="secondary-inner">
                        <ul>
                            <li><a href="javascript:;" class="btn-link"><i class="ik ik-facebook"></i></a></li>
                            <li><a href="javascript:;" class="btn-link"><i class="ik ik-linkedin"></i></a></li>
                            <!-- Search Button ==== -->
                            <li class="search-btn"><a href="#aboutModal" data-toggle="modal" data-target="#myModal">
                                <button id="quik-search-btn" type="button" class="btn-link"><i class="ik ik-search"></i>
                                </button>
                            </a></li>
                        </ul>
                    </div>
                </div>
                <!-- Modal -->
                <!-- <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-body">
                                <center><br/>
                                <img src="{{ URL::to('images/profile_pict_1628778312.png') }}" name="aboutme" width="150" height="150" border="0" class="img-circle"></a><br/>
                                <h3 class="media-heading">Athumani Juma</h3>
                                <span><strong>Roll Number: </strong></span>
                                    <span class="btn btn-danger">NIL</span>
                                </center>
                                <hr>
                                <center>
                                <p class="text-center"><strong>Practicing History: </strong><br>

                                <br>
                                </center>
                            </div>
                            <div class="modal-footer">
                                <center>
                                <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                                </center>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- Navigation Menu ==== -->
                <div class="menu-links navbar-collapse collapse justify-content-start" id="menuDropdown">
                    <div class="menu-logo">
                        <a href="#"><img src="assets/images/Judiciary-Logo.png" style="width: 60px; height: auto !important;" alt=""></a>
                    </div>
                    <ul class="nav navbar-nav">
                        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a href="javascript:;" routerLink="/ewakili/home">{{'navigation.home' | translate}} </a>
                        </li>
                        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a href="javascript:;">{{'navigation.about' | translate}} <i class="ik ik-chevron-down ik-2x"></i></a>
                            <ul class="sub-menu">
                                <li><a routerLink="/ewakili/about">{{'navigation.about-ewakili' | translate}}</a></li>
                            </ul>
                        </li>
                        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a href="javascript:;">{{'navigation.guide' | translate}} <i class="ik ik-chevron-down ik-2x"></i></a>
                            <ul class="sub-menu">
                                <li><a routerLink="/ewakili/guide">{{'navigation.user-guide' | translate}}</a></li>
                            </ul>
                        </li>
                        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/ewakili/contact" href="javascript:;">{{'navigation.contact' | translate}}</a>
                        </li>
                        <!-- <li><a href="javascript:;">Applications <i class="ik ik-chevron-down ik-2x"></i></a>
                            <ul class="sub-menu">
                                <li><a href="javascript:;">Temporary Admission</a></li>
                            </ul>
                        </li> -->
                        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a href="javascript:;">{{'navigation.e-service' | translate}} <i class="ik ik-chevron-down ik-2x"></i></a>
                            <ul class="sub-menu">
                                <li><a target="_blank" href="https://jsds.judiciary.go.tz/">{{'navigation.case-management' | translate}} </a></li>
                                <li><a href="#">{{'navigation.court-broker' | translate}}</a></li>
                                <!-- <li><a href="#">{{'navigation.portal' | translate}}</a></li> -->
                                <li><a target="_blank" href="https://jmap.judiciary.go.tz/">{{'navigation.court-mapping' | translate}}</a></li>
                                <li><a target="_blank" href="https://elibrary.judiciary.go.tz/">{{'navigation.e-library' | translate}}</a></li>
                            </ul>
                        </li>


                    </ul>
                    <div class="nav-social-link">
                        <a href="javascript:;"><i class="ik ik-facebook"></i></a>
                        <a href="javascript:;"><i class="ik ik-linkedin"></i></a>
                    </div>
                </div>
                <!-- Navigation Menu END ==== -->
            </div>
        </div>
    </div>
</header>
