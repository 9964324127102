<div class="content-fluid">
    <form (ngSubmit)="saveChangePassword(changePasswordForm.value)" [formGroup]="changePasswordForm" autocomplete="off"
          novalidate>

        <app-modal-header [title]="title" (closeModal)="closeThisModal($event)"></app-modal-header>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="outline" class="material-form">
                    <mat-label>Old password</mat-label>
                    <input type="password" formControlName="oldPassword" matInput placeholder="old password">
                    <mat-error>
            <span *ngIf="!changePasswordForm.get('oldPassword').valid && changePasswordForm.get('oldPassword').touched">
              Old password is required
            </span></mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="outline" class="material-form">
                    <mat-label>New password</mat-label>
                    <input type="password" formControlName="newPassword" matInput placeholder="New password">
                    <mat-error>
            <span *ngIf="!changePasswordForm.get('newPassword').valid && changePasswordForm.get('newPassword').touched && changePasswordForm.get('newPassword').errors.required">
              New password is required
            </span></mat-error>
                    <mat-error>
            <span *ngIf="changePasswordForm.get('newPassword').dirty && changePasswordForm.get('newPassword').errors?.hasNumber">
            {{'Password must have at least one Number'}}
          </span></mat-error>
                    <mat-error>
            <span *ngIf="changePasswordForm.get('newPassword').dirty && changePasswordForm.get('newPassword').errors?.hasCapitalCase">
            {{'Password must have at least one Capital Case'}}
          </span></mat-error>
                    <mat-error>
                        <div *ngIf="changePasswordForm.get('newPassword').dirty && changePasswordForm.get('newPassword').errors?.hasSmallCase">            {{ 'Password must have at least one Small Case'}}        </div>
                    </mat-error>
                    <mat-error>
            <span *ngIf="changePasswordForm.get('newPassword').dirty && changePasswordForm.get('newPassword').errors?.hasSpecialCharacters">
            {{'Password must have at least one Special Character'}}
          </span></mat-error>
                    <mat-error>
            <span *ngIf="changePasswordForm.get('newPassword').dirty && changePasswordForm.get('newPassword').errors?.minlength">
              {{'Password must have 6 or more character'}}
            </span></mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="outline" class="material-form">
                    <mat-label>Confirm new password</mat-label>
                    <input type="password" formControlName="comfirmPassword" matInput
                           placeholder="Confirm new password">
                    <mat-error>
            <span *ngIf="!changePasswordForm.get('comfirmPassword').valid && changePasswordForm.get('comfirmPassword').touched">
              New password confirmation is required
            </span></mat-error>
                </mat-form-field>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12">
                <div class="text-right">
                    <button [disabled]="!changePasswordForm.valid" color="primary" mat-raised-button type="submit">
                        save
                    </button>
                </div>
            </div>
        </div>

    </form>
</div>
