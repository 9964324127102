import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { UserActionDecision } from './user-action-decision.model';

export const loadUserActionDecisions = createAction(
  '[UserActionDecision/API] Load UserActionDecisions',
  props<{ userActionDecisions: UserActionDecision[] }>()
);

export const addUserActionDecision = createAction(
  '[UserActionDecision/API] Add UserActionDecision',
  props<{ userActionDecision: UserActionDecision }>()
);

export const upsertUserActionDecision = createAction(
  '[UserActionDecision/API] Upsert UserActionDecision',
  props<{ userActionDecision: UserActionDecision }>()
);

export const addUserActionDecisions = createAction(
  '[UserActionDecision/API] Add UserActionDecisions',
  props<{ userActionDecisions: UserActionDecision[] }>()
);

export const upsertUserActionDecisions = createAction(
  '[UserActionDecision/API] Upsert UserActionDecisions',
  props<{ userActionDecisions: UserActionDecision[] }>()
);

export const updateUserActionDecision = createAction(
  '[UserActionDecision/API] Update UserActionDecision',
  props<{ userActionDecision: Update<UserActionDecision> }>()
);

export const updateUserActionDecisions = createAction(
  '[UserActionDecision/API] Update UserActionDecisions',
  props<{ userActionDecisions: Update<UserActionDecision>[] }>()
);

export const deleteUserActionDecision = createAction(
  '[UserActionDecision/API] Delete UserActionDecision',
  props<{ id: string }>()
);

export const deleteUserActionDecisions = createAction(
  '[UserActionDecision/API] Delete UserActionDecisions',
  props<{ ids: string[] }>()
);

export const clearUserActionDecisions = createAction(
  '[UserActionDecision/API] Clear UserActionDecisions'
);


// effect actions

export const fetchCurrentActionUserDecisions = createAction(
    '[UserActionDecision/API] fetch current User actions from the server'
);
