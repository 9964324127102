import {Component, ContentChild, OnInit, TemplateRef} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from "../../../../services/auth/auth.service";
import {Subscription} from "rxjs";
import {HttpClient} from "@angular/common/http";
import { SettingsService } from 'src/app/services/settings/settings.service';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {

  currentYear: any;
  staffLoginForm: FormGroup;
  loading = false;
  loginView = false;
  @ContentChild(TemplateRef, {static: true}) AuthContent: TemplateRef<any>;
  lang: string;

  subscription: Subscription = new Subscription();
  resetPasswordForm = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
  });

  constructor(
    private fb: FormBuilder,
    private route: Router,
    private auth: AuthService,
    private http: HttpClient,
    private settingsService: SettingsService,
  ) {
    if (this.auth.alreadyLoggedIn()) {
      this.route.navigate(['/documents']);
    }
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.staffLoginForm = this.fb.group({
      username: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.min(6)]]
    });
    this.lang = this.settingsService.getCurrentLang();
  }

  async login() {
    this.loading = true;
    try {
      await this.auth.login(this.staffLoginForm.value);
      return this.auth.authRole();
    } catch (e) {
    }
    this.loading = false;
  }

  getSliders() {
    let imagesArray = [];
    // this.http.get('http://admin.eganet.go.tz/api/sliders/default').subscribe((images) => {
    //     imagesArray = images._data;
    // });
    // return imagesArray;
  }

  changeView() {
    this.loginView = !this.loginView;
  }
  switchLang(language: string) {
    this.settingsService.switchLang(language);
    this.lang = language;
}


  resetPassword(formData: any) {
    this.auth.openSnackbar('This Operation is Under Maintenance, Pleas contact helpdesk@ega.go.tz for Help');

    // this.auth.tempToken();

    // if (localStorage.getItem('tempClient')) {
    //   this.subscription.add(
    //     this.apollo.mutate({
    //       mutation: RESET_PASSWORD,
    //       variables: {
    //         email: formData.email
    //       }
    //     }).subscribe(({data}: any) => {
    //       if (data.resetPassword.code === 9000) {
    //         this.store.dispatch(logoutAction());
    //         this.loginView = false;
    //         this.auth.openSnackbar('Password Sent To Your E-mail');
    //       }
    //     })
    //   );
    // }
  }
}
