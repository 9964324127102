import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../../services/auth/auth.service';
import {NotificationService} from "../../../../services/notifications/notification.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../store/reducers";
import {resendPasswordResetMail} from "../../../../store/entities/settings/user/user.actions";

@Component({
    selector: 'app-should-reset-password',
    templateUrl: './should-reset-password.component.html',
    styleUrls: ['./should-reset-password.component.scss']
})
export class ShouldResetPasswordComponent implements OnInit {

    constructor(
        private auth: AuthService,
        private notificationService: NotificationService,
        private store: Store<AppState>
    ) {
    }

    ngOnInit(): void {
    }

    login($event: MouseEvent) {
        $event.preventDefault();
        return this.auth.logout('Login as a different user');
    }

    resendMail($event) {
        $event.preventDefault();
        this.notificationService.confirmation(
            'Confirm that you want to re-send password reset mail'
        ).then(
            res => {
                if (res.value) {
                    this.store.dispatch(resendPasswordResetMail());
                }
            }
        );
    }
}
