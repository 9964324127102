import {Component, Input, OnInit} from '@angular/core';
import {Bill} from '../../../store/entities/bills/bill/bill.model';
import {Observable} from 'rxjs';
import {AuthUser} from '../../../store/entities/auth-user/auth-user.model';
import {selectLoggedInUser} from '../../../store/entities/auth-user/auth-user.selector';
import {Store} from '@ngrx/store';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-bill-details',
    templateUrl: './bill-details.component.html',
    styleUrls: ['./bill-details.component.scss']
})
export class BillDetailsComponent implements OnInit {

    @Input() bill: Bill;
    today = new Date();
    qrCodeData: string;
    currentYear = new Date().getFullYear();

    loggedInUser$: Observable<AuthUser>;

    constructor(
        private store: Store
    ) {
    }

    ngOnInit(): void {
        this.loggedInUser$ = this.store.select(selectLoggedInUser);
        const dueDate = new DatePipe('en-GB').transform(this.bill?.dueDate, 'YYYY-MM-dd');
        this.qrCodeData = `{
            "opType": "2",
            "shortCode": "001001",
            "billReference": "${this.bill?.controlNumber}",
            "amount": "${this.bill?.totalAmount}",
            "billCcy": "${this.bill?.currency?.toUpperCase()}",
            "billExprDt": "${dueDate}",
            "billPayOpt": "2",
            "billRsv01": "Tanzania Judiciary"
        }`;
    }

}
