import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { AppNotification } from './app-notification.model';
import * as AppNotificationActions from './app-notification.actions';

export const appNotificationsFeatureKey = 'appNotifications';

export interface State extends EntityState<AppNotification> {
  // additional entities state properties
}

export const adapter: EntityAdapter<AppNotification> = createEntityAdapter<AppNotification>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(AppNotificationActions.addAppNotification,
    (state, action) => adapter.addOne(action.appNotification, state)
  ),
  on(AppNotificationActions.upsertAppNotification,
    (state, action) => adapter.upsertOne(action.appNotification, state)
  ),
  on(AppNotificationActions.addAppNotifications,
    (state, action) => adapter.addMany(action.appNotifications, state)
  ),
  on(AppNotificationActions.upsertAppNotifications,
    (state, action) => adapter.upsertMany(action.appNotifications, state)
  ),
  on(AppNotificationActions.updateAppNotification,
    (state, action) => adapter.updateOne(action.appNotification, state)
  ),
  on(AppNotificationActions.updateAppNotifications,
    (state, action) => adapter.updateMany(action.appNotifications, state)
  ),
  on(AppNotificationActions.deleteAppNotification,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(AppNotificationActions.deleteAppNotifications,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(AppNotificationActions.loadAppNotifications,
    (state, action) => adapter.setAll(action.appNotifications, state)
  ),
  on(AppNotificationActions.clearAppNotifications,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
