import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {District} from './district.model';

export const loadDistricts = createAction(
    '[District/API] Load Districts',
    props<{ districts: District[] }>()
);

export const addDistrict = createAction(
    '[District/API] Add District',
    props<{ district: District }>()
);

export const upsertDistrict = createAction(
    '[District/API] Upsert District',
    props<{ district: District }>()
);

export const addDistricts = createAction(
    '[District/API] Add Districts',
    props<{ districts: District[] }>()
);

export const upsertDistricts = createAction(
    '[District/API] Upsert Districts',
    props<{ districts: District[] }>()
);

export const updateDistrict = createAction(
    '[District/API] Update District',
    props<{ district: Update<District> }>()
);

export const updateDistricts = createAction(
    '[District/API] Update Districts',
    props<{ districts: Update<District>[] }>()
);

export const deleteDistrict = createAction(
    '[District/API] Delete District',
    props<{ id: string }>()
);

export const deleteDistricts = createAction(
    '[District/API] Delete Districts',
    props<{ ids: string[] }>()
);

export const clearDistricts = createAction(
    '[District/API] Clear Districts'
);

export const getAllDistricts = createAction(
    '[District/API] Get All Districts'
);

