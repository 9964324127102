import {FirmGrapqhlFields} from '../firm/firm.grapqhl';
import gql from 'graphql-tag';

export const FirmMemberGraphqlFields = `
    id
    uid
    active
    approvalStatus
    confirmCode
    createdAt
    createdBy{
        id
        fullName
    }
    deletedAt
    disputed
    firm{
        ${FirmGrapqhlFields}
    }
    firmBranches{
        id
        uid
        approvalStatus
        box
        branchName
        city
        code
        createdAt
        createdBy{
            id
        }
        district{
            id
            name
        }
        email
        phone
        postcode
        street
        region{
            id
            name
        }
        ward
    }
    profile{
        id
        user{
            id
        }
    }
    since
    till
    updatedAt
`;

export const GET_USER_FIRM_MEMBER = gql`
    query getUserFirm{
        getUserFirm{
            ${FirmMemberGraphqlFields}
        }
    }
`;

export const CONFIRM_FIRM = gql`
    query confirmFirm($code:String,$firmMemberUid:String){
        confirmFirm(code:$code,firmMemberUid:$firmMemberUid){
            data{
                ${FirmMemberGraphqlFields}
            }
            errorDescription
            status
            code
        }
    }
`;

export const LEAVE_FIRM = gql`
    query leaveFirm($firmMemberUid:String){
        leaveFirm(firmMemberUid:$firmMemberUid){
            data{
                ${FirmMemberGraphqlFields}
            }
            errorDescription
            status
            code
        }
    }
`;





