import {UserEffects} from '../entities/settings/user/user.effects';
import {RoleEffects} from '../entities/settings/role/role.effects';
import {WorkFlowStageEffects} from '../entities/settings/work-flow-stage/work-flow-stage.effects';
import {AuthUserEffects} from '../entities/auth-user/auth-user.effects';
import {AdvocateProfileEffects} from '../entities/advocate/advocate-profile/advocate-profile.effects';
import {CountryEffects} from '../entities/settings/country/country.effects';
import {PermissionGroupEffects} from '../entities/settings/permission-group/permission-group.effects';
import {QualificationEffects} from '../entities/settings/qualification/qualification.effects';
import {AttachmentTypeEffects} from '../entities/settings/attachment-type/attachment-type.effects';
import {WorkExperienceEffects} from '../entities/advocate/work-experience/work-experience.effects';
import {PetitionEducationEffects} from '../entities/advocate/petition-education/petition-education.effects';
import {RegionEffects} from '../entities/settings/region/region.effects';
import {DistrictEffects} from '../entities/settings/district/district.effects';
import {ProfileAddressEffects} from '../entities/advocate/profile-address/profile-address.effects';
import {ApplicationDocumentEffects} from '../entities/advocate/application-document/application-document.effects';
import {FirmEffects} from '../entities/advocate/firm/firm.effects';
import {ProfileContactEffects} from '../entities/advocate/profile-contact/profile-contact.effects';
import {ActionUserTypeEffects} from '../entities/settings/action-user-type/action-user-type.effects';
import {ApplicationEffects} from '../entities/management/application/application.effects';
import {FirmBranchEffects} from '../entities/advocate/firm-branch/firm-branch.effects';
import {FirmMemberEffects} from '../entities/advocate/firm-member/firm-member.effects';
import {PetitionSessionEffects} from '../entities/settings/petition-session/petition-session.effects';
import {UserActionDecisionEffects} from '../entities/management/user-action-decision/user-action-decision.effects';
import {AdvocateEffects} from '../entities/advocate/advocate/advocate.effects';
import {CleMemberEffects} from '../entities/settings/cle-member/cle-member.effects';
import {CleCoramEffects} from '../entities/management/cle-coram/cle-coram.effects';
import {CoramPetitionEffects} from '../entities/management/coram-petition/coram-petition.effects';
import {PetitionEffects} from '../entities/management/petition/petition.effects';
import {AppearanceVenueEffects} from '../entities/settings/appearance-venue/appearance-venue.effects';
import {BarExamEffects} from '../entities/management/bar-exam/bar-exam.effects';
import {FeeTypeEffects} from '../entities/settings/fee-type/fee-type.effects';
import {FeeEffects} from '../entities/settings/fee/fee.effects';
import {AppearanceEffects} from '../entities/management/appearance/appearance.effects';
import {RenewalBatchEffects} from '../entities/settings/renewal-batch/renewal-batch.effects';
import {BillEffects} from '../entities/bills/bill/bill.effects';
import {PaymentEffects} from '../entities/bills/payment/payment.effects';
import {ReconciliationBatchEffects} from '../entities/bills/reconciliation-batch/reconciliation-batch.effects';
import {ReconciliationEffects} from '../entities/bills/reconciliation/reconciliation.effects';
import {LegalProfessionalViewEffects} from '../entities/management/legal-professional-view/legal-professional-view.effects';
import {AppNotificationEffects} from '../entities/management/app-notification/app-notification.effects';
import {GroupBillEffects} from '../entities/bills/group-bill/group-bill.effects';
import {SignatureEffects} from '../entities/settings/signature/signature.effects';

import {
    AdvocateChangeStatusEffects
} from '../entities/management/advocate-change-status/advocate-change-status.effects';

export const effects = [
    AuthUserEffects,
    UserEffects,
    RoleEffects,
    WorkFlowStageEffects,
    AdvocateProfileEffects,
    CountryEffects,
    PermissionGroupEffects,
    PermissionGroupEffects,
    QualificationEffects,
    AttachmentTypeEffects,
    WorkExperienceEffects,
    PetitionEducationEffects,
    RegionEffects,
    DistrictEffects,
    ProfileAddressEffects,
    ApplicationDocumentEffects,
    FirmEffects,
    FirmBranchEffects,
    ProfileContactEffects,
    ActionUserTypeEffects,
    ApplicationEffects,
    FirmMemberEffects,
    PetitionSessionEffects,
    UserActionDecisionEffects,
    AdvocateEffects,
    CleMemberEffects,
    CleCoramEffects,
    CoramPetitionEffects,
    PetitionEffects,
    AppearanceVenueEffects,
    BarExamEffects,
    FeeTypeEffects,
    FeeEffects,
    AppearanceEffects,
    RenewalBatchEffects,
    BillEffects,
    PaymentEffects,
    ReconciliationBatchEffects,
    ReconciliationEffects,
    LegalProfessionalViewEffects,
    AppNotificationEffects,
    AdvocateChangeStatusEffects,
    GroupBillEffects,
    SignatureEffects,
];
