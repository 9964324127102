import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {map, switchMap} from 'rxjs/operators';
import {ResponseCode} from '../../../../shared/enums/http-status-codes.enum';
import {approveFirm, getFirmByUid, refreshFirmsTable, saveFirm, upsertFirm} from './firm.actions';
import {APPROVE_FIRM, GET_FIRM_BY_UID, SAVE_FIRM} from './firm.grapqhl';
import {Firm} from './firm.model';
import {getUserFirmMember} from '../firm-member/firm-member.actions';
import {refreshSubmitButton} from '../advocate-profile/advocate-profile.actions';


@Injectable()
export class FirmEffects {

    saveFirm$ = createEffect(() => this.actions$.pipe(
            ofType(saveFirm),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: SAVE_FIRM,
                    variables: {
                        firm: action.firm
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.saveFirm?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(refreshSubmitButton());
                            this.store.dispatch(upsertFirm({firm: data.saveFirm.data as Firm}));
                            this.store.dispatch(getUserFirmMember());
                            return this.notificationService.successMessage('Saved Successfully');
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.saveFirm);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    approveFirm$ = createEffect(() => this.actions$.pipe(
            ofType(approveFirm),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: APPROVE_FIRM,
                    variables: {
                        firmApproveDto: action.firmApproval
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.approveFirm?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(refreshSubmitButton());
                            this.store.dispatch(upsertFirm({firm: data.approveFirm.data as Firm}));
                            this.store.dispatch(refreshFirmsTable());
                            return this.notificationService.successMessage('Firm Approval Status Updated Successfully');
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.approveFirm);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    getFirmByUid$ = createEffect(() => this.actions$.pipe(
            ofType(getFirmByUid),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: GET_FIRM_BY_UID,
                    variables: {
                        uid: action.firmUid
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.getFirmById?.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(upsertFirm({firm: data?.getFirmById?.data}));
                                // return this.notificationService.successMessage('Saved successfully');
                            } else {
                                return this.responseCodesService.errorMessageByCode(data.getFirmById);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService
    ) {
    }

}
