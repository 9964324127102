import gql from 'graphql-tag';


export const ASSIGN_ATTACHMENT_TYPES_TO_WORKFLOW_PROCESS = gql`
    mutation assignWorkflowAttachment($attachment:AssignWorkflowAttachmentDtoInput){
        assignWorkflowAttachment(attachment:$attachment){
            code
            errorDescription
            status
        }
    }
`;

