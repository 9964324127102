import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Bill } from './bill.model';
import * as BillActions from './bill.actions';

export const billsFeatureKey = 'bills';

export interface State extends EntityState<Bill> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Bill> = createEntityAdapter<Bill>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(BillActions.addBill,
    (state, action) => adapter.addOne(action.bill, state)
  ),
  on(BillActions.upsertBill,
    (state, action) => adapter.upsertOne(action.bill, state)
  ),
  on(BillActions.addBills,
    (state, action) => adapter.addMany(action.bills, state)
  ),
  on(BillActions.upsertBills,
    (state, action) => adapter.upsertMany(action.bills, state)
  ),
  on(BillActions.updateBill,
    (state, action) => adapter.updateOne(action.bill, state)
  ),
  on(BillActions.updateBills,
    (state, action) => adapter.updateMany(action.bills, state)
  ),
  on(BillActions.deleteBill,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(BillActions.deleteBills,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(BillActions.loadBills,
    (state, action) => adapter.setAll(action.bills, state)
  ),
  on(BillActions.clearBills,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
