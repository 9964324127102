import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {fetchAllCountries, upsertCountrys} from './country.actions';
import {LIST_ALL_COUNTRIES} from './country.graphql';
import {Apollo} from 'apollo-angular';
import {AuthService} from '../../../../services/auth/auth.service';
import {Store} from '@ngrx/store';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {Country} from './country.model';


@Injectable()
export class CountryEffects {

    allCountries$ = createEffect(() => this.actions$.pipe(
        ofType(fetchAllCountries),
        switchMap((action) => {
            return this.apollo.query({
                query: LIST_ALL_COUNTRIES,
                fetchPolicy: 'network-only'
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        this.store.dispatch(upsertCountrys({countrys: Object.values(data)[0] as Country[]}));
                    }
                })
            );
        })
    ), {dispatch: false});


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService,
        private auth: AuthService,
    ) {
    }

}

