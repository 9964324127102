<app-modal-header (closeModal)="closeModal($event)" [title]="title"></app-modal-header>

<ng-container *ngIf="attachment$ | async as attachment, else progressBar">
    <ng-container *ngIf="applicationDocument?.filename?.includes('.pdf') && attachment">
        <ngx-extended-pdf-viewer [base64Src]="attachment"
                                 [showBookmarkButton]="false"
                                 [showDownloadButton]="true"
                                 [showFindButton]="true"
                                 [showHandToolButton]="true"
                                 [showOpenFileButton]="false"
                                 [showPresentationModeButton]="true"
                                 [textLayer]="true"
                                 height="80vh" useBrowserLocale="true">
        </ngx-extended-pdf-viewer>
    </ng-container>

    <ng-container *ngIf="applicationDocument?.filename?.includes('.png' || '.jpg' || '.jpeg') && attachment">
        <div style="width: 40%; margin: 0 auto">
            <img src="data:image/png;base64,{{attachment}}" alt="{{applicationDocument?.filename}}" class="w-100">
        </div>
    </ng-container>
</ng-container>

<ng-template #progressBar>
    <div class="col-12 text-center px-0">
        <mat-progress-bar color="warn" mode="indeterminate"></mat-progress-bar>
        <i>Please wait...</i>
    </div>
</ng-template>

