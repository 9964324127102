import gql from 'graphql-tag';

export const countryGqlFields = `
    id
    capital
    code
    currency
    phoneCode
    name
    phoneCode
    updatedAt
    createdAt
`;

export const LIST_ALL_COUNTRIES = gql`
    query getAllCountries{
        getAllCountry{
            ${countryGqlFields}
        }
    }
`;
