import gql from 'graphql-tag';

export  const LIST_PERMISSION_GROUP_FOR_COMPANY = gql`
  query listPermissionGroups{
    listPermissionGroups{
      code
      dataList{
        id
        groupName
        permissions{
          id
          displayName
        }
      }
      status
      errorDescription
    }
  }
`;
