import gql from 'graphql-tag';

export const FirmGrapqhlFields = `
    id
    uid
    active
    approval
    approvalStatus
    createdAt
    createdBy{
        id
        fullName
    }
    deletedAt
    firmBranches{
        id
        uid
        branchName
    }
    firmModel
    firmType
    members
    name
    profile{
        id
    }
    taxPayerName
    tinNumber
    updatedAt
    memberList{
        id
        active
        approvalStatus
        confirmCode
        createdAt
        createdBy{
            id
            uid
            fullName
        }
        deletedAt
        disputed
        profile{
            id
            fullname
            gender
        }
        since
        till
        uid
        updatedAt
    }
`;

export const SAVE_FIRM = gql`
    mutation saveFirm($firm:FirmDtoInput){
        saveFirm(firmDto:$firm){
            data{
                ${FirmGrapqhlFields}
            }
            errorDescription
            status
            code
        }
    }
`;

export const GET_FIRMS_BY_STATUS_DT = gql`
    query getFirmByStatusDt($dtInput:DataTablesInputInput,$firmApprovalStatus:FirmApprovalStatus){
        getFirmByStatusDt(dtInput:$dtInput,firmApprovalStatus:$firmApprovalStatus){
            data{
                ${FirmGrapqhlFields}
            }
            draw
            recordsTotal
            recordsFiltered
        }
    }
`;

export const APPROVE_FIRM = gql`
    mutation approveFirm($firmApproveDto:FirmApproveDtoInput){
        approveFirm(firmApproveDto:$firmApproveDto){
            data{
                ${FirmGrapqhlFields}
            }
            errorDescription
            status
            code
        }
    }
`;

export const GET_FIRM_BY_UID = gql`
    query getFirmById($uid: String){
        getFirmById(uid:$uid){
            data{
                ${FirmGrapqhlFields}
            }
            errorDescription
            status
            code
        }
    }
`;




