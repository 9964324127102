import {Component, OnInit} from '@angular/core';
import {SettingsService} from '../../../../../services/settings/settings.service';

@Component({
    selector: 'app-public-layout-header',
    templateUrl: './public-layout-header.component.html',
    styleUrls: ['./public-layout-header.component.scss']
})
export class PublicLayoutHeaderComponent implements OnInit {

    lang: string;

    constructor(
        private settingsService: SettingsService,
    ) {
    }

    ngOnInit(): void {
        this.lang = this.settingsService.getCurrentLang();
    }

    switchLang(language: string) {
        this.settingsService.switchLang(language);
        this.lang = language;
    }

}
