import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Fee } from './fee.model';
import * as FeeActions from './fee.actions';

export const feesFeatureKey = 'fees';

export interface State extends EntityState<Fee> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Fee> = createEntityAdapter<Fee>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(FeeActions.addFee,
    (state, action) => adapter.addOne(action.fee, state)
  ),
  on(FeeActions.upsertFee,
    (state, action) => adapter.upsertOne(action.fee, state)
  ),
  on(FeeActions.addFees,
    (state, action) => adapter.addMany(action.fees, state)
  ),
  on(FeeActions.upsertFees,
    (state, action) => adapter.upsertMany(action.fees, state)
  ),
  on(FeeActions.updateFee,
    (state, action) => adapter.updateOne(action.fee, state)
  ),
  on(FeeActions.updateFees,
    (state, action) => adapter.updateMany(action.fees, state)
  ),
  on(FeeActions.deleteFee,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(FeeActions.deleteFees,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(FeeActions.loadFees,
    (state, action) => adapter.setAll(action.fees, state)
  ),
  on(FeeActions.clearFees,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
