<app-auth-layout>
    <ng-template #AuthContent>
        <ng-container>
            <div class="auth-wrapper">
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-xl-4 col-lg-6 col-md-7 my-auto p-0">
                            <div class="authentication-form mx-auto">
                                <div class="logo-centered">
                                    <img src="assets/images/Judiciary-Logo.png" style="height:60px; width: 60px;"
                                         alt="logo icon">
                                </div>
                                <h3 class="text-center">Password Reset Required!</h3>

                                <div class="alert alert-info" role="alert">
                                    You should reset your password in order to continue accessing your account. <br><br>Follow
                                    the link that we have sent to your e-mail. Thanks
                                </div>

                                <div class="register">
                                    <p>Didn't receive email? &nbsp;&nbsp;<i class="fa fa-recycle"></i>&nbsp; <a href="#" (click)="resendMail($event)">Resend Mail</a></p>
                                </div>
                                <div class="register">
                                    <p>or <a href="#" (click)="login($event)">Login as a different user</a> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-template>
</app-auth-layout>

