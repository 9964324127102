import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {editCleMember, fetchAllCleMembers, upsertCleMember, upsertCleMembers} from './cle-member.actions';
import {LIST_ALL_CLE_MEMBERS, UPDATE_CLE_MEMBER} from './cle-member.graphql';
import {CleMember} from './cle-member.model';
import {ResponseCode} from '../../../../shared/enums/http-status-codes.enum';


@Injectable()
export class CleMemberEffects {

    allCleMembers$ = createEffect(() => this.actions$.pipe(
        ofType(fetchAllCleMembers),
        switchMap((action) => {
            return this.apollo.query({
                query: LIST_ALL_CLE_MEMBERS,
                fetchPolicy: 'network-only'
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        this.store.dispatch(upsertCleMembers({cleMembers: Object.values(data)[0] as CleMember[]}));
                    }
                })
            );
        })
    ), {dispatch: false});

    editCleMember$ = createEffect(() => this.actions$.pipe(
            ofType(editCleMember),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: UPDATE_CLE_MEMBER,
                    variables: {
                        uid: action.uid,
                        member: action.cleMember
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.updateCleMember?.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(upsertCleMember({cleMember: data?.updateCleMember?.data}));
                                return this.notificationService.successMessage('Updated successfully');
                            } else {
                                return this.responseCodesService.errorMessageByMessage(data.updateCleMember);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService,
    ) {
    }

}
