import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../../services/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class ShouldResetPasswordGuard implements CanActivate {

    constructor(
        private auth: AuthService,
        private router: Router
    ) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.navigateToResetPassword();
    }

    async navigateToResetPassword() {
        this.auth.authRole();
        const srp = JSON.parse(localStorage.getItem('srp') ) as boolean;
        return srp ? this.router.navigate(['', 'should-password-reset']) : true;
    }
}
