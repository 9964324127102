import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { FirmMember } from './firm-member.model';
import * as FirmMemberActions from './firm-member.actions';

export const firmMembersFeatureKey = 'firmMembers';

export interface State extends EntityState<FirmMember> {
  // additional entities state properties
}

export const adapter: EntityAdapter<FirmMember> = createEntityAdapter<FirmMember>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(FirmMemberActions.addFirmMember,
    (state, action) => adapter.addOne(action.firmMember, state)
  ),
  on(FirmMemberActions.upsertFirmMember,
    (state, action) => adapter.upsertOne(action.firmMember, state)
  ),
  on(FirmMemberActions.addFirmMembers,
    (state, action) => adapter.addMany(action.firmMembers, state)
  ),
  on(FirmMemberActions.upsertFirmMembers,
    (state, action) => adapter.upsertMany(action.firmMembers, state)
  ),
  on(FirmMemberActions.updateFirmMember,
    (state, action) => adapter.updateOne(action.firmMember, state)
  ),
  on(FirmMemberActions.updateFirmMembers,
    (state, action) => adapter.updateMany(action.firmMembers, state)
  ),
  on(FirmMemberActions.deleteFirmMember,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(FirmMemberActions.deleteFirmMembers,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(FirmMemberActions.loadFirmMembers,
    (state, action) => adapter.setAll(action.firmMembers, state)
  ),
  on(FirmMemberActions.clearFirmMembers,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
