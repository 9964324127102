import {Component, Input, OnInit} from '@angular/core';
import {ApplicationDocument} from '../../../../store/entities/advocate/application-document/application-document.model';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {
    ViewAttachmentComponent
} from '../../../../shared/components/other-views/view-attachment/view-attachment.component';

@Component({
    selector: 'app-application-attachments-list',
    templateUrl: './application-attachments-list.component.html',
    styleUrls: ['./application-attachments-list.component.scss']
})
export class ApplicationAttachmentsListComponent implements OnInit {

    @Input() applicationDocuments: ApplicationDocument[];
    @Input() applicationType: string;

    constructor(
        private dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
    }

    viewAttachment(document: ApplicationDocument) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '65%';
        dialogConfig.data = document;
        this.dialog.open(ViewAttachmentComponent, dialogConfig);
    }

}
