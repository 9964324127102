import gql from 'graphql-tag';

export const RenewalBatchGqlFields = `
    id
    uid
    active
    createdAt
    createdBy{
        id
    }
    deletedAt
    endDate
    firstDeadline
    firstPenalt
    secondDeadline
    secondPenalt
    startDate
    updatedAt
    year
`;

export const SAVE_RENEWAL_BATCH = gql`
    mutation saveRenewalBatch($renewalBatchDto:RenewalBatchDtoInput){
        saveRenewalBatch(renewalBatchDto:$renewalBatchDto){
            code
            data{
                ${RenewalBatchGqlFields}
            }
            errorDescription
            status
        }
    }
`;


export const UPDATE_RENEWAL_BATCH = gql`
    mutation updateRenewalBatch($renewalBatchDto:RenewalBatchDtoInput,$uid:String){
        updateRenewalBatch(renewalBatchDto:$renewalBatchDto,uid:$uid){
            code
            data{
                ${RenewalBatchGqlFields}
            }
            errorDescription
            status
        }
    }
`;


export const GET_RENEWAL_BATCH = gql`
    query getRenewalBatch{
        getRenewalBatch{
            ${RenewalBatchGqlFields}
        }
    }
`;




