import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatSliderModule} from '@angular/material/slider';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_FORMATS, MatNativeDateModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {MatTabsModule} from '@angular/material/tabs';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatStepperModule} from '@angular/material/stepper';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatListModule} from '@angular/material/list';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatBadgeModule} from '@angular/material/badge';
import {SvgIconsService} from '../../services/svg/svg-icons.service';
import {DomSanitizer} from '@angular/platform-browser';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {LayoutModule} from '@angular/cdk/layout';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatRadioModule} from '@angular/material/radio';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        MatSliderModule,
        CommonModule,
        MatSidenavModule,
        MatToolbarModule,
        MatDialogModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        MatTabsModule,
        MatProgressBarModule,
        MatStepperModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSnackBarModule,
        MatListModule,
        MatTooltipModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatBadgeModule,
        MatExpansionModule,
        MatGridListModule,
        MatCardModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        LayoutModule,
        MatSlideToggleModule,
        MatRadioModule,
        MatNativeDateModule
    ],
    exports: [
        MatSliderModule,
        CommonModule,
        MatSidenavModule,
        MatToolbarModule,
        MatDialogModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        MatTabsModule,
        MatProgressBarModule,
        MatStepperModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSnackBarModule,
        MatListModule,
        MatTooltipModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatBadgeModule,
        MatExpansionModule,
        MatGridListModule,
        MatCardModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        LayoutModule,
        MatSlideToggleModule,
        MatRadioModule,
        MatNativeDateModule
    ],
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: ['l', 'LL'],
                },
                display: {
                    dateInput: 'L',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
    ],
})
export class MaterialModule {
    constructor(
        private matIconRegistry: MatIconRegistry,
        private svgIconService: SvgIconsService,
        private domSanitizer: DomSanitizer
    ) {
        for (let i = 0; i < this.svgIconService.customerIcons.length; i++) {
            this.matIconRegistry.addSvgIconLiteral(
                this.svgIconService.customerIcons[i].name,
                this.domSanitizer.bypassSecurityTrustHtml(
                    this.svgIconService.customerIcons[i].tag
                )
            );
        }
    }
}
