import {LocationStrategy} from '@angular/common';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {AppState} from 'src/app/store/reducers';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {changePassword, changePasswordCompleted} from '../../../../store/entities/settings/user/user.actions';
import {Actions, ofType} from "@ngrx/effects";
import {Subscription} from "rxjs";
import {CustomValidators} from "../../../customs/custom-validators";

@Component({
    selector: 'app-change-password-form',
    templateUrl: './change-password-form.component.html',
    styleUrls: ['./change-password-form.component.scss']
})
export class ChangePasswordFormComponent implements OnInit, OnDestroy {

    changePasswordForm: FormGroup;
    title: any;
    passwordChanged: boolean;
    subscription = new Subscription();

    constructor(
        private fb: FormBuilder,
        private store: Store<AppState>,
        private dialogRef: MatDialogRef<ChangePasswordFormComponent>,
        private locationStrategy: LocationStrategy,
        private nService: NotificationService,
        @Inject(MAT_DIALOG_DATA) data,
        private actions$: Actions
    ) {
        this.passwordChanged = data;
    }

    ngOnInit() {
        this.title = 'Change password';
        this.initChangePasswordForm();
        this.listenToActions();
        this.preventBackButton();
    }

    initChangePasswordForm() {
        const specialCharacters = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
        this.changePasswordForm = this.fb.group({
            oldPassword: [null, Validators.required],
            newPassword: [null, [
                // 1. Password Field is Required
                Validators.required,
                // 2. check whether the entered password has a number
                CustomValidators.patternValidator(/\d/, {hasNumber: true}),
                // 3. check whether the entered password has upper case letter
                CustomValidators.patternValidator(/[A-Z]/, {hasCapitalCase: true}),
                // 4. check whether the entered password has a lower-case letter
                CustomValidators.patternValidator(/[a-z]/, {hasSmallCase: true}),
                // 5. check whether the entered password has a special character
                CustomValidators.patternValidator(specialCharacters, {hasSpecialCharacters: true}),
                // 6. Has a minimum length of 8 characters
                Validators.minLength(6)
            ]],
            comfirmPassword: [null, Validators.required]
        });
    }

    preventBackButton() {
        history.pushState(null, null, location.href);
        this.locationStrategy.onPopState(() => {
            history.pushState(null, null, location.href);
        });
    }

    saveChangePassword(value: any) {
        this.nService.confirmation('You are about to change your password, Continue', 'Yes').then(data => {
            if (data.value) {
                console.log('accepted');
                this.store.dispatch(changePassword({passwords: value}));
            }
        });
    }

    listenToActions() {
        this.subscription.add(
            this.actions$.pipe(ofType(changePasswordCompleted)).subscribe(res => {
                this.closeThisModal(true);
            })
        );
    }

    closeThisModal(close: boolean) {
        if (close) {
            this.dialogRef.close();
        }
    }

    ngOnDestroy(): void {
    }
}
