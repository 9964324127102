import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {ResponseCode} from '../../../../shared/enums/http-status-codes.enum';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {
    deleteProfileAddress,
    editProfileAddress,
    getProfileAddressesByLoggedUser,
    listProfileAddressesByLoggedUser,
    loadProfileAddresss,
    removeProfileAddress,
    saveProfileAddress,
    upsertProfileAddress
} from './profile-address.actions';
import {
    DELETE_PROFILE_ADDRESS,
    GET_PROFILE_ADDRESSES_BY_USER,
    LIST_PROFILE_ADDRESSES_BY_USER,
    SAVE_PROFILE_ADDRESS,
    UPDATE_PROFILE_ADDRESS
} from './profile-address.graphql';
import {ProfileAddress} from './profile-address.model';
import {refreshSubmitButton} from '../advocate-profile/advocate-profile.actions';
import {getFullAdvocateProfileDetails} from '../../management/full-profile-detail/full-profile-detail.actions';


@Injectable()
export class ProfileAddressEffects {

    saveProfileAddress$ = createEffect(() => this.actions$.pipe(
            ofType(saveProfileAddress),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: SAVE_PROFILE_ADDRESS,
                    variables: {
                        profileAddressesDto: action.profileAddress
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.saveProfileAddresses?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(refreshSubmitButton());
                            this.store.dispatch(upsertProfileAddress({profileAddress: data.saveProfileAddresses.data as ProfileAddress}));
                            this.store.dispatch(getFullAdvocateProfileDetails({}));
                            return this.notificationService.successMessage('Saved Successfully');
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.saveProfileAddresses);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    editProfileAddress$ = createEffect(() => this.actions$.pipe(
            ofType(editProfileAddress),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: UPDATE_PROFILE_ADDRESS,
                    variables: {
                        uid: action.uid,
                        profileAddressesDto: action.profileAddress
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.updateProfileAddresses?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(upsertProfileAddress({profileAddress: data.updateProfileAddresses.data as ProfileAddress}));
                            this.store.dispatch(getFullAdvocateProfileDetails({}));
                            return this.notificationService.successMessage('Saved Successfully');
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.updateProfileAddresses);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    removeProfileAddress$ = createEffect(() => this.actions$.pipe(
            ofType(removeProfileAddress),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: DELETE_PROFILE_ADDRESS,
                    variables: {
                        uid: action.uid
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.deleteProfileAddress?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(refreshSubmitButton());
                            this.store.dispatch(deleteProfileAddress({id: data.deleteProfileAddress.data.id as number}));
                            return this.notificationService.successMessage('Address Removed  Successfully');
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.deleteProfileAddress);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    getProfileAddressesByLoggedUsers$ = createEffect(() => this.actions$.pipe(
        ofType(getProfileAddressesByLoggedUser),
        switchMap((action) => {
            return this.apollo
                .query({
                    query: GET_PROFILE_ADDRESSES_BY_USER,
                    fetchPolicy: 'network-only',
                })
                .pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.getProfileAddressesByUser?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(loadProfileAddresss({
                                profileAddresss: data.getProfileAddressesByUser.datalist as ProfileAddress[]
                            }));
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.getProfileAddressesByUser);
                        }
                    })
                );
        })
    ), {dispatch: false});


    listProfileAddressesByLoggedUsers$ = createEffect(() => this.actions$.pipe(
        ofType(listProfileAddressesByLoggedUser),
        switchMap((action) => {
            return this.apollo
                .query({
                    query: LIST_PROFILE_ADDRESSES_BY_USER,
                    fetchPolicy: 'network-only',
                })
                .pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            this.store.dispatch(loadProfileAddresss({profileAddresss: Object.values(data)[0] as ProfileAddress[]}));
                        }
                    })
                );
        })
    ), {dispatch: false});


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService
    ) {
    }

}
