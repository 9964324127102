import gql from 'graphql-tag';

export const LIST_ACTION_USER_DECISIONS = gql`
    query getDecisionByActionUser{
        getDecionByActionUser{
            id
            name
            approveStatusEnums
        }
    }
`;
