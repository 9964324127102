import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { WorkFlowStage } from './work-flow-stage.model';
import * as WorkFlowStageActions from './work-flow-stage.actions';

export const workFlowStagesFeatureKey = 'workFlowStages';

export interface State extends EntityState<WorkFlowStage> {
  // additional entities state properties
}

export const adapter: EntityAdapter<WorkFlowStage> = createEntityAdapter<WorkFlowStage>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(WorkFlowStageActions.addWorkFlowStage,
    (state, action) => adapter.addOne(action.workFlowStage, state)
  ),
  on(WorkFlowStageActions.upsertWorkFlowStage,
    (state, action) => adapter.upsertOne(action.workFlowStage, state)
  ),
  on(WorkFlowStageActions.addWorkFlowStages,
    (state, action) => adapter.addMany(action.workFlowStages, state)
  ),
  on(WorkFlowStageActions.upsertWorkFlowStages,
    (state, action) => adapter.upsertMany(action.workFlowStages, state)
  ),
  on(WorkFlowStageActions.updateWorkFlowStage,
    (state, action) => adapter.updateOne(action.workFlowStage, state)
  ),
  on(WorkFlowStageActions.updateWorkFlowStages,
    (state, action) => adapter.updateMany(action.workFlowStages, state)
  ),
  on(WorkFlowStageActions.deleteWorkFlowStage,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(WorkFlowStageActions.deleteWorkFlowStages,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(WorkFlowStageActions.loadWorkFlowStages,
    (state, action) => adapter.setAll(action.workFlowStages, state)
  ),
  on(WorkFlowStageActions.clearWorkFlowStages,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
