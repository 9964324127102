import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-attachment-column-item',
  templateUrl: './attachment-column-item.component.html',
  styleUrls: ['./attachment-column-item.component.scss']
})
export class AttachmentColumnItemComponent implements OnInit {

  @Input() applicationUuid: string;

  constructor() {

   }

  ngOnInit(): void {
  }

}
