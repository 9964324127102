import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {map, switchMap} from 'rxjs/operators';
import {ResponseCode} from '../../../../shared/enums/http-status-codes.enum';
import {
    getAllWorkExperiences,
    getWorkExperienceByUser,
    loadWorkExperiences,
    saveWorkExperience,
    upsertWorkExperience,
    upsertWorkExperiences
} from './work-experience.actions';
import {
    GET_APPLICANT_WORK_EXPERIENCES,
    GET_WORK_EXPERIENCE_BY_USER,
    SAVE_WORK_EXPERIENCE
} from './work-experience.graphql';
import {WorkExperience} from './work-experience.model';
import {refreshSubmitButton} from '../advocate-profile/advocate-profile.actions';
import {getFullAdvocateProfileDetails} from '../../management/full-profile-detail/full-profile-detail.actions';


@Injectable()
export class WorkExperienceEffects {


    saveWorkExperience$ = createEffect(() => this.actions$.pipe(
            ofType(saveWorkExperience),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: SAVE_WORK_EXPERIENCE,
                    variables: {
                        workExperienceDto: action.workExperience
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.saveWorkExperience?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(refreshSubmitButton());
                            this.store.dispatch(upsertWorkExperience({workExperience: data.saveWorkExperience.data as WorkExperience}));
                            this.store.dispatch(getFullAdvocateProfileDetails({}));
                            return this.notificationService.successMessage('Saved Successfully');
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.saveWorkExperience);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    getWorkExperienceByUser$ = createEffect(() => this.actions$.pipe(
            ofType(getWorkExperienceByUser),
            switchMap((action) => {
                return this.apollo
                    .query({
                        query: GET_WORK_EXPERIENCE_BY_USER,
                        fetchPolicy: 'network-only',
                    })
                    .pipe(
                        this.notificationService.catchError(),
                        map(({data}: any) => {
                            const workExperiences = data.getWorkExperienceByUser as WorkExperience[];
                            this.store.dispatch(upsertWorkExperiences({
                                workExperiences
                            }));

                        })
                    );
            })
        ),
        {dispatch: false});


    getAllApplicantWorkExperiences$ = createEffect(() => this.actions$.pipe(
            ofType(getAllWorkExperiences),
            switchMap((action) => {
                return this.apollo
                    .query({
                        query: GET_APPLICANT_WORK_EXPERIENCES,
                        fetchPolicy: 'network-only',
                    })
                    .pipe(
                        this.notificationService.catchError(),
                        map(({data}: any) => {
                            if (data?.getAllWorkExperience?.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(loadWorkExperiences({
                                    workExperiences: data.getAllWorkExperience.data as WorkExperience[]
                                }));
                            }
                        })
                    );
            })
        ),
        {dispatch: false});

    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService
    ) {
    }

}
