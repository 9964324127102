import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {AdvocateProfile} from './advocate-profile.model';

export const loadAdvocateProfiles = createAction(
    '[AdvocateProfile/API] Load AdvocateProfiles',
    props<{ advocateProfiles: AdvocateProfile[] }>()
);

export const addAdvocateProfile = createAction(
    '[AdvocateProfile/API] Add AdvocateProfile',
    props<{ advocateProfile: AdvocateProfile }>()
);

export const upsertAdvocateProfile = createAction(
    '[AdvocateProfile/API] Upsert AdvocateProfile',
    props<{ advocateProfile: AdvocateProfile }>()
);

export const addAdvocateProfiles = createAction(
    '[AdvocateProfile/API] Add AdvocateProfiles',
    props<{ advocateProfiles: AdvocateProfile[] }>()
);

export const upsertAdvocateProfiles = createAction(
    '[AdvocateProfile/API] Upsert AdvocateProfiles',
    props<{ advocateProfiles: AdvocateProfile[] }>()
);

export const updateAdvocateProfile = createAction(
    '[AdvocateProfile/API] Update AdvocateProfile',
    props<{ advocateProfile: Update<AdvocateProfile> }>()
);

export const updateAdvocateProfiles = createAction(
    '[AdvocateProfile/API] Update AdvocateProfiles',
    props<{ advocateProfiles: Update<AdvocateProfile>[] }>()
);

export const deleteAdvocateProfile = createAction(
    '[AdvocateProfile/API] Delete AdvocateProfile',
    props<{ id: string }>()
);

export const deleteAdvocateProfiles = createAction(
    '[AdvocateProfile/API] Delete AdvocateProfiles',
    props<{ ids: string[] }>()
);

export const clearAdvocateProfiles = createAction(
    '[AdvocateProfile/API] Clear AdvocateProfiles'
);


/*
*  Effect actions
* */


export const getAdvocateProfileByUser = createAction(
    '[AdvocateProfile/API] get advocate profile by logged in user'
);

export const saveAdvocateProfile = createAction(
    '[AdvocateProfile/API] save advocate profile',
    props<{ profile: AdvocateProfile }>()
);

export const editAdvocateProfile = createAction(
    '[AdvocateProfile/API] edit current advocate profile',
    props<{ profile: AdvocateProfile, uid: string }>()
);

export const refreshSubmitButton = createAction(
    '[AdvocateProfile/API] refresh submit button for toggling',
);

export const enrollPetitionersInCurrentSession = createAction(
    '[AdvocateProfile/API] enroll petitioners in the current session',
);

export const changePetitionerName = createAction(
    '[AdvocateProfile/API] admin update name of the petitioner',
    props<{uuid: string, name: string}>()
);
