import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Region } from './region.model';

export const loadRegions = createAction(
  '[Region/API] Load Regions', 
  props<{ regions: Region[] }>()
);

export const addRegion = createAction(
  '[Region/API] Add Region',
  props<{ region: Region }>()
);

export const upsertRegion = createAction(
  '[Region/API] Upsert Region',
  props<{ region: Region }>()
);

export const addRegions = createAction(
  '[Region/API] Add Regions',
  props<{ regions: Region[] }>()
);

export const upsertRegions = createAction(
  '[Region/API] Upsert Regions',
  props<{ regions: Region[] }>()
);

export const updateRegion = createAction(
  '[Region/API] Update Region',
  props<{ region: Update<Region> }>()
);

export const updateRegions = createAction(
  '[Region/API] Update Regions',
  props<{ regions: Update<Region>[] }>()
);

export const deleteRegion = createAction(
  '[Region/API] Delete Region',
  props<{ id: string }>()
);

export const deleteRegions = createAction(
  '[Region/API] Delete Regions',
  props<{ ids: string[] }>()
);

export const clearRegions = createAction(
  '[Region/API] Clear Regions'
);

export  const getAllRegions = createAction(
    '[Region/API] Get All Regions'
);


