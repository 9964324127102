import gql from 'graphql-tag';

export const FeeTypeGraphqlFields = `
    active
    createdAt
    createdBy{
        id
    }
    deletedAt
    gfscode
    id
    service
    subspCode
    uid
    updatedAt
    updatedBy{
        id
    }
`;


export const GET_ALL_FEE_TYPES = gql`
    query getAllFeeTypes{
        getAllFeeTypes{
            ${FeeTypeGraphqlFields}
        }
    }
`;

export const GET_FEE_TYPE_BY_ID = gql`
    query getFeeTypeById($feeTypeUid:String){
        getFeeTypeById(feeTypeUid:feeTypeUid){
             code
             errorDescription
             status
             data{
                ${FeeTypeGraphqlFields}
             }
        }
    }
`;

export const SAVE_FEE_TYPE = gql`
    mutation saveFeeType($feeType:FeeTypeDtoInput){
        saveFeeType(feeType:$feeType){
            code
            data{
                ${FeeTypeGraphqlFields}
            }
            status
            errorDescription
        }
    }
`;

export const UPDATE_FEE_TYPE = gql`
    mutation updateFeeType($feeType:FeeTypeDtoInput,$feeTypeUid:String){
        updateFeeType(feeTypeDto:$feeType,feeTypeUid:$feeTypeUid){
            code
            data{
                ${FeeTypeGraphqlFields}
            }
            status
            errorDescription
        }
    }
`;





