import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { PetitionSession } from './petition-session.model';
import * as PetitionSessionActions from './petition-session.actions';

export const petitionSessionsFeatureKey = 'petitionSessions';

export interface State extends EntityState<PetitionSession> {
  // additional entities state properties
}

export const adapter: EntityAdapter<PetitionSession> = createEntityAdapter<PetitionSession>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(PetitionSessionActions.addPetitionSession,
    (state, action) => adapter.addOne(action.petitionSession, state)
  ),
  on(PetitionSessionActions.upsertPetitionSession,
    (state, action) => adapter.upsertOne(action.petitionSession, state)
  ),
  on(PetitionSessionActions.addPetitionSessions,
    (state, action) => adapter.addMany(action.petitionSessions, state)
  ),
  on(PetitionSessionActions.upsertPetitionSessions,
    (state, action) => adapter.upsertMany(action.petitionSessions, state)
  ),
  on(PetitionSessionActions.updatePetitionSession,
    (state, action) => adapter.updateOne(action.petitionSession, state)
  ),
  on(PetitionSessionActions.updatePetitionSessions,
    (state, action) => adapter.updateMany(action.petitionSessions, state)
  ),
  on(PetitionSessionActions.deletePetitionSession,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(PetitionSessionActions.deletePetitionSessions,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(PetitionSessionActions.loadPetitionSessions,
    (state, action) => adapter.setAll(action.petitionSessions, state)
  ),
  on(PetitionSessionActions.clearPetitionSessions,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
