import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './shared/components/auth/login/login.component';
import {BeforeLoginService} from './common/guards/before-login.service';
import {PasswordResetComponent} from './shared/components/auth/password-reset/password-reset.component';
import {AfterLoginService} from './common/guards/after-login.service';
import {SelfRegistrationComponent} from './shared/components/auth/self-registration/self-registration.component';
import {NonStaffGuardGuard} from './common/guards/non-staff-guard.guard';
import {StaffGuardGuard} from './common/guards/staff-guard.guard';
import {ForgotPasswordComponent} from './shared/components/auth/forgot-password/forgot-password.component';
import {ShouldResetPasswordGuard} from './common/guards/should-reset-password.guard';
import {ShouldResetPasswordComponent} from './shared/components/auth/should-reset-password/should-reset-password.component';


const routes: Routes = [
    {
        path: '',
        redirectTo: '/ewakili/home',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [BeforeLoginService]
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [BeforeLoginService]
    },
    {
        path: 'registration',
        component: SelfRegistrationComponent,
        canActivate: [BeforeLoginService]
    },
    {
        path: 'password-reset',
        component: PasswordResetComponent
    },
    {
        path: 'should-password-reset',
        component: ShouldResetPasswordComponent,
        canActivate: [AfterLoginService]
    },
    {
        path: 'settings', loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AfterLoginService, StaffGuardGuard, ShouldResetPasswordGuard]
    },
    {
        path: 'ewakili',
        loadChildren: () => import('./modules/tams-public/tams-public.module').then(m => m.TamsPublicModule),
    },
    {
        path: 'management',
        loadChildren: () => import('./modules/management/management.module').then(m => m.ManagementModule),
        canActivate: [AfterLoginService, StaffGuardGuard, ShouldResetPasswordGuard]
    },
    {
        path: 'advocate',
        loadChildren: () => import('./modules/advocate/advocate.module').then(m => m.AdvocateModule),
        canActivate: [AfterLoginService, NonStaffGuardGuard, ShouldResetPasswordGuard]
    },
    {path: '**', redirectTo: '/login', pathMatch: 'full'},
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            useHash: true,
            relativeLinkResolution: 'legacy'
        })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
