import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Appearance } from './appearance.model';
import * as AppearanceActions from './appearance.actions';

export const appearancesFeatureKey = 'appearances';

export interface State extends EntityState<Appearance> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Appearance> = createEntityAdapter<Appearance>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(AppearanceActions.addAppearance,
    (state, action) => adapter.addOne(action.appearance, state)
  ),
  on(AppearanceActions.upsertAppearance,
    (state, action) => adapter.upsertOne(action.appearance, state)
  ),
  on(AppearanceActions.addAppearances,
    (state, action) => adapter.addMany(action.appearances, state)
  ),
  on(AppearanceActions.upsertAppearances,
    (state, action) => adapter.upsertMany(action.appearances, state)
  ),
  on(AppearanceActions.updateAppearance,
    (state, action) => adapter.updateOne(action.appearance, state)
  ),
  on(AppearanceActions.updateAppearances,
    (state, action) => adapter.updateMany(action.appearances, state)
  ),
  on(AppearanceActions.deleteAppearance,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(AppearanceActions.deleteAppearances,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(AppearanceActions.loadAppearances,
    (state, action) => adapter.setAll(action.appearances, state)
  ),
  on(AppearanceActions.clearAppearances,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
