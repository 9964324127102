import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {map, switchMap} from 'rxjs/operators';
import {applyForFirm, getUserFirmBranch, saveFirmBranch, upsertFirmBranch} from './firm-branch.actions';
import {APPLY_FOR_FIRM, GET_USER_FIRM_BRANCH, SAVE_FIRM_BRANCH} from './firm-branch.graphql';
import {FirmBranch} from './firm-branch.model';
import {ResponseCode} from '../../../../shared/enums/http-status-codes.enum';
import {getUserFirmMember} from '../firm-member/firm-member.actions';
import {refreshSubmitButton} from '../advocate-profile/advocate-profile.actions';


@Injectable()
export class FirmBranchEffects {


    userFirmBranch$ = createEffect(() => this.actions$.pipe(
        ofType(getUserFirmBranch),
        switchMap((action) => {
            return this.apollo
                .query({
                    query: GET_USER_FIRM_BRANCH,
                    fetchPolicy: 'network-only',
                })
                .pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            this.store.dispatch(upsertFirmBranch({firmBranch: Object.values(data)[0] as FirmBranch}));
                        }
                    })
                );
        })
    ), {dispatch: false});

    saveFirmBranch$ = createEffect(() => this.actions$.pipe(
            ofType(saveFirmBranch),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: SAVE_FIRM_BRANCH,
                    variables: {
                        firmBranch: action.firmBranch
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.saveFirmBranch?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(refreshSubmitButton());
                            this.store.dispatch(upsertFirmBranch({firmBranch: data.saveFirmBranch.data as FirmBranch}));
                            this.store.dispatch(getUserFirmMember());
                            return this.notificationService.successMessage('Saved Successfully');
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.saveFirmBranch);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    applyForFirm$ = createEffect(() => this.actions$.pipe(
            ofType(applyForFirm),
            switchMap((action) => {
                return this.apollo.query({
                    query: APPLY_FOR_FIRM,
                    fetchPolicy: 'network-only',
                    variables: {
                        firmUid: action.firmUid,
                        firmBranchUid: action.firmBranchUid
                    }
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.applyForFirm?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(refreshSubmitButton());
                            this.store.dispatch(upsertFirmBranch({firmBranch: data.applyForFirm.data as FirmBranch}));
                            this.store.dispatch(getUserFirmMember());
                            return this.notificationService.successMessage('Saved Successfully');
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.applyForFirm);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService
    ) {
    }

}
