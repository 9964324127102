import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../../services/auth/auth.service';
import {Subscription} from 'rxjs';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {CustomValidators} from "../../../customs/custom-validators";

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit, OnDestroy {

    formErrors: { password: string, confirmPassword: string };
    passwordForm: FormGroup;
    private subscription = new Subscription();


    loading = false;
    private readonly rememberToken: string;

    constructor(
        private auth: AuthService,
        private fb: FormBuilder,
        private router: Router,
        private route: Router,
        private activatedRoute: ActivatedRoute,
        private notificationService: NotificationService
    ) {
        this.rememberToken = this.activatedRoute.snapshot.queryParamMap.get('token');
    }

    ngOnInit() {
        this.initForm();
        this.checkPasswordValidity();
    }

    initForm() {
        const specialCharacters = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
        this.passwordForm = this.fb.group({
            newPassword: [null, [
                // 1. Password Field is Required
                Validators.required,
                // 2. check whether the entered password has a number
                CustomValidators.patternValidator(/\d/, {hasNumber: true}),
                // 3. check whether the entered password has upper case letter
                CustomValidators.patternValidator(/[A-Z]/, {hasCapitalCase: true}),
                // 4. check whether the entered password has a lower-case letter
                CustomValidators.patternValidator(/[a-z]/, {hasSmallCase: true}),
                // 5. check whether the entered password has a special character
                CustomValidators.patternValidator(specialCharacters, {hasSpecialCharacters: true}),
                // 6. Has a minimum length of 8 characters
                Validators.minLength(6)
            ]],
            comfirmPassword: [null, Validators.required]
        });
    }

    submitPassword() {
        console.log(this.passwordForm.value);
        this.subscription.add(
            this.auth.createPassword(this.passwordForm.value, this.rememberToken).subscribe(
                res => {
                    this.notificationService.successMessage(res.message);
                    return this.auth.logout('Password reset was successful, Please login with your newly created password');
                },
                error => {
                    return this.notificationService.errorMessageSwalFire(error.error.message);
                }
            )
        );
    }

    checkPasswordValidity() {

        this.subscription.add(
            this.auth.passwordValidity(this.rememberToken).subscribe(
                res => null,
                error => {
                    this.notificationService.errorMessageSwalFire(error.error.message);
                    return this.router.navigate(['', 'login']);
                }
            )
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
