import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {ResponseCode} from '../../../../shared/enums/http-status-codes.enum';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {
    editPetitionEducation,
    getPetitionEducationByUser,
    loadPetitionEducations,
    savePetitionEducation,
    upsertPetitionEducation
} from './petition-education.actions';
import {
    EDIT_PETITION_EDUCATION,
    GET_PETITION_EDUCATION_BY_USER,
    SAVE_PETITION_EDUCATION
} from './petition-education.graphql';
import {refreshSubmitButton} from '../advocate-profile/advocate-profile.actions';
import {getFullAdvocateProfileDetails} from '../../management/full-profile-detail/full-profile-detail.actions';


@Injectable()
export class PetitionEducationEffects {

    getPetitionEducationByUser$ = createEffect(() => this.actions$.pipe(
            ofType(getPetitionEducationByUser),
            switchMap((action) => {
                return this.apollo.query({
                    query: GET_PETITION_EDUCATION_BY_USER,
                    fetchPolicy: 'network-only',
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data) {
                                if (data?.getPetitionEducationByUser?.code === ResponseCode.SUCCESS) {
                                    this.store.dispatch(loadPetitionEducations({
                                        petitionEducations:
                                        data?.getPetitionEducationByUser?.dataList
                                    }));
                                } else {
                                    // return this.responseCodesService.errorMessageByMessage(data.getPetitionEducationByUser);
                                }
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    savePEducation$ = createEffect(() => this.actions$.pipe(
            ofType(savePetitionEducation),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: SAVE_PETITION_EDUCATION,
                    variables: {
                        education: action.petitionEducation
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.savePetitionEducation?.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(refreshSubmitButton());
                                this.store.dispatch(upsertPetitionEducation({petitionEducation: data?.savePetitionEducation?.data}));
                                this.store.dispatch(getFullAdvocateProfileDetails({}));
                                return this.notificationService.successMessage('data saved successfully');
                            } else {
                                return this.responseCodesService.errorMessageByMessage(data.savePetitionEducation);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    editPEducation$ = createEffect(() => this.actions$.pipe(
            ofType(editPetitionEducation),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: EDIT_PETITION_EDUCATION,
                    variables: {
                        education: action.petitionEducation,
                        uid: action.uid
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.updatePetitionEducation?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(upsertPetitionEducation({petitionEducation: data.updatePetitionEducation.data}));
                            this.store.dispatch(getFullAdvocateProfileDetails({}));
                            return this.notificationService.successMessage('Edited Successfully');
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.updatePetitionEducation);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService
    ) {
    }
}
