<div class="container bill-wrapper">
    <div class="bill-header px-1 py-3">
        <img src="assets/images/Judiciary-Logo.png" alt="">
        <h4 class="sub-title">
            United Republic of Tanzania
        </h4>
        <h2 class="main-title">
            Judiciary of Tanzania
        </h2>
        <h4 class="sub-title">
            Government Bill
        </h4>
    </div>

    <div class="bill-details">
        <div class="bill-sub-details pb-0">
            <table class="table">
                <tbody>
                <tr>
                    <th>Control Number</th>
                    <td class="fw-700">: {{bill?.controlNumber || 'N/A'}}</td>
                </tr>
                <tr>
                    <th>Payment Ref</th>
                    <td class="fw-700">: {{bill?.billId}}</td>
                </tr>
                <tr>
                    <th>Service Provider Code</th>
                    <td class="fw-700">: SP140</td>
                </tr>
                <tr>
                    <th>Payer Name</th>
                    <td>: {{(bill?.payer?.fullName | uppercase) || 'N/A'}}</td>
                </tr>
                <tr>
                    <th>Payer Phone</th>
                    <td>: {{bill?.payer?.phone || 'N/A'}}</td>
                </tr>
                <tr>
                    <th>Bill Description</th>
                    <td>: {{bill?.feeType?.service}}</td>
                </tr>
                </tbody>
            </table>
        </div>


        <div class="bill-sub-details pb-0 qr-code">
            <qrcode [qrdata]="qrCodeData" [width]="280" [errorCorrectionLevel]="'M'"></qrcode>
            <!--<img src="assets/images/qr-code.png" alt="">-->
            <p>SCAN AND PAY</p>
        </div>



    </div>
    <div class="bill-total">
        <table class="table">
            <tbody>
            <tr *ngFor="let billItem of bill?.billItems">
                <th></th>
                <td>: {{billItem?.fee?.description}}</td>
                <td>: {{billItem?.amount | currency:'':''}}.</td>
            </tr>
            <tr>
                <th></th>
                <td class="fw-700">Total Billed Amount</td>
                <td class="fw-800">: {{bill?.totalAmount |  currency:'':''}} ({{bill?.currency}})</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="bill-details">
        <div class="bill-sub-details py-0">
            <table class="table">
                <tbody>
                <!--<tr>
                    <th>Amount in Words</th>
                    <td class="fw-700">: {{ bill.}}.</td>
                </tr>-->
                <tr>
                    <th>Expires on</th>
                    <td class="fw-700">: {{bill?.dueDate | date : 'mediumDate'}}</td>
                </tr>
                <tr>
                    <th>Prepared By</th>
                    <td class="fw-700">: {{bill?.createdBy?.fullName | uppercase}}</td>
                </tr>
                <tr>
                    <th>Collection Centre</th>
                    <td>: Head Quarter</td>
                </tr>
                <tr *ngIf="loggedInUser$|async as user">
                    <th>Printed By</th>
                    <td>: {{user.fullName}}</td>
                </tr>
                <tr>
                    <th>Printed On</th>
                    <td>: {{today| date : 'medium'}}</td>
                </tr>
                <tr>
                    <th>Signature</th>
                    <td>: ..............................................</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="bill-sub-details py-0">
        </div>
    </div>

    <div class="bill-details">
        <div class="bill-sub-details py-0">
            <h3 class="sub-title">Jinsi ya Kulipa</h3>
            <ol>
                <li>Kupitia Benki: Fika tawi lolote au wakala wa benki ya CRDB, NMB, BOT. Namba ya kumbukumbu: <span
                        class="fw-700">{{bill.controlNumber}}</span></li>
                <li>Kupitia Mitandao ya Simu:
                    <ul>
                        <li>Ingia kwenye menyu ya mtandao husika</li>
                        <li>Chagua 4 (Lipa Bili)</li>
                        <li>Chagua 5 (Malipo ya Serikali)</li>
                        <li>Ingiza <span class="fw-700">{{bill.controlNumber}}</span> kama namba ya kumbukumbu</li>
                    </ul>
                </li>
            </ol>
        </div>
        <div class="bill-sub-details py-0">
            <h3 class="sub-title">How to Pay</h3>
            <ol>
                <li>Via Bank: Visit any branch or bank agent of CRDB, NMB, BOT. Reference Number: <span
                        class="fw-700">{{bill.controlNumber}}</span></li>
                <li>Via Mobile Network Operators (MNO): Enter to the respective USSD Menu of MNO
                    <ul>
                        <li>Select 4 (Make Payments)</li>
                        <li>Select 5 (Government Payments)</li>
                        <li>Enter <span class="fw-700">{{bill.controlNumber}}</span> as reference number</li>
                    </ul>
                </li>
            </ol>
        </div>
    </div>

    <p class="footer-copyright">Copyright &copy; {{currentYear}}. Judiciary of Tanzania</p>
</div>
