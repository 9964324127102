import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {AuthService} from '../../../../services/auth/auth.service';
import {map, switchMap} from 'rxjs/operators';
import {getAllRegions, loadRegions} from './region.actions';
import {GET_ALL_REGIONS} from './region.graphql';
import {Region} from './region.model';


@Injectable()
export class RegionEffects {

    allRegions = createEffect(() => this.actions$.pipe(
        ofType(getAllRegions),
        switchMap((action) => {
            return this.apollo.query({
                query: GET_ALL_REGIONS,
                fetchPolicy: 'network-only'
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        this.store.dispatch(loadRegions({regions: Object.values(data)[0] as Region[]}));
                    }
                })
            );
        })
    ), {dispatch: false});

    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService,
        private auth: AuthService,
    ) {
    }

}
