<app-auth-layout>
    <ng-template #AuthContent>
        <ng-container *ngIf="!loginView">
            <div class="auth-wrapper">
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-xl-4 col-lg-6 col-md-7 my-auto p-0">
                            <div class="authentication-form mx-auto">
                                <div class="logo-centered">
                                    <img src="assets/images/Judiciary-Logo.png" style="height:60px; width: 60px;"
                                         alt="logo icon">
                                </div>
                                <h3>Sign In to e-Wakili</h3>

                                <form (ngSubmit)="login()" [formGroup]="staffLoginForm" autocomplete="off" novalidate>
                                    <div class="form-group">
                                        <input type="text" formControlName="username" class="form-control is-valid"
                                               placeholder="Email">
                                        <i class="ik ik-user"></i>
                                    </div>
                                    <div class="form-group">
                                        <input type="password" formControlName="password" class="form-control is-valid"
                                               placeholder="Password" id="exampleInputPassword">
                                        <i class="ik ik-lock"></i>
                                    </div>
                                    <span *ngIf="loading" class="d-block w-100 mb-2" style="margin-top: -4px">
                                       <mat-progress-bar color="warn" mode="indeterminate"></mat-progress-bar>
                                    </span>
                                    <div class="row">
                                        <div class="col text-left">
                                            <label class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="item_checkbox"
                                                       name="item_checkbox" value="option1">
                                                <span class="custom-control-label">&nbsp;Remember Me</span>
                                            </label>
                                        </div>
                                        <div class="col text-right">
                                            <a routerLink="/forgot-password">Forgot Password ?</a>
                                        </div>
                                    </div>
                                    <div class="sign-btn text-center">
                                        <button class="btn btn-theme" [disabled]="!staffLoginForm.valid || loading"
                                                data-loading-text="Processing.....">Sign In
                                        </button>
                                    </div>
                                </form>
                                <div class="register">
                                    <p>Know the Advocate! <i class="ik ik-home"></i> <a routerLink="/ewakili/home">Go
                                        back home</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-template>
</app-auth-layout>
