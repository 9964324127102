import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ViewAttachmentComponent } from '../other-views/view-attachment/view-attachment.component';

@Component({
  selector: 'app-attachments-list',
  templateUrl: './attachments-list.component.html',
  styleUrls: ['./attachments-list.component.scss']
})
export class AttachmentsListComponent implements OnInit {
  @Input() attachments: any[];
  constructor( private dialog: MatDialog) { }

  ngOnInit(): void {
  }
  openDocument(uuid: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '70%';
    dialogConfig.data = uuid;
    this.dialog.open(ViewAttachmentComponent, dialogConfig);
}

}
