import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import {CleMember, CleMemberDto} from './cle-member.model';

export const loadCleMembers = createAction(
  '[CleMember/API] Load CleMembers',
  props<{ cleMembers: CleMember[] }>()
);

export const addCleMember = createAction(
  '[CleMember/API] Add CleMember',
  props<{ cleMember: CleMember }>()
);

export const upsertCleMember = createAction(
  '[CleMember/API] Upsert CleMember',
  props<{ cleMember: CleMember }>()
);

export const addCleMembers = createAction(
  '[CleMember/API] Add CleMembers',
  props<{ cleMembers: CleMember[] }>()
);

export const upsertCleMembers = createAction(
  '[CleMember/API] Upsert CleMembers',
  props<{ cleMembers: CleMember[] }>()
);

export const updateCleMember = createAction(
  '[CleMember/API] Update CleMember',
  props<{ cleMember: Update<CleMember> }>()
);

export const updateCleMembers = createAction(
  '[CleMember/API] Update CleMembers',
  props<{ cleMembers: Update<CleMember>[] }>()
);

export const deleteCleMember = createAction(
  '[CleMember/API] Delete CleMember',
  props<{ id: string }>()
);

export const deleteCleMembers = createAction(
  '[CleMember/API] Delete CleMembers',
  props<{ ids: string[] }>()
);

export const clearCleMembers = createAction(
  '[CleMember/API] Clear CleMembers'
);

/*
*  effect actions
* */

export const fetchAllCleMembers = createAction(
    '[CleMember/API] fetch all cle members from the server'
);

export const editCleMember = createAction(
    '[CleMember/API] edit single cle member',
    props<{uid: string, cleMember: CleMemberDto}>()
);
