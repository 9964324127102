import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {FirmMember} from './firm-member.model';

export const loadFirmMembers = createAction(
    '[FirmMember/API] Load FirmMembers',
    props<{ firmMembers: FirmMember[] }>()
);

export const addFirmMember = createAction(
    '[FirmMember/API] Add FirmMember',
    props<{ firmMember: FirmMember }>()
);

export const upsertFirmMember = createAction(
    '[FirmMember/API] Upsert FirmMember',
    props<{ firmMember: FirmMember }>()
);

export const addFirmMembers = createAction(
    '[FirmMember/API] Add FirmMembers',
    props<{ firmMembers: FirmMember[] }>()
);

export const upsertFirmMembers = createAction(
    '[FirmMember/API] Upsert FirmMembers',
    props<{ firmMembers: FirmMember[] }>()
);

export const updateFirmMember = createAction(
    '[FirmMember/API] Update FirmMember',
    props<{ firmMember: Update<FirmMember> }>()
);

export const updateFirmMembers = createAction(
    '[FirmMember/API] Update FirmMembers',
    props<{ firmMembers: Update<FirmMember>[] }>()
);

export const deleteFirmMember = createAction(
    '[FirmMember/API] Delete FirmMember',
    props<{ id: string }>()
);

export const deleteFirmMembers = createAction(
    '[FirmMember/API] Delete FirmMembers',
    props<{ ids: string[] }>()
);

export const clearFirmMembers = createAction(
    '[FirmMember/API] Clear FirmMembers'
);

export const getUserFirmMember = createAction(
    '[FirmMember/API] Get User FirmMembers'
);

export const confirmFirm = createAction(
    '[FirmMember/API] Confirm Firm  FirmMembers',
    props<{ code: string, firmMemberUid: string }>()
);

export const leaveFirm = createAction(
    '[FirmMember/API] Leave Firm ',
    props<{ firmMemberUid: string }>()
)
