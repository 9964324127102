import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {map, switchMap} from 'rxjs/operators';
import {confirmFirm, getUserFirmMember, leaveFirm, upsertFirmMember} from './firm-member.actions';
import {CONFIRM_FIRM, GET_USER_FIRM_MEMBER, LEAVE_FIRM} from './firm-member.graphql';
import {ResponseCode} from '../../../../shared/enums/http-status-codes.enum';
import {FirmMember} from './firm-member.model';
import {refreshSubmitButton} from '../advocate-profile/advocate-profile.actions';
import {getFullAdvocateProfileDetails} from '../../management/full-profile-detail/full-profile-detail.actions';


@Injectable()
export class FirmMemberEffects {

    getUserFirmMember$ = createEffect(() => this.actions$.pipe(
            ofType(getUserFirmMember),
            switchMap((action) => {
                return this.apollo.query({
                    query: GET_USER_FIRM_MEMBER,
                    fetchPolicy: 'network-only',
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data && data.getUserFirm) {
                            this.store.dispatch(upsertFirmMember({firmMember: data.getUserFirm}));
                            this.store.dispatch(getFullAdvocateProfileDetails({}));
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    confirmFirm$ = createEffect(() => this.actions$.pipe(
            ofType(confirmFirm),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: CONFIRM_FIRM,
                    variables: {
                        firmMemberUid: action.firmMemberUid,
                        code: action.code
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.confirmFirm?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(refreshSubmitButton());
                            this.store.dispatch(upsertFirmMember({firmMember: data.confirmFirm.data as FirmMember}));
                            this.store.dispatch(getFullAdvocateProfileDetails({}));
                            return this.notificationService.successMessage('Verified Successfully');
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.confirmFirm);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    leaveFirm$ = createEffect(() => this.actions$.pipe(
        ofType(leaveFirm),
        switchMap((action) => {
            return this.apollo.query({
                query: LEAVE_FIRM,
                fetchPolicy: 'network-only',
                variables: {
                    firmMemberUid: action.firmMemberUid
                },
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data?.leaveFirm?.code === ResponseCode.SUCCESS) {
                        this.store.dispatch(refreshSubmitButton());
                        this.store.dispatch(upsertFirmMember({firmMember: data.leaveFirm.data as FirmMember}));
                        this.store.dispatch(getFullAdvocateProfileDetails({}));
                        return this.notificationService.successMessage('Leave Firm Successfully');
                    } else {
                        return this.responseCodesService.errorMessageByMessage(data.confirmFirm);
                    }
                })
            );
        })
    ),
    {dispatch: false}
);

    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService
    ) {
    }

}
