import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {Bill} from './bill.model';

export const loadBills = createAction(
    '[Bill/API] Load Bills',
    props<{ bills: Bill[] }>()
);

export const addBill = createAction(
    '[Bill/API] Add Bill',
    props<{ bill: Bill }>()
);

export const upsertBill = createAction(
    '[Bill/API] Upsert Bill',
    props<{ bill: Bill }>()
);

export const addBills = createAction(
    '[Bill/API] Add Bills',
    props<{ bills: Bill[] }>()
);

export const upsertBills = createAction(
    '[Bill/API] Upsert Bills',
    props<{ bills: Bill[] }>()
);

export const updateBill = createAction(
    '[Bill/API] Update Bill',
    props<{ bill: Update<Bill> }>()
);

export const updateBills = createAction(
    '[Bill/API] Update Bills',
    props<{ bills: Update<Bill>[] }>()
);

export const deleteBill = createAction(
    '[Bill/API] Delete Bill',
    props<{ id: string }>()
);

export const deleteBills = createAction(
    '[Bill/API] Delete Bills',
    props<{ ids: string[] }>()
);

export const clearBills = createAction(
    '[Bill/API] Clear Bills'
);

export const getApplicantBills = createAction(
    '[Bill/API] Get Applicant Bills'
);

export const requestControlNumber = createAction(
    '[Bill/API] Request Control Number',
    props<{ billUid: string }>()
);
export const fetchBillByUid = createAction(
    '[Bill/API] Fetch Bill By Uid',
    props<{ billUid: string }>()
);

export const cancelBill = createAction(
    '[Bill/API] Cancel Bill By bill Id',
    props<{ billId: string }>()
);


export const extendBill = createAction(
    '[Bill/API] Extend Bill By bill Id',
    props<{ billDto: { billRef: string, expireDate: string } }>()
);

export const extendBillCompleted = createAction(
    '[Bill/API] Extend Bill By bill Completed'
);

export const regenerateBill = createAction(
    '[Bill/API] Regenerate Bill',
    props<{ billId: string }>()
);


export const reuseControlNumber = createAction(
    '[Bill/API] Reuse Control number',
    props<{ billUid: string }>()
);


export const refreshBillsTable = createAction(
    '[Bill/API] Refresh Control number',
    props<{ billUid: string }>()
);


export const getApplicationPaidBill = createAction(
    '[Proforma/API] Get Application Paid  bills by uid',
    props<{ applicationUid: string }>()
);





