import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ProfileContact } from './profile-contact.model';
import * as ProfileContactActions from './profile-contact.actions';

export const profileContactsFeatureKey = 'profileContacts';

export interface State extends EntityState<ProfileContact> {
  // additional entities state properties
}

export const adapter: EntityAdapter<ProfileContact> = createEntityAdapter<ProfileContact>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(ProfileContactActions.addProfileContact,
    (state, action) => adapter.addOne(action.profileContact, state)
  ),
  on(ProfileContactActions.upsertProfileContact,
    (state, action) => adapter.upsertOne(action.profileContact, state)
  ),
  on(ProfileContactActions.addProfileContacts,
    (state, action) => adapter.addMany(action.profileContacts, state)
  ),
  on(ProfileContactActions.upsertProfileContacts,
    (state, action) => adapter.upsertMany(action.profileContacts, state)
  ),
  on(ProfileContactActions.updateProfileContact,
    (state, action) => adapter.updateOne(action.profileContact, state)
  ),
  on(ProfileContactActions.updateProfileContacts,
    (state, action) => adapter.updateMany(action.profileContacts, state)
  ),
  on(ProfileContactActions.deleteProfileContact,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ProfileContactActions.deleteProfileContacts,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ProfileContactActions.loadProfileContacts,
    (state, action) => adapter.setAll(action.profileContacts, state)
  ),
  on(ProfileContactActions.clearProfileContacts,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
