import gql from 'graphql-tag';
import {PermissionsGqlFields} from '../permission/permission.graphql';

export const RoleGqlFields = `
    id
    uid
    name
    displayName
    description
    permissions{
      ${PermissionsGqlFields}
    }
`;

export const LIST_ALL_ROLES = gql`
    query listRoles{
        listRoles{
            ${RoleGqlFields}
        }
    }
`;

export const LIST_INSTITUTION_ROLES = gql`
  query getAllRoles{
    getAllRoles{
      ${RoleGqlFields}
    }
  }
`;


export const CREATE_ROLE = gql`
  mutation saveRole($role: RoleDtoInput!){
    saveRole(roleDto: $role){
      code
      data{
        ${RoleGqlFields}
      }
      status
      errorDescription
    }
  }
`;

export const EDIT_ROLE = gql`
  mutation updateRole($role: RoleInput){
    updateRole(role: $role){
      code
      status
      data{
        ${RoleGqlFields}
      }
    }
  }
`;


export const DELETE_ROLE = gql`
  mutation deleteRole($roleId: Long){
    deleteRole(roleId: $roleId){
      code
      data{
        ${RoleGqlFields}
      }
    }
  }
`;

export const GET_PERMISSIONS_BY_ROLE_ID = gql`
  query getOneRole($id: String!){
    getRoleById(uuid: $id){
      ${RoleGqlFields}
    }
  }
`;

export const GET_ALL_PERMISSIONS = gql`
  query allPermissions{
    getAllPermissions{
      groupName
      permissionList{
        ${PermissionsGqlFields}
      }
    }
  }
`;

export const UPDATE_ROLE_PERMISSIONS = gql`
  mutation assignPermissions($permissions: AssignPermissionsInput!){
    assignPermissions(assignPermissionsInput: $permissions){
      code
      data{
        ${RoleGqlFields}
      }
      status
      errorDescription
    }
  }
`;

export const ASSIGN_ROLES_TO_USER = gql`
  mutation assignRole($rolesAssign: AssignRolesInput!){
    assignRoles(assignRolesInput: $rolesAssign){
      code
      data{
        ${RoleGqlFields}
      }
      status
      errorDescription
    }
  }
`;

export const ASSIGN_PERMISSIONS_TO_ROLE = gql`
  mutation assignPermissionsToRole($permissionIds: [Long], $roleUuid:String ){
    assignPermissionsToRole(permissionIds: $permissionIds, roleUuid: $roleUuid){
      code
      errorDescription
      data{
        name
        displayName
        permissions{
          id
          name
          displayName
        }
      }
      status
    }
  }
`;
