import {Injectable} from '@angular/core';
import {NavMenuInfo} from '../../shared/constants/modules-menu.constants';

@Injectable({
    providedIn: 'root'
})
export class SideBarMenuService {

    sideMenu: NavMenuInfo[] = [
        {
            name: 'Management Menu',
            navMenus: [
                {
                    name: 'Dashboard',
                    icon: 'dashboard',
                    iconType: 'MATERIAL',
                    route: '/management/dashboard',
                    permissions: ['ROLE_VIEW_USER_APPLICATION', 'ROLE_VIEW_APPLICATION_BY_ACTION_USER'],
                },
                {
                    name: 'Petition Applications',
                    icon: 'drafts',
                    iconType: 'MATERIAL',
                    route: '/management/petition-applications',
                    permissions: ['ROLE_VIEW_APPLICATION_BY_ACTION_USER', 'ROLE_VIEW_PENDING_ADMISSION'],
                },
                {
                    name: 'Permit Applications',
                    icon: 'layers',
                    iconType: 'MATERIAL',
                    route: '',
                    permissions: ['ROLE_VIEW_APPLICATION_BY_ACTION_USER'],
                    children: [{
                        name: 'Name Change Applications',
                        icon: '',
                        iconType: '',
                        route: '/management/applications-for-name-change',
                        permissions: ['ROLE_VIEW_APPLICATION_BY_ACTION_USER']
                    }, {
                        name: 'Practising Application',
                        icon: '',
                        iconType: '',
                        route: '/management/applications-for-practising',
                        permissions: ['ROLE_VIEW_APPLICATION_BY_ACTION_USER']
                    }, {
                        name: 'Non Practising Applications',
                        icon: '',
                        iconType: '',
                        route: '/management/applications-for-non-practising',
                        permissions: ['ROLE_VIEW_APPLICATION_BY_ACTION_USER']
                    }, {
                        name: 'Non Profit Applications',
                        icon: '',
                        iconType: '',
                        route: '/management/applications-for-non-profit',
                        permissions: ['ROLE_VIEW_APPLICATION_BY_ACTION_USER']
                    }, {
                        name: 'Renewal Applications',
                        icon: '',
                        iconType: '',
                        route: '/management/applications-for-renewal',
                        permissions: ['ROLE_VIEW_APPLICATION_BY_ACTION_USER']
                    }, {
                        name: 'Retirement Applications',
                        icon: '',
                        iconType: '',
                        route: '/management/applications-for-retirement',
                        permissions: ['ROLE_VIEW_APPLICATION_BY_ACTION_USER']
                    }, {
                        name: 'Suspension Applications',
                        icon: '',
                        iconType: '',
                        route: '/management/applications-for-suspension',
                        permissions: ['ROLE_VIEW_APPLICATION_BY_ACTION_USER']
                    }, {
                        name: 'Junior Renewal Applications',
                        icon: '',
                        iconType: '',
                        route: '/management/applications-for-junior',
                        permissions: ['ROLE_VIEW_APPLICATION_BY_ACTION_USER']
                    }, {
                        name: 'Senior Renewal Applications',
                        icon: '',
                        iconType: '',
                        route: '/management/applications-for-senior',
                        permissions: ['ROLE_VIEW_APPLICATION_BY_ACTION_USER']
                    }
                    ]
                },
                {
                    name: 'Bills',
                    icon: 'money',
                    iconType: 'MATERIAL',
                    route: '',
                    permissions: ['ROLE_MANAGE_BILL', 'ROLE_VIEW_ALL_BILL', 'ROLE_VIEW_ALL_PAYMENT',
                        'ROLE_SEND_RECONCILIATION_REQUEST', 'ROLE_DELETE_RECONCILIATION_BATCH'],
                    children: [
                        {
                            name: 'Client Bills',
                            icon: '',
                            iconType: '',
                            route: '/management/bills/client-bills',
                            permissions: ['ROLE_MANAGE_BILL', 'ROLE_VIEW_ALL_BILL']
                        },
                        {
                            name: 'Client Payments',
                            icon: '',
                            iconType: '',
                            route: '/management/bills/client-payments',
                            permissions: ['ROLE_VIEW_ALL_PAYMENT']
                        }
                        ,
                        {
                            name: 'Reconciliation Batches',
                            icon: '',
                            iconType: '',
                            route: '/management/bills/reconciliation-batches',
                            permissions: ['ROLE_VIEW_RECONCILIATION_BATCH']
                        },
                        {
                            name: 'Group Bills',
                            icon: '',
                            iconType: '',
                            route: '/management/bills/group-bills',
                            permissions: ['ROLE_VIEW_ALL_PAYMENT']
                        }
                    ]
                },
                {
                    name: 'Legal Professionals\' Objections',
                    icon: 'group_add',
                    iconType: 'MATERIAL',
                    route: '/management/legal-objections',
                    permissions: ['ROLE_VIEW_LEGAL_VIEW', 'ROLE_LEGAL_VIEW_PETITIONER', 'ROLE_CREATE_LEGAL_VIEW'],
                },
                {
                    name: 'Advocates',
                    icon: 'supervisor_account',
                    iconType: 'MATERIAL',
                    route: '/management/advocates',
                    permissions: ['ROLE_VIEW_ADVOCATE'],
                },
                {
                    name: 'Advocates Status Changes',
                    icon: 'change_circle',
                    iconType: 'MATERIAL',
                    route: '/management/advocates-status-changes',
                    permissions: ['ROLE_VIEW_ADVOCATE_STATUS_CHANGE'],
                },
/*                {
                    name: 'Certificates',
                    icon: 'card_membership',
                    iconType: 'MATERIAL',
                    route: '/management/certificates',
                    permissions: ['ROLE_VIEW_ADVOCATE'],
                },*/
                {
                    name: 'Sessions Management',
                    icon: 'date_range',
                    iconType: 'MATERIAL',
                    route: '/management/sessions-management',
                    permissions: ['ROLE_MANAGE_SESSION', 'ROLE_VIEW_LEGAL_VIEW_BY_ACTION_USER'],
                },
                {
                    name: 'Firms',
                    icon: 'collections_bookmark',
                    iconType: 'MATERIAL',
                    route: '/management/firms',
                    permissions: ['ROLE_MANAGE_FIRM', 'ROLE_APPROVE_FIRM'],
                },
                {
                    name: 'Users',
                    icon: 'supervisor_account',
                    iconType: 'MATERIAL',
                    route: '/management/users',
                    permissions: ['ROLE_USER_VIEW', 'ROLE_USER_STAFF_ADD'],
                },
                {
                    name: 'Roles',
                    icon: 'admin_panel_settings',
                    iconType: 'MATERIAL',
                    route: '/management/roles',
                    permissions: ['ROLE_ROLE_VIEW', 'ROLE_ROLE_ADD'],
                },
            ]
        },
        {
            name: 'Settings Menu',
            navMenus: [
                {
                    name: 'Countries List',
                    icon: 'flag',
                    iconType: 'MATERIAL',
                    route: '/settings/countries',
                    permissions: ['ROLE_MANAGE_COUNTRY'],
                },
                {
                    name: 'Signatory List',
                    icon: 'supervisor_account',
                    iconType: 'MATERIAL',
                    route: '/settings/signatories',
                    permissions: ['ROLE_MANAGE_ATTACHMENT_TYPE', 'ROLE_CREATE_ATTACHMENT_TYPE'],
                },
                {
                    name: 'Petition Sessions',
                    icon: 'av_timer',
                    iconType: 'MATERIAL',
                    route: '/settings/petition-sessions',
                    permissions: ['ROLE_MANAGE_SESSION', 'ROLE_CREATE_SESSION'],
                },
                {
                    name: 'Attachment Types',
                    icon: 'attachment',
                    iconType: 'MATERIAL',
                    route: '/settings/attachment-types',
                    permissions: ['ROLE_MANAGE_ATTACHMENT_TYPE', 'ROLE_CREATE_ATTACHMENT_TYPE'],
                },
                {
                    name: 'Qualifications',
                    icon: 'school',
                    iconType: 'MATERIAL',
                    route: '/settings/qualifications',
                    permissions: ['ROLE_VIEW_USER_QUALIFICATION', 'ROLE_MANAGE_QUALIFICATION', 'ROLE_CREATE_QUALIFICATION'],
                },
                {
                    name: 'CLE Members',
                    icon: 'people',
                    iconType: 'MATERIAL',
                    route: '/settings/cle-members',
                    permissions: ['ROLE_VIEW_CLE_MEMBER'],
                },
                {
                    name: 'Appearance Venues',
                    icon: 'other_houses',
                    iconType: 'MATERIAL',
                    route: '/settings/appearance-venues',
                    permissions: ['ROLE_MANAGE_VENUE', 'ROLE_CREATE_VENUE'],
                },
                {
                    name: 'Work flow processes',
                    icon: 'account_tree',
                    iconType: 'MATERIAL',
                    route: '/settings/work-flow-processes',
                    permissions: ['ROLE_MANAGE_WORKFLOW_PROCESS'],
                },
                {
                    name: 'Renewal Batch',
                    icon: 'account_tree',
                    iconType: 'MATERIAL',
                    route: '/settings/renewal-batch',
                    permissions: ['ROLE_VIEW_RENEWAL_BATCH', 'ROLE_CREATE_RENEWAL_BATCH'],
                },
                {
                    name: 'Fee Types',
                    icon: 'money',
                    iconType: 'MATERIAL',
                    route: '/settings/fee-types',
                    permissions: ['ROLE_MANAGE_FEETYPE', 'ROLE_CREATE_FEETYPE'],
                },
                {
                    name: 'Fees',
                    icon: 'money',
                    iconType: 'MATERIAL',
                    route: '/settings/fees',
                    permissions: ['ROLE_MANAGE_FEE', 'ROLE_CREATE_FEE'],
                }
            ]
        }
    ];


    advocateSideMenu: NavMenuInfo[] = [
        this.getPetitionerMenu()
    ];

    constructor() {
    }

    getPetitionerMenu() {
        return {
            name: 'Petitioner Menu',
            navMenus: [
                {
                    name: 'My Profile',
                    icon: 'person',
                    iconType: 'MATERIAL',
                    route: '/advocate/my-profile',
                    permissions: ['ROLE_VIEW_PROFILE_DETAILS', 'ROLE_MANAGE_PROFILE'],
                },
                {
                    name: 'Bills',
                    icon: 'money',
                    iconType: 'MATERIAL',
                    route: '/advocate/my-bills',
                    permissions: ['ROLE_VIEW_APPLICANT_BILL', 'ROLE_REQUEST_CONTROL_NUMBER'],
                },
                {
                    name: 'Payments',
                    icon: 'fa fa-hand-holding-usd',
                    iconType: 'FA',
                    route: '/advocate/my-payments',
                    permissions: ['ROLE_VIEW_APPLICANT_BILL'],
                }
            ]
        };
    }
}
