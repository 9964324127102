import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {map, switchMap} from 'rxjs/operators';
import {
    deleteProfileContact,
    getAllProfileContactsByUser,
    loadProfileContacts,
    removeProfileContact,
    saveProfileContacts,
    upsertProfileContact
} from './profile-contact.actions';
import {
    DELETE_PROFILE_CONTACT,
    GET_PROFILE_CONTACTS_BY_LOGGED_USER,
    SAVE_PROFILE_CONTACT
} from './profile-contact.graphql';
import {ProfileContact} from './profile-contact.model';
import {ResponseCode} from '../../../../shared/enums/http-status-codes.enum';
import {refreshSubmitButton} from '../advocate-profile/advocate-profile.actions';
import {getFullAdvocateProfileDetails} from '../../management/full-profile-detail/full-profile-detail.actions';


@Injectable()
export class ProfileContactEffects {


    removeProfileContact$ = createEffect(() => this.actions$.pipe(
            ofType(removeProfileContact),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: DELETE_PROFILE_CONTACT,
                    variables: {
                        uid: action.uid
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.deleteProfileContact?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(refreshSubmitButton());
                            this.store.dispatch(deleteProfileContact({id: data.deleteProfileContact.data.id as number}));
                            return this.notificationService.successMessage('Contact Removed  Successfully');
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.deleteProfileContact);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    saveProfileContact$ = createEffect(() => this.actions$.pipe(
            ofType(saveProfileContacts),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: SAVE_PROFILE_CONTACT,
                    variables: {
                        profileContactDto: action.profileContact
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.saveProfileContact?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(refreshSubmitButton());
                            this.store.dispatch(upsertProfileContact({profileContact: data.saveProfileContact.data as ProfileContact}));
                            this.store.dispatch(getFullAdvocateProfileDetails({}));
                            return this.notificationService.successMessage('Saved Successfully');
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.saveProfileContact);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    getProfileContactsByLoggedUser$ = createEffect(() => this.actions$.pipe(
        ofType(getAllProfileContactsByUser),
        switchMap((action) => {
            return this.apollo
                .query({
                    query: GET_PROFILE_CONTACTS_BY_LOGGED_USER,
                    fetchPolicy: 'network-only',
                })
                .pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            this.store.dispatch(loadProfileContacts({profileContacts: Object.values(data)[0] as ProfileContact[]}));
                        }
                    })
                );
        })
    ), {dispatch: false});


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService
    ) {
    }

}
