import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Advocate } from './advocate.model';
import * as AdvocateActions from './advocate.actions';

export const advocatesFeatureKey = 'advocates';

export interface State extends EntityState<Advocate> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Advocate> = createEntityAdapter<Advocate>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(AdvocateActions.addAdvocate,
    (state, action) => adapter.addOne(action.advocate, state)
  ),
  on(AdvocateActions.upsertAdvocate,
    (state, action) => adapter.upsertOne(action.advocate, state)
  ),
  on(AdvocateActions.addAdvocates,
    (state, action) => adapter.addMany(action.advocates, state)
  ),
  on(AdvocateActions.upsertAdvocates,
    (state, action) => adapter.upsertMany(action.advocates, state)
  ),
  on(AdvocateActions.updateAdvocate,
    (state, action) => adapter.updateOne(action.advocate, state)
  ),
  on(AdvocateActions.updateAdvocates,
    (state, action) => adapter.updateMany(action.advocates, state)
  ),
  on(AdvocateActions.deleteAdvocate,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(AdvocateActions.deleteAdvocates,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(AdvocateActions.loadAdvocates,
    (state, action) => adapter.setAll(action.advocates, state)
  ),
  on(AdvocateActions.clearAdvocates,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
