import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { AdvocateChangeStatus } from './advocate-change-status.model';
import * as AdvocateChangeStatusActions from './advocate-change-status.actions';

export const advocateChangeStatusesFeatureKey = 'advocateChangeStatuses';

export interface State extends EntityState<AdvocateChangeStatus> {
  // additional entities state properties
}

export const adapter: EntityAdapter<AdvocateChangeStatus> = createEntityAdapter<AdvocateChangeStatus>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(AdvocateChangeStatusActions.addAdvocateChangeStatus,
    (state, action) => adapter.addOne(action.advocateChangeStatus, state)
  ),
  on(AdvocateChangeStatusActions.upsertAdvocateChangeStatus,
    (state, action) => adapter.upsertOne(action.advocateChangeStatus, state)
  ),
  on(AdvocateChangeStatusActions.addAdvocateChangeStatuss,
    (state, action) => adapter.addMany(action.advocateChangeStatuss, state)
  ),
  on(AdvocateChangeStatusActions.upsertAdvocateChangeStatuss,
    (state, action) => adapter.upsertMany(action.advocateChangeStatuss, state)
  ),
  on(AdvocateChangeStatusActions.updateAdvocateChangeStatus,
    (state, action) => adapter.updateOne(action.advocateChangeStatus, state)
  ),
  on(AdvocateChangeStatusActions.updateAdvocateChangeStatuss,
    (state, action) => adapter.updateMany(action.advocateChangeStatuss, state)
  ),
  on(AdvocateChangeStatusActions.deleteAdvocateChangeStatus,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(AdvocateChangeStatusActions.deleteAdvocateChangeStatuss,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(AdvocateChangeStatusActions.loadAdvocateChangeStatuss,
    (state, action) => adapter.setAll(action.advocateChangeStatuss, state)
  ),
  on(AdvocateChangeStatusActions.clearAdvocateChangeStatuss,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
