import gql from 'graphql-tag';
import {appearanceVenueGqlFields} from '../../settings/appearance-venue/appearance-venue.graphql';
import {UserGqlFields} from '../../settings/user/user.graphql';
import {petitionSessionsGqlFields} from '../../settings/petition-session/petition-session.graphql';

export const barExamGqlFields = `
    active
    appearanceVenue{
        ${appearanceVenueGqlFields}
    }
    createdAt
    createdBy{
        ${UserGqlFields}
    }
    deletedAt
    examDate
    reportingTime
    id
    petitionSession{
        ${petitionSessionsGqlFields}
    }
    uid
    updatedAt
`;

export const GET_BAR_EXAMS_BY_SESSION_UID = gql`
    query getBarExamBySession($sessionUid: String){
        getBarExamBySession(petitionSessionUid: $sessionUid){
            ${barExamGqlFields}
        }
    }
`;

export const CREATE_BAR_EXAM = gql`
    mutation saveBarExam($bar: BarExamDtoInput){
        saveBarExam(barExamDto: $bar){
            code
            data{
                ${barExamGqlFields}
            }
            errorDescription
            status
        }
    }
`;

export const UPDATE_BAR_EXAM = gql`
    mutation updateBarExam($barExamUid: String, $barExamDto: BarExamDtoInput){
        updateBarExam(barExamUid: $barExamUid, barExamDto: $barExamDto){
            code
            data{
                ${barExamGqlFields}
            }
            errorDescription
            status
        }
    }
`;

