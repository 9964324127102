import gql from 'graphql-tag';
import {UserGqlFields} from '../../settings/user/user.graphql';

export const petitionersGqlFields = `
    active
    admitAs
    rollNo
    lstExemptionApprovalStatus
    exemptionDate
    appearance{
        id
        uid
        appearDate
        reportingTime
        appearanceVenue{
            id
            name
        }
    }
    appearanceVenue{
        id
        uid
        name
    }
    application{
        id
        uid
        currentStage{
          id
          name
        }
    }
    barExam{
        id
        uid
    }
    createdAt
    createdBy{
        id
        uid
    }
    id
    lstRegno
    petitionNo
    petitionSession{
        id
        uid
        active
    }
    priority
    profile{
        id
        uid
        advocate{
            id
            rollNo
        }
        active
        createdAt
        updatedAt
        createdBy{
            id
            fullName
        }
        dob
        fullname
        idNumber
        user{
            ${UserGqlFields}
        }
        idType
        picture
        title
        gender
    }
    legalProfessionalViewList{
          id
          active
          uid
          comment
          createdAt
          updatedAt
          user{
            id
            fullName
            uid
          }
          approveStatus
          submissionAt
          createdBy{
            id
            uid
            fullName
          }
          workflowProcess{
            id
            name
            displayName
          }
    }
    qualifications
    seniority
    seniorpriority
    uid
    updatedAt
`;

export const LIST_OF_BAR_EXAM_PETITIONERS = gql`
    query getBarExamPetitionersByBarExam($barExamUId: String){
        getPetitionersByBarExam(barExamUid: $barExamUId){
            ${petitionersGqlFields}
        }
    }
`;

export const LIST_PETITIONERS_BY_APPEARANCE_UID_DT = gql`
    query getPetitionerByAppearance($appearanceUid: String, $dtInput: DataTablesInputInput){
        getPetitionerByAppearance(appearanceUid: $appearanceUid, dtInput: $dtInput){
            data{
                ${petitionersGqlFields}
            }
            recordsFiltered
            recordsTotal
            draw
            error
        }
    }
`;

export const LIST_OF_PENDING_BAR_EXAM_PETITIONERS = gql`
    query getPendingBarExam($sessionUid: String) {
        getPendingBarExam(petitionSessionUid: $sessionUid){
            ${petitionersGqlFields}
        }
    }
`;

export const APPLY_FOR_APPEARANCE_VENUE = gql`
    mutation applyForVenue($appearanceVenueUid: String, $petitionUid: String){
        applyForVenue(appearanceVenueUid: $appearanceVenueUid, petitionUid: $petitionUid){
            code
            status
            data{
                ${petitionersGqlFields}
            }
            errorDescription
        }
    }
`;

export const PENDING_ADMISSION_PETITIONS = gql`
    query getPendingAdmissionBySession($dtInput: DataTablesInputInput, $sessionUid: String){
        getPendingAdmissionBySession(dtInput: $dtInput, petitionSessionUid: $sessionUid){
            data{
                ${petitionersGqlFields}
            }
            draw
            error
            recordsTotal
            recordsFiltered
        }
    }
`;

export const PRE_ASSIGN_ROLL_NUMBER = gql`
    mutation preAssignRollNumber($sessionUid: String){
        preAssignRollNumber(petitionSessionUid: $sessionUid){
            code
            data
            errorDescription
            status
        }
    }
`;

export const GET_ROLL_NUMBERS_BY_SESSION = gql`
    query getRollNumbersBySession($sessionUid: String){
        getRollNumberBySession(petitionSessionUid: $sessionUid)
    }
`;

export const RE_ARRANGE_ROLL_NUMBER = gql`
    mutation reArrangeRollNumber($petitionUid: String, $rollNumber: Int){
        reArrangeRollNumber(petitionUid: $petitionUid, rollNumber: $rollNumber){
            code
            data{
                ${petitionersGqlFields}
            }
            errorDescription
            status
        }
    }
`;

export const GET_LEGAL_VIEW_PETITIONERS_PENDING = gql`
    query getLegalViewPetitioners($dtInput: DataTablesInputInput){
        getLegalViewPetitioners(dtInput: $dtInput){
            data{
                ${petitionersGqlFields}
            }
            recordsFiltered
            recordsTotal
            error
            draw
        }
    }
`;

export const GET_LEGAL_VIEW_PETITIONERS_BY_CURRENT_USER = gql`
    query getLegalViewByCurrentUser{
        getLegalProfessionalViewByCurrentUser{
            ${petitionersGqlFields}
        }
    }
`;

export const PETITIONERS_BY_SESSION_DT = gql`
    query petitionersBySession($dtInput: DataTablesInputInput, $pSessionUid: String){
        getPetitionersBySession(dtInput: $dtInput, petitionSessionUid: $pSessionUid){
            data{
                ${petitionersGqlFields}
            }
            draw
            error
            recordsTotal
            recordsFiltered
        }
    }
`;

export const PETITIONERS_WITH_NO_APPEARANCE_DT = gql`
    query petitionersWithNoAppearance($dtInput: DataTablesInputInput, $pSessionUid: String){
        getPetitionersWithNoApperance(dtInput: $dtInput, petitionSessionUid: $pSessionUid){
            data{
                ${petitionersGqlFields}
            }
            draw
            error
            recordsTotal
            recordsFiltered
        }
    }
`;

export const PETITIONERS_WITH_NO_VENUE_DT = gql`
    query petitionersWithNoVenue($dtInput: DataTablesInputInput, $pSessionUid: String){
        getPetitionersWithNoVenue(dtInput: $dtInput, petitionSessionUid: $pSessionUid){
            data{
                ${petitionersGqlFields}
            }
            draw
            error
            recordsTotal
            recordsFiltered
        }
    }
`;

export const CHANGE_PETITION_APPEARANCE = gql`
    mutation changePetAppearance($appearanceDto: UpdateAppearanceDtoInput){
        changeAppearance(updateAppearanceDto: $appearanceDto){
            code
            data{
                id
                appearDate
            }
            errorDescription
            status
        }
    }
`;

export const CHANGE_PETITIONER_SESSION = gql`
    mutation changePetitionerSession($petitionUid: String,$petitionSessionUid: String){
        changePetitionerSession(petitionUid: $petitionUid, petitionSessionUid: $petitionSessionUid, ){
            code
            status
            data{
                ${petitionersGqlFields}
            }
            errorDescription
        }
    }
`;


export const SHOW_CHANGE_PETITIONER_SESSION = gql`
    query showChangePetitionerSession($petitionUid: String) {
        showChangePetitionerSession(petitionUid: $petitionUid)
    }
`;



