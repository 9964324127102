import {Injectable} from '@angular/core';
import {DatePipe} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../notifications/notification.service';
import {AppState} from '../../store/reducers';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {READ_ATTACHMENT} from '../../store/entities/advocate/application-document/application-document.graphql';
import {ResponseCode} from '../../shared/enums/http-status-codes.enum';
import {ResponseCodesService} from '../response-codes/response-codes.service';
import {HttpHeaders} from '@angular/common/http';
import {GET_ROLL_NUMBERS_BY_SESSION, LIST_OF_PENDING_BAR_EXAM_PETITIONERS, SHOW_CHANGE_PETITIONER_SESSION} from '../../store/entities/management/petition/petition.graphql';
import {AdvocateProfile} from '../../store/entities/advocate/advocate-profile/advocate-profile.model';
import {
    GET_ADVOCATE_PROFILE_BY_USER_PHOTO
} from '../../store/entities/advocate/advocate-profile/advocate-profile.graphql';
import { GroupBill, PreviewGroupBillDto, RenewBillDto } from 'src/app/store/entities/bills/group-bill/group-bill.model';
import { FETCH_GROUP_BILL_BY_UID, PREVIEW_GROUP_BILL } from 'src/app/store/entities/bills/group-bill/group-bill.graphql';
import { User } from 'src/app/store/entities/settings/user/user.model';
import { LIST_OF_STAFF } from 'src/app/store/entities/settings/user/user.graphql';
import { Signature } from 'src/app/store/entities/settings/signature/signature.model';
import { GET_CERTIFICATE_SIGNATURE } from 'src/app/store/entities/settings/signature/signature.graphql';
import { Fee } from 'src/app/store/entities/settings/fee/fee.model';
import { GET_RENEWAL_OUT_OF_TIME_FEES } from 'src/app/store/entities/settings/fee/fee.graphql';
import { Petition } from 'src/app/store/entities/management/petition/petition.model';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {


    totalItemsCount: { itemTitle: string, count: number }[] = [];

    constructor(
        private datePipe: DatePipe,
        public dialog: MatDialog,
        private apollo: Apollo,
        private store: Store<AppState>,
        private translate: TranslateService,
        private notificationService: NotificationService,
        private responseCodesService: ResponseCodesService
    ) {
    }

    formatDate(date): string {
        return this.datePipe.transform(date, 'dd/MM/y');
    }

    encoder(data, loop = 2, key = 'secured'): string {
        let returnedData = btoa(data);
        const keyValue = btoa(key);
        returnedData = btoa(keyValue + returnedData);
        for (let i = 0; i < loop; i++) {
            returnedData = btoa(returnedData);
        }
        return returnedData;
    }

    decoder(data, loop = 2, key = 'secured') {
        let returnedData = data;

        for (let i = 0; i < loop; i++) {
            returnedData = atob(returnedData);
        }
        const keyValue = btoa(key);
        returnedData = atob(returnedData);

        return atob(returnedData.substr(keyValue.length));
    }

    getAttachment(uid: string): Observable<string> {
        return this.apollo.query({
            context: {
                headers: new HttpHeaders().set('ngLoader', 'false')
            },
            query: READ_ATTACHMENT,
            variables: {uid}
        }).pipe(
            map(({data}: any) => {
                if (data?.viewApplicationDocument.code === ResponseCode.SUCCESS) {
                    return data?.viewApplicationDocument?.data;
                } else {
                    return this.responseCodesService.errorMessageByMessage(data?.viewApplicationDocument);
                }
            })
        );
    }

    getCurrentUserProfilePicture(): Observable<AdvocateProfile> {
        return this.apollo.query({
            context: {
                headers: new HttpHeaders().set('ngLoader', 'false')
            },
            fetchPolicy: 'network-only',
            query: GET_ADVOCATE_PROFILE_BY_USER_PHOTO,
        }).pipe(
            map(({data}: any) => {
                if (data?.getProfileByUser.code === ResponseCode.SUCCESS) {
                    return data?.getProfileByUser?.data;
                }
            })
        );
    }

    getRollNumbers(sessionUid: string): Observable<number[]> {
        return this.apollo.query({
            /*            context: {
                            headers: new HttpHeaders().set('ngLoader', 'false')
                        },*/
            query: GET_ROLL_NUMBERS_BY_SESSION,
            variables: {sessionUid}
        }).pipe(
            map(({data}: any) => {
                return data?.getRollNumberBySession || [];
            })
        );
    }

    convertEnumToArray(receivedEnum): any[] {
        return Object.values(receivedEnum);
    }

    getFullNameWithTitle(title: string, fullName: string): string {
        if (title) {
            if (title.includes('.')) {
                return `${title} ${fullName}`?.toLowerCase();
            } else {
                return `${title}. ${fullName}`?.toLowerCase();
            }
        } else {
            return fullName?.toLowerCase();
        }
    }

    updateTotal(count: number, itemTitle: string) {
        this.totalItemsCount.push({itemTitle, count});
    }

    getCount(itemTitle: string): number {
        let count = 0;
        const foundStatus = this.totalItemsCount?.find(appS => appS.itemTitle === itemTitle);
        if (foundStatus) {
            count = foundStatus.count;
        }
        return count;
    }

    getSumOfItems() {
        return this.totalItemsCount.map(item => item.count).reduce((sum, current) => sum + current, 0);
    }

    switchLang(lang: string): void {
        this.translate.use(lang);
        localStorage.setItem('lang', lang);
    }

    getCurrentLang(): string {
        const currentLang = localStorage.getItem('lang');
        return currentLang ? currentLang : 'sw';
    }

    downloadPDF(
        format: string | 'pdf',
        fileName: string | 'mdms.pdf',
        base64: string,
        dataFormat: string | 'application/pdf'
    ) {
        const linkSource = 'data:' + dataFormat + ';base64,' + base64;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    uploadedFile(event) {
        const uploaded = event.target.files[0] as File;
        const reader = new FileReader();
        return {
            fileName: uploaded.name,
            fileSize: parseFloat(((uploaded.size) / 1000000).toFixed(5)) + 'MB',
        };
    }

    previewGroupBill(billDetails: PreviewGroupBillDto): Observable<RenewBillDto> {
        return this.apollo.query({
            context: {
                headers: new HttpHeaders().set('ngLoader', 'false')
            },
            fetchPolicy: 'network-only',
            query: PREVIEW_GROUP_BILL,
            variables: {
                billDetails: billDetails
            }
        }).pipe(
            map(({data}: any) => {
                if (data?.previewGroupBill.code === ResponseCode.SUCCESS) {
                    return data?.previewGroupBill?.data;
                }
            })
        );
    }

    getGroupBillByUid(billUid: string): Observable<GroupBill> {
        return this.apollo.query({
            context: {
                headers: new HttpHeaders().set('ngLoader', 'false')
            },
            fetchPolicy: 'network-only',
            query: FETCH_GROUP_BILL_BY_UID,
            variables: {
                billUid: billUid
            }
        }).pipe(
            map(({data}: any) => {
                if (data?.viewGroupBill.code === ResponseCode.SUCCESS) {
                    return data?.viewGroupBill?.data;
                }
            })
        );
    }

    showChangePetitionerSession(petitionUid: string): Observable<Boolean> {
        return this.apollo.query({
            context: {
                headers: new HttpHeaders().set('ngLoader', 'false')
            },
            fetchPolicy: 'network-only',
            query: SHOW_CHANGE_PETITIONER_SESSION,
            variables: {
                petitionUid: petitionUid
            }
        }).pipe(
            map(({data}: any) => {
                    return data?.showChangePetitionerSession;
            })
        );
    }

    getStaffList(): Observable<User[]> {
        return this.apollo.query({
            context: {
                headers: new HttpHeaders().set('ngLoader', 'false')
            },
            fetchPolicy: 'network-only',
            query: LIST_OF_STAFF
        }).pipe(
            map(({data}: any) => {
                if (data?.getStaffList?.length != -1) {
                    return data?.getStaffList;
                }
            })
        );
    }

    getCertificateSignature(userType: string, appUid: string): Observable<Signature> {
        return this.apollo.query({
            context: {
                headers: new HttpHeaders().set('ngLoader', 'false')
            },
            fetchPolicy: 'network-only',
            query: GET_CERTIFICATE_SIGNATURE,
            variables: {
                userType: userType,
                appUid: appUid
            }
        }).pipe(
            map(({data}: any) => {
                if (data?.getCertificateSignature.code === ResponseCode.SUCCESS) {
                    return data?.getCertificateSignature?.data;
                }
            })
        );
    }

    getRenewalOutOfTimeFees(applicationUid: string): Observable<Fee[]> {
        return this.apollo.query({
            context: {
                headers: new HttpHeaders().set('ngLoader', 'false')
            },
            fetchPolicy: 'network-only',
            query: GET_RENEWAL_OUT_OF_TIME_FEES,
            variables: {
                applicationUid: applicationUid
            }
        }).pipe(
            map(({data}: any) => {
                return data?.getRenewalOutOfTimeFees;
            })
        );
    }

    getPendingBarExamPetitioners(sessionUid: string): Observable<Petition[]> {
        return this.apollo.query({
            context: {
                headers: new HttpHeaders().set('ngLoader', 'false')
            },
            fetchPolicy: 'network-only',
            query: LIST_OF_PENDING_BAR_EXAM_PETITIONERS,
            variables: {
                sessionUid: sessionUid
            }
        }).pipe(
            map(({data}: any) => {
                return data?.getPendingBarExam;
            })
        );
    }
}
