import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { CoramPetition } from './coram-petition.model';

export const loadCoramPetition = createAction(
  '[CoramPetition/API] Load CoramPetition',
  props<{ coramPetition: CoramPetition }>()
);

export const loadCoramPetitions = createAction(
  '[CoramPetition/API] Load CoramPetitions',
  props<{ coramPetitions: CoramPetition[] }>()
);

export const addCoramPetition = createAction(
  '[CoramPetition/API] Add CoramPetition',
  props<{ coramPetition: CoramPetition }>()
);

export const upsertCoramPetition = createAction(
  '[CoramPetition/API] Upsert CoramPetition',
  props<{ coramPetition: CoramPetition }>()
);

export const addCoramPetitions = createAction(
  '[CoramPetition/API] Add CoramPetitions',
  props<{ coramPetitions: CoramPetition[] }>()
);

export const upsertCoramPetitions = createAction(
  '[CoramPetition/API] Upsert CoramPetitions',
  props<{ coramPetitions: CoramPetition[] }>()
);

export const updateCoramPetition = createAction(
  '[CoramPetition/API] Update CoramPetition',
  props<{ coramPetition: Update<CoramPetition> }>()
);

export const updateCoramPetitions = createAction(
  '[CoramPetition/API] Update CoramPetitions',
  props<{ coramPetitions: Update<CoramPetition>[] }>()
);

export const deleteCoramPetition = createAction(
  '[CoramPetition/API] Delete CoramPetition',
  props<{ id: string }>()
);

export const deleteCoramPetitions = createAction(
  '[CoramPetition/API] Delete CoramPetitions',
  props<{ ids: string[] }>()
);

export const clearCoramPetitions = createAction(
  '[CoramPetition/API] Clear CoramPetitions'
);


/*
*  effect actions
* */
export const fetchAllPetitionersByCoram = createAction(
    '[CoramPetition/API] fetch all petitioners by coram uid from the server',
    props<{coramUid: string}>()
);
