export interface UserType {
  name: string;
  displayName: string;
  value: number;
}
export interface UserCategory {
  name: string;
  displayName: string;
  value: number;
}
export const userTypes: UserType[] = [
  {
    name: 'RELIGIOUS_REPRESENTATIVE',
    displayName: 'Religious representative',
    value: 1,
  },
  {
    name: 'REGISTRATION_OFFICER',
    displayName: 'Registration officer',
    value: 2,
  },
  {
    name: 'REGISTRATION_MANAGER',
    displayName: 'Registration manager',
    value: 3,
  },
  {
    name: 'REGISTRATOR_GENERAL',
    displayName: 'Registrator general',
    value: 4,
  },
  {
    name: 'DISTRICT_REGISTRAR',
    displayName: 'District registrar',
    value: 5,
  },
];

export enum UserCategoryEnum {
  STAFF = 1,
  NON_STAFF = 2,
}
export const userCategories: UserCategory[] = [
  {
    name: 'STAFF',
    displayName: 'Staff',
    value: 1,
  },
  {
    name: 'NON_STAFF',
    displayName: 'Non staff',
    value: 2,
  },
];
export const genders = [
  { displayName: 'Male', name: 'MALE' },
  { displayName: 'Female', name: 'FEMALE' },
];
export const maritalStatuses = [
  { displayName: 'Single', name: 'SINGLE' },
  { displayName: 'Married', name: 'MARRIED' },
  { displayName: 'Divorced', name: 'DIVORCED' },
];
export const localNationality = 'Tanzania, United Republic of';
