import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ReconciliationBatch } from './reconciliation-batch.model';
import * as ReconciliationBatchActions from './reconciliation-batch.actions';

export const reconciliationBatchesFeatureKey = 'reconciliationBatches';

export interface State extends EntityState<ReconciliationBatch> {
  // additional entities state properties
}

export const adapter: EntityAdapter<ReconciliationBatch> = createEntityAdapter<ReconciliationBatch>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(ReconciliationBatchActions.addReconciliationBatch,
    (state, action) => adapter.addOne(action.reconciliationBatch, state)
  ),
  on(ReconciliationBatchActions.upsertReconciliationBatch,
    (state, action) => adapter.upsertOne(action.reconciliationBatch, state)
  ),
  on(ReconciliationBatchActions.addReconciliationBatchs,
    (state, action) => adapter.addMany(action.reconciliationBatchs, state)
  ),
  on(ReconciliationBatchActions.upsertReconciliationBatchs,
    (state, action) => adapter.upsertMany(action.reconciliationBatchs, state)
  ),
  on(ReconciliationBatchActions.updateReconciliationBatch,
    (state, action) => adapter.updateOne(action.reconciliationBatch, state)
  ),
  on(ReconciliationBatchActions.updateReconciliationBatchs,
    (state, action) => adapter.updateMany(action.reconciliationBatchs, state)
  ),
  on(ReconciliationBatchActions.deleteReconciliationBatch,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ReconciliationBatchActions.deleteReconciliationBatchs,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ReconciliationBatchActions.loadReconciliationBatchs,
    (state, action) => adapter.setAll(action.reconciliationBatchs, state)
  ),
  on(ReconciliationBatchActions.clearReconciliationBatchs,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
