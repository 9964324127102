import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {ResponseCode} from '../../../../shared/enums/http-status-codes.enum';
import {Store} from '@ngrx/store';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {
    changePetitionerName,
    editAdvocateProfile,
    enrollPetitionersInCurrentSession,
    getAdvocateProfileByUser,
    refreshSubmitButton,
    saveAdvocateProfile,
    upsertAdvocateProfile
} from './advocate-profile.actions';
import {
    CHANGE_PETITIONER_FULLNAME,
    EDIT_ADVOCATE_PROFILE,
    ENROLL_PETITIONERS,
    GET_ADVOCATE_PROFILE_BY_USER,
    GET_FULL_PROFILE_DETAILS_BY_UID,
    GET_FULL_PROFILE_DETAILS_BY_USER,
    SAVE_ADVOCATE_PROFILE
} from './advocate-profile.graphql';
import {
    getFullAdvocateProfileDetails,
    getFullProfileDetails,
    loadFullProfileDetail
} from '../../management/full-profile-detail/full-profile-detail.actions';
import {FullProfileDetail} from '../../management/full-profile-detail/full-profile-detail.model';


@Injectable()
export class AdvocateProfileEffects {

    getProfileByLoggedInUser$ = createEffect(() => this.actions$.pipe(
            ofType(getAdvocateProfileByUser),
            switchMap((action) => {
                return this.apollo.query({
                    query: GET_ADVOCATE_PROFILE_BY_USER,
                    fetchPolicy: 'network-only',
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data) {
                                if (data?.getProfileByUser?.code === ResponseCode.SUCCESS) {
                                    this.store.dispatch(upsertAdvocateProfile({advocateProfile: data?.getProfileByUser?.data}));
                                } else {
                                    // return this.responseCodesService.errorMessageByCode(data.getProfileByUser.code);
                                }
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    getAdvocateFullProfileDetailsByUid$ = createEffect(() => this.actions$.pipe(
            ofType(getFullProfileDetails),
            switchMap((action) => {
                return this.apollo.query({
                    query: GET_FULL_PROFILE_DETAILS_BY_UID,
                    fetchPolicy: 'network-only',
                    variables: {
                        uid: action.applicationUid
                    }
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data) {
                                const returnedProfile: any = Object.values(data)[0];
                                // the returned result has no id, so assign id manually since one record is returned
                                // returnedProfile.id = 1;
                                // Object.assign({}, returnedProfile, {id: 1});
                                this.store.dispatch(loadFullProfileDetail({
                                    fullProfileDetail: Object.assign({}, returnedProfile, {id: 1}) as FullProfileDetail
                                }));
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    getAdvocateFullProfileDetails$ = createEffect(() => this.actions$.pipe(
            ofType(getFullAdvocateProfileDetails),
            switchMap((action) => {
                return this.apollo.query({
                    query: GET_FULL_PROFILE_DETAILS_BY_USER,
                    fetchPolicy: 'network-only',
                    variables: {
                        userUid: action.userUid || null
                    }
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data) {
                                const returnedProfile: any = Object.values(data)[0];
                                // the returned result has no id, so assign id manually since one record is returned
                                // returnedProfile.id = 1;
                                this.store.dispatch(loadFullProfileDetail({
                                    fullProfileDetail: Object.assign({}, returnedProfile, {id: 1}) as FullProfileDetail
                                }));
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    newAdvocateProfile$ = createEffect(() => this.actions$.pipe(
            ofType(saveAdvocateProfile),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: SAVE_ADVOCATE_PROFILE,
                    variables: {
                        profile: action.profile
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.saveProfile?.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(upsertAdvocateProfile({advocateProfile: data?.saveProfile?.data}));
                                this.store.dispatch(refreshSubmitButton());
                                this.store.dispatch(getFullAdvocateProfileDetails({}));
                                return this.notificationService.successMessage('data saved successfully');
                            } else {
                                return this.responseCodesService.errorMessageByMessage(data.saveProfile);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    editProfile$ = createEffect(() => this.actions$.pipe(
            ofType(editAdvocateProfile),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: EDIT_ADVOCATE_PROFILE,
                    variables: {
                        profile: action.profile,
                        uid: action.uid
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.updateProfile?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(upsertAdvocateProfile({advocateProfile: data.updateProfile.data}));
                            this.store.dispatch(getFullAdvocateProfileDetails({}));
                            return this.notificationService.successMessage('Edited Successfully');
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.updateProfile);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    enrollPetitioner$ = createEffect(() => this.actions$.pipe(
            ofType(enrollPetitionersInCurrentSession),
            switchMap((action) => {
                return this.apollo.query({
                    query: ENROLL_PETITIONERS,
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.enrollPetitioners?.code === ResponseCode.SUCCESS) {
                            const message = `${data?.enrollPetitioners?.data} petitioners have been enrolled successfully`;
                            return this.notificationService.successMessageSwalFire(message);
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.enrollPetitioners);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    editOnlyFullName$ = createEffect(() => this.actions$.pipe(
            ofType(changePetitionerName),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: CHANGE_PETITIONER_FULLNAME,
                    variables: {
                        uuid: action.uuid,
                        fullname: action.name
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.updatePetitionerFullname?.code === ResponseCode.SUCCESS) {
                            // this.store.dispatch(upsertAdvocateProfile({advocateProfile: data.updatePetitionerFullname.data}));
                            // this.store.dispatch(getFullAdvocateProfileDetails({}));
                            return this.notificationService.successMessage('Edited Successfully');
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.updatePetitionerFullname);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService
    ) {
    }
}
