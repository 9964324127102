import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, map, switchMap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {
    GET_GROUP_BILLS,
    FETCH_GROUP_BILL_BY_UID,
    PREVIEW_GROUP_BILL,
    SAVE_GROUP_BILL
} from './group-bill.graphql';
import {
    fetchGroupBillByUid,
    previewGroupBill,
    saveNewGroupBill,
    upsertGroupBill
} from './group-bill.actions';
import {GroupBill} from './group-bill.model';
import {ResponseCode} from '../../../../shared/enums/http-status-codes.enum';


@Injectable()
export class GroupBillEffects {

    fetchGroupBillByUid$ = createEffect(() => this.actions$.pipe(
            ofType(fetchGroupBillByUid),
            switchMap((action) => {
                return this.apollo.query({
                    query: FETCH_GROUP_BILL_BY_UID,
                    fetchPolicy: 'network-only',
                    variables: {
                        billUid: action.groupBillUid
                    }
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.viewGroupBill?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(upsertGroupBill({groupBill: data.viewGroupBill.data as GroupBill}));
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.viewGroupBill);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    previewGroupBill$ = createEffect(() => this.actions$.pipe(
            ofType(previewGroupBill),
            switchMap((action) => {
                return this.apollo.query({
                    query: PREVIEW_GROUP_BILL,
                    fetchPolicy: 'network-only',
                    variables: {
                        billDetails: action.previewGroupBillDto
                    }
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.previewGroupBill?.code === ResponseCode.SUCCESS) {
                            return data?.previewGroupBil;
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.previewGroupBill);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    saveNewGroupBill$ = createEffect(() => this.actions$.pipe(
        ofType(saveNewGroupBill),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: SAVE_GROUP_BILL,
                variables: {
                    billDetail: action.createGroupBillDto
                },
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        if (data?.createGroupBill?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(upsertGroupBill({groupBill: data?.createGroupBill?.data}));
                            return this.notificationService.successMessage('Bill created successfully');
                        } else {
                            return this.responseCodesService.errorMessageByCode(data.createGroupBill.code);
                        }
                    }
                })
            );
        })
    ),
    {dispatch: false}
);

    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService
    ) {
    }

}
