import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { FeeType } from './fee-type.model';
import * as FeeTypeActions from './fee-type.actions';

export const feeTypesFeatureKey = 'feeTypes';

export interface State extends EntityState<FeeType> {
  // additional entities state properties
}

export const adapter: EntityAdapter<FeeType> = createEntityAdapter<FeeType>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(FeeTypeActions.addFeeType,
    (state, action) => adapter.addOne(action.feeType, state)
  ),
  on(FeeTypeActions.upsertFeeType,
    (state, action) => adapter.upsertOne(action.feeType, state)
  ),
  on(FeeTypeActions.addFeeTypes,
    (state, action) => adapter.addMany(action.feeTypes, state)
  ),
  on(FeeTypeActions.upsertFeeTypes,
    (state, action) => adapter.upsertMany(action.feeTypes, state)
  ),
  on(FeeTypeActions.updateFeeType,
    (state, action) => adapter.updateOne(action.feeType, state)
  ),
  on(FeeTypeActions.updateFeeTypes,
    (state, action) => adapter.updateMany(action.feeTypes, state)
  ),
  on(FeeTypeActions.deleteFeeType,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(FeeTypeActions.deleteFeeTypes,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(FeeTypeActions.loadFeeTypes,
    (state, action) => adapter.setAll(action.feeTypes, state)
  ),
  on(FeeTypeActions.clearFeeTypes,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
