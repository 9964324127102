import {Injectable} from '@angular/core';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(
        private router: Router,
        private snackbar: MatSnackBar,
    ) {
    }

    successSnackBar(message, closeText = 'Dismiss', duration = 5000, verticalPosition: any = 'top', horizontalPosition: any = 'center') {
        this.snackbar.open(message, closeText, {
            duration,
            verticalPosition,
            horizontalPosition,
            panelClass: ['green-snackbar']
        });
    }

    errorSnackBar(message, closeText = 'Dismiss', duration = 5000, verticalPosition: any = 'top', horizontalPosition: any = 'center') {
        this.snackbar.open(message, closeText, {
            duration,
            verticalPosition,
            horizontalPosition,
            panelClass: ['danger-snackbar']
        });
    }

    successMessage(message: string) {
        this.successSnackBar(message);
    }

    errorMessage(message: string) {
        this.errorSnackBar(message);
    }

    successMessageSwalFire(message: string) {
        return Swal.fire(
            {
                title: message,
                text: '',
                icon: 'success',
                allowOutsideClick: false
            }
        );
    }

    errorMessageSwalFire(message: string) {

        return Swal.fire(
            {
                title: 'Attention',
                text: message,
                icon: 'error',
                allowOutsideClick: false
            }
        );
    }

    noErrorCode() {
        return Swal.fire(
            {
                title: 'Unknown Error Code Returned',
                text: '',
                icon: 'error',
                allowOutsideClick: false
            }
        );
    }

    catchError() {
        return catchError((error) => {

            Swal.fire(
                {
                    icon: 'error',
                    title: 'Internal Server Error',
                    // text: 'Unable to access resources',
                    text: error,
                }
            );
            return of([]);
        });
    }

    confirmation(message: string = 'Are you sure?', confirmButtonText: string = 'YES') {
        return Swal.fire({
            text: message,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText,
            allowOutsideClick: false
        });
    }

    confirmationInvitation(message: string = 'Are you sure?', confirmButtonText: string = 'YES') {
        return Swal.fire({
            title: message,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Reject',
            confirmButtonText,
            allowOutsideClick: false
        });
    }

    async successMessageWithRedirect(message: string, route: string) {
        this.successSnackBar(message);

        return this.router.navigate([route]);

    }

}
