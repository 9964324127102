import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {ResponseCode} from '../../../../shared/enums/http-status-codes.enum';
import {getFullAdvocateProfileDetails} from '../full-profile-detail/full-profile-detail.actions';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {newAdvocateStatus, upsertAdvocateChangeStatus} from "./advocate-change-status.actions";
import {CHANGE_ADVOCATE_STATUS} from "./advocate-change-status.graphql";


@Injectable()
export class AdvocateChangeStatusEffects {

    /*    allPetitionerForCJInterview$ = createEffect(() => this.actions$.pipe(
            ofType(fetchPetitionsByAppearanceUid),
            switchMap((action) => {
                return this.apollo.query({
                    query: LIST_PETITIONERS_BY_APPEARANCE,
                    fetchPolicy: 'network-only',
                    variables: {
                        appearanceUid: action.appearanceUid
                    }
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            this.store.dispatch(upsertPetitions({
                                petitions:
                                    Object.values(data)[0] as Petition[]
                            }));
                        }
                    })
                );
            })
        ), {dispatch: false});*/

    advocateChangeStatus$ = createEffect(() => this.actions$.pipe(
            ofType(newAdvocateStatus),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: CHANGE_ADVOCATE_STATUS,
                    variables: {
                        advocateStatus: action.statusData,
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.updateAdvocateStatus?.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(upsertAdvocateChangeStatus({advocateChangeStatus: data?.updateAdvocateStatus?.data}));
                                this.store.dispatch(getFullAdvocateProfileDetails({userUid: action.statusData?.profileUid}));
                                return this.notificationService.successMessage('Advocate updated successfully');
                            } else {
                                return this.responseCodesService.errorMessageByMessage(data.updateAdvocateStatus);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService
    ) {
    }

}
