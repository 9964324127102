<app-auth-layout>
    <ng-template #AuthContent>
        <ng-container *ngIf="!loginView">
            <div class="auth-wrapper">
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-xl-4 col-lg-6 col-md-7 my-auto p-0">
                            <div class="authentication-form mx-auto">
                                <div class="logo-centered">
                                    <img src="assets/images/Judiciary-Logo.png" style="height:60px; width: 60px;"
                                         alt="logo icon">
                                </div>
                                <h3>Report a forgotten password!</h3>

                                <div class="alert alert-info" role="alert">
                                    Submit the email address that you use to access your account at e-Wakili in order to
                                    get a password reset link.
                                </div>

                                <form (ngSubmit)="save(forgotPasswordForm.value)" [formGroup]="forgotPasswordForm"
                                      autocomplete="off" novalidate>
                                    <div class="form-group">
                                        <input type="text" formControlName="email" class="form-control is-valid"
                                               placeholder="Email">
                                        <i class="ik ik-user"></i>
                                    </div>
                                    <span *ngIf="loading" class="d-block w-100 mb-2" style="margin-top: -4px">
                                       <mat-progress-bar color="warn" mode="indeterminate"></mat-progress-bar>
                                    </span>
                                    <div class="sign-btn text-center">
                                        <button class="btn btn-theme" [disabled]="!forgotPasswordForm.valid || loading"
                                                data-loading-text="Processing.....">Submit
                                        </button>
                                    </div>
                                </form>
                                <div class="register">
                                    <p>Remember password? <a style="color:blue;" routerLink="/login"> Back To Login</a>
                                        | <i
                                                class="ik ik-home"></i> <a href="">Home</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-template>
</app-auth-layout>
