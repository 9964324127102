import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Qualification } from './qualification.model';
import * as QualificationActions from './qualification.actions';

export const qualificationsFeatureKey = 'qualifications';

export interface State extends EntityState<Qualification> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Qualification> = createEntityAdapter<Qualification>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(QualificationActions.addQualification,
    (state, action) => adapter.addOne(action.qualification, state)
  ),
  on(QualificationActions.upsertQualification,
    (state, action) => adapter.upsertOne(action.qualification, state)
  ),
  on(QualificationActions.addQualifications,
    (state, action) => adapter.addMany(action.qualifications, state)
  ),
  on(QualificationActions.upsertQualifications,
    (state, action) => adapter.upsertMany(action.qualifications, state)
  ),
  on(QualificationActions.updateQualification,
    (state, action) => adapter.updateOne(action.qualification, state)
  ),
  on(QualificationActions.updateQualifications,
    (state, action) => adapter.updateMany(action.qualifications, state)
  ),
  on(QualificationActions.deleteQualification,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(QualificationActions.deleteQualifications,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(QualificationActions.loadQualifications,
    (state, action) => adapter.setAll(action.qualifications, state)
  ),
  on(QualificationActions.clearQualifications,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
