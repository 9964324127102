import gql from 'graphql-tag';
import {FeeTypeGraphqlFields} from '../fee-type/fee-type.graphql';

export const FeeGraphqlFields = `
    active
    amount
    createdAt
    createdBy{
        id
    }
    currency
    deleted
    deletedAt
    description
    feeType{
        ${FeeTypeGraphqlFields}
    }
    id
    service
    uid
    updatedAt
    updatedBy{
        id
    }
`;


export const GET_ALL_FEES = gql`
    query getAllFees{
        getAllFees{
            ${FeeGraphqlFields}
        }
    }
`;

export const GET_FEE_BY_ID = gql`
    query getFeeById($feeUid:String){
        getFeeById(feeUid:$feeUid){
             code
             errorDescription
             status
             data{
                ${FeeGraphqlFields}
             }
        }
    }
`;

export const SAVE_FEE = gql`
    mutation saveFee($fee:FeeDtoInput){
        saveFee(fee:$fee){
            code
            data{
                ${FeeGraphqlFields}
            }
            status
            errorDescription
        }
    }
`;

export const UPDATE_FEE = gql`
    mutation updateFee($fee:FeeDtoInput,$feeUid:String){
        updateFee(feeDto:$fee,feeUid:$feeUid){
            code
            data{
                ${FeeGraphqlFields}
            }
            status
            errorDescription
        }
    }
`;


export const DELETE_FEE = gql`
    mutation deleteFee($feeUid:String){
        deleteFee(feeUid:$feeUid){
            code
            data{
                ${FeeGraphqlFields}
            }
            status
            errorDescription
        }
    }
`;

export const GET_RENEWAL_OUT_OF_TIME_FEES = gql`
    query getRenewalOutOfTimeFees($applicationUid:String){
        getRenewalOutOfTimeFees(applicationUid:$applicationUid){
            ${FeeGraphqlFields}
        }
    }
`;





