import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../services/notifications/notification.service';
import {AppState} from '../../reducers';
import {ResponseCodesService} from '../../../services/response-codes/response-codes.service';
import {map, switchMap} from 'rxjs/operators';
import {showRenewalButton, updateRenewalButton} from './auth-user.actions';
import {SHOW_RENEWAL_BUTTON} from './auth-user.graphql';

@Injectable()
export class AuthUserEffects {


    showRenewalButton$ = createEffect(() => this.actions$.pipe(
        ofType(showRenewalButton),
        switchMap((action) => {
            return this.apollo
                .query({
                    query: SHOW_RENEWAL_BUTTON,
                    fetchPolicy: 'network-only',
                })
                .pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            this.store.dispatch(updateRenewalButton({renewalButton: data.showRenewButton}));
                        }
                    })
                );
        })
    ), {dispatch: false});

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService,
        private apollo: Apollo,
        private notificationService: NotificationService
    ) {
    }

}
