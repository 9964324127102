import {Action, ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import * as fromAuthUser from '../entities/auth-user/auth-user.reducer';
import * as fromUser from '../entities/settings/user/user.reducer';
import * as fromRole from '../entities/settings/role/role.reducer';
import * as fromPermission from '../entities/settings/permission/permission.reducer';
import * as fromWorkFlowProcess from '../entities/settings/work-flow-process/work-flow-process.reducer';
import * as fromWorkFlowStage from '../entities/settings/work-flow-stage/work-flow-stage.reducer';
import * as fromCountry from '../entities/settings/country/country.reducer';
import * as fromAdvocateProfile from '../entities/advocate/advocate-profile/advocate-profile.reducer';
import * as fromPermissionGroup from '../entities/settings/permission-group/permission-group.reducer';
import * as fromActionUserType from '../entities/settings/action-user-type/action-user-type.reducer';
import * as fromQualification from '../entities/settings/qualification/qualification.reducer';
import * as fromAttachmentTypes from '../entities/settings/attachment-type/attachment-type.reducer';
import * as fromWorkExperience from '../entities/advocate/work-experience/work-experience.reducer';
import * as fromProfileAddress from '../entities/advocate/profile-address/profile-address.reducer';
import * as fromRegion from '../entities/settings/region/region.reducer';
import * as fromDistrict from '../entities/settings/district/district.reducer';
import * as fromPetitionEducation from '../entities/advocate/petition-education/petition-education.reducer';
import * as fromProfileContact from '../entities/advocate/profile-contact/profile-contact.reducer';
import * as fromApplicationDocument from '../entities/advocate/application-document/application-document.reducer';
import * as fromPetitionApplication from '../entities/management/application/application.reducer';
import * as fromFirm from '../entities/advocate/firm/firm.reducer';
import * as fromFirmBranch from '../entities/advocate/firm-branch/firm-branch.reducer';
import * as fromFirmMember from '../entities/advocate/firm-member/firm-member.reducer';
import * as fromFullProfileDetail from '../entities/management/full-profile-detail/full-profile-detail.reducer';
import * as fromPetitionSession from '../entities/settings/petition-session/petition-session.reducer';
import * as fromActionUserDecision from '../entities/management/user-action-decision/user-action-decision.reducer';
import * as fromAdvocate from '../entities/advocate/advocate/advocate.reducer';
import * as fromCleMember from '../entities/settings/cle-member/cle-member.reducer';
import * as fromCleCoram from '../entities/management/cle-coram/cle-coram.reducer';
import * as fromPetitionByCoram from '../entities/management/coram-petition/coram-petition.reducer';
import * as fromRenewalBatch from '../entities/settings/renewal-batch/renewal-batch.reducer';
import * as fromPetition from '../entities/management/petition/petition.reducer';
import * as fromAppearanceVenue from '../entities/settings/appearance-venue/appearance-venue.reducer';
import * as fromBarExam from '../entities/management/bar-exam/bar-exam.reducer';
import * as fromAppearance from '../entities/management/appearance/appearance.reducer';
import * as fromFeeType from '../entities/settings/fee-type/fee-type.reducer';
import * as fromFee from '../entities/settings/fee/fee.reducer';
import * as fromBill from '../entities/bills/bill/bill.reducer';
import * as fromLegalProfessionalView from '../entities/management/legal-professional-view/legal-professional-view.reducer';
import * as fromPayment from '../entities/bills/payment/payment.reducer';
import * as fromReconciliation from '../entities/bills/reconciliation/reconciliation.reducer';
import * as fromReconciliationBatch from '../entities/bills/reconciliation-batch/reconciliation-batch.reducer';
import * as fromAppNotification from '../entities/management/app-notification/app-notification.reducer';
import * as fromAdvocateChangeStatus from '../entities/management/advocate-change-status/advocate-change-status.reducer';
import * as fromGroupBill from '../entities/bills/group-bill/group-bill.reducer';
import * as fromSignature from '../entities/settings/signature/signature.reducer';


export interface AppState {
    [fromAuthUser.authUsersFeatureKey]: fromAuthUser.State;
    [fromUser.usersFeatureKey]: fromUser.State;
    [fromRole.rolesFeatureKey]: fromRole.State;
    [fromPermission.permissionsFeatureKey]: fromPermission.State;
    [fromWorkFlowProcess.workFlowProcessesFeatureKey]: fromWorkFlowProcess.State;
    [fromWorkFlowStage.workFlowStagesFeatureKey]: fromWorkFlowStage.State;
    [fromCountry.countriesFeatureKey]: fromCountry.State;
    [fromAdvocateProfile.advocateProfilesFeatureKey]: fromAdvocateProfile.State;
    [fromPermissionGroup.permissionGroupsFeatureKey]: fromPermissionGroup.State;
    [fromActionUserType.actionUserTypesFeatureKey]: fromActionUserType.State;
    [fromQualification.qualificationsFeatureKey]: fromQualification.State;
    [fromAttachmentTypes.attachmentTypesFeatureKey]: fromAttachmentTypes.State;
    [fromWorkExperience.workExperiencesFeatureKey]: fromWorkExperience.State;
    [fromProfileAddress.profileAddressesFeatureKey]: fromProfileAddress.State;
    [fromRegion.regionsFeatureKey]: fromRegion.State;
    [fromDistrict.districtsFeatureKey]: fromDistrict.State;
    [fromPetitionEducation.petitionEducationsFeatureKey]: fromPetitionEducation.State;
    [fromProfileContact.profileContactsFeatureKey]: fromProfileContact.State;
    [fromApplicationDocument.applicationDocumentsFeatureKey]: fromApplicationDocument.State;
    [fromFirm.firmsFeatureKey]: fromFirm.State;
    [fromFirmBranch.firmBranchesFeatureKey]: fromFirmBranch.State;
    [fromPetitionApplication.applicationsFeatureKey]: fromPetitionApplication.State;
    [fromFirmMember.firmMembersFeatureKey]: fromFirmMember.State;
    [fromFullProfileDetail.fullProfileDetailsFeatureKey]: fromFullProfileDetail.State;
    [fromPetitionSession.petitionSessionsFeatureKey]: fromPetitionSession.State;
    [fromActionUserDecision.userActionDecisionsFeatureKey]: fromActionUserDecision.State;
    [fromAdvocate.advocatesFeatureKey]: fromAdvocate.State;
    [fromCleMember.cleMembersFeatureKey]: fromCleMember.State;
    [fromCleCoram.cleCoramsFeatureKey]: fromCleCoram.State;
    [fromPetitionByCoram.coramPetitionsFeatureKey]: fromPetitionByCoram.State;
    [fromRenewalBatch.renewalBatchesFeatureKey]: fromRenewalBatch.State;
    [fromPetition.petitionsFeatureKey]: fromPetition.State;
    [fromAppearanceVenue.appearanceVenuesFeatureKey]: fromAppearanceVenue.State;
    [fromBarExam.barExamsFeatureKey]: fromBarExam.State;
    [fromFeeType.feeTypesFeatureKey]: fromFeeType.State;
    [fromFee.feesFeatureKey]: fromFee.State;
    [fromAppearance.appearancesFeatureKey]: fromAppearance.State;
    [fromBill.billsFeatureKey]: fromBill.State;
    [fromLegalProfessionalView.legalProfessionalViewsFeatureKey]: fromLegalProfessionalView.State;
    [fromPayment.paymentsFeatureKey]: fromPayment.State;
    [fromReconciliation.reconciliationsFeatureKey]: fromReconciliation.State;
    [fromReconciliationBatch.reconciliationBatchesFeatureKey]: fromReconciliationBatch.State;
    [fromAppNotification.appNotificationsFeatureKey]: fromAppNotification.State;
    [fromAdvocateChangeStatus.advocateChangeStatusesFeatureKey]: fromAdvocateChangeStatus.State;
    [fromGroupBill.groupBillsFeatureKey]: fromGroupBill.State;
    [fromSignature.signaturesFeatureKey]: fromSignature.State;
}

export const reducers: ActionReducerMap<AppState> = {
    [fromAuthUser.authUsersFeatureKey]: fromAuthUser.reducer,
    [fromUser.usersFeatureKey]: fromUser.reducer,
    [fromRole.rolesFeatureKey]: fromRole.reducer,
    [fromPermission.permissionsFeatureKey]: fromPermission.reducer,
    [fromWorkFlowProcess.workFlowProcessesFeatureKey]: fromWorkFlowProcess.reducer,
    [fromWorkFlowStage.workFlowStagesFeatureKey]: fromWorkFlowStage.reducer,
    [fromPermissionGroup.permissionGroupsFeatureKey]: fromPermissionGroup.reducer,
    [fromCountry.countriesFeatureKey]: fromCountry.reducer,
    [fromAdvocateProfile.advocateProfilesFeatureKey]: fromAdvocateProfile.reducer,
    [fromActionUserType.actionUserTypesFeatureKey]: fromActionUserType.reducer,
    [fromQualification.qualificationsFeatureKey]: fromQualification.reducer,
    [fromAttachmentTypes.attachmentTypesFeatureKey]: fromAttachmentTypes.reducer,
    [fromWorkExperience.workExperiencesFeatureKey]: fromWorkExperience.reducer,
    [fromProfileAddress.profileAddressesFeatureKey]: fromProfileAddress.reducer,
    [fromRegion.regionsFeatureKey]: fromRegion.reducer,
    [fromDistrict.districtsFeatureKey]: fromDistrict.reducer,
    [fromPetitionEducation.petitionEducationsFeatureKey]: fromPetitionEducation.reducer,
    [fromProfileContact.profileContactsFeatureKey]: fromProfileContact.reducer,
    [fromApplicationDocument.applicationDocumentsFeatureKey]: fromApplicationDocument.reducer,
    [fromFirm.firmsFeatureKey]: fromFirm.reducer,
    [fromFirmBranch.firmBranchesFeatureKey]: fromFirmBranch.reducer,
    [fromPetitionApplication.applicationsFeatureKey]: fromPetitionApplication.reducer,
    [fromFirmMember.firmMembersFeatureKey]: fromFirmMember.reducer,
    [fromFullProfileDetail.fullProfileDetailsFeatureKey]: fromFullProfileDetail.reducer,
    [fromPetitionSession.petitionSessionsFeatureKey]: fromPetitionSession.reducer,
    [fromActionUserDecision.userActionDecisionsFeatureKey]: fromActionUserDecision.reducer,
    [fromAdvocate.advocatesFeatureKey]: fromAdvocate.reducer,
    [fromCleMember.cleMembersFeatureKey]: fromCleMember.reducer,
    [fromCleCoram.cleCoramsFeatureKey]: fromCleCoram.reducer,
    [fromPetitionByCoram.coramPetitionsFeatureKey]: fromPetitionByCoram.reducer,
    [fromRenewalBatch.renewalBatchesFeatureKey]: fromRenewalBatch.reducer,
    [fromPetition.petitionsFeatureKey]: fromPetition.reducer,
    [fromAppearanceVenue.appearanceVenuesFeatureKey]: fromAppearanceVenue.reducer,
    [fromBarExam.barExamsFeatureKey]: fromBarExam.reducer,
    [fromFeeType.feeTypesFeatureKey]: fromFeeType.reducer,
    [fromFee.feesFeatureKey]: fromFee.reducer,
    [fromAppearance.appearancesFeatureKey]: fromAppearance.reducer,
    [fromBill.billsFeatureKey]: fromBill.reducer,
    [fromLegalProfessionalView.legalProfessionalViewsFeatureKey]: fromLegalProfessionalView.reducer,
    [fromPayment.paymentsFeatureKey]: fromPayment.reducer,
    [fromReconciliation.reconciliationsFeatureKey]: fromReconciliation.reducer,
    [fromReconciliationBatch.reconciliationBatchesFeatureKey]: fromReconciliationBatch.reducer,
    [fromAppNotification.appNotificationsFeatureKey]: fromAppNotification.reducer,
    [fromAdvocateChangeStatus.advocateChangeStatusesFeatureKey]: fromAdvocateChangeStatus.reducer,
    [fromGroupBill.groupBillsFeatureKey]: fromGroupBill.reducer,
    [fromSignature.signaturesFeatureKey]: fromSignature.reducer,
};

export function clearState(
    reducer: ActionReducer<AppState>
): ActionReducer<AppState> {
    return (state: AppState, action: Action): AppState => {
        if (action.type === '[AuthUser/API] Logout') {
            state = undefined;
        }
        return reducer(state, action);
    };
}

// export const metaReducers: MetaReducer<AppState>[] = [clearState];
export const metaReducers: MetaReducer<AppState>[] = !environment.production
    ? [clearState]
    : [];

// export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];

export const appState = (state: AppState) => state;
