import {Component, ElementRef, ViewChild} from '@angular/core';
import {Spinkit} from 'ng-http-loader';
import {AuthService} from './services/auth/auth.service';
import {SpinnerComponent} from './shared/components/other-views/spinner/spinner.component';
import {TranslateService} from '@ngx-translate/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

declare var require: any;

const htmlToPdfmake = require('html-to-pdfmake');
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'e-Wakili';
    public spinkit = Spinkit;
    public spinner = SpinnerComponent;

    @ViewChild('pdfTable')
    pdfTable!: ElementRef;

    public downloadAsPDF() {
        const pdfTable = this.pdfTable.nativeElement;
        const html = htmlToPdfmake(pdfTable.innerHTML);
        const documentDefinition = {content: html};
        pdfMake.createPdf(documentDefinition).download();

    }

    constructor(
        private auth: AuthService,
        public translate: TranslateService,
    ) {
        this.translate.addLangs(['en', 'sw']);

        if (!localStorage.getItem('lang')) {
            localStorage.setItem('lang', 'sw');
            this.translate.setDefaultLang('sw');
        } else {
            this.translate.setDefaultLang(localStorage.getItem('lang'));
        }
    }
}
