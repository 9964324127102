import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {fetchCurrentActionUserDecisions, loadUserActionDecisions} from './user-action-decision.actions';
import {LIST_ACTION_USER_DECISIONS} from './user-action-decision.graphql';
import {UserActionDecision} from './user-action-decision.model';


@Injectable()
export class UserActionDecisionEffects {

    allActionUserDecisions$ = createEffect(() => this.actions$.pipe(
            ofType(fetchCurrentActionUserDecisions),
            switchMap((action) => {
                return this.apollo
                    .query({
                        query: LIST_ACTION_USER_DECISIONS,
                        fetchPolicy: 'network-only',
                    })
                    .pipe(
                        this.notificationService.catchError(),
                        map(({data}: any) => {
                            if (data) {
                                this.store.dispatch(loadUserActionDecisions({
                                    userActionDecisions:
                                        Object.values(data)[0] as UserActionDecision[]
                                }));
                            }
                        })
                    );
            })
        ),
        {dispatch: false}
    );

    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
    ) {
    }
}
