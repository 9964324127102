import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { District } from './district.model';
import * as DistrictActions from './district.actions';

export const districtsFeatureKey = 'districts';

export interface State extends EntityState<District> {
  // additional entities state properties
}

export const adapter: EntityAdapter<District> = createEntityAdapter<District>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(DistrictActions.addDistrict,
    (state, action) => adapter.addOne(action.district, state)
  ),
  on(DistrictActions.upsertDistrict,
    (state, action) => adapter.upsertOne(action.district, state)
  ),
  on(DistrictActions.addDistricts,
    (state, action) => adapter.addMany(action.districts, state)
  ),
  on(DistrictActions.upsertDistricts,
    (state, action) => adapter.upsertMany(action.districts, state)
  ),
  on(DistrictActions.updateDistrict,
    (state, action) => adapter.updateOne(action.district, state)
  ),
  on(DistrictActions.updateDistricts,
    (state, action) => adapter.updateMany(action.districts, state)
  ),
  on(DistrictActions.deleteDistrict,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(DistrictActions.deleteDistricts,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(DistrictActions.loadDistricts,
    (state, action) => adapter.setAll(action.districts, state)
  ),
  on(DistrictActions.clearDistricts,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
