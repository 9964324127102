import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap, tap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {
    assignPermissionsToRole,
    assignPermissionToRoleFinished,
    assignRoleToUser,
    deleteRole,
    editRole,
    getPermissionsByRoleId,
    listAllRoles,
    newRole,
    removeRole,
    updateRolePermissions,
    upsertRole,
    upsertRoles
} from './role.actions';
import {
    ASSIGN_PERMISSIONS_TO_ROLE,
    ASSIGN_ROLES_TO_USER,
    CREATE_ROLE,
    DELETE_ROLE,
    EDIT_ROLE,
    GET_ALL_PERMISSIONS,
    GET_PERMISSIONS_BY_ROLE_ID,
    LIST_ALL_ROLES,
    UPDATE_ROLE_PERMISSIONS
} from './role.graphql';
import {Role} from './role.model';
import {getAllPermissions, loadPermissions} from '../permission/permission.actions';
import {Permission} from '../permission/permission.model';
import {fetchOneUser} from '../user/user.actions';
import {ResponseCode} from "../../../../shared/enums/http-status-codes.enum";


@Injectable()
export class RoleEffects {
    allRoles$ = createEffect(() => this.actions$.pipe(
        ofType(listAllRoles),
        switchMap((action) => {
            return this.apollo.query({
                query: LIST_ALL_ROLES,
                fetchPolicy: 'network-only'
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        this.store.dispatch(upsertRoles({roles: Object.values(data)[0] as Role[]}));
                    }
                })
            );
        })
    ), {dispatch: false});

    newRole$ = createEffect(() => this.actions$.pipe(
        ofType(newRole),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: CREATE_ROLE,
                variables: {
                    role: action?.role
                }
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        if (data.saveRole.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(upsertRole({role: data.saveRole.data}));
                            return this.notificationService.successMessage('Role saved successfully');
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.saveRole);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});

    editRole$ = createEffect(() => this.actions$.pipe(
        ofType(editRole),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: EDIT_ROLE,
                variables: {
                    role: action.role
                }
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data.updateRole.code === ResponseCode.SUCCESS) {
                        this.store.dispatch(upsertRole({role: data.updateRole.data}));
                        return this.notificationService.successMessage('Edited Successfully');
                    } else {
                        return this.responseCodesService.errorMessageByCode(data.updateRole.code);
                    }
                })
            );
        })
    ), {dispatch: false});

    delRole$ = createEffect(() => this.actions$.pipe(
        ofType(removeRole),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: DELETE_ROLE,
                variables: {
                    roleId: action.id
                }
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data.deleteRole.code === ResponseCode.SUCCESS) {
                        this.store.dispatch(deleteRole({id: data.deleteRole.data.id}));
                        return this.notificationService.successMessage('Role deleted successfully');
                    } else {
                        return this.responseCodesService.errorMessageByCode(data.deleteRole.code);
                    }
                })
            );
        })
    ), {dispatch: false});


    updateRolePermissions$ = createEffect(() => this.actions$.pipe(
        ofType(updateRolePermissions),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: UPDATE_ROLE_PERMISSIONS,
                variables: {
                    permissions: {
                        uuid: action.roleId,
                        permissionIds: action.permissionIds
                    }
                }
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data.assignPermissions.code === ResponseCode.SUCCESS) {
                        this.store.dispatch(upsertRole({role: data.assignPermissions.data}));
                        return this.notificationService.successMessage('Role permissions updated successfully.');
                    } else {
                        return this.responseCodesService.errorMessageByCode(data.assignPermissions.code);
                    }
                })
            );
        })
    ), {dispatch: false});


    assignUserRoles$ = createEffect(() => this.actions$.pipe(
        ofType(assignRoleToUser),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: ASSIGN_ROLES_TO_USER,
                variables: {
                    rolesAssign: {
                        uuid: action.userUuid,
                        roleIds: action.roleIds
                    }
                }
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data.assignRoles.code === ResponseCode.SUCCESS) {
                        // this.store.dispatch(upsertRole({role: data.assignRoles.data}));
                        this.store.dispatch(fetchOneUser({uuid: action.userUuid}));
                        return this.notificationService.successMessage('User role assigned successfully.');
                    } else {
                        return this.responseCodesService.errorMessageByCode(data?.assignRoles?.code);
                    }
                })
            );
        })
    ), {dispatch: false});

    rolePermissions$ = createEffect(() => this.actions$.pipe(
        ofType(getPermissionsByRoleId),
        switchMap((action) => {
            return this.apollo.query({
                query: GET_PERMISSIONS_BY_ROLE_ID,
                fetchPolicy: 'network-only',
                variables: {id: action.roleId}
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        this.store.dispatch(upsertRole({role: data.getRoleById}));
                    }
                })
            );
        })
    ), {dispatch: false});

    getAllPermissions$ = createEffect(() => this.actions$.pipe(
        ofType(getAllPermissions),
        switchMap((action) => {
            return this.apollo.query({
                query: GET_ALL_PERMISSIONS,
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        const permissions = data.getAllPermissions as Permission[];
                        const newPermissionsArray = [] as Permission[];
                        permissions.forEach((permissionGroup, index) => {
                            newPermissionsArray.push({
                                id: index,
                                ...permissionGroup
                            });
                        });
                        this.store.dispatch(loadPermissions({permissions: newPermissionsArray}));
                    }
                })
            );
        })
    ), {dispatch: false});


    saveRolePermissions = createEffect(() => this.actions$.pipe(
        ofType(assignPermissionsToRole),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: ASSIGN_PERMISSIONS_TO_ROLE,
                variables: {
                    permissionIds: action.permissionIds,
                    roleUuid: action.roleUuid
                }
            }).pipe(
                tap(() => this.store.dispatch(assignPermissionToRoleFinished())),
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data.assignPermissionsToRole.code === 'SUCCESS') {
                        return this.notificationService.successMessage('Permissions saved successfully');
                    } else {
                        return this.responseCodesService.errorMessageByCode(data.assignPermissionsToRole);
                    }
                }),
            );
        })
    ), {dispatch: false});

    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService,
    ) {
    }

}
