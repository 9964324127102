import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {FeeType} from './fee-type.model';

export const loadFeeTypes = createAction(
    '[FeeType/API] Load FeeTypes',
    props<{ feeTypes: FeeType[] }>()
);

export const addFeeType = createAction(
    '[FeeType/API] Add FeeType',
    props<{ feeType: FeeType }>()
);

export const upsertFeeType = createAction(
    '[FeeType/API] Upsert FeeType',
    props<{ feeType: FeeType }>()
);

export const addFeeTypes = createAction(
    '[FeeType/API] Add FeeTypes',
    props<{ feeTypes: FeeType[] }>()
);

export const upsertFeeTypes = createAction(
    '[FeeType/API] Upsert FeeTypes',
    props<{ feeTypes: FeeType[] }>()
);

export const updateFeeType = createAction(
    '[FeeType/API] Update FeeType',
    props<{ feeType: Update<FeeType> }>()
);

export const updateFeeTypes = createAction(
    '[FeeType/API] Update FeeTypes',
    props<{ feeTypes: Update<FeeType>[] }>()
);

export const deleteFeeType = createAction(
    '[FeeType/API] Delete FeeType',
    props<{ id: string }>()
);

export const deleteFeeTypes = createAction(
    '[FeeType/API] Delete FeeTypes',
    props<{ ids: string[] }>()
);

export const clearFeeTypes = createAction(
    '[FeeType/API] Clear FeeTypes'
);

export const saveFeeType = createAction(
    '[FeeType/API] Save FeeTypes',
    props<{ feeType: any }>()
);

export const editFeeType = createAction(
    '[FeeType/API] Edit FeeTypes',
    props<{ feeType: any, uid: string }>()
);

export const getAllFeeTypes = createAction(
    '[FeeType/API] Get All FeeTypes'
);

export const getFeeTypeByUid = createAction(
    '[FeeType/API] Get  FeeTypes By Id',
    props<{ feeTypeUid: string }>()
);
