import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {ResponseCode} from '../../../../shared/enums/http-status-codes.enum';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {
    assignAttachmentTypeToQualification,
    deleteQualification,
    editExistingQualification,
    fetchAllQualifications,
    getOneQualificationByUid,
    removerQualification,
    saveNewQualification,
    upsertQualification,
    upsertQualifications
} from './qualification.actions';
import {
    ASSIGN_ATTACHMENT_TYPES_TO_QUALIFICATION,
    DELETE_QUALIFICATION,
    GET_ALL_QUALIFICATIONS,
    GET_ONE_QUALIFICATION_BY_UID,
    SAVE_QUALIFICATION,
    UPDATE_QUALIFICATION
} from './qualification.graphql';
import {Qualification} from './qualification.model';


@Injectable()
export class QualificationEffects {

    allQualifications$ = createEffect(() => this.actions$.pipe(
            ofType(fetchAllQualifications),
            switchMap((action) => {
                return this.apollo.query({
                        query: GET_ALL_QUALIFICATIONS,
                        fetchPolicy: 'network-only',
                    })
                    .pipe(
                        this.notificationService.catchError(),
                        map(({data}: any) => {
                            if (data) {
                                this.store.dispatch(upsertQualifications({qualifications: Object.values(data)[0] as Qualification[]}));
                            }
                        })
                    );
            })
        ),
        {dispatch: false}
    );

    getOneQualification$ = createEffect(() => this.actions$.pipe(
            ofType(getOneQualificationByUid),
            switchMap((action) => {
                return this.apollo.query({
                        query: GET_ONE_QUALIFICATION_BY_UID,
                        fetchPolicy: 'network-only',
                        variables: {
                            uid: action.uid
                        }
                    })
                    .pipe(
                        this.notificationService.catchError(),
                        map(({data}: any) => {
                            if (data) {
                                if (data?.getQualificationById?.code === ResponseCode.SUCCESS) {
                                    this.store.dispatch(upsertQualification({qualification: data?.getQualificationById?.data}));
                                } else {
                                    return this.responseCodesService.errorMessageByCode(data.getQualificationById.code);
                                }
                            }
                        })
                    );
            })
        ),
        {dispatch: false}
    );


    newQualification$ = createEffect(() => this.actions$.pipe(
            ofType(saveNewQualification),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: SAVE_QUALIFICATION,
                    variables: {
                        qualification: action.qualification
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.saveQualification?.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(upsertQualification({qualification: data?.saveQualification?.data}));
                                return this.notificationService.successMessage('Saved successfully');
                            } else {
                                return this.responseCodesService.errorMessageByCode(data.saveQualification.code);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    assignAttachmentToQualification$ = createEffect(() => this.actions$.pipe(
            ofType(assignAttachmentTypeToQualification),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: ASSIGN_ATTACHMENT_TYPES_TO_QUALIFICATION,
                    variables: {
                        attachment: action.attachment
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.assignAttachment?.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(getOneQualificationByUid({uid: action.qualificationUid}));
                                return this.notificationService.successMessage('Assigned successfully');
                            } else {
                                return this.responseCodesService.errorMessageByCode(data.assignAttachment.code);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    editQualification$ = createEffect(() => this.actions$.pipe(
            ofType(editExistingQualification),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: UPDATE_QUALIFICATION,
                    variables: {
                        qualification: action.qualification,
                        uid: action.uid
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.updateQualification?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(upsertQualification({qualification: data.updateQualification.data}));
                            return this.notificationService.successMessage('Edited Successfully');
                        } else {
                            return this.responseCodesService.errorMessageByCode(data.updateQualification.code);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    deleteQualification$ = createEffect(() => this.actions$.pipe(
            ofType(removerQualification),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: DELETE_QUALIFICATION,
                    variables: {
                        uid: action.uid
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.deleteQualification?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(deleteQualification({id: data.deleteQualification.data.id}));
                            return this.notificationService.successMessage('Deleted Successfully');
                        } else {
                            return this.responseCodesService.errorMessageByCode(data.deleteQualification.code);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService
    ) {
    }
}
