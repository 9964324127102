import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import {User, UserQualification} from './user.model';
import {UserCategoryEnum} from '../../../../shared/constants/user.constants';

export const loadUsers = createAction(
    '[User/API] Load Users',
    props<{ users: User[] }>()
);

export const addUser = createAction(
    '[User/API] Add User',
    props<{ user: User }>()
);

export const upsertUser = createAction(
    '[User/API] Upsert User',
    props<{ user: User }>()
);

export const addUsers = createAction(
    '[User/API] Add Users',
    props<{ users: User[] }>()
);

export const upsertUsers = createAction(
    '[User/API] Upsert Users',
    props<{ users: User[] }>()
);

export const updateUser = createAction(
    '[User/API] Update User',
    props<{ user: Update<User> }>()
);

export const updateUsers = createAction(
    '[User/API] Update Users',
    props<{ users: Update<User>[] }>()
);

export const deleteUser = createAction(
    '[User/API] Delete User',
    props<{ id: string }>()
);

export const deleteUsers = createAction(
    '[User/API] Delete Users',
    props<{ ids: string[] }>()
);

export const clearUsers = createAction(
    '[User/API] Clear Users'
);


//// Effect actions
export const fetchAllUsers = createAction(
    '[User/API] Load All User From Server'
);

export const fetchOneUser = createAction(
    '[User/API] get only one user From Server',
    props<{ uuid: string }>()
);

export const getLoggedInUser = createAction(
    '[User/API] get logged in user from the server'
);

export const saveUserQualifications = createAction(
    '[User/API] save user qualifications to the server',
    props<{ userQualification: UserQualification }>()
);

export const activateUser = createAction(
    '[User/API] enable or disable user not login',
    props<{ uuid: string }>()
);

export const newUser = createAction(
    '[User/API] Add New User To Server',
    props<{ user: User }>()
);

export const saveStaff = createAction(
    '[User/API] Save Staff ',
    props<{ staff: User }>()
);


export const editUser = createAction(
    '[User/API] Edit Existing User To Server',
    props<{ user: User }>()
);

export const changePassword = createAction(
    '[User/API] change user password in the server',
    props<{ passwords: any }>()
);
export const resendPasswordResetMail = createAction(
    '[User/API] Resend passwprd user password in the server'
);

export const changePasswordCompleted = createAction(
    '[User/API] change user password in the server completed'
);

export const removeUser = createAction(
    '[User/API] Remove User From Server',
    props<{ uuid: string, id: number }>()
);

export const newPublicUser = createAction(
    '[User/API] Add Public User',
    props<{ publicUser: User }>()
);

export const getMarriageRegisterList = createAction(
    '[User/API] Get Marriage Register List',
);

export const fetchAllUsersByCategory = createAction(
    '[User/API] Load All Users From Server By Category',
    props<{ userCategory: UserCategoryEnum }>()
);

