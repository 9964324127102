import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import { CreateGroupBillDto, GroupBill, PreviewGroupBillDto } from './group-bill.model';


export const loadGroupBills = createAction(
    '[GroupBill/API] Load Group Bills',
    props<{ groupBills: GroupBill[] }>()
);

export const addGroupBill = createAction(
    '[GroupBill/API] Add Group Bill',
    props<{ groupBill: GroupBill }>()
);

export const upsertGroupBill = createAction(
    '[GroupBill/API] Upsert Group Bill',
    props<{ groupBill: GroupBill }>()
);

export const addGroupBills = createAction(
    '[GroupBill/API] Add Group Bills',
    props<{ groupBills: GroupBill[] }>()
);

export const upsertGroupBills = createAction(
    '[GroupBill/API] Upsert Group Bills',
    props<{ groupBills: GroupBill[] }>()
);

export const updateGroupBill = createAction(
    '[GroupBill/API] Update Group Bill',
    props<{ groupBill: Update<GroupBill> }>()
);

export const updateGroupBills = createAction(
    '[GroupBill/API] Update Group Bills',
    props<{ groupBills: Update<GroupBill>[] }>()
);

export const deleteGroupBill = createAction(
    '[GroupBill/API] Delete Group Bill',
    props<{ id: string }>()
);

export const deleteGroupBills = createAction(
    '[GroupBill/API] Delete Group Bills',
    props<{ ids: string[] }>()
);

export const clearGroupBills = createAction(
    '[GroupBill/API] Clear Group Bills'
);

export const getGroupBills = createAction(
    '[GroupBill/API] Get Group Bills'
);

export const fetchGroupBillByUid = createAction(
    '[GroupBill/API] Fetch Group Bill By Uid',
    props<{ groupBillUid: string }>()
);

export const previewGroupBill = createAction(
    '[GroupBill/API] Preview Group Bill',
    props<{ previewGroupBillDto: PreviewGroupBillDto }>()
);

export const saveNewGroupBill = createAction(
    '[GroupBill/API] save new Group Bill',
    props<{ createGroupBillDto: CreateGroupBillDto }>()
);









