import gql from 'graphql-tag';
import {UserGqlFields} from '../user/user.graphql';

export const signatureGqlFields = `
    id
    uid
    createdAt
    signature
    userType
    startDate
    endDate
    user{
        id
        uid
        fullName
        username
        email
    }
    createdBy{
        id
        uid
        fullName
        username
        email
    }
`;

export const SAVE_SIGNATURE = gql`
    mutation saveSignature($signatureDto: SignatureDtoInput){
        saveSignature(signatureDto: $signatureDto){
            code
            data{
                ${signatureGqlFields}
            }
            errorDescription
            status
        }
    }
`;

export const GET_ALL_SIGNATURE = gql`
    query getAllSignature{
        getAllSignature{
            ${signatureGqlFields}
        }
    }
`;


export const UPDATE_SIGNATURE = gql`
    mutation updateSignature($signatureDto: SignatureDtoInput, $uid: String){
        updateSignature(signatureDto: $signatureDto, uid: $uid){
            code
            data{
                ${signatureGqlFields}
            }
            errorDescription
            status
        }
    }
`;

export const DELETE_SIGNATURE = gql`
    mutation deleteSignature($uid: String){
        deleteSignature(uid: $uid){
            code
            data{
                ${signatureGqlFields}
            }
            errorDescription
            status
        }
    }
`;

export const GET_CERTIFICATE_SIGNATURE = gql`
  query getCertificateSignature($userType:String, $appUid:String){
    getCertificateSignature(userType:$userType, appUid:$appUid){
        data{
           ${signatureGqlFields}
        }
        code
        status
        errorDescription
     }
  }
`;
