import gql from 'graphql-tag';

export const petitionSessionsGqlFields = `
        id
        uid
        admissionDate
        admittedCount
        closeDate
        active
        createdBy{
            id
            uid
            fullName
        }
        openDate
        updatedAt
        createdAt
`;

export const LIST_ALL_PETITION_SESSIONS = gql`
    query getAllSessions{
        getAllPetitionSession{
            ${petitionSessionsGqlFields}
        }
    }
`;

export const GET_PETITION_SESSION_UID = gql`
    query getPetitionSessionByUid($sessionUid: String){
        getPetitionSessionById(uid: $sessionUid){
            code
            data{
                ${petitionSessionsGqlFields}
            }
            errorDescription
            status
        }
    }
`;

export const SAVE_PETITION_SESSION = gql`
    mutation saveSession($session: PetitionSessionDtoInput){
        savePetitionSession(petitionSessionDto: $session){
            code
            data{
                ${petitionSessionsGqlFields}
            }
            status
            errorDescription
        }
    }
`;

export const UPDATE_PETITION_SESSION = gql`
    mutation updatePSession($uid: String, $session: PetitionSessionDtoInput){
        updatePetitionSession(uid: $uid, petitionSessionDto: $session){
            code
            data{
                ${petitionSessionsGqlFields}
            }
            status
            errorDescription
        }
    }
`;

export const REMOVE_PETITION_SESSION = gql`
    mutation deletePSession($uid: String){
        deletePetitionSession(uid: $uid){
            code
            data{
                ${petitionSessionsGqlFields}
            }
            status
            errorDescription
        }
    }
`;
