import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {fetchAllPetitionersByCoram, loadCoramPetition} from './coram-petition.actions';
import {LIST_ALL_PETITIONERS_BY_CORAM} from './coram-petition.graphql';
import {CoramPetition} from './coram-petition.model';


@Injectable()
export class CoramPetitionEffects {

    allPetitionsBYCorams$ = createEffect(() => this.actions$.pipe(
        ofType(fetchAllPetitionersByCoram),
        switchMap((action) => {
            return this.apollo.query({
                query: LIST_ALL_PETITIONERS_BY_CORAM,
                fetchPolicy: 'network-only',
                variables: {
                    coramUid: action.coramUid
                }
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        const returnedData: any = Object.values(data)[0];
                        this.store.dispatch(loadCoramPetition({
                            coramPetition:
                                Object.assign({}, returnedData, {id: 1}) as CoramPetition
                        }));
                    }
                })
            );
        })
    ), {dispatch: false});


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
    ) {
    }

}
