import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {ResponseCode} from '../../../../shared/enums/http-status-codes.enum';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {AuthService} from '../../../../services/auth/auth.service';
import {
    CREATE_WORK_FLOW_STAGE,
    GET_ALL_WORK_FLOW_PROCESS,
    GET_WORK_FLOW_STAGES_BY_PROCESS,
} from './work-flow-stage.graphql';
import {
    assignWorkflowAttachments,
    fetchWorkFlowProcesses,
    upsertWorkFlowProcesss
} from '../work-flow-process/work-flow-process.actions';
import {WorkFlowProcess} from '../work-flow-process/work-flow-process.model';
import {fetchAllWorkFlowStageByProcess, loadWorkFlowStages, newWorkFlowStage} from './work-flow-stage.actions';
import {WorkFlowStage} from './work-flow-stage.model';
import {ASSIGN_ATTACHMENT_TYPES_TO_WORKFLOW_PROCESS} from '../work-flow-process/work-flow-process.graphql';


@Injectable()
export class WorkFlowStageEffects {

    workProcesses$ = createEffect(() => this.actions$.pipe(
        ofType(fetchWorkFlowProcesses),
        switchMap((action) => {
            return this.apollo.query({
                query: GET_ALL_WORK_FLOW_PROCESS,
                fetchPolicy: 'network-only'
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        this.store.dispatch(upsertWorkFlowProcesss({
                            workFlowProcesss:
                                Object.values(data)[0] as WorkFlowProcess[]
                        }));
                    }
                })
            );
        })
    ), {dispatch: false});

    assignAttachmentToWorkflowProcess$ = createEffect(() => this.actions$.pipe(
            ofType(assignWorkflowAttachments),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: ASSIGN_ATTACHMENT_TYPES_TO_WORKFLOW_PROCESS,
                    variables: {
                        attachment: action.attachment
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.assignWorkflowAttachment?.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(fetchWorkFlowProcesses());
                                return this.notificationService.successMessage('Assigned successfully');
                            } else {
                                return this.responseCodesService.errorMessageByCode(data.assignAttachment.code);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    createOrUpdateWorkStage$ = createEffect(() => this.actions$.pipe(
        ofType(newWorkFlowStage),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: CREATE_WORK_FLOW_STAGE,
                variables: {
                    stage: action.workFlowStage
                }
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        if (data.saveWorkflowStage.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(loadWorkFlowStages({workFlowStages: data.saveWorkflowStage.dataList}));
                            return this.notificationService.successMessage('Work flow stage created successfully');
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.saveWorkflowStage);
                        }
                    }
                })
            );
        })
    ), {dispatch: false});

    getWorkFlowStage$ = createEffect(() => this.actions$.pipe(
        ofType(fetchAllWorkFlowStageByProcess),
        switchMap((action) => {
            return this.apollo.query({
                query: GET_WORK_FLOW_STAGES_BY_PROCESS,
                fetchPolicy: 'network-only',
                variables: {
                    uid: action.workProcessUid
                }
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        if (data?.getWorkflowStageByProcess) {
                            this.store.dispatch(loadWorkFlowStages({
                                workFlowStages:
                                    Object.values(data)[0] as WorkFlowStage[]
                            }));
                        }
                    }
                })
            );
        })
    ), {dispatch: false});


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService,
        private auth: AuthService,
    ) {
    }

}
