import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { AttachmentType } from './attachment-type.model';

export const loadAttachmentTypes = createAction(
  '[AttachmentType/API] Load AttachmentTypes',
  props<{ attachmentTypes: AttachmentType[] }>()
);

export const addAttachmentType = createAction(
  '[AttachmentType/API] Add AttachmentType',
  props<{ attachmentType: AttachmentType }>()
);

export const upsertAttachmentType = createAction(
  '[AttachmentType/API] Upsert AttachmentType',
  props<{ attachmentType: AttachmentType }>()
);

export const addAttachmentTypes = createAction(
  '[AttachmentType/API] Add AttachmentTypes',
  props<{ attachmentTypes: AttachmentType[] }>()
);

export const upsertAttachmentTypes = createAction(
  '[AttachmentType/API] Upsert AttachmentTypes',
  props<{ attachmentTypes: AttachmentType[] }>()
);

export const updateAttachmentType = createAction(
  '[AttachmentType/API] Update AttachmentType',
  props<{ attachmentType: Update<AttachmentType> }>()
);

export const updateAttachmentTypes = createAction(
  '[AttachmentType/API] Update AttachmentTypes',
  props<{ attachmentTypes: Update<AttachmentType>[] }>()
);

export const deleteAttachmentType = createAction(
  '[AttachmentType/API] Delete AttachmentType',
  props<{ id: string }>()
);

export const deleteAttachmentTypes = createAction(
  '[AttachmentType/API] Delete AttachmentTypes',
  props<{ ids: string[] }>()
);

export const clearAttachmentTypes = createAction(
  '[AttachmentType/API] Clear AttachmentTypes'
);




/*
*  Effect actions
* */

export const fetchAllAttachmentTypes = createAction(
    '[AttachmentType/API] fetch all attachment types from the server'
);

export const fetchAllAttachmentTypesByUser = createAction(
    '[AttachmentType/API] fetch all attachment types by user from the server'
);

export const saveNewAttachmentType = createAction(
    '[AttachmentType/API] save new attachment type to the server',
    props<{ attachmentType: AttachmentType }>()
);

export const editExistingAttachmentType = createAction(
    '[AttachmentType/API] update exiting attachment type to the server',
    props<{ attachmentType: AttachmentType, uid: string }>()
);

export const removerAttachmentType = createAction(
    '[AttachmentType/API] remove existing attachment type from server',
    props<{ uid: string }>()
);
