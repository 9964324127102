<div class="auth-wrapper">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-5 col-md-7 my-auto p-0">
                <div class="authentication-form mx-auto">
                    <div class="logo-centered">
                        <img src="assets/images/Judiciary-Logo.png" style="height:60px; width: 60px;"
                             alt="logo icon">
                    </div>
                    <h3>Create A New Password</h3>

                    <form (ngSubmit)="submitPassword()" [formGroup]="passwordForm" method="POST">
                        <div class="form-group">
                            <input type="password" formControlName="newPassword" class="form-control is-valid"
                                   placeholder="Password">
                            <i class="ik ik-lock"></i>

                            <div>
            <span class="text-danger"
                  *ngIf="!passwordForm.get('newPassword').valid && passwordForm.get('newPassword').touched && passwordForm.get('newPassword').errors.required">
              New password is required
            </span>
                            </div>
                            <div>
            <span class="text-danger"
                  *ngIf="passwordForm.get('newPassword').dirty && passwordForm.get('newPassword').errors?.hasNumber">
            {{'Password must have at least one Number'}}
          </span>
                            </div>
                            <div>
            <span class="text-danger"
                  *ngIf="passwordForm.get('newPassword').dirty && passwordForm.get('newPassword').errors?.hasCapitalCase">
            {{'Password must have at least one Capital Case'}}
          </span>
                            </div>
                            <div>
                                <div *ngIf="passwordForm.get('newPassword').dirty && passwordForm.get('newPassword').errors?.hasSmallCase">            {{ 'Password must have at least one Small Case'}}        </div>
                            </div>
                            <div>
            <span class="text-danger"
                  *ngIf="passwordForm.get('newPassword').dirty && passwordForm.get('newPassword').errors?.hasSpecialCharacters">
            {{'Password must have at least one Special Character'}}
          </span>
                            </div>
                            <div>
            <span class="text-danger"
                  *ngIf="passwordForm.get('newPassword').dirty && passwordForm.get('newPassword').errors?.minlength">
              {{'Password must have 6 or more character'}}
            </span>
                            </div>
                        </div>
                        <div class="form-group">
                            <input type="password" formControlName="comfirmPassword" class="form-control is-valid"
                                   placeholder="Confirm Password">
                            <i class="ik ik-lock"></i>
                        </div>
                        <div class="sign-btn text-center">
                            <button [disabled]="!passwordForm.valid" class="btn btn-theme" data-loading-text="Submiting request.....">Create</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
