import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ResponseCode} from '../../../../shared/enums/http-status-codes.enum';
import {
    editAppearance,
    fetchAllAppearances,
    getAppearanceByVenue,
    loadAppearances,
    saveAppearance,
    upsertAppearance
} from './appearance.actions';
import {map, switchMap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {Store} from '@ngrx/store';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {AppState} from '../../../reducers';
import {CREATE_APPEARANCE, GET_APPEARANCE_BY_VENUE, LIST_APPEARANCES, UPDATE_APPEARANCE} from './appearance.graphql';
import {Appearance} from './appearance.model';


@Injectable()
export class AppearanceEffects {

    allAppearancesBySession$ = createEffect(() => this.actions$.pipe(
        ofType(fetchAllAppearances),
        switchMap((action) => {
            return this.apollo.query({
                query: LIST_APPEARANCES,
                fetchPolicy: 'network-only',
                variables: {
                    sessionUid: action.sessionUid
                }
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        this.store.dispatch(loadAppearances({
                            appearances: Object.values(data)[0] as Appearance[]
                        }));
                    }
                })
            );
        })
    ), {dispatch: false});


    allAppearancesByVenue$ = createEffect(() => this.actions$.pipe(
        ofType(getAppearanceByVenue),
        switchMap((action) => {
            return this.apollo.query({
                query: GET_APPEARANCE_BY_VENUE,
                fetchPolicy: 'network-only',
                variables: {
                    sessionUid: action.sessionUid,
                    venueUid: action.venueUid
                }
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        this.store.dispatch(loadAppearances({
                            appearances: Object.values(data)[0] as Appearance[]
                        }));
                    }
                })
            );
        })
    ), {dispatch: false});


    createAppearance$ = createEffect(() => this.actions$.pipe(
            ofType(saveAppearance),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: CREATE_APPEARANCE,
                    variables: {
                        appearance: action.appearance
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.saveAppearance?.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(upsertAppearance({appearance: data?.saveAppearance?.data}));
                                return this.notificationService.successMessage('Saved successfully');
                            } else {
                                return this.responseCodesService.errorMessageByMessage(data.saveAppearance);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    editAppearance$ = createEffect(() => this.actions$.pipe(
            ofType(editAppearance),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: UPDATE_APPEARANCE,
                    variables: {
                        appearanceUid: action.appearanceUid,
                        appearance: action.appearance
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.updateAppearance?.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(upsertAppearance({appearance: data?.updateAppearance?.data}));
                                return this.notificationService.successMessage('Updated successfully');
                            } else {
                                return this.responseCodesService.errorMessageByMessage(data.updateAppearance);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    /*  removeAppearance$ = createEffect(() => this.actions$.pipe(
              ofType(removeAppearance),
              switchMap((action) => {
                return this.apollo.mutate({
                  mutation: REMOVE_APPEARANCE_VENUE,
                  variables: {
                    venueUid: action.uid
                  },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                      if (data) {
                        if (data?.deleteAppearance?.code === ResponseCode.SUCCESS) {
                          this.store.dispatch(deleteAppearance({id: data?.deleteAppearance?.data?.id}));
                          return this.notificationService.successMessage('Saved successfully');
                        } else {
                          return this.responseCodesService.errorMessageByMessage(data.deleteAppearance);
                        }
                      }
                    })
                );
              })
          ),
          {dispatch: false}
      );*/


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService,
    ) {
    }

}
