import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {Role} from './role.model';

export const loadRoles = createAction(
    '[Role/API] Load Roles',
    props<{ roles: Role[] }>()
);

export const addRole = createAction(
    '[Role/API] Add Role',
    props<{ role: Role }>()
);

export const upsertRole = createAction(
    '[Role/API] Upsert Role',
    props<{ role: Role }>()
);

export const addRoles = createAction(
    '[Role/API] Add Roles',
    props<{ roles: Role[] }>()
);

export const upsertRoles = createAction(
    '[Role/API] Upsert Roles',
    props<{ roles: Role[] }>()
);

export const updateRole = createAction(
    '[Role/API] Update Role',
    props<{ role: Update<Role> }>()
);

export const updateRoles = createAction(
    '[Role/API] Update Roles',
    props<{ roles: Update<Role>[] }>()
);

export const deleteRole = createAction(
    '[Role/API] Delete Role',
    props<{ id: string }>()
);

export const deleteRoles = createAction(
    '[Role/API] Delete Roles',
    props<{ ids: string[] }>()
);

export const clearRoles = createAction(
    '[Role/API] Clear Roles'
);

// server roles
export const listAllRoles = createAction(
    '[Roles/API] List All Roles'
);

export const newRole = createAction(
    '[Roles/API] New Role',
    props<{ role: Role }>()
);

export const editRole = createAction(
    '[Roles/API] Edit Role',
    props<{ role: Role }>()
);

export const removeRole = createAction(
    '[Roles/API] Delete Role',
    props<{ id: number }>()
);

export const getPermissionsByRoleId = createAction(
    '[Roles/API] get Role Permissions',
    props<{ roleId: number }>()
);


export const updateRolePermissions = createAction(
    '[Roles/API] Updater Role Permissions',
    props<{
        permissionIds: number[],
        roleId: number
    }>()
);

export const assignRoleToUser = createAction(
    '[Roles/API] Assign roles to user',
    props<{ roleIds: number[], userUuid: string }>()
);


export const assignPermissionsToRole = createAction(
    '[Role/API] Assign Permissions To Role',
    props<{
        permissionIds: number[],
        roleUuid: string
    }>()
);

export  const  assignPermissionToRoleFinished = createAction(
    '[Role/API] Assigning  Roles To Permission Completed'
);

