import gql from 'graphql-tag';

export const advocateChangeStatusGqlFields = `
        id
        uid
        active
        description
        endDate
        previousStatus
        profile{
            id
            uid
            fullname
            advocate{
                id
                uid
                rollNo
            }
        }
        reason
        startDate
        status
        createdAt
        createdBy{
            id
            uid
        }
`;

export const CHANGE_ADVOCATE_STATUS = gql`
    mutation updateAdvocateStatus($advocateStatus: AdvocateStatusChangeDtoInput){
        updateAdvocateStatus(advocateStatusChangeDto: $advocateStatus){
            code
            data{
                ${advocateChangeStatusGqlFields}
            }
        }
    }
`;

export const GET_ADVOCATE_STATUS_CHANGE = gql`
    query getAdvocatesStatusChange($dtInput: DataTablesInputInput){
        getAdvocateStatusChange(dtInput: $dtInput){
            data{
                ${advocateChangeStatusGqlFields}
            }
            draw
            error
            recordsTotal
            recordsFiltered
        }
    }
`;
