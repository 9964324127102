import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {ResponseCode} from '../../../../shared/enums/http-status-codes.enum';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {
    deleteAppearanceVenue,
    editAppearanceVenue,
    fetchAllAppearanceVenues,
    removeAppearanceVenue,
    saveAppearanceVenue,
    upsertAppearanceVenue,
    upsertAppearanceVenues
} from './appearance-venue.actions';
import {
    CREATE_APPEARANCE_VENUE,
    LIST_APPEARANCE_VENUES,
    REMOVE_APPEARANCE_VENUE,
    UPDATE_APPEARANCE_VENUE
} from './appearance-venue.graphql';
import {AppearanceVenue} from './appearance-venue.model';


@Injectable()
export class AppearanceVenueEffects {

    allAppearanceVenues$ = createEffect(() => this.actions$.pipe(
        ofType(fetchAllAppearanceVenues),
        switchMap((action) => {
            return this.apollo.query({
                query: LIST_APPEARANCE_VENUES,
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        this.store.dispatch(upsertAppearanceVenues({
                            appearanceVenues:
                                Object.values(data)[0] as AppearanceVenue[]
                        }));
                    }
                })
            );
        })
    ), {dispatch: false});


    createAppearanceVenue$ = createEffect(() => this.actions$.pipe(
            ofType(saveAppearanceVenue),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: CREATE_APPEARANCE_VENUE,
                    variables: {
                        venue: action.venue
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.saveAppearanceVenue?.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(upsertAppearanceVenue({appearanceVenue: data?.saveAppearanceVenue?.data}));
                                return this.notificationService.successMessage('Saved successfully');
                            } else {
                                return this.responseCodesService.errorMessageByMessage(data.saveAppearanceVenue);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    editAppearanceVenue$ = createEffect(() => this.actions$.pipe(
            ofType(editAppearanceVenue),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: UPDATE_APPEARANCE_VENUE,
                    variables: {
                        venueUid: action.uid,
                        venue: action.venue
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.updateAppearanceVenue?.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(upsertAppearanceVenue({appearanceVenue: data?.updateAppearanceVenue?.data}));
                                return this.notificationService.successMessage('Updated successfully');
                            } else {
                                return this.responseCodesService.errorMessageByMessage(data.updateAppearanceVenue);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    removeAppearanceVenue$ = createEffect(() => this.actions$.pipe(
            ofType(removeAppearanceVenue),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: REMOVE_APPEARANCE_VENUE,
                    variables: {
                        venueUid: action.uid
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.deleteAppearanceVenue?.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(deleteAppearanceVenue({id: data?.deleteAppearanceVenue?.data?.id}));
                                return this.notificationService.successMessage('Saved successfully');
                            } else {
                                return this.responseCodesService.errorMessageByMessage(data.deleteAppearanceVenue);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService,
    ) {
    }

}
