import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CleCoram } from './cle-coram.model';
import * as CleCoramActions from './cle-coram.actions';

export const cleCoramsFeatureKey = 'cleCorams';

export interface State extends EntityState<CleCoram> {
  // additional entities state properties
}

export const adapter: EntityAdapter<CleCoram> = createEntityAdapter<CleCoram>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(CleCoramActions.addCleCoram,
    (state, action) => adapter.addOne(action.cleCoram, state)
  ),
  on(CleCoramActions.upsertCleCoram,
    (state, action) => adapter.upsertOne(action.cleCoram, state)
  ),
  on(CleCoramActions.addCleCorams,
    (state, action) => adapter.addMany(action.cleCorams, state)
  ),
  on(CleCoramActions.upsertCleCorams,
    (state, action) => adapter.upsertMany(action.cleCorams, state)
  ),
  on(CleCoramActions.updateCleCoram,
    (state, action) => adapter.updateOne(action.cleCoram, state)
  ),
  on(CleCoramActions.updateCleCorams,
    (state, action) => adapter.updateMany(action.cleCorams, state)
  ),
  on(CleCoramActions.deleteCleCoram,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(CleCoramActions.deleteCleCorams,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(CleCoramActions.loadCleCorams,
    (state, action) => adapter.setAll(action.cleCorams, state)
  ),
  on(CleCoramActions.clearCleCorams,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
