import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Reconciliation } from './reconciliation.model';
import * as ReconciliationActions from './reconciliation.actions';

export const reconciliationsFeatureKey = 'reconciliations';

export interface State extends EntityState<Reconciliation> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Reconciliation> = createEntityAdapter<Reconciliation>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(ReconciliationActions.addReconciliation,
    (state, action) => adapter.addOne(action.reconciliation, state)
  ),
  on(ReconciliationActions.upsertReconciliation,
    (state, action) => adapter.upsertOne(action.reconciliation, state)
  ),
  on(ReconciliationActions.addReconciliations,
    (state, action) => adapter.addMany(action.reconciliations, state)
  ),
  on(ReconciliationActions.upsertReconciliations,
    (state, action) => adapter.upsertMany(action.reconciliations, state)
  ),
  on(ReconciliationActions.updateReconciliation,
    (state, action) => adapter.updateOne(action.reconciliation, state)
  ),
  on(ReconciliationActions.updateReconciliations,
    (state, action) => adapter.updateMany(action.reconciliations, state)
  ),
  on(ReconciliationActions.deleteReconciliation,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ReconciliationActions.deleteReconciliations,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ReconciliationActions.loadReconciliations,
    (state, action) => adapter.setAll(action.reconciliations, state)
  ),
  on(ReconciliationActions.clearReconciliations,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
