import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import {BarExam, BarExamDtoInput} from './bar-exam.model';


export const loadBarExams = createAction(
  '[BarExam/API] Load BarExams',
  props<{ barExams: BarExam[] }>()
);

export const addBarExam = createAction(
  '[BarExam/API] Add BarExam',
  props<{ barExam: BarExam }>()
);

export const upsertBarExam = createAction(
  '[BarExam/API] Upsert BarExam',
  props<{ barExam: BarExam }>()
);

export const addBarExams = createAction(
  '[BarExam/API] Add BarExams',
  props<{ barExams: BarExam[] }>()
);

export const upsertBarExams = createAction(
  '[BarExam/API] Upsert BarExams',
  props<{ barExams: BarExam[] }>()
);

export const updateBarExam = createAction(
  '[BarExam/API] Update BarExam',
  props<{ barExam: Update<BarExam> }>()
);

export const updateBarExams = createAction(
  '[BarExam/API] Update BarExams',
  props<{ barExams: Update<BarExam>[] }>()
);

export const deleteBarExam = createAction(
  '[BarExam/API] Delete BarExam',
  props<{ id: string }>()
);

export const deleteBarExams = createAction(
  '[BarExam/API] Delete BarExams',
  props<{ ids: string[] }>()
);

export const clearBarExams = createAction(
  '[BarExam/API] Clear BarExams'
);


/*
*  Effect actions
* */

export const fetchAllBarExamsBySessionUid = createAction(
    '[BarExam/API] fetch All bar exams by current session from server',
    props<{ sessionUid: string }>()
);

export const saveBarExam = createAction(
    '[BarExam/API] add new bar exam to server',
    props<{barExam: BarExamDtoInput}>()
);

export const editBarExam = createAction(
    '[BarExam/API] edit existing bar exam to server',
    props<{barExam: BarExamDtoInput, barExamUid: string}>()
);

