import gql from 'graphql-tag';

export const AppNotificationGqlFields = `
  id
  type
  count
`;

export const LIST_APP_NOTIFICATIONS = gql`
  query listAppNotifications{
    listAppNotifications{
      ${AppNotificationGqlFields}
    }
  }
`;

