import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Advocate } from './advocate.model';

export const loadAdvocates = createAction(
  '[Advocate/API] Load Advocates', 
  props<{ advocates: Advocate[] }>()
);

export const addAdvocate = createAction(
  '[Advocate/API] Add Advocate',
  props<{ advocate: Advocate }>()
);

export const upsertAdvocate = createAction(
  '[Advocate/API] Upsert Advocate',
  props<{ advocate: Advocate }>()
);

export const addAdvocates = createAction(
  '[Advocate/API] Add Advocates',
  props<{ advocates: Advocate[] }>()
);

export const upsertAdvocates = createAction(
  '[Advocate/API] Upsert Advocates',
  props<{ advocates: Advocate[] }>()
);

export const updateAdvocate = createAction(
  '[Advocate/API] Update Advocate',
  props<{ advocate: Update<Advocate> }>()
);

export const updateAdvocates = createAction(
  '[Advocate/API] Update Advocates',
  props<{ advocates: Update<Advocate>[] }>()
);

export const deleteAdvocate = createAction(
  '[Advocate/API] Delete Advocate',
  props<{ id: string }>()
);

export const deleteAdvocates = createAction(
  '[Advocate/API] Delete Advocates',
  props<{ ids: string[] }>()
);

export const clearAdvocates = createAction(
  '[Advocate/API] Clear Advocates'
);
