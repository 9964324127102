import gql from 'graphql-tag';
import {UserGqlFields} from '../user/user.graphql';

export const qualificationGqlFields = `
    id
    uid
    createdAt
    name
    qualificationName
    group
    createdBy{
        ${UserGqlFields}
    }
`;

export const SAVE_QUALIFICATION = gql`
    mutation saveQualification($qualification: QualificationDtoInput){
        saveQualification(qualificationDto: $qualification){
            code
            data{
                ${qualificationGqlFields}
            }
            status
            errorDescription
        }
    }
`;

export const GET_ALL_QUALIFICATIONS = gql`
    query getQualifications{
        getAllQualification{
            ${qualificationGqlFields}
            qualificationAttachmentList{
                id
                attachmentType{
                    id
                    uid
                    name
                }
                qualification{
                    id
                    uid
                    name
                }
            }
        }
    }
`;

export const GET_ONE_QUALIFICATION_BY_UID = gql`
    query getQualificationByUid($uid: String){
        getQualificationById(uid: $uid){
            code
            data{
                ${qualificationGqlFields}
                qualificationAttachmentList{
                    id
                    attachmentType{
                        id
                        uid
                        name
                    }
                    qualification{
                        id
                        uid
                        name
                    }
                }
            }
            status
            errorDescription
        }
    }
`;

export const ASSIGN_ATTACHMENT_TYPES_TO_QUALIFICATION = gql`
    mutation assignAttachmentTypeToQualification($attachment: AssignAttachmentDtoInput){
        assignAttachment(attachment: $attachment){
            code
            errorDescription
            status
        }
    }
`;

export const UPDATE_QUALIFICATION = gql`
    mutation updateQualification($uid: String, $qualification: QualificationDtoInput){
        updateQualification(uid: $uid, qualificationDto: $qualification){
            code
            data{
                ${qualificationGqlFields}
            }
            status
            errorDescription
        }
    }
`;

export const DELETE_QUALIFICATION = gql`
    mutation deleteQualification($uid: String){
        deleteQualification(uid: $uid){
            code
            data{
                ${qualificationGqlFields}
            }
            status
            errorDescription
        }
    }
`;
