import gql from 'graphql-tag';

export const ProfileAddressGraphqlFields = `
    id
    uid
    active
    box
    city
    contactUsage
    country{
        id
        name
    }
    createdAt
    createdBy{
        id
        uid
        fullName
    }
    district{
        id
        uid
        name
    }
    isForeign
    postcode
    preference
    profile{
        id
        user{
            id
        }
    }
    region{
        id
        uid
        name
        districts{
            id
            uid
            name
        }
    }
    street
    updatedAt
    ward
`;

export const SAVE_PROFILE_ADDRESS = gql`
   mutation saveProfileAddresses($profileAddressesDto:ProfileAddressesDtoInput){
     saveProfileAddresses(profileAddressesDto:$profileAddressesDto){
        data{
            ${ProfileAddressGraphqlFields}
        }
        errorDescription
        status
        code
     }
   }
`;

export const UPDATE_PROFILE_ADDRESS = gql`
    mutation updateProfileAddresses($uid:String,$profileAddressesDto:ProfileAddressesDtoInput){
        updateProfileAddresses(uid:$uid,profileAddressesDto:$profileAddressesDto){
            data{
                ${ProfileAddressGraphqlFields}
            }
            errorDescription
            status
            code
        }
    }
`;

export const DELETE_PROFILE_ADDRESS = gql`
    mutation deleteProfileAddress($uid:String){
        deleteProfileAddress(uid:$uid){
            data{
                ${ProfileAddressGraphqlFields}
            }
            errorDescription
            status
            code
        }
    }
`;

export const GET_PROFILE_ADDRESSES_BY_USER = gql`
    query getProfileAddressesByUser{
        getProfileAddressesByUser{
            data{
                ${ProfileAddressGraphqlFields}
            }
            errorDescription
            status
            code
        }
    }
`;

export const LIST_PROFILE_ADDRESSES_BY_USER = gql`
    query listProfileAddressesByUser{
        listProfileAddressesByUser{
            ${ProfileAddressGraphqlFields}
        }
    }
`;






