import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ActionUserType } from './action-user-type.model';
import * as ActionUserTypeActions from './action-user-type.actions';

export const actionUserTypesFeatureKey = 'actionUserTypes';

export interface State extends EntityState<ActionUserType> {
  // additional entities state properties
}

export const adapter: EntityAdapter<ActionUserType> = createEntityAdapter<ActionUserType>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(ActionUserTypeActions.addActionUserType,
    (state, action) => adapter.addOne(action.actionUserType, state)
  ),
  on(ActionUserTypeActions.upsertActionUserType,
    (state, action) => adapter.upsertOne(action.actionUserType, state)
  ),
  on(ActionUserTypeActions.addActionUserTypes,
    (state, action) => adapter.addMany(action.actionUserTypes, state)
  ),
  on(ActionUserTypeActions.upsertActionUserTypes,
    (state, action) => adapter.upsertMany(action.actionUserTypes, state)
  ),
  on(ActionUserTypeActions.updateActionUserType,
    (state, action) => adapter.updateOne(action.actionUserType, state)
  ),
  on(ActionUserTypeActions.updateActionUserTypes,
    (state, action) => adapter.updateMany(action.actionUserTypes, state)
  ),
  on(ActionUserTypeActions.deleteActionUserType,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ActionUserTypeActions.deleteActionUserTypes,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ActionUserTypeActions.loadActionUserTypes,
    (state, action) => adapter.setAll(action.actionUserTypes, state)
  ),
  on(ActionUserTypeActions.clearActionUserTypes,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
