import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {AuthService} from '../../../../services/auth/auth.service';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-self-registration',
    templateUrl: './self-registration.component.html',
    styleUrls: ['./self-registration.component.scss']
})
export class SelfRegistrationComponent implements OnInit, OnDestroy {

    registrationForm: FormGroup;
    subscription = new Subscription();

    constructor(
        private fb: FormBuilder,
        private auth: AuthService,
        private notificationService: NotificationService,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.initForm();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    initForm() {
        this.registrationForm = this.fb.group({
            fullName: [null, Validators.required],
            phone: [null, Validators.required],
            email: [null, Validators.required],
            address: [null, Validators.required]
        });
    }

    save(value: any) {

        this.subscription.add(
            this.auth.register(value).subscribe(
                res => {
                    this.notificationService.successMessage(res.message);
                    return this.router.navigate(['', 'login']);
                },
                error => {
                    return this.notificationService.errorMessageSwalFire(error.error.message);
                }
            )
        );

    }
}
