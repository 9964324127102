import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {ReconciliationBatch} from './reconciliation-batch.model';

export const loadReconciliationBatchs = createAction(
    '[ReconciliationBatch/API] Load ReconciliationBatchs',
    props<{ reconciliationBatchs: ReconciliationBatch[] }>()
);

export const addReconciliationBatch = createAction(
    '[ReconciliationBatch/API] Add ReconciliationBatch',
    props<{ reconciliationBatch: ReconciliationBatch }>()
);

export const upsertReconciliationBatch = createAction(
    '[ReconciliationBatch/API] Upsert ReconciliationBatch',
    props<{ reconciliationBatch: ReconciliationBatch }>()
);

export const addReconciliationBatchs = createAction(
    '[ReconciliationBatch/API] Add ReconciliationBatchs',
    props<{ reconciliationBatchs: ReconciliationBatch[] }>()
);

export const upsertReconciliationBatchs = createAction(
    '[ReconciliationBatch/API] Upsert ReconciliationBatchs',
    props<{ reconciliationBatchs: ReconciliationBatch[] }>()
);

export const updateReconciliationBatch = createAction(
    '[ReconciliationBatch/API] Update ReconciliationBatch',
    props<{ reconciliationBatch: Update<ReconciliationBatch> }>()
);

export const updateReconciliationBatchs = createAction(
    '[ReconciliationBatch/API] Update ReconciliationBatchs',
    props<{ reconciliationBatchs: Update<ReconciliationBatch>[] }>()
);

export const deleteReconciliationBatch = createAction(
    '[ReconciliationBatch/API] Delete ReconciliationBatch',
    props<{ id: string }>()
);

export const deleteReconciliationBatchs = createAction(
    '[ReconciliationBatch/API] Delete ReconciliationBatchs',
    props<{ ids: string[] }>()
);

export const clearReconciliationBatchs = createAction(
    '[ReconciliationBatch/API] Clear ReconciliationBatchs'
);

export const sendReconciliationRequest = createAction(
    '[ReconciliationBatch/API] Request Reconciliation Request',
    props<{ date: string }>()
);

export const sendReconciliationRequestCompleted = createAction(
    '[ReconciliationBatch/API] Request Reconciliation Request Completed'
);

