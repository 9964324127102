import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-report',
  templateUrl: './service-report.component.html',
  styleUrls: ['./service-report.component.scss']
})
export class ServiceReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
