import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CleMember } from './cle-member.model';
import * as CleMemberActions from './cle-member.actions';

export const cleMembersFeatureKey = 'cleMembers';

export interface State extends EntityState<CleMember> {
  // additional entities state properties
}

export const adapter: EntityAdapter<CleMember> = createEntityAdapter<CleMember>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(CleMemberActions.addCleMember,
    (state, action) => adapter.addOne(action.cleMember, state)
  ),
  on(CleMemberActions.upsertCleMember,
    (state, action) => adapter.upsertOne(action.cleMember, state)
  ),
  on(CleMemberActions.addCleMembers,
    (state, action) => adapter.addMany(action.cleMembers, state)
  ),
  on(CleMemberActions.upsertCleMembers,
    (state, action) => adapter.upsertMany(action.cleMembers, state)
  ),
  on(CleMemberActions.updateCleMember,
    (state, action) => adapter.updateOne(action.cleMember, state)
  ),
  on(CleMemberActions.updateCleMembers,
    (state, action) => adapter.updateMany(action.cleMembers, state)
  ),
  on(CleMemberActions.deleteCleMember,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(CleMemberActions.deleteCleMembers,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(CleMemberActions.loadCleMembers,
    (state, action) => adapter.setAll(action.cleMembers, state)
  ),
  on(CleMemberActions.clearCleMembers,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
