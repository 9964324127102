import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from './material/material.module';
import {LoginComponent} from './components/auth/login/login.component';
import {MainLayoutComponent} from './components/layouts/main-layout/main-layout.component';
import {DataTableComponent} from './components/data-table/data-table.component';
import {LayoutModule} from '@angular/cdk/layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {RouterModule} from '@angular/router';
import {ModalHeaderComponent} from './components/layouts/modal-header/modal-header.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {StrLimitPipe} from './pipes/str-limit.pipe';
import {QuillModule} from 'ngx-quill';
import {ModalModule} from 'ng-modal-lib';
import {NgxPermissionsModule} from 'ngx-permissions';
import {ViewAttachmentComponent} from './components/other-views/view-attachment/view-attachment.component';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {PasswordResetComponent} from './components/auth/password-reset/password-reset.component';
import {AuthLayoutComponent} from './components/auth/auth-layout/auth-layout.component';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {SafePipe} from './pipes/safe.pipe';
import {MatSelectSearchComponent} from './components/mat-select-search/mat-select-search.component';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {PageTitleComponent} from './components/layouts/page-title/page-title.component';
import {SearchPipe} from './pipes/search.pipe';
import {ChangePasswordFormComponent} from './components/auth/change-password-form/change-password-form.component';
import {SpinnerComponent} from './components/other-views/spinner/spinner.component';
import {MatRippleModule} from '@angular/material/core';
import {EmptySetComponent} from './components/other-views/empty-set/empty-set.component';
import {FormsService} from './services/forms.service';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {AttachmentColumnItemComponent} from './components/attachment-column-item/attachment-column-item.component';
import { CustomFormFieldComponent } from './components/custom-form-field/custom-form-field.component';
import { AttachmentsListComponent } from './components/attachments-list/attachments-list.component';
import {TranslateModule} from '@ngx-translate/core';
import { ServiceReportComponent } from './components/service-report/service-report.component';
import { SelfRegistrationComponent } from './components/auth/self-registration/self-registration.component';
import { PublicLayoutComponent } from './components/layouts/public/public-layout/public-layout.component';
import { PublicLayoutHeaderComponent } from './components/layouts/public/public-layout-header/public-layout-header.component';
import { PublicLayoutFooterComponent } from './components/layouts/public/public-layout-footer/public-layout-footer.component';
import { ApplicationTypePipe } from './pipes/application-type.pipe';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {QRCodeModule} from 'angularx-qrcode';
import {NgxPrintModule} from 'ngx-print';
import { BillDetailsComponent } from './components/bill-details/bill-details.component';
import { PaymentDetailsComponent } from './components/payment-details/payment-details.component';
import { GepgStatusCodePipe } from './pipes/gepg-status-code.pipe';
import {
    ApplicationAttachmentsListComponent
} from '../modules/advocate/advocate-profile/application-attachments-list/application-attachments-list.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { ShouldResetPasswordComponent } from './components/auth/should-reset-password/should-reset-password.component';



@NgModule({
    declarations: [
        LoginComponent,
        PageTitleComponent,
        MainLayoutComponent,
        DataTableComponent,
        ModalHeaderComponent,
        StrLimitPipe,
        SearchPipe,
        ViewAttachmentComponent,
        PasswordResetComponent,
        AuthLayoutComponent,
        MatSelectSearchComponent,
        SafePipe,
        ChangePasswordFormComponent,
        SpinnerComponent,
        EmptySetComponent,
        AttachmentColumnItemComponent,
        CustomFormFieldComponent,
        AttachmentsListComponent,
        ServiceReportComponent,
        SelfRegistrationComponent,
        PublicLayoutComponent,
        PublicLayoutHeaderComponent,
        PublicLayoutFooterComponent,
        ApplicationTypePipe,
        BillDetailsComponent,
        PaymentDetailsComponent,
        GepgStatusCodePipe,
        ApplicationAttachmentsListComponent,
        ForgotPasswordComponent,
        ShouldResetPasswordComponent,
    ],
    providers: [FormsService],
    imports: [
        CommonModule,
        MaterialModule,
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        RouterModule,
        ReactiveFormsModule,
        NgxPermissionsModule,
        QuillModule,
        QuillModule.forRoot(),
        ModalModule,
        NgxExtendedPdfViewerModule,
        FormsModule,
        NgxMatSelectSearchModule,
        NgxSkeletonLoaderModule,
        MatRippleModule,
        NgxDropzoneModule,
        TranslateModule,
        NgxMaterialTimepickerModule,
        QRCodeModule,
        NgxMaterialTimepickerModule,
        NgxPrintModule,
    ],
    exports: [
        MaterialModule,
        NgxDropzoneModule,
        ReactiveFormsModule,
        MainLayoutComponent,
        PublicLayoutComponent,
        DataTableComponent,
        ModalHeaderComponent,
        PageTitleComponent,
        EmptySetComponent,
        NgxPermissionsModule,
        StrLimitPipe,
        SearchPipe,
        QuillModule,
        NgxSkeletonLoaderModule,
        NgxMatSelectSearchModule,
        TranslateModule,
        MatSelectSearchComponent,
        SafePipe,
        CustomFormFieldComponent,
        AttachmentsListComponent,
        ApplicationTypePipe,
        NgxMaterialTimepickerModule,
        NgxPrintModule,
        NgxMaterialTimepickerModule,
        QRCodeModule,
        BillDetailsComponent,
        PaymentDetailsComponent,
        GepgStatusCodePipe,
        ApplicationAttachmentsListComponent
    ],
})
export class SharedModule {
}
