import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {settlePayment, upsertReconciliation} from './reconciliation.actions';
import {SETTLE_PAYMENT} from './reconciliation.graphql';
import {ResponseCode} from '../../../../shared/enums/http-status-codes.enum';
import {Reconciliation} from './reconciliation.model';


@Injectable()
export class ReconciliationEffects {

    settlePayment$ = createEffect(() => this.actions$.pipe(
            ofType(settlePayment),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: SETTLE_PAYMENT,
                    variables: {
                        id: action.id
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data.settlePayment.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(upsertReconciliation({reconciliation: data.settlePayment.data as Reconciliation}));
                                return this.notificationService.successMessage('Payment settled successfully');
                            } else {
                                return this.responseCodesService.errorMessageByMessage(data.settlePayment);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService
    ) {
    }

}
