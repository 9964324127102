import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Petition } from './petition.model';

export const loadPetitions = createAction(
  '[Petition/API] Load Petitions',
  props<{ petitions: Petition[] }>()
);

export const addPetition = createAction(
  '[Petition/API] Add Petition',
  props<{ petition: Petition }>()
);

export const upsertPetition = createAction(
  '[Petition/API] Upsert Petition',
  props<{ petition: Petition }>()
);

export const addPetitions = createAction(
  '[Petition/API] Add Petitions',
  props<{ petitions: Petition[] }>()
);

export const upsertPetitions = createAction(
  '[Petition/API] Upsert Petitions',
  props<{ petitions: Petition[] }>()
);

export const updatePetition = createAction(
  '[Petition/API] Update Petition',
  props<{ petition: Update<Petition> }>()
);

export const updatePetitions = createAction(
  '[Petition/API] Update Petitions',
  props<{ petitions: Update<Petition>[] }>()
);

export const deletePetition = createAction(
  '[Petition/API] Delete Petition',
  props<{ id: string }>()
);

export const deletePetitions = createAction(
  '[Petition/API] Delete Petitions',
  props<{ ids: string[] }>()
);

export const clearPetitions = createAction(
  '[Petition/API] Clear Petitions'
);


/*
*  Effect actions
* */
export const fetchAllPendingPetitionsBySessionUid = createAction(
    '[Petition/API] fetch all petitioners by current bar exam date from server',
    props<{ sessionUid: string }>()
);

export const fetchPetitionsByAppearanceUid = createAction(
    '[Petition/API] fetch all petitioners by appearance from server'
);

export const fetchAllPetitionsByBarUid = createAction(
    '[Petition/API] get all petitioners done bar exam from server server',
    props<{barUid: string}>()
);

export const preAssignRollNumber = createAction(
    '[Petition/API] pre assign roll number',
    props<{ sessionUid: string }>()
);

export const refreshPendingAdmissionTable = createAction(
    '[Petition/API] refresh pending admission petitioners table',
);

export const applyVenueAppearance = createAction(
    '[Petition/API] apply for venue appearance',
    props<{petitionUid: string, appearanceVenueUid: string}>()
);

export const reArrangeRollNumber = createAction(
    '[Petition/API] re arrange roll number for prioritization',
    props<{petitionUid: string, rollNumber: number}>()
);

export const getLegalProfessionalViewByCurrentUser = createAction(
    '[Petition/API] get legal professional view by current user',
);

export const changePetitionAppearance = createAction(
    '[Petition/API] admin change petition appearance',
    props<{changeAppearance: {appearanceUid: string, petitionUid: string}}>()
);

export const changePetitionerSession = createAction(
  '[Petition/API] change petitioner session',
  props<{petitionSessionUid: string, petitionUid: string}>()
);
