import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {ProfileContact} from './profile-contact.model';

export const loadProfileContacts = createAction(
    '[ProfileContact/API] Load ProfileContacts',
    props<{ profileContacts: ProfileContact[] }>()
);

export const addProfileContact = createAction(
    '[ProfileContact/API] Add ProfileContact',
    props<{ profileContact: ProfileContact }>()
);

export const upsertProfileContact = createAction(
    '[ProfileContact/API] Upsert ProfileContact',
    props<{ profileContact: ProfileContact }>()
);

export const addProfileContacts = createAction(
    '[ProfileContact/API] Add ProfileContacts',
    props<{ profileContacts: ProfileContact[] }>()
);

export const upsertProfileContacts = createAction(
    '[ProfileContact/API] Upsert ProfileContacts',
    props<{ profileContacts: ProfileContact[] }>()
);

export const updateProfileContact = createAction(
    '[ProfileContact/API] Update ProfileContact',
    props<{ profileContact: Update<ProfileContact> }>()
);

export const updateProfileContacts = createAction(
    '[ProfileContact/API] Update ProfileContacts',
    props<{ profileContacts: Update<ProfileContact>[] }>()
);

export const deleteProfileContact = createAction(
    '[ProfileContact/API] Delete ProfileContact',
    props<{ id: number }>()
);

export const deleteProfileContacts = createAction(
    '[ProfileContact/API] Delete ProfileContacts',
    props<{ ids: string[] }>()
);

export const clearProfileContacts = createAction(
    '[ProfileContact/API] Clear ProfileContacts'
);

export const saveProfileContacts = createAction(
    '[ProfileContact/API] Save ProfileContacts',
    props<{ profileContact: any }>()
);


export const editProfileContacts = createAction(
    '[ProfileContact/API] Update ProfileContacts',
    props<{ profileContact: any, uid: string }>()
);

export const removeProfileContact = createAction(
    '[ProfileContact/API] Remove ProfileContacts',
    props<{ uid: string }>()
);

export const getAllProfileContactsByUser = createAction(
    '[ProfileContact/API] Get All ProfileContacts By Logged User',
);


