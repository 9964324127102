import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { AppearanceVenue } from './appearance-venue.model';
import * as AppearanceVenueActions from './appearance-venue.actions';

export const appearanceVenuesFeatureKey = 'appearanceVenues';

export interface State extends EntityState<AppearanceVenue> {
  // additional entities state properties
}

export const adapter: EntityAdapter<AppearanceVenue> = createEntityAdapter<AppearanceVenue>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(AppearanceVenueActions.addAppearanceVenue,
    (state, action) => adapter.addOne(action.appearanceVenue, state)
  ),
  on(AppearanceVenueActions.upsertAppearanceVenue,
    (state, action) => adapter.upsertOne(action.appearanceVenue, state)
  ),
  on(AppearanceVenueActions.addAppearanceVenues,
    (state, action) => adapter.addMany(action.appearanceVenues, state)
  ),
  on(AppearanceVenueActions.upsertAppearanceVenues,
    (state, action) => adapter.upsertMany(action.appearanceVenues, state)
  ),
  on(AppearanceVenueActions.updateAppearanceVenue,
    (state, action) => adapter.updateOne(action.appearanceVenue, state)
  ),
  on(AppearanceVenueActions.updateAppearanceVenues,
    (state, action) => adapter.updateMany(action.appearanceVenues, state)
  ),
  on(AppearanceVenueActions.deleteAppearanceVenue,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(AppearanceVenueActions.deleteAppearanceVenues,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(AppearanceVenueActions.loadAppearanceVenues,
    (state, action) => adapter.setAll(action.appearanceVenues, state)
  ),
  on(AppearanceVenueActions.clearAppearanceVenues,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
