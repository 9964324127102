import gql from 'graphql-tag';

export const DistrictGraphqlFields = `
    id
    uid
    name
    region{
        id
        uid
        name
    }
`;

export const GET_ALL_DISTRICTS = gql`
    query getAllDistricts{
        getAllDistricts{
            ${DistrictGraphqlFields}
        }
    }
`;
