import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {ResponseCode} from '../../../../shared/enums/http-status-codes.enum';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {AuthService} from '../../../../services/auth/auth.service';
import {deleteFee, editFee, getAllFees, getFeeByUid, removeFee, saveFee, upsertFee, upsertFees} from './fee.actions';
import {DELETE_FEE, GET_ALL_FEES, GET_FEE_BY_ID, SAVE_FEE, UPDATE_FEE} from './fee.graphql';


@Injectable()
export class FeeEffects {

    getAllFees$ = createEffect(() => this.actions$.pipe(
        ofType(getAllFees),
        switchMap((action) => {
            return this.apollo.query({
                query: GET_ALL_FEES,
                fetchPolicy: 'network-only'
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        const fees = data.getAllFees;
                        this.store.dispatch(upsertFees({fees}));
                    }
                })
            );
        })
    ), {dispatch: false});

    getFeeById$ = createEffect(() => this.actions$.pipe(
            ofType(getFeeByUid),
            switchMap((action) => {
                return this.apollo.query({
                    query: GET_FEE_BY_ID,
                    variables: {
                        feeUid: action.feeUid
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.getFeeById?.code === ResponseCode.SUCCESS) {
                                const fee = data?.getFeeById?.data;
                                this.store.dispatch(upsertFee({fee}));
                            } else {
                                return this.responseCodesService.errorMessageByCode(data.getFeeById.code);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    saveFee$ = createEffect(() => this.actions$.pipe(
            ofType(saveFee),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: SAVE_FEE,
                    variables: {
                        fee: action.fee
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.saveFee?.code === ResponseCode.SUCCESS) {
                                const fee = data?.saveFee?.data;
                                this.store.dispatch(upsertFee({fee}));
                                return this.notificationService.successMessage('Saved successfully');
                            } else {
                                return this.responseCodesService.errorMessageByCode(data.saveFee.code);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    deleteFee$ = createEffect(() => this.actions$.pipe(
            ofType(removeFee),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: DELETE_FEE,
                    variables: {
                        feeUid: action.feeUid
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.deleteFee?.code === ResponseCode.SUCCESS) {
                                const fee = data?.deleteFee?.data;
                                this.store.dispatch(deleteFee({id: data?.deleteFee?.data?.id}));
                                return this.notificationService.successMessage('Deleted successfully');
                            } else {
                                return this.responseCodesService.errorMessageByCode(data.deleteFee.code);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    updateFee$ = createEffect(() => this.actions$.pipe(
            ofType(editFee),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: UPDATE_FEE,
                    variables: {
                        fee: action.fee,
                        feeUid: action.uid
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.updateFee?.code === ResponseCode.SUCCESS) {
                                const fee = data?.updateFee?.data;
                                this.store.dispatch(upsertFee({fee}));
                                return this.notificationService.successMessage('Saved successfully');
                            } else {
                                return this.responseCodesService.errorMessageByCode(data.updateFee.code);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService,
        private auth: AuthService,
    ) {
    }

}
