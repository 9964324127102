import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {AuthService} from '../../../../services/auth/auth.service';
import {map, switchMap} from 'rxjs/operators';
import {
    editFeeType,
    getAllFeeTypes,
    getFeeTypeByUid,
    saveFeeType,
    upsertFeeType,
    upsertFeeTypes
} from './fee-type.actions';
import {GET_ALL_FEE_TYPES, GET_FEE_TYPE_BY_ID, SAVE_FEE_TYPE, UPDATE_FEE_TYPE} from './fee-type.graphql';
import {ResponseCode} from '../../../../shared/enums/http-status-codes.enum';


@Injectable()
export class FeeTypeEffects {
    getAllFeeTypes$ = createEffect(() => this.actions$.pipe(
        ofType(getAllFeeTypes),
        switchMap((action) => {
            return this.apollo.query({
                query: GET_ALL_FEE_TYPES,
                fetchPolicy: 'network-only'
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        const feeTypes = data.getAllFeeTypes;
                        this.store.dispatch(upsertFeeTypes({feeTypes}));
                    }
                })
            );
        })
    ), {dispatch: false});

    getFeeTypeById$ = createEffect(() => this.actions$.pipe(
            ofType(getFeeTypeByUid),
            switchMap((action) => {
                return this.apollo.query({
                    query: GET_FEE_TYPE_BY_ID,
                    variables: {
                        feeTypeUid: action.feeTypeUid
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.getFeeTypeById?.code === ResponseCode.SUCCESS) {
                                const feeType = data?.getFeeTypeById?.data;
                                this.store.dispatch(upsertFeeType({feeType}));
                            } else {
                                return this.responseCodesService.errorMessageByCode(data.getFeeTypeById.code);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    saveFeeType$ = createEffect(() => this.actions$.pipe(
            ofType(saveFeeType),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: SAVE_FEE_TYPE,
                    variables: {
                        feeType: action.feeType
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.saveFeeType?.code === ResponseCode.SUCCESS) {
                                const feeType = data?.saveFeeType?.data;
                                this.store.dispatch(upsertFeeType({feeType}));
                                return this.notificationService.successMessage('Saved successfully');
                            } else {
                                return this.responseCodesService.errorMessageByCode(data.saveFeeType.code);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    updateFeeType$ = createEffect(() => this.actions$.pipe(
            ofType(editFeeType),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: UPDATE_FEE_TYPE,
                    variables: {
                        feeType: action.feeType,
                        feeTypeUid: action.uid
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.updateFeeType?.code === ResponseCode.SUCCESS) {
                                const feeType = data?.updateFeeType?.data;
                                this.store.dispatch(upsertFeeType({feeType}));
                                return this.notificationService.successMessage('Saved successfully');
                            } else {
                                return this.responseCodesService.errorMessageByCode(data.updateFeeType.code);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService,
        private auth: AuthService,
    ) {
    }

}
