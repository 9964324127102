import gql from 'graphql-tag';

export const PaymentGraphqlFields = `
    amountInWords
    bill{
        uid
        billId
        currency
        createdAt
        feeType{
            service
        }
        createdBy{
            fullName
        }
        profile{
            id
            uid
            fullname
            user{
                id
                uid
            }
        }
        billItems{
            fee{
                description
            }
            amount
        }
    }
    billamt
    billpayopt
    ccy
    createdAt
    ctraccnum
    id
    paidamt
    payctrnum
    payrefid
    pspname
    pspreceiptnumber
    pyrcellnum
    pyremail
    pyrname
    spcode
    trxdttm
    trxid
    uid
    usdpaychnl
`;

export const GET_APPLICANT_PAYMENTS = gql`
    query getApplicantPayments{
        getApplicantPayments{
            ${PaymentGraphqlFields}
        }
    }
`;


export const FETCH_RECEIPT_BY_PAYMENT_ID = gql`
    query viewRecept($paymentUid:String){
        viewRecept(paymentUid:$paymentUid){
            data{
                ${PaymentGraphqlFields}
            }
            status
            errorDescription
            code
        }
    }
`;

export const GET_CLIENT_PAYMENTS = gql`
    query getPaymentList($dtInput: DataTablesInputInput, $dateTo: LocalDate, $dateFrom: LocalDate){
        getPaymentList(dtInput:$dtInput,dateTo:$dateTo,dateFrom:$dateFrom){
            data{
                 ${PaymentGraphqlFields}
            }
            draw
            recordsTotal
            recordsFiltered
        }
    }
`;







