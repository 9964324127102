import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import {Appearance, AppearanceDtoInput} from './appearance.model';

export const loadAppearances = createAction(
  '[Appearance/API] Load Appearances',
  props<{ appearances: Appearance[] }>()
);

export const addAppearance = createAction(
  '[Appearance/API] Add Appearance',
  props<{ appearance: Appearance }>()
);

export const upsertAppearance = createAction(
  '[Appearance/API] Upsert Appearance',
  props<{ appearance: Appearance }>()
);

export const addAppearances = createAction(
  '[Appearance/API] Add Appearances',
  props<{ appearances: Appearance[] }>()
);

export const upsertAppearances = createAction(
  '[Appearance/API] Upsert Appearances',
  props<{ appearances: Appearance[] }>()
);

export const updateAppearance = createAction(
  '[Appearance/API] Update Appearance',
  props<{ appearance: Update<Appearance> }>()
);

export const updateAppearances = createAction(
  '[Appearance/API] Update Appearances',
  props<{ appearances: Update<Appearance>[] }>()
);

export const deleteAppearance = createAction(
  '[Appearance/API] Delete Appearance',
  props<{ id: string }>()
);

export const deleteAppearances = createAction(
  '[Appearance/API] Delete Appearances',
  props<{ ids: string[] }>()
);

export const clearAppearances = createAction(
  '[Appearance/API] Clear Appearances'
);


/*
*  Effect actions
* */

export const fetchAllAppearances = createAction(
    '[Appearance/API] fetch All appearance from server',
    props<{sessionUid: string}>()
);

export const getAppearanceByVenue = createAction(
    '[Appearance/API] get All appearance by venue from server',
    props<{sessionUid: string, venueUid: string}>()
);

export const saveAppearance = createAction(
    '[Appearance/API] add new appearance to server',
    props<{appearance: AppearanceDtoInput}>()
);

export const editAppearance = createAction(
    '[Appearance/API] edit existing appearance to server',
    props<{appearance: AppearanceDtoInput, appearanceUid: string}>()
);

export const removeAppearance = createAction(
    '[Appearance/API] remove this appearance to server',
    props<{uid: string}>()
);
