import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {FullProfileDetail} from './full-profile-detail.model';

export const loadFullProfileDetail = createAction(
  '[FullProfileDetail/API] Load FullProfileDetail',
  props<{ fullProfileDetail: FullProfileDetail }>()
);

export const loadFullProfileDetails = createAction(
  '[FullProfileDetail/API] Load FullProfileDetails',
  props<{ fullProfileDetails: FullProfileDetail[] }>()
);

export const addFullProfileDetail = createAction(
  '[FullProfileDetail/API] Add FullProfileDetail',
  props<{ fullProfileDetail: FullProfileDetail }>()
);

export const upsertFullProfileDetail = createAction(
  '[FullProfileDetail/API] Upsert FullProfileDetail',
  props<{ fullProfileDetail: FullProfileDetail }>()
);

export const addFullProfileDetails = createAction(
  '[FullProfileDetail/API] Add FullProfileDetails',
  props<{ fullProfileDetails: FullProfileDetail[] }>()
);

export const upsertFullProfileDetails = createAction(
  '[FullProfileDetail/API] Upsert FullProfileDetails',
  props<{ fullProfileDetails: FullProfileDetail[] }>()
);

export const updateFullProfileDetail = createAction(
  '[FullProfileDetail/API] Update FullProfileDetail',
  props<{ fullProfileDetail: Update<FullProfileDetail> }>()
);

export const updateFullProfileDetails = createAction(
  '[FullProfileDetail/API] Update FullProfileDetails',
  props<{ fullProfileDetails: Update<FullProfileDetail>[] }>()
);

export const deleteFullProfileDetail = createAction(
  '[FullProfileDetail/API] Delete FullProfileDetail',
  props<{ id: string }>()
);

export const deleteFullProfileDetails = createAction(
  '[FullProfileDetail/API] Delete FullProfileDetails',
  props<{ ids: string[] }>()
);

export const clearFullProfileDetails = createAction(
  '[FullProfileDetail/API] Clear FullProfileDetails'
);


// Effect Actions


export const getFullProfileDetails = createAction(
    '[FullProfileDetail/API] get advocate full profile details',
    props<{ applicationUid: string }>()
);

export const getFullAdvocateProfileDetails = createAction(
    '[FullProfileDetail/API] get advocate full profile details for advocate or petitioner side',
    props<{userUid?: string}>()
);
