import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Firm } from './firm.model';
import * as FirmActions from './firm.actions';

export const firmsFeatureKey = 'firms';

export interface State extends EntityState<Firm> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Firm> = createEntityAdapter<Firm>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(FirmActions.addFirm,
    (state, action) => adapter.addOne(action.firm, state)
  ),
  on(FirmActions.upsertFirm,
    (state, action) => adapter.upsertOne(action.firm, state)
  ),
  on(FirmActions.addFirms,
    (state, action) => adapter.addMany(action.firms, state)
  ),
  on(FirmActions.upsertFirms,
    (state, action) => adapter.upsertMany(action.firms, state)
  ),
  on(FirmActions.updateFirm,
    (state, action) => adapter.updateOne(action.firm, state)
  ),
  on(FirmActions.updateFirms,
    (state, action) => adapter.updateMany(action.firms, state)
  ),
  on(FirmActions.deleteFirm,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(FirmActions.deleteFirms,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(FirmActions.loadFirms,
    (state, action) => adapter.setAll(action.firms, state)
  ),
  on(FirmActions.clearFirms,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
