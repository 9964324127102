import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {RenewalBatch} from './renewal-batch.model';

export const loadRenewalBatchs = createAction(
    '[RenewalBatch/API] Load RenewalBatchs',
    props<{ renewalBatchs: RenewalBatch[] }>()
);

export const addRenewalBatch = createAction(
    '[RenewalBatch/API] Add RenewalBatch',
    props<{ renewalBatch: RenewalBatch }>()
);

export const upsertRenewalBatch = createAction(
    '[RenewalBatch/API] Upsert RenewalBatch',
    props<{ renewalBatch: RenewalBatch }>()
);

export const addRenewalBatchs = createAction(
    '[RenewalBatch/API] Add RenewalBatchs',
    props<{ renewalBatchs: RenewalBatch[] }>()
);

export const upsertRenewalBatchs = createAction(
    '[RenewalBatch/API] Upsert RenewalBatchs',
    props<{ renewalBatchs: RenewalBatch[] }>()
);

export const updateRenewalBatch = createAction(
    '[RenewalBatch/API] Update RenewalBatch',
    props<{ renewalBatch: Update<RenewalBatch> }>()
);

export const updateRenewalBatchs = createAction(
    '[RenewalBatch/API] Update RenewalBatchs',
    props<{ renewalBatchs: Update<RenewalBatch>[] }>()
);

export const deleteRenewalBatch = createAction(
    '[RenewalBatch/API] Delete RenewalBatch',
    props<{ id: string }>()
);

export const deleteRenewalBatchs = createAction(
    '[RenewalBatch/API] Delete RenewalBatchs',
    props<{ ids: string[] }>()
);

export const clearRenewalBatchs = createAction(
    '[RenewalBatch/API] Clear RenewalBatchs'
);

export const getRenewalBatch = createAction(
    '[RenewalBatch/API] Get Single RenewalBatchs'
);


export const saveRenewalBatch = createAction(
    '[RenewalBatch/API] Save  RenewalBatchs',
    props<{ renewalBatch: any }>()
);

export const editRenewalBatch = createAction(
    '[RenewalBatch/API] Edit  RenewalBatchs',
    props<{ renewalBatch: any, uid: string }>()
);
