import gql from 'graphql-tag';

export const workFlowStageGqlFields = `
    id
    uid
    active
    billable
    stage
    createdAt
    updatedAt
    createdBy{
        id
        fullName
    }
    actionUserType{
        id
        uid
        name
        displayName
    }
    workflowProcess{
        id
        uid
        name
        displayName
    }
`;

export const CREATE_WORK_FLOW_STAGE = gql `
    mutation saveWorkStage($stage: WorkflowStageDtoInput){
        saveWorkflowStage(workflowStageDto: $stage){
            code
            data{
                ${workFlowStageGqlFields}
            }
            dataList{
                ${workFlowStageGqlFields}
            }
            status
            errorDescription
        }
    }
`;

export const GET_WORK_FLOW_STAGES_BY_PROCESS = gql `
    query getWorkFlowStagesByProcess($uid: String){
        getWorkflowStageByProcess(workflowProcessUid: $uid){
            ${workFlowStageGqlFields}
        }
    }
`;

export const GET_ALL_WORK_FLOW_PROCESS = gql `
    query getWorkFlowProcesses{
        getWorkflowProcess{
            id
            uid
            active
            displayName
            name
            attachments{
                attachmentType{
                    id
                    uid
                    name
                }
            }
            updatedAt
            createdAt
        }
    }
`;
