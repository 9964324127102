import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from './shared/shared.module';
import {GraphQLModule} from './graphql.module';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientModule,
} from '@angular/common/http';
import {NgxPermissionsModule, NgxPermissionsService} from 'ngx-permissions';
import {NgHttpLoaderModule} from 'ng-http-loader';
import {StoreModule} from '@ngrx/store';
import {metaReducers, reducers} from './store/reducers';
import {environment} from '../environments/environment';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {DatePipe} from '@angular/common';
import {AuthInterceptor} from './common/interceptors/auth.interceptor';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {AuthService} from './services/auth/auth.service';
import {EffectsModule} from '@ngrx/effects';
import {effects} from './store/effects';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        GraphQLModule,
        HttpClientModule,
        NgxPermissionsModule.forRoot(),
        NgHttpLoaderModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient],
            },
        }),
        SharedModule,
        AppRoutingModule,
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
            },
        }),
        !environment.production
            ? StoreDevtoolsModule.instrument({
                maxAge: 25,
                logOnly: environment.production,
            })
            : [],
        EffectsModule.forRoot([...effects]),
    ],
    providers: [
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
        NgxPermissionsService,
        {
            provide: APP_INITIALIZER,
            useFactory: (auth: AuthService) => {
                return async () => {
                    // console.log('checking for authentication 1');
                    await auth.authRole();
                    // console.log('checking for authentication 2');
                };
            },
            deps: [AuthService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}

export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
