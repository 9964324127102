export enum NotificationType {
    PETITION_APPLICATIONS = 'PETITION_APPLICATIONS',
    NAME_CHANGE_APPLICATIONS = 'NAME_CHANGE_APPLICATIONS',
    PRACTISING_APPLICATIONS = 'PRACTISING_APPLICATIONS',
    NON_PRACTISING_APPLICATIONS = 'NON_PRACTISING_APPLICATIONS',
    NON_PROFIT_APPLICATIONS = 'NON_PROFIT_APPLICATIONS',
    RETIREMENT_APPLICATIONS = 'RETIREMENT_APPLICATIONS',
    SUSPENSION_APPLICATIONS = 'SUSPENSION_APPLICATIONS',
    RENEWAL_APPLICATIONS = 'RENEWAL_APPLICATIONS'
}

export interface AppNotification {
    id: number;
    type: NotificationType;
    count: number;
}
