import gql from 'graphql-tag';

export const ReconciliationBatchGraphqlFields = `
    id
    reconcDate
    reconcStsCode
    reconcilliations{
        id
    }
    spCode
    spName
    spReconcReqId
    reconcDate
`;
export const SEND_RECONCILIATION_REQUEST = gql`
    mutation sendReconciliationRequest($date:String){
        sendReconciliationRequest(date:$date)
    }
`;

export const RECONCILIATION_BATCH_LIST = gql`
    query getReconciliationBatchList($dtInput: DataTablesInputInput){
        getReconciliationBatchList(dtInput:$dtInput){
            data{
                ${ReconciliationBatchGraphqlFields}
            }
            draw
            recordsTotal
            recordsFiltered
        }
    }
`;

export const  EXPORT_RECONCILIATION_BATCH = gql`
    query exportReconciliationBatch($reconBatchId:Long){
      exportReconciliationBatch(reconBatchId:$reconBatchId){
        data
        code
        status
        errorDescription
      }
    }
`;





