import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {AppNotification} from './app-notification.model';

export const loadAppNotifications = createAction(
    '[AppNotification/API] Load AppNotifications',
    props<{ appNotifications: AppNotification[] }>()
);

export const addAppNotification = createAction(
    '[AppNotification/API] Add AppNotification',
    props<{ appNotification: AppNotification }>()
);

export const upsertAppNotification = createAction(
    '[AppNotification/API] Upsert AppNotification',
    props<{ appNotification: AppNotification }>()
);

export const addAppNotifications = createAction(
    '[AppNotification/API] Add AppNotifications',
    props<{ appNotifications: AppNotification[] }>()
);

export const upsertAppNotifications = createAction(
    '[AppNotification/API] Upsert AppNotifications',
    props<{ appNotifications: AppNotification[] }>()
);

export const updateAppNotification = createAction(
    '[AppNotification/API] Update AppNotification',
    props<{ appNotification: Update<AppNotification> }>()
);

export const updateAppNotifications = createAction(
    '[AppNotification/API] Update AppNotifications',
    props<{ appNotifications: Update<AppNotification>[] }>()
);

export const deleteAppNotification = createAction(
    '[AppNotification/API] Delete AppNotification',
    props<{ id: string }>()
);

export const deleteAppNotifications = createAction(
    '[AppNotification/API] Delete AppNotifications',
    props<{ ids: string[] }>()
);

export const clearAppNotifications = createAction(
    '[AppNotification/API] Clear AppNotifications'
);

export const listAppNotifications = createAction(
    '[AppNotification/API] List AppNotifications'
);
