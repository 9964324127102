<!-- Filter -->
<div class="table-filter">
      <mat-form-field>
        <mat-label>Search ...</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
      </mat-form-field>

</div>
<!-- end of Filter -->

<div class="mat-elevation-z8">

    <mat-table [dataSource]="listData" matSort>


        <ng-container *ngFor="let tableData of objectKeys(columnHeader); let i = index;">
            <ng-container *ngIf="i == 0">
              <!-- SN Column -->
              <ng-container matColumnDef="{{tableData}}">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> {{columnHeader[tableData]}} </mat-header-cell>
                  <mat-cell *matCellDef="let element; let i = index"> {{i + 1 + myPaginator.pageIndex * myPaginator.pageSize}} </mat-cell>
              </ng-container>
              <!-- end of SN Column -->
            </ng-container>

            <ng-container *ngIf="!actionColumn">
                <ng-container *ngIf="(i > 0)">
                  <ng-container matColumnDef="{{tableData}}">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>{{columnHeader[tableData]}} </mat-header-cell>
                      <mat-cell *matCellDef="let element">{{element[tableData]}}</mat-cell>
                  </ng-container>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="actionColumn">

              <ng-container *ngIf="(i > 0) && (i < (objectKeys(columnHeader).length - 1))">
                <ng-container matColumnDef="{{tableData}}">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{columnHeader[tableData]}} </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element[tableData]}}</mat-cell>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="i == (objectKeys(columnHeader).length - 1)">
                  <ng-container matColumnDef="{{tableData}}">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>{{columnHeader[tableData]}} </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        <ng-container [ngTemplateOutlet]="actions" [ngTemplateOutletContext]="{data : row}"></ng-container>
                      </mat-cell>
                  </ng-container>
              </ng-container>

            </ng-container>

        </ng-container>



        <!-- Table Configurations -->
        <mat-header-row *matHeaderRowDef="objectKeys(columnHeader)"></mat-header-row>
        <mat-row *matRowDef="let row; columns: objectKeys(columnHeader);"></mat-row>
        <!-- end of Table Configurations -->

        <!-- If No Data Found -->
        <mat-footer-row *matFooterRowDef="['noData']"
                        [ngClass]="{ hide: !(listData != null && listData.data.length == 0) }"></mat-footer-row>
        <ng-container matColumnDef="noData">
          <mat-footer-cell *matFooterCellDef colspan="8">No Data</mat-footer-cell>
        </ng-container>
        <!-- end if no Data Found -->

        <!-- Loading Data Message -->
        <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{ hide: listData != null }"></mat-footer-row>
        <ng-container matColumnDef="loading">
          <mat-footer-cell *matFooterCellDef colspan="8">Loading ...</mat-footer-cell>
        </ng-container>
        <!-- end of Loading Data Message -->

    </mat-table>

    <mat-paginator #myPaginator [pageSizeOptions]="[10, 50, 100, 200]" [pageSize]="10" showFirstLastButtons></mat-paginator>
</div>
