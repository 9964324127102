import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Application } from './application.model';
import * as ApplicationActions from './application.actions';

export const applicationsFeatureKey = 'applications';

export interface State extends EntityState<Application> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Application> = createEntityAdapter<Application>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(ApplicationActions.addApplication,
    (state, action) => adapter.addOne(action.application, state)
  ),
  on(ApplicationActions.upsertApplication,
    (state, action) => adapter.upsertOne(action.application, state)
  ),
  on(ApplicationActions.addApplications,
    (state, action) => adapter.addMany(action.applications, state)
  ),
  on(ApplicationActions.upsertApplications,
    (state, action) => adapter.upsertMany(action.applications, state)
  ),
  on(ApplicationActions.updateApplication,
    (state, action) => adapter.updateOne(action.application, state)
  ),
  on(ApplicationActions.updateApplications,
    (state, action) => adapter.updateMany(action.applications, state)
  ),
  on(ApplicationActions.deleteApplication,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ApplicationActions.deleteApplications,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ApplicationActions.loadApplications,
    (state, action) => adapter.setAll(action.applications, state)
  ),
  on(ApplicationActions.clearApplications,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
