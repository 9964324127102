import gql from 'graphql-tag';

export const petitionEducationGqlFields = `
    id
    uid
    beginYear
    endYear
    title
    school
    petitionEducation
    updatedAt
    createdAt
    active
    createdBy {
        id
        fullName
    }
    profile{
        id
    }
`;

export const SAVE_PETITION_EDUCATION = gql`
    mutation savePetitionEducation($education: PetitionEducationDtoInput) {
        savePetitionEducation(petitionEducationDto: $education) {
            code
            data {
                ${petitionEducationGqlFields}
            }
            status
            errorDescription
        }
    }
`;


export const GET_PETITION_EDUCATION_BY_USER = gql`
    query getPetitionEducation{
        getPetitionEducationByUser{
            code
            data{
                ${petitionEducationGqlFields}
            }
            dataList{
                ${petitionEducationGqlFields}
            }
            errorDescription
            status
        }
    }
`;

export const EDIT_PETITION_EDUCATION = gql`
    mutation updatePetitionEducation($uid: String,$education: PetitionEducationDtoInput){
        updatePetitionEducation(uid: $uid, petitionEducationDto: $education){
            code
            data{
                ${petitionEducationGqlFields}
            }
            status
            errorDescription
        }
    }
`;
