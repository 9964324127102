import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Apollo} from 'apollo-angular';
import {Store} from '@ngrx/store';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {
    editBarExam,
    fetchAllBarExamsBySessionUid, loadBarExams,
    saveBarExam,
    upsertBarExam,
    upsertBarExams
} from './bar-exam.actions';
import {map, switchMap} from 'rxjs/operators';
import {CREATE_BAR_EXAM, GET_BAR_EXAMS_BY_SESSION_UID, UPDATE_BAR_EXAM} from './bar-exam.graphql';
import {ResponseCode} from '../../../../shared/enums/http-status-codes.enum';
import {BarExam} from './bar-exam.model';


@Injectable()
export class BarExamEffects {

    allBarExams$ = createEffect(() => this.actions$.pipe(
        ofType(fetchAllBarExamsBySessionUid),
        switchMap((action) => {
            return this.apollo.query({
                query: GET_BAR_EXAMS_BY_SESSION_UID,
                fetchPolicy: 'network-only',
                variables: {
                    sessionUid: action.sessionUid
                }
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        this.store.dispatch(loadBarExams({barExams:
                                Object.values(data)[0] as BarExam[]
                        }));
                    }
                })
            );
        })
    ), {dispatch: false});


    createBarExam$ = createEffect(() => this.actions$.pipe(
            ofType(saveBarExam),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: CREATE_BAR_EXAM,
                    variables: {
                        bar: action.barExam
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.saveBarExam?.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(upsertBarExam({barExam: data?.saveBarExam?.data}));
                                return this.notificationService.successMessage('Saved successfully');
                            } else {
                                return this.responseCodesService.errorMessageByMessage(data.saveBarExam);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    editBarExam$ = createEffect(() => this.actions$.pipe(
            ofType(editBarExam),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: UPDATE_BAR_EXAM,
                    variables: {
                        barExamUid: action.barExamUid,
                        barExamDto: action.barExam
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.updateBarExam?.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(upsertBarExam({barExam: data?.updateBarExam?.data}));
                                return this.notificationService.successMessage('Updated successfully');
                            } else {
                                return this.responseCodesService.errorMessageByMessage(data.updateBarExam);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService,
    ) {
    }

}
