import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { AppearanceVenue } from './appearance-venue.model';

export const loadAppearanceVenues = createAction(
  '[AppearanceVenue/API] Load AppearanceVenues', 
  props<{ appearanceVenues: AppearanceVenue[] }>()
);

export const addAppearanceVenue = createAction(
  '[AppearanceVenue/API] Add AppearanceVenue',
  props<{ appearanceVenue: AppearanceVenue }>()
);

export const upsertAppearanceVenue = createAction(
  '[AppearanceVenue/API] Upsert AppearanceVenue',
  props<{ appearanceVenue: AppearanceVenue }>()
);

export const addAppearanceVenues = createAction(
  '[AppearanceVenue/API] Add AppearanceVenues',
  props<{ appearanceVenues: AppearanceVenue[] }>()
);

export const upsertAppearanceVenues = createAction(
  '[AppearanceVenue/API] Upsert AppearanceVenues',
  props<{ appearanceVenues: AppearanceVenue[] }>()
);

export const updateAppearanceVenue = createAction(
  '[AppearanceVenue/API] Update AppearanceVenue',
  props<{ appearanceVenue: Update<AppearanceVenue> }>()
);

export const updateAppearanceVenues = createAction(
  '[AppearanceVenue/API] Update AppearanceVenues',
  props<{ appearanceVenues: Update<AppearanceVenue>[] }>()
);

export const deleteAppearanceVenue = createAction(
  '[AppearanceVenue/API] Delete AppearanceVenue',
  props<{ id: string }>()
);

export const deleteAppearanceVenues = createAction(
  '[AppearanceVenue/API] Delete AppearanceVenues',
  props<{ ids: string[] }>()
);

export const clearAppearanceVenues = createAction(
  '[AppearanceVenue/API] Clear AppearanceVenues'
);

/*
*  Effect actions
* */

export const fetchAllAppearanceVenues = createAction(
    '[AppearanceVenue/API] fetch All appearance venue from server',
);

export const saveAppearanceVenue = createAction(
    '[AppearanceVenue/API] add new appearance venue to server',
    props<{venue: AppearanceVenue}>()
);

export const editAppearanceVenue = createAction(
    '[AppearanceVenue/API] edit existing appearance venue to server',
    props<{venue: AppearanceVenue, uid: string}>()
);

export const removeAppearanceVenue = createAction(
    '[AppearanceVenue/API] remove this appearance venue to server',
    props<{uid: string}>()
);
