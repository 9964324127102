import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLink, HttpLinkModule } from 'apollo-angular-link-http';
import { environment } from '../environments/environment';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { AuthService } from './services/auth/auth.service';


@NgModule({
  exports: [
    HttpClientModule,
    ApolloModule,
    HttpLinkModule
  ],
  imports: [],
})
export class GraphQLModule {

  constructor(apollo: Apollo, httpLink: HttpLink, private readonly auth: AuthService) {
    const uri = environment.SERVER_URL + '/graphql';
    apollo.create({
      link: ApolloLink.from([
        // this.errorLink(),
        httpLink.create({ uri }),
      ]),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'network-only',
          errorPolicy: 'all'
        }
      }
    }, 'default');
  }

}
