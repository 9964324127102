import {Pipe, PipeTransform} from '@angular/core';
import {GepgErrorCodes} from '../../services/response-codes/response-codes.service';

@Pipe({
    name: 'gepgStatusCode'
})
export class GepgStatusCodePipe implements PipeTransform {

    transform(value: string, ...args: unknown[]): unknown {
        const codes = value?.split(';');
        return value + ', ' + codes?.map(c => GepgErrorCodes[c]).join(', ');
    }

}
