import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {sendReconciliationRequest, sendReconciliationRequestCompleted} from './reconciliation-batch.actions';
import {SEND_RECONCILIATION_REQUEST} from './reconciliation-batch.graphql';


@Injectable()
export class ReconciliationBatchEffects {

    sendReconciliationRequest$ = createEffect(() => this.actions$.pipe(
            ofType(sendReconciliationRequest),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: SEND_RECONCILIATION_REQUEST,
                    variables: {
                        date: action.date
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data.sendReconciliationRequest) {
                                this.store.dispatch(sendReconciliationRequestCompleted());
                                return this.notificationService.successMessage('Bill Reconciliation batch  requested successfully. Hold on for few minutes and come back to see the response.');
                            } else {
                                return this.notificationService.errorMessageSwalFire('Unable to send  reconciliation request, Please try again later.');
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService
    ) {
    }

}
