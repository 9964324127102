import gql from 'graphql-tag';
import {advocateGqlFields} from '../../advocate/advocate/advocate.graphql';

export const cleMembersGqlFields = `
    id
    uid
    active
    updatedAt
    createdAt
    title
    user{
      id
      uid
      fullName
    }
    advocate{
      ${advocateGqlFields}
      profile{
        title
        fullname
        user{
          id
          uid
          actionUserTypeUsers{
            id
            actionUserType{
              id
              name
              displayName
            }
          }
        }
      }
    }
`;

export const LIST_ALL_CLE_MEMBERS = gql`
    query getAllCleMembers{
        getAllCleMember{
            ${cleMembersGqlFields}
        }
    }
`;

export const UPDATE_CLE_MEMBER = gql`
    mutation updateCleMember($uid: String, $member: CleMemberDtoInput){
        updateCleMember(uid: $uid, cleMemberDto: $member){
            code
            data{
                ${cleMembersGqlFields}
            }
            status
            errorDescription
        }
    }
`;
