import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {listAppNotifications, loadAppNotifications} from './app-notification.actions';
import {map, switchMap} from 'rxjs/operators';
import {LIST_APP_NOTIFICATIONS} from './app-notification.graphql';
import {Apollo} from 'apollo-angular';
import {AppState} from '../../../reducers';
import {Store} from '@ngrx/store';
import {NotificationService} from '../../../../services/notifications/notification.service';


@Injectable()
export class AppNotificationEffects {


    listAppNotifications$ = createEffect(() => this.actions$.pipe(
        ofType(listAppNotifications),
        switchMap((action) => {
            return this.apollo.watchQuery({
                query: LIST_APP_NOTIFICATIONS,
                fetchPolicy: 'network-only',
            }).valueChanges.pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        this.store.dispatch(loadAppNotifications({appNotifications: data.listAppNotifications}));
                    }
                })
            );
        })
    ), {dispatch: false});


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private store: Store<AppState>,
        private notificationService: NotificationService
    ) {
    }

}
