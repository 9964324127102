import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {ResponseCode} from '../../../../shared/enums/http-status-codes.enum';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {
    approvePetitionApplication,
    getApplicationByUid,
    reSubmitApplication,
    savePermitApplication,
    saveRenewalApplication,
    submitPetitionApplication,
    updatePetitionAdmitAs,
    upsertApplication
} from './application.actions';
import {
    APPROVE_PETITION_APPLICATION,
    GET_APPLICATION_BY_UID,
    RESUBMIT_OTHER_APPLICATION,
    SAVE_PERMIT_APPLICATION,
    SAVE_RENEWAL_APPLICATION,
    SUBMIT_PETITION_APPLICATION,
    UPDATE_PETITION_ADMIT_AS
} from './application.graphql';
import {refreshSubmitButton} from '../../advocate/advocate-profile/advocate-profile.actions';
import {getFullAdvocateProfileDetails, getFullProfileDetails} from '../full-profile-detail/full-profile-detail.actions';
import {listAppNotifications} from '../app-notification/app-notification.actions';
import {showRenewalButton} from '../../auth-user/auth-user.actions';


@Injectable()
export class ApplicationEffects {

    submitApplication$ = createEffect(() => this.actions$.pipe(
            ofType(submitPetitionApplication),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: SUBMIT_PETITION_APPLICATION,
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.savePetitionApplication?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(upsertApplication({application: data.savePetitionApplication.data}));
                            this.store.dispatch(getFullAdvocateProfileDetails({}));
                            this.store.dispatch(refreshSubmitButton());
                            return this.notificationService.successMessage('Your application submitted successfully');
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.savePetitionApplication);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    savePermitApplication$ = createEffect(() => this.actions$.pipe(
            ofType(savePermitApplication),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: SAVE_PERMIT_APPLICATION,
                    variables: {
                        permitApplication: action.permitApplication
                    }
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.savePermitApplication?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(upsertApplication({application: data.savePermitApplication.data}));
                            return this.notificationService.successMessage('Your application submitted successfully');
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.savePermitApplication);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    approveApplication$ = createEffect(() => this.actions$.pipe(
            ofType(approvePetitionApplication),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: APPROVE_PETITION_APPLICATION,
                    variables: {
                        approve: action.approve
                    }
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.approveApplication?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(upsertApplication({application: data.approveApplication?.data}));
                            this.store.dispatch(getFullProfileDetails({applicationUid: data.approveApplication?.data?.uid}));
                            this.store.dispatch(getApplicationByUid({uid: action.approve.uid}));
                            this.store.dispatch(listAppNotifications());
                            return this.notificationService.successMessage(`Your decision '${action.approve.decision}' have been saved successfully`);
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.approveApplication);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    applicationByUid$ = createEffect(() => this.actions$.pipe(
            ofType(getApplicationByUid),
            switchMap((action) => {
                return this.apollo.query({
                    query: GET_APPLICATION_BY_UID,
                    variables: {
                        uid: action.uid
                    }
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.getApplicationByUid?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(upsertApplication({application: data.getApplicationByUid.data}));
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.getApplicationByUid);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    updatePAdmitAs$ = createEffect(() => this.actions$.pipe(
            ofType(updatePetitionAdmitAs),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: UPDATE_PETITION_ADMIT_AS,
                    variables: {
                        admit: action.admit,
                        applicationUid: action.applicationUid
                    }
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.updatePetitionAdmitAs?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(getFullProfileDetails({applicationUid: action.applicationUid}));
                            this.store.dispatch(upsertApplication({application: data.updatePetitionAdmitAs.data}));
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.updatePetitionAdmitAs);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    saveRenewalApplication$ = createEffect(() => this.actions$.pipe(
            ofType(saveRenewalApplication),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: SAVE_RENEWAL_APPLICATION,
                    variables: {
                        renewalApplicationDto: action.renewalApplication
                    }
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.saveRenewalApplication?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(upsertApplication({application: data.saveRenewalApplication.data}));
                            this.store.dispatch(showRenewalButton());
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.saveRenewalApplication);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    resubmitApplication$ = createEffect(() => this.actions$.pipe(
            ofType(reSubmitApplication),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: RESUBMIT_OTHER_APPLICATION,
                    variables: {
                        appUid: action.applicationUid,
                        applicationDto: action.resubmitApplication
                    }
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.resubmitApplication?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(getFullAdvocateProfileDetails({}));
                            this.store.dispatch(upsertApplication({application: data.resubmitApplication.data}));
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.saveRenewalApplication);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService
    ) {
    }
}
