import {AuthService} from '../../services/auth/auth.service';
import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {map, catchError} from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private auth: AuthService
    ) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const currentClient = JSON.parse(localStorage.getItem('currentClient'));

        if (currentClient) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentClient}`
                }
            });
            request = request.clone({url: request.url + '?access_token=' + currentClient});
        }
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {

                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                const data = {
                    reason: error && error.error.reason ? error.error.reason : '',
                    status: error.status,
                    causedBy: error.message
                };
                this.auth.collectFailedRequest(data);
                return throwError(error);
            }));
    }

}
