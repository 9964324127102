<div class="wrapper">
    <header class="header-top" header-theme="light">
        <div class="container-fluid">
            <div class="d-flex justify-content-between">
                <div class="top-menu d-flex align-items-center">
                    <button type="button" class="btn-icon mobile-nav-toggle d-lg-none"><span></span></button>
                    <div class="header-search">
                        <div class="input-group">
                            <span class="input-group-addon search-close"><i class="ik ik-x"></i></span>
                            <input type="text" class="form-control">
                            <span class="input-group-addon search-btn"><i class="ik ik-search"></i></span>
                        </div>
                    </div>
                    <button type="button" id="navbar-fullscreen" class="nav-link"><i class="ik ik-maximize"></i>
                    </button>
                </div>
                <ng-container *ngIf="authUser$ | async as user">
                    <div class="top-menu d-flex align-items-center">

                        <ng-container *ngIf=" totalAppNotifications$|async as totNotifications">
                            <ng-container *ngIf="totNotifications > 0">
                                <div class="dropdown">
                                    <a class="nav-link dropdown-toggle" href="#" id="notiDropdown" role="button"
                                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
                                            class="ik ik-bell"></i><span
                                            class="badge bg-danger" style="width: unset !important;">{{totNotifications}}</span></a>
                                    <div class="dropdown-menu dropdown-menu-right notification-dropdown"
                                         aria-labelledby="notiDropdown">
                                        <h4 class="header">Notifications</h4>

                                        <ng-container *ngFor="let notification of appNotifications$ | async">
                                            <ng-container [ngSwitch]="notification.type">

                                                <ng-container *ngIf="notification.count>0">
                                                    <div *ngSwitchCase="type.PETITION_APPLICATIONS"
                                                         class="notifications-wrap">
                                                        <a href="#"
                                                           [routerLink]="['','management','petition-applications']"
                                                           class="media">
                                                        <span class="media-body">
                                                            <span class="heading-font-family media-heading">Petition Applications</span><br>
                                                            <span class="media-content">{{notification.count}}
                                                                application<span
                                                                        *ngIf="notification.count> 1">s</span> need<span
                                                                        *ngIf="notification.count == 1">s</span>  your action</span>
                                                        </span>
                                                        </a>
                                                    </div>

                                                    <div *ngSwitchCase="type.NAME_CHANGE_APPLICATIONS"
                                                         class="notifications-wrap">
                                                        <a href="#"
                                                           [routerLink]="['','management','applications-for-name-change']"
                                                           class="media">
                                                        <span class="media-body">
                                                            <span class="heading-font-family media-heading">Name Change Applications</span><br>
                                                            <span class="media-content">{{notification.count}}
                                                                application<span
                                                                        *ngIf="notification.count> 1">s</span> need<span
                                                                        *ngIf="notification.count == 1">s</span>  your action</span>
                                                        </span>
                                                        </a>
                                                    </div>

                                                    <div *ngSwitchCase="type.PRACTISING_APPLICATIONS"
                                                         class="notifications-wrap">
                                                        <a href="#"
                                                           [routerLink]="['','management','applications-for-practising']"
                                                           class="media">
                                                        <span class="media-body">
                                                            <span class="heading-font-family media-heading">Practising Applications</span><br>
                                                            <span class="media-content">{{notification.count}}
                                                                application<span
                                                                        *ngIf="notification.count> 1">s</span> need<span
                                                                        *ngIf="notification.count == 1">s</span>  your action</span>
                                                        </span>
                                                        </a>
                                                    </div>


                                                    <div *ngSwitchCase="type.NON_PRACTISING_APPLICATIONS"
                                                         class="notifications-wrap">
                                                        <a href="#"
                                                           [routerLink]="['','management','applications-for-non-practising']"
                                                           class="media">
                                                        <span class="media-body">
                                                            <span class="heading-font-family media-heading">Non Practising Applications</span><br>
                                                            <span class="media-content">{{notification.count}}
                                                                application<span
                                                                        *ngIf="notification.count> 1">s</span> need<span
                                                                        *ngIf="notification.count == 1">s</span>  your action</span>
                                                        </span>
                                                        </a>
                                                    </div>


                                                    <div *ngSwitchCase="type.NON_PROFIT_APPLICATIONS"
                                                         class="notifications-wrap">
                                                        <a href="#"
                                                           [routerLink]="['','management','applications-for-non-profit']"
                                                           class="media">
                                                        <span class="media-body">
                                                            <span class="heading-font-family media-heading">Non Profit Applications</span><br>
                                                            <span class="media-content">{{notification.count}}
                                                                application<span
                                                                        *ngIf="notification.count> 1">s</span> need<span
                                                                        *ngIf="notification.count == 1">s</span>  your action</span>
                                                        </span>
                                                        </a>
                                                    </div>

                                                    <div *ngSwitchCase="type.RETIREMENT_APPLICATIONS"
                                                         class="notifications-wrap">
                                                        <a href="#"
                                                           [routerLink]="['','management','applications-for-retirement']"
                                                           class="media">
                                                        <span class="media-body">
                                                            <span class="heading-font-family media-heading">Retirement Applications</span><br>
                                                            <span class="media-content">{{notification.count}}
                                                                application<span
                                                                        *ngIf="notification.count> 1">s</span> need<span
                                                                        *ngIf="notification.count == 1">s</span>  your action</span>
                                                        </span>
                                                        </a>
                                                    </div>

                                                    <div *ngSwitchCase="type.SUSPENSION_APPLICATIONS"
                                                         class="notifications-wrap">
                                                        <a href="#"
                                                           [routerLink]="['','management','applications-for-suspension']"
                                                           class="media">
                                                        <span class="media-body">
                                                            <span class="heading-font-family media-heading">Suspension Applications</span><br>
                                                            <span class="media-content">{{notification.count}}
                                                                application<span
                                                                        *ngIf="notification.count> 1">s</span> need<span
                                                                        *ngIf="notification.count == 1">s</span>  your action</span>
                                                        </span>
                                                        </a>
                                                    </div>

                                                    <div *ngSwitchCase="type.RENEWAL_APPLICATIONS"
                                                         class="notifications-wrap">
                                                        <a href="#"
                                                           [routerLink]="['','management','applications-for-renewal']"
                                                           class="media">
                                                        <span class="media-body">
                                                            <span class="heading-font-family media-heading">Renewal Out Of Time Applications</span><br>
                                                            <span class="media-content">{{notification.count}}
                                                                application<span
                                                                        *ngIf="notification.count> 1">s</span> need<span
                                                                        *ngIf="notification.count == 1">s</span>  your action</span>
                                                        </span>
                                                        </a>
                                                    </div>
                                                </ng-container>

                                            </ng-container>

                                        </ng-container>

                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>


                        <!--    <button type="button" class="nav-link ml-10" id="apps_modal_btn" data-toggle="modal"
                                    data-target="#appsModal"><i class="ik ik-grid"></i></button>-->

                        <span class="d-inline-block pl-3 font-weight-bold cursor-pointer text-capitalize">
                            <ng-container *ngIf="currentUserProfile$ | async as userPTitle, else otherUserName">
                                {{getFullNameWithTitle(userPTitle?.title, user.fullName)}}&nbsp;
                            </ng-container>
                            <ng-template #otherUserName>
                              {{user?.fullName}}
                            </ng-template>
                        </span>

                        <div class="dropdown">
                            <a role="button" aria-haspopup="true" aria-expanded="false" class="cursor-pointer"
                               [matMenuTriggerFor]="userMenu">
                                <ng-container *ngIf="currentUserProfile$ | async as userProfile, else defaultImage">

                                    <ng-container *ngIf="userProfile?.photo, else defaultImage">
                                        <img class="avatar" src="data:image/png;base64,{{userProfile?.photo}}"
                                             alt="user profile image">
                                    </ng-container>

                                </ng-container>
                            </a>
                        </div>

                        <mat-menu #userMenu="matMenu">

                            <button mat-menu-item *ngIf="!user.isInternalUser"
                                    routerLink="/advocate/my-profile">
                                <mat-icon>face</mat-icon>
                                Profile
                            </button>
                            <button mat-menu-item (click)="changePassword(user)">
                                <mat-icon>vpn_key</mat-icon>
                                Change Password
                            </button>
                            <mat-divider></mat-divider>
                            <button mat-menu-item (click)="logout()">
                                <mat-icon>power_settings_new</mat-icon>
                                Log Out
                            </button>
                        </mat-menu>
                    </div>
                </ng-container>
            </div>
        </div>
    </header>

    <div class="page-wrap">
        <div class="app-sidebar colored">
            <div class="sidebar-header">
                <a class="header-brand" href="index.html">
                    <div class="logo-img">
                        <img src="assets/images/Judiciary-Logo.png" width="50" class="header-brand-img" alt="Judiciary">
                    </div>
                    <span class="text">e-WAKILI</span>
                </a>
            </div>

            <div class="sidebar-content">
                <div class="nav-container">
                    <nav id="main-menu-navigation" class="navigation-main">
                        <ng-container *ngFor="let mainMenu of mainMenus">
                            <div class="nav-lavel">{{mainMenu?.name}}</div>
                            <ng-container *ngFor="let navMenu of mainMenu.navMenus">
                                <ng-container
                                        *ngIf="(navMenu?.permissions != undefined) && (navMenu.permissions.length > 0)">
                                    <!-- without children -->
                                    <ng-container *ngIf="!navMenu.children">
                                        <div *ngxPermissionsOnly="navMenu.permissions" class="nav-item"
                                             routerLinkActive="active">
                                            <a routerLink="{{navMenu?.route}}">
                                                <!--             <i
                                                                 class="ik ik-bar-chart-2">

                                                         </i>-->
                                                <mat-icon
                                                        *ngIf="navMenu?.iconType === 'MATERIAL'">{{navMenu?.icon}}</mat-icon>
                                                <mat-icon mat-list-icon *ngIf="navMenu?.iconType === 'SVG'"
                                                          svgIcon="{{ navMenu.icon }}"
                                                ></mat-icon>

                                                <i *ngIf="navMenu?.iconType=='FA'" class="{{navMenu?.icon}}"></i>
                                                <span>{{navMenu?.name}}</span></a>
                                        </div>
                                    </ng-container>

                                    <!-- with children -->
                                    <ng-container *ngIf="navMenu.children">
                                        <div class="nav-item has-sub" *ngxPermissionsOnly="navMenu.permissions">
                                            <a href="javascript:void(0)">
                                                <mat-icon
                                                        *ngIf="navMenu?.iconType === 'MATERIAL'">{{navMenu?.icon}}</mat-icon>
                                                <mat-icon mat-list-icon *ngIf="navMenu?.iconType === 'SVG'"
                                                          svgIcon="{{ navMenu.icon }}"
                                                ></mat-icon>
                                                <span>{{navMenu?.name}}</span>
                                                <!-- <i
                                                     class="ik ik-layers"></i>-->
                                                <!--<span class="badge badge-danger">150+</span>-->
                                            </a>
                                            <div class="submenu-content">
                                                <ng-container *ngFor="let childMenu of navMenu.children">
                                                    <a *ngxPermissionsOnly="childMenu.permissions"
                                                       routerLink="{{childMenu?.route}}" class="menu-item"
                                                       routerLinkActive="active">{{childMenu?.name}}</a>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>

                    </nav>
                </div>
            </div>
        </div>

        <div class="main-content">
            <div class="container-fluid position-relative" [@routeAnimations]="prepareRoute(outlet)">

                <router-outlet #outlet="outlet"></router-outlet>

            </div>
        </div>

        <aside class="right-sidebar">
            <div class="sidebar-chat" data-plugin="chat-sidebar">
                <div class="sidebar-chat-info">
                    <h6>Chat List</h6>
                    <form class="mr-t-10">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Search for friends ...">
                            <i class="ik ik-search"></i>
                        </div>
                    </form>
                </div>
                <div class="chat-list">
                    <div class="list-group row">
                        <a href="javascript:void(0)" class="list-group-item" data-chat-user="Gene Newman">
                            <figure class="user--online">
                                <img src="assets/template/img/users/1.jpg" class="rounded-circle" alt="">
                            </figure>
                            <span><span class="name">Gene Newman</span>  <span
                                    class="username">@gene_newman</span> </span>
                        </a>
                        <a href="javascript:void(0)" class="list-group-item" data-chat-user="Billy Black">
                            <figure class="user--online">
                                <img src="assets/template/img/users/2.jpg" class="rounded-circle" alt="">
                            </figure>
                            <span><span class="name">Billy Black</span>  <span
                                    class="username">@billyblack</span> </span>
                        </a>
                        <a href="javascript:void(0)" class="list-group-item" data-chat-user="Herbert Diaz">
                            <figure class="user--online">
                                <img src="assets/template/img/users/3.jpg" class="rounded-circle" alt="">
                            </figure>
                            <span><span class="name">Herbert Diaz</span>  <span class="username">@herbert</span> </span>
                        </a>
                        <a href="javascript:void(0)" class="list-group-item" data-chat-user="Sylvia Harvey">
                            <figure class="user--busy">
                                <img src="assets/template/img/users/4.jpg" class="rounded-circle" alt="">
                            </figure>
                            <span><span class="name">Sylvia Harvey</span>  <span class="username">@sylvia</span> </span>
                        </a>
                        <a href="javascript:void(0)" class="list-group-item active" data-chat-user="Marsha Hoffman">
                            <figure class="user--busy">
                                <img src="assets/template/img/users/5.jpg" class="rounded-circle" alt="">
                            </figure>
                            <span><span class="name">Marsha Hoffman</span>  <span
                                    class="username">@m_hoffman</span> </span>
                        </a>
                        <a href="javascript:void(0)" class="list-group-item" data-chat-user="Mason Grant">
                            <figure class="user--offline">
                                <img src="assets/template/img/users/1.jpg" class="rounded-circle" alt="">
                            </figure>
                            <span><span class="name">Mason Grant</span>  <span
                                    class="username">@masongrant</span> </span>
                        </a>
                        <a href="javascript:void(0)" class="list-group-item" data-chat-user="Shelly Sullivan">
                            <figure class="user--offline">
                                <img src="assets/template/img/users/2.jpg" class="rounded-circle" alt="">
                            </figure>
                            <span><span class="name">Shelly Sullivan</span>  <span
                                    class="username">@shelly</span></span>
                        </a>
                    </div>
                </div>
            </div>
        </aside>


        <footer class="footer">
            <div class="w-100 clearfix">
                <span class="text-center text-sm-left d-md-inline-block">Copyright © 2018 - {{currentYear}}. All Rights Reserved.</span>
                <span class="float-none float-sm-right mt-1 mt-sm-0 text-center">e-WAKILI v1.0</span>
            </div>
        </footer>

    </div>
</div>


<div class="modal fade apps-modal" id="appsModal" tabindex="-1" role="dialog" aria-labelledby="appsModalLabel"
     aria-hidden="true" data-backdrop="false">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><i class="ik ik-x-circle"></i></button>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="quick-search">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4 ml-auto mr-auto">
                            <div class="input-wrap">
                                <input type="text" id="quick-search" class="form-control" placeholder="Search..."/>
                                <i class="ik ik-search"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-body d-flex align-items-center">
                <div class="container">
                    <div class="apps-wrap">
                        <div class="app-item">
                            <a href="#"><i class="ik ik-bar-chart-2"></i><span>Dashboard</span></a>
                        </div>
                        <div class="app-item">
                            <a href="#"><i class="ik ik-mail"></i><span>Message</span></a>
                        </div>
                        <div class="app-item">
                            <a href="#"><i class="ik ik-users"></i><span>Accounts</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #defaultImage>
    <img class="avatar" src="assets/images/avatar-dp.png" alt="">
</ng-template>
