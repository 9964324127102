import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { PermissionGroup } from './permission-group.model';

export const loadPermissionGroups = createAction(
  '[PermissionGroup/API] Load PermissionGroups', 
  props<{ permissionGroups: PermissionGroup[] }>()
);

export const addPermissionGroup = createAction(
  '[PermissionGroup/API] Add PermissionGroup',
  props<{ permissionGroup: PermissionGroup }>()
);

export const upsertPermissionGroup = createAction(
  '[PermissionGroup/API] Upsert PermissionGroup',
  props<{ permissionGroup: PermissionGroup }>()
);

export const addPermissionGroups = createAction(
  '[PermissionGroup/API] Add PermissionGroups',
  props<{ permissionGroups: PermissionGroup[] }>()
);

export const upsertPermissionGroups = createAction(
  '[PermissionGroup/API] Upsert PermissionGroups',
  props<{ permissionGroups: PermissionGroup[] }>()
);

export const updatePermissionGroup = createAction(
  '[PermissionGroup/API] Update PermissionGroup',
  props<{ permissionGroup: Update<PermissionGroup> }>()
);

export const updatePermissionGroups = createAction(
  '[PermissionGroup/API] Update PermissionGroups',
  props<{ permissionGroups: Update<PermissionGroup>[] }>()
);

export const deletePermissionGroup = createAction(
  '[PermissionGroup/API] Delete PermissionGroup',
  props<{ id: string }>()
);

export const deletePermissionGroups = createAction(
  '[PermissionGroup/API] Delete PermissionGroups',
  props<{ ids: string[] }>()
);

export const clearPermissionGroups = createAction(
  '[PermissionGroup/API] Clear PermissionGroups'
);

// effects actions

export const listPermissionGroups = createAction(
    '[PermissionGroup/API] List PermissionGroups For Company'
);
