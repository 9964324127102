import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { LegalProfessionalView } from './legal-professional-view.model';
import * as LegalProfessionalViewActions from './legal-professional-view.actions';

export const legalProfessionalViewsFeatureKey = 'legalProfessionalViews';

export interface State extends EntityState<LegalProfessionalView> {
  // additional entities state properties
}

export const adapter: EntityAdapter<LegalProfessionalView> = createEntityAdapter<LegalProfessionalView>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(LegalProfessionalViewActions.addLegalProfessionalView,
    (state, action) => adapter.addOne(action.legalProfessionalView, state)
  ),
  on(LegalProfessionalViewActions.upsertLegalProfessionalView,
    (state, action) => adapter.upsertOne(action.legalProfessionalView, state)
  ),
  on(LegalProfessionalViewActions.addLegalProfessionalViews,
    (state, action) => adapter.addMany(action.legalProfessionalViews, state)
  ),
  on(LegalProfessionalViewActions.upsertLegalProfessionalViews,
    (state, action) => adapter.upsertMany(action.legalProfessionalViews, state)
  ),
  on(LegalProfessionalViewActions.updateLegalProfessionalView,
    (state, action) => adapter.updateOne(action.legalProfessionalView, state)
  ),
  on(LegalProfessionalViewActions.updateLegalProfessionalViews,
    (state, action) => adapter.updateMany(action.legalProfessionalViews, state)
  ),
  on(LegalProfessionalViewActions.deleteLegalProfessionalView,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(LegalProfessionalViewActions.deleteLegalProfessionalViews,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(LegalProfessionalViewActions.loadLegalProfessionalViews,
    (state, action) => adapter.setAll(action.legalProfessionalViews, state)
  ),
  on(LegalProfessionalViewActions.clearLegalProfessionalViews,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
