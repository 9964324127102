import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Fee } from './fee.model';

export const loadFees = createAction(
  '[Fee/API] Load Fees', 
  props<{ fees: Fee[] }>()
);

export const addFee = createAction(
  '[Fee/API] Add Fee',
  props<{ fee: Fee }>()
);

export const upsertFee = createAction(
  '[Fee/API] Upsert Fee',
  props<{ fee: Fee }>()
);

export const addFees = createAction(
  '[Fee/API] Add Fees',
  props<{ fees: Fee[] }>()
);

export const upsertFees = createAction(
  '[Fee/API] Upsert Fees',
  props<{ fees: Fee[] }>()
);

export const updateFee = createAction(
  '[Fee/API] Update Fee',
  props<{ fee: Update<Fee> }>()
);

export const updateFees = createAction(
  '[Fee/API] Update Fees',
  props<{ fees: Update<Fee>[] }>()
);

export const deleteFee = createAction(
  '[Fee/API] Delete Fee',
  props<{ id: number }>()
);

export const deleteFees = createAction(
  '[Fee/API] Delete Fees',
  props<{ ids: string[] }>()
);

export const clearFees = createAction(
  '[Fee/API] Clear Fees'
);

export const saveFee = createAction(
    '[Fee/API] Save Fees',
    props<{ fee: any }>()
);

export const editFee = createAction(
    '[Fee/API] Edit Fees',
    props<{ fee: any, uid: string }>()
);

export const getAllFees = createAction(
    '[Fee/API] Get All Fees'
);

export const getFeeByUid = createAction(
    '[Fee/API] Get  Fees By Id',
    props<{ feeUid: string }>()
);

export const removeFee = createAction(
    '[Fee/API] Remove  Fees By uid',
    props<{ feeUid: string }>()
);


