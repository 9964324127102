import gql from 'graphql-tag';

export const UserGqlFields = `
    id
    uid
    fullName
    username
    email
    phoneText
    phone
    rolesList{
       id
       name
       displayName
    }
    address
    profile{
        id
    }
    isInternalUser
    accountNonLocked
    actionUserTypeUsers{
        id
        actionUserType{
            id
            name
            displayName
            actionDecisions{
                id
                name
                approveStatusEnums
            }
        }
    }
`;

export const LIST_USERS_DT = gql`
    query listUsersDt($dtInput:DataTablesInputInput,$isStaff:Boolean){
        listUsersDt(dtInput:$dtInput,isStaff:$isStaff){
            data{
                ${UserGqlFields}
            }
            draw
            recordsTotal
            recordsFiltered
        }
    }
`;

export const SAVE_USER_QUALIFICATIONS = gql`
    mutation saveUserQualifications($qualifications: UserQualificationDtoInput){
        saveUserQualification(userQualificationDto: $qualifications){
            code
            status
            errorDescription
        }
    }
`;

export const GET_LOGGED_IN_USER = gql`
    query getLoggedInUser{
        getLoggedInUser{
            ${UserGqlFields}
            userQualification{
                id
                qualification{
                    id
                    uid
                    name
                    qualificationName
                }
            }
        }
    }
`;

export const SAVE_STAFF = gql`
   mutation saveStaff($staff:StaffDtoInput){
    saveStaff(staff:$staff){
        data{
            ${UserGqlFields}
        }
        status
        code
        errorDescription
    }
   }
`;

export const LIST_OF_STAFF = gql`
    query getStaffList{
        getStaffList{
            ${UserGqlFields}
        }   
    }
`;

