import gql from 'graphql-tag';

export const BillGqlFields = `
    application{
        uid
    }
    bankAccountNumber
    bankName
    statusCose
    paidYear
    paidDate
    bankSwiftCode
    billDate
    billId
    amountInWord
    controlNumber
    controlNumberRequested
    createdAt
    createdBy{
        uid
    }
    currency
    dueDate
    emailControlNumberSent
    emailPaymentInfoSent
    feeType{
        uid
        service
        subspCode
    }
    id
    payer{
        id
        fullName
        phone
    }
    payment{
        uid
        createdAt
    }
    paymentStatus
    profile{
        uid
        fullname
        user{
           id
        }
    }
    smsControlNumberSent
    smsPaymentInfoSent
    totalAmount
    uid
    billItems{
        fee{
            description
        }
        amount
    }
`;
export const GET_APPLICANT_BILLS = gql`
 query getApplicantBill{
    getApplicantBill{
        ${BillGqlFields}
    }
 }
`;

export const REQUEST_CONTROL_NUMBER = gql`
  mutation requestControlNumber($billUid:String){
        requestControlNumber(billUid:$billUid)
  }
`;

export const FETCH_BILL_BY_UID = gql`
  query viewBill($billUid:String){
     viewBill(billUid:$billUid){
        data{
           ${BillGqlFields}
        }
        code
        status
        errorDescription
     }
  }
`;

export const GET_CLIENT_BILLS = gql`
    query getBillList($dtInput:DataTablesInputInput,$dateTo:LocalDate,$dateFrom:LocalDate){
        getBillList(dtInput:$dtInput,dateFrom:$dateFrom,dateTo:$dateTo){
            data{
                 ${BillGqlFields}
            }
            draw
            recordsTotal
            recordsFiltered
        }
    }
`;

export const CANCEL_BILL = gql`
    mutation cancelBill($billId:String){
        cancelBill(billId:$billId)
    }
`;

export const EXTEND_BILL = gql`
    mutation extendBill($billDto: BillExtDtoInput){
        extendBill(billDto:$billDto)
    }
`;

export  const REGENERATE_BILL = gql`
    mutation regenerateBill($billId:String){
        regenerateBill(billId:$billId)
    }
`;

export const REUSE_CONTROL_NUMBER = gql`
    mutation controlNumberReUse($billUid:String){
        controlNumberReUse(billUid:$billUid)
    }
`;


export  const  GET_APPLICATION_PAID_BILL = gql`
  query getApplicationPaidBill($applicationUid: String){
    getApplicationPaidBill(applicationUid: $applicationUid){
      data{
        ${BillGqlFields}
      }
      status
      code
      errorDescription
    }
  }
`;








