import gql from 'graphql-tag';

export const applicationDocumentGqlFields = `
  id
  uid
  active
  application{
    id
  }
  comment
  attachmentType{
    id
    uid
    name
  }
  createdBy{
    id
    fullName
  }
  filename
  status
  valid
  createdAt
  updatedAt
`;

export const SAVE_ATTACHMENTS = gql`
    mutation saveAppAttachments($attachments: ApplicationDocumentsDtoInput){
        saveApplicationAttachments(applicationDocumentsDto: $attachments){
            code
            data{
                ${applicationDocumentGqlFields}
            }
            dataList{
                ${applicationDocumentGqlFields}
            }
            errorDescription
            status
        }
    }
`;

export const GET_USER_ATTACHMENTS = gql`
    query getAppDocumentByUser{
        getDocumentsByUser{
            ${applicationDocumentGqlFields}
        }
    }
`;


export const READ_ATTACHMENT = gql`
    query viewApplicationDocument($uid: String){
        viewApplicationDocument(uid: $uid) {
            code
            data
            errorDescription
            status
        }
    }
`;
