import { Injectable } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class FormsService {
  constructor() {}
  // conditionallyRequiredValidator(masterControlLabel: string, operator: string, conditionalValue: any, slaveControlLabel: string) {
  //   return (group: FormGroup): {[key: string]: any} => {
  //     const masterControl = group.controls[masterControlLabel];
  //     const slaveControl = group.controls[slaveControlLabel];
  //     if (eval(`'${masterControl.value}' ${operator} '${conditionalValue}'`)) {
  //       return Validators.required(slaveControl)
  //     }
  //     slaveControl.setErrors(null);
  //     return null;
  //   }
  // }
  conditionallyRequiredValidator(
    formGroup: FormGroup,
    masterControlLabel: string,
    operator: string,
    conditionalValue: any,
    slaveControlLabel: string
  ) {
    formGroup.get(masterControlLabel).valueChanges.subscribe(val => {
      console.log(formGroup, masterControlLabel, operator, conditionalValue, slaveControlLabel);
          if(eval(`${val} ${operator} ${conditionalValue}`)) {
            formGroup.get(slaveControlLabel).setValidators(Validators.required);
          } else {
            formGroup.get(slaveControlLabel).clearValidators();
          }
    });
  }
}
