import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { WorkFlowProcess } from './work-flow-process.model';
import * as WorkFlowProcessActions from './work-flow-process.actions';

export const workFlowProcessesFeatureKey = 'workFlowProcesses';

export interface State extends EntityState<WorkFlowProcess> {
  // additional entities state properties
}

export const adapter: EntityAdapter<WorkFlowProcess> = createEntityAdapter<WorkFlowProcess>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(WorkFlowProcessActions.addWorkFlowProcess,
    (state, action) => adapter.addOne(action.workFlowProcess, state)
  ),
  on(WorkFlowProcessActions.upsertWorkFlowProcess,
    (state, action) => adapter.upsertOne(action.workFlowProcess, state)
  ),
  on(WorkFlowProcessActions.addWorkFlowProcesss,
    (state, action) => adapter.addMany(action.workFlowProcesss, state)
  ),
  on(WorkFlowProcessActions.upsertWorkFlowProcesss,
    (state, action) => adapter.upsertMany(action.workFlowProcesss, state)
  ),
  on(WorkFlowProcessActions.updateWorkFlowProcess,
    (state, action) => adapter.updateOne(action.workFlowProcess, state)
  ),
  on(WorkFlowProcessActions.updateWorkFlowProcesss,
    (state, action) => adapter.updateMany(action.workFlowProcesss, state)
  ),
  on(WorkFlowProcessActions.deleteWorkFlowProcess,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(WorkFlowProcessActions.deleteWorkFlowProcesss,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(WorkFlowProcessActions.loadWorkFlowProcesss,
    (state, action) => adapter.setAll(action.workFlowProcesss, state)
  ),
  on(WorkFlowProcessActions.clearWorkFlowProcesss,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
