import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { FirmBranch } from './firm-branch.model';
import * as FirmBranchActions from './firm-branch.actions';

export const firmBranchesFeatureKey = 'firmBranches';

export interface State extends EntityState<FirmBranch> {
  // additional entities state properties
}

export const adapter: EntityAdapter<FirmBranch> = createEntityAdapter<FirmBranch>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(FirmBranchActions.addFirmBranch,
    (state, action) => adapter.addOne(action.firmBranch, state)
  ),
  on(FirmBranchActions.upsertFirmBranch,
    (state, action) => adapter.upsertOne(action.firmBranch, state)
  ),
  on(FirmBranchActions.addFirmBranchs,
    (state, action) => adapter.addMany(action.firmBranchs, state)
  ),
  on(FirmBranchActions.upsertFirmBranchs,
    (state, action) => adapter.upsertMany(action.firmBranchs, state)
  ),
  on(FirmBranchActions.updateFirmBranch,
    (state, action) => adapter.updateOne(action.firmBranch, state)
  ),
  on(FirmBranchActions.updateFirmBranchs,
    (state, action) => adapter.updateMany(action.firmBranchs, state)
  ),
  on(FirmBranchActions.deleteFirmBranch,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(FirmBranchActions.deleteFirmBranchs,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(FirmBranchActions.loadFirmBranchs,
    (state, action) => adapter.setAll(action.firmBranchs, state)
  ),
  on(FirmBranchActions.clearFirmBranchs,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
