import {createSelector} from '@ngrx/store';
import {AppState} from '../../../reducers';
import * as fromAppNotificationReducer from './app-notification.reducer';

export const currentState = (state: AppState) => state[fromAppNotificationReducer.appNotificationsFeatureKey];


export const selectAllAppNotifications = createSelector(currentState, fromAppNotificationReducer.selectAll);


export const selectAppNotificationCounts =
    createSelector(selectAllAppNotifications, (notifications) => {
        return notifications.map(n => n.count).reduce((a, current) => a + current, 0) as number;
    });

