import gql from 'graphql-tag';

export const WorkExperienceGraphqlFields = `
    id
    active
    beginYear
    createdAt
    createdBy{
        id
        fullName
    }
    deletedAt
    endYear
    profile{
        id
        user{
            id
        }
    }
    place
    title
    uid
    updatedAt
`;

export const SAVE_WORK_EXPERIENCE = gql`
    mutation saveWorkExperience($workExperienceDto:WorkExperienceDtoInput){
        saveWorkExperience(workExperienceDto:$workExperienceDto){
            data{
                ${WorkExperienceGraphqlFields}
            }
            code
            errorDescription
            status
        }
    }
`;

export const EDIT_WORK_EXPERIENCE = gql`
    mutation updateWorkExperience($workExperienceDto:WorkExperienceDtoInput, $uid:String){
        updateWorkExperience(workExperienceDto:$workExperienceDto, uid:$uid){
            data{
                ${WorkExperienceGraphqlFields}
            }
            code
            errorDescription
            status
        }
    }
`;

export const GET_WORK_EXPERIENCE_BY_USER = gql`
    query getWorkExperienceByUser{
        getWorkExperienceByUser{
            ${WorkExperienceGraphqlFields}
        }
    }
`;

export const GET_APPLICANT_WORK_EXPERIENCES = gql`
    query getAllWorkExperience{
        getAllWorkExperience{
            ${WorkExperienceGraphqlFields}
        }
    }
`;






