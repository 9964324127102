import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {WorkExperience} from './work-experience.model';

export const loadWorkExperiences = createAction(
    '[WorkExperience/API] Load WorkExperiences',
    props<{ workExperiences: WorkExperience[] }>()
);

export const addWorkExperience = createAction(
    '[WorkExperience/API] Add WorkExperience',
    props<{ workExperience: WorkExperience }>()
);

export const upsertWorkExperience = createAction(
    '[WorkExperience/API] Upsert WorkExperience',
    props<{ workExperience: WorkExperience }>()
);

export const addWorkExperiences = createAction(
    '[WorkExperience/API] Add WorkExperiences',
    props<{ workExperiences: WorkExperience[] }>()
);

export const upsertWorkExperiences = createAction(
    '[WorkExperience/API] Upsert WorkExperiences',
    props<{ workExperiences: WorkExperience[] }>()
);

export const updateWorkExperience = createAction(
    '[WorkExperience/API] Update WorkExperience',
    props<{ workExperience: Update<WorkExperience> }>()
);

export const updateWorkExperiences = createAction(
    '[WorkExperience/API] Update WorkExperiences',
    props<{ workExperiences: Update<WorkExperience>[] }>()
);

export const deleteWorkExperience = createAction(
    '[WorkExperience/API] Delete WorkExperience',
    props<{ id: string }>()
);

export const deleteWorkExperiences = createAction(
    '[WorkExperience/API] Delete WorkExperiences',
    props<{ ids: string[] }>()
);

export const clearWorkExperiences = createAction(
    '[WorkExperience/API] Clear WorkExperiences'
);

export const saveWorkExperience = createAction(
    '[WorkExperience/API] Save Work Experience',
    props<{ workExperience: any }>()
);

export const editWorkExperience = createAction(
    '[WorkExperience/API] Edit Work Experience',
    props<{ workExperience: any, uid: string }>()
);

export const getWorkExperienceByUser = createAction(
    '[WorkExperience/API] Get Work Experience By User'
);

export const getAllWorkExperiences = createAction(
    '[WorkExperience/API] Get All Work Experience By User'
);


