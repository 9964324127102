export enum SelectTypeEnum {
    MARITAL_STATUS = 'MARITAL_STATUS',
    SPOUSE_MARITAL_STATUS ='MARITAL_STATUS',
    MARRIAGE_TYPE = 'MARRIAGE_TYPE',
    MARRIAGE_FORM = 'MARRIAGE_FORM'
}
export enum InputTypeEnum {
    TEXT = 'TEXT',
    DATE = 'DATE',
    SELECT = 'SELECT'
}