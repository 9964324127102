import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import {LegalProfessionalView, LegalProfessionalViewDto} from './legal-professional-view.model';

export const loadLegalProfessionalViews = createAction(
  '[LegalProfessionalView/API] Load LegalProfessionalViews',
  props<{ legalProfessionalViews: LegalProfessionalView[] }>()
);

export const addLegalProfessionalView = createAction(
  '[LegalProfessionalView/API] Add LegalProfessionalView',
  props<{ legalProfessionalView: LegalProfessionalView }>()
);

export const upsertLegalProfessionalView = createAction(
  '[LegalProfessionalView/API] Upsert LegalProfessionalView',
  props<{ legalProfessionalView: LegalProfessionalView }>()
);

export const addLegalProfessionalViews = createAction(
  '[LegalProfessionalView/API] Add LegalProfessionalViews',
  props<{ legalProfessionalViews: LegalProfessionalView[] }>()
);

export const upsertLegalProfessionalViews = createAction(
  '[LegalProfessionalView/API] Upsert LegalProfessionalViews',
  props<{ legalProfessionalViews: LegalProfessionalView[] }>()
);

export const updateLegalProfessionalView = createAction(
  '[LegalProfessionalView/API] Update LegalProfessionalView',
  props<{ legalProfessionalView: Update<LegalProfessionalView> }>()
);

export const updateLegalProfessionalViews = createAction(
  '[LegalProfessionalView/API] Update LegalProfessionalViews',
  props<{ legalProfessionalViews: Update<LegalProfessionalView>[] }>()
);

export const deleteLegalProfessionalView = createAction(
  '[LegalProfessionalView/API] Delete LegalProfessionalView',
  props<{ id: string }>()
);

export const deleteLegalProfessionalViews = createAction(
  '[LegalProfessionalView/API] Delete LegalProfessionalViews',
  props<{ ids: string[] }>()
);

export const clearLegalProfessionalViews = createAction(
  '[LegalProfessionalView/API] Clear LegalProfessionalViews'
);



/*
*  Effect actions
* */
export const saveLegalProfessionalViewComment = createAction(
    '[LegalProfessionalView/API] save legal professional view',
    props<{ legalPro: LegalProfessionalViewDto, applicationUid: string }>()
);
