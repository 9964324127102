import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, map, switchMap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {
    CANCEL_BILL,
    EXTEND_BILL,
    FETCH_BILL_BY_UID,
    GET_APPLICANT_BILLS,
    GET_APPLICATION_PAID_BILL,
    REGENERATE_BILL,
    REQUEST_CONTROL_NUMBER,
    REUSE_CONTROL_NUMBER
} from './bill.graphql';
import {
    cancelBill,
    extendBill,
    extendBillCompleted,
    fetchBillByUid,
    getApplicantBills,
    getApplicationPaidBill,
    loadBills,
    regenerateBill,
    requestControlNumber,
    reuseControlNumber,
    upsertBill
} from './bill.actions';
import {Bill} from './bill.model';
import {ResponseCode} from '../../../../shared/enums/http-status-codes.enum';


@Injectable()
export class BillEffects {

    requestControlNumber$ = createEffect(() => this.actions$.pipe(
            ofType(requestControlNumber),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: REQUEST_CONTROL_NUMBER,
                    variables: {
                        billUid: action.billUid
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data.requestControlNumber) {
                                return this.notificationService.successMessage('Control number requested successfully.');
                            } else {
                                return this.notificationService.errorMessageSwalFire('Unable to request bill, Please try again later.');
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    getApplicantBills$ = createEffect(() => this.actions$.pipe(
        ofType(getApplicantBills),
        switchMap((action) => {
            return this.apollo.query({
                query: GET_APPLICANT_BILLS,
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        this.store.dispatch(loadBills({
                            bills: Object.values(data)[0] as Bill[]
                        }));
                    }
                })
            );
        })
    ), {dispatch: false});

    fetchBillByUid$ = createEffect(() => this.actions$.pipe(
            ofType(fetchBillByUid),
            switchMap((action) => {
                return this.apollo.query({
                    query: FETCH_BILL_BY_UID,
                    fetchPolicy: 'network-only',
                    variables: {
                        billUid: action.billUid
                    }
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.viewBill?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(upsertBill({bill: data.viewBill.data as Bill}));
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.viewBill);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    cancelBill$ = createEffect(() => this.actions$.pipe(
            ofType(cancelBill),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: CANCEL_BILL,
                    variables: {
                        billId: action.billId
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data.cancelBill) {
                                return this.notificationService.successMessage('Bill cancellation requested successfully.');
                            } else {
                                return this.notificationService.errorMessageSwalFire('Unable to request bill cancellation, Please try again later.');
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    extendBill$ = createEffect(() => this.actions$.pipe(
            ofType(extendBill),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: EXTEND_BILL,
                    variables: {
                        billDto: action.billDto
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data.extendBill) {
                                this.store.dispatch(extendBillCompleted());
                                return this.notificationService.successMessage('Bill extension requested successfully.');
                            } else {
                                return this.notificationService.errorMessageSwalFire('Unable to request bill extension, Please try again later.');
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    regenerateBill$ = createEffect(() => this.actions$.pipe(
            ofType(regenerateBill),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: REGENERATE_BILL,
                    variables: {
                        billId: action.billId
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data.regenerateBill) {
                                return this.notificationService.successMessage('Bill regeneration requested successfully.');
                            } else {
                                return this.notificationService.errorMessageSwalFire('Unable to send bill regeneration request, Please try again later.');
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    reuseControlNumber$ = createEffect(() => this.actions$.pipe(
            ofType(reuseControlNumber),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: REUSE_CONTROL_NUMBER,
                    variables: {
                        billUid: action.billUid
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data.controlNumberReUse) {
                                return this.notificationService.successMessage('Control number reuse requested successfully.');
                            } else {
                                return this.notificationService.errorMessageSwalFire('Unable to send bill control number re-use request, Please try again later.');
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    getApplicationPaidBill$ = createEffect(() => this.actions$.pipe(
            ofType(getApplicationPaidBill),
            concatMap((action) => {
                return this.apollo.query({
                    query: GET_APPLICATION_PAID_BILL,
                    variables: {
                        applicationUid: action.applicationUid
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data.getApplicationPaidBill?.code === 'SUCCESS') {
                            const bill = data.getApplicationPaidBill.data as Bill;
                            this.store.dispatch(upsertBill({bill}));
                        } else {
                            // return this.responseCodesService.errorMessageByMessage(data.getApplicationPaidBill);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService
    ) {
    }

}
