// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    //SERVER_URL: 'http://localhost:8083',

    // SERVER_URL: 'http://10.1.65.93:8990',
    // SERVER_URL: 'http://127.0.0.1:8443',
    // SERVER_URL: 'http://127.0.0.1:8087',
    // SERVER_URL: 'http://192.168.100.209:8443',
    // SERVER_URL: 'http://192.168.8.101:8087',
    // SERVER_URL: 'http://192.168.100.139:8087',
    // SERVER_URL: 'http://192.168.8.103:8087',
    //SERVER_URL: 'http://172.16.33.249:8087',
    // SERVER_URL: 'http://154.118.230.227:8087',
    // SERVER_URL: 'http://192.168.96.232:8087',
    // SERVER_URL: 'http://192.168.100.24:8087',
    //SERVER_URL: 'http://154.118.230.227:8087',
    SERVER_URL: 'https://ewakili.judiciary.go.tz:8087',
    CLIENT_ID: 'tams',
    CLIENT_SECRET: 'tams.123',
    DEFAULT_COUNTRY: 219,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
