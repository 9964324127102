import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {ProfileAddress} from './profile-address.model';

export const loadProfileAddresss = createAction(
    '[ProfileAddress/API] Load ProfileAddresss',
    props<{ profileAddresss: ProfileAddress[] }>()
);

export const addProfileAddress = createAction(
    '[ProfileAddress/API] Add ProfileAddress',
    props<{ profileAddress: ProfileAddress }>()
);

export const upsertProfileAddress = createAction(
    '[ProfileAddress/API] Upsert ProfileAddress',
    props<{ profileAddress: ProfileAddress }>()
);

export const addProfileAddresss = createAction(
    '[ProfileAddress/API] Add ProfileAddresss',
    props<{ profileAddresss: ProfileAddress[] }>()
);

export const upsertProfileAddresss = createAction(
    '[ProfileAddress/API] Upsert ProfileAddresss',
    props<{ profileAddresss: ProfileAddress[] }>()
);

export const updateProfileAddress = createAction(
    '[ProfileAddress/API] Update ProfileAddress',
    props<{ profileAddress: Update<ProfileAddress> }>()
);

export const updateProfileAddresss = createAction(
    '[ProfileAddress/API] Update ProfileAddresss',
    props<{ profileAddresss: Update<ProfileAddress>[] }>()
);

export const deleteProfileAddress = createAction(
    '[ProfileAddress/API] Delete ProfileAddress',
    props<{ id: number }>()
);

export const deleteProfileAddresss = createAction(
    '[ProfileAddress/API] Delete ProfileAddresss',
    props<{ ids: string[] }>()
);

export const clearProfileAddresss = createAction(
    '[ProfileAddress/API] Clear ProfileAddresss'
);

export const saveProfileAddress = createAction(
    '[ProfileAddress/API] Save Profile Address',
    props<{ profileAddress: any }>()
);

export const editProfileAddress = createAction(
    '[ProfileAddress/API] Edit Profile Address',
    props<{ profileAddress: any, uid: string }>()
);

export const removeProfileAddress = createAction(
    '[ProfileAddress/API] Remove Profile Address',
    props<{ uid: string }>()
);

export const getProfileAddressesByLoggedUser = createAction(
    '[ProfileAddress/API] Get Profile Address By Logged User',
);

export const listProfileAddressesByLoggedUser = createAction(
    '[ProfileAddress/API] List Profile Address By Logged User',
);


