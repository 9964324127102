import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {ResponseCode} from '../../../../shared/enums/http-status-codes.enum';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {
    deletePetitionSession,
    editPetitionSession,
    fetchAllPetitionSessions, getPetitionSessionByUid, removePetitionSession,
    savePetitionSession,
    upsertPetitionSession,
    upsertPetitionSessions
} from './petition-session.actions';
import {
    GET_PETITION_SESSION_UID,
    LIST_ALL_PETITION_SESSIONS,
    REMOVE_PETITION_SESSION,
    SAVE_PETITION_SESSION,
    UPDATE_PETITION_SESSION
} from './petition-session.graphql';
import {PetitionSession} from './petition-session.model';


@Injectable()
export class PetitionSessionEffects {

    allPetitionSessions$ = createEffect(() => this.actions$.pipe(
            ofType(fetchAllPetitionSessions),
            switchMap((action) => {
                return this.apollo
                    .query({
                        query: LIST_ALL_PETITION_SESSIONS,
                        fetchPolicy: 'network-only',
                    })
                    .pipe(
                        this.notificationService.catchError(),
                        map(({data}: any) => {
                            if (data) {
                                this.store.dispatch(upsertPetitionSessions({petitionSessions:
                                        Object.values(data)[0] as PetitionSession[]}));
                            }
                        })
                    );
            })
        ),
        {dispatch: false}
    );

    getPetitionSession$ = createEffect(() => this.actions$.pipe(
            ofType(getPetitionSessionByUid),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: GET_PETITION_SESSION_UID,
                    variables: {
                        sessionUid: action.sessionUid
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.getPetitionSessionById?.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(upsertPetitionSession({petitionSession: data?.getPetitionSessionById?.data}));
                                // return this.notificationService.successMessage('Saved successfully');
                            } else {
                                return this.responseCodesService.errorMessageByMessage(data.getPetitionSessionById);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    newPetitionSession$ = createEffect(() => this.actions$.pipe(
            ofType(savePetitionSession),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: SAVE_PETITION_SESSION,
                    variables: {
                        session: action.petitionSession
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.savePetitionSession?.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(upsertPetitionSession({petitionSession: data?.savePetitionSession?.data}));
                                return this.notificationService.successMessage('Saved successfully');
                            } else {
                                return this.responseCodesService.errorMessageByMessage(data.savePetitionSession);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    editPetitionSession$ = createEffect(() => this.actions$.pipe(
            ofType(editPetitionSession),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: UPDATE_PETITION_SESSION,
                    variables: {
                        session: action.petitionSession,
                        uid: action.uid
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.updatePetitionSession?.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(upsertPetitionSession({petitionSession: data?.updatePetitionSession?.data}));
                                return this.notificationService.successMessage('Updated successfully');
                            } else {
                                return this.responseCodesService.errorMessageByMessage(data.updatePetitionSession);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    removePetitionSession$ = createEffect(() => this.actions$.pipe(
            ofType(removePetitionSession),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: REMOVE_PETITION_SESSION,
                    variables: {
                        uid: action.uid
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.deletePetitionSession?.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(deletePetitionSession({id: data?.deletePetitionSession?.data?.id}));
                                return this.notificationService.successMessage('Deleted successfully');
                            } else {
                                return this.responseCodesService.errorMessageByMessage(data.deletePetitionSession);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService
    ) {
    }
}
