import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { NotificationService } from 'src/app/services/notifications/notification.service';
import { ResponseCodesService } from 'src/app/services/response-codes/response-codes.service';
import { AppState } from 'src/app/store/reducers';
import { deleteSignature, editExistingSignature, fetchAllSignature, removerSignature, saveNewSignature, upsertSignature, upsertSignatures } from './signature.actions';
import { map, switchMap } from 'rxjs/operators';
import { Signature } from './signature.model';
import { ResponseCode } from 'src/app/shared/enums/http-status-codes.enum';
import {
  DELETE_SIGNATURE,
  GET_ALL_SIGNATURE, SAVE_SIGNATURE, UPDATE_SIGNATURE
} from './signature.graphql';


@Injectable()
export class SignatureEffects {

  fetchAllSignature$ = createEffect(() => this.actions$.pipe(
    ofType(fetchAllSignature),
    switchMap((action) => {
        return this.apollo
            .query({
                query: GET_ALL_SIGNATURE,
                fetchPolicy: 'network-only',
            })
            .pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        this.store.dispatch(upsertSignatures({signatures: Object.values(data)[0] as Signature[]}));
                    }
                })
            );
    })
),
{dispatch: false}
);


saveNewSignature$ = createEffect(() => this.actions$.pipe(
    ofType(saveNewSignature),
    switchMap((action) => {
        return this.apollo.mutate({
            mutation: SAVE_SIGNATURE,
            variables: {
              signatureDto: action.signatureDto
            },
        }).pipe(
            this.notificationService.catchError(),
            map(({data}: any) => {
                if (data) {
                    if (data?.saveSignature?.code === ResponseCode.SUCCESS) {
                        this.store.dispatch(upsertSignature({signature: data?.saveSignature?.data}));
                        return this.notificationService.successMessage('Saved successfully');
                    } else {
                        return this.responseCodesService.errorMessageByCode(data.saveSignature.code);
                    }
                }
            })
        );
    })
),
{dispatch: false}
);

editSignature$ = createEffect(() => this.actions$.pipe(
    ofType(editExistingSignature),
    switchMap((action) => {
        return this.apollo.mutate({
            mutation: UPDATE_SIGNATURE,
            variables: {
                signatureDto: action.signatureDto,
                uid: action.uid
            },
        }).pipe(
            this.notificationService.catchError(),
            map(({data}: any) => {
                if (data?.updateSignature?.code === ResponseCode.SUCCESS) {
                    this.store.dispatch(upsertSignature({signature: data.updateSignature.data}));
                    return this.notificationService.successMessage('Edited Successfully');
                } else {
                    return this.responseCodesService.errorMessageByCode(data.updateSignature.code);
                }
            })
        );
    })
),
{dispatch: false}
);

deleteSignature$ = createEffect(() => this.actions$.pipe(
    ofType(removerSignature),
    switchMap((action) => {
        return this.apollo.mutate({
            mutation: DELETE_SIGNATURE,
            variables: {
                uid: action.uid
            },
        }).pipe(
            this.notificationService.catchError(),
            map(({data}: any) => {
                if (data?.deleteSignature?.code === ResponseCode.SUCCESS) {
                    this.store.dispatch(deleteSignature({id: data.deleteSignature.data.id}));
                    return this.notificationService.successMessage('Deleted Successfully');
                } else {
                    return this.responseCodesService.errorMessageByCode(data.deleteSignature.code);
                }
            })
        );
    })
),
{dispatch: false}
);



  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private notificationService: NotificationService,
    private store: Store<AppState>,
    private responseCodesService: ResponseCodesService
  ) {}

}
