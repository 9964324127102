import {Pipe, PipeTransform} from '@angular/core';
import {ApplicationTypeEnum} from '../../store/entities/management/application/application.model';

@Pipe({
    name: 'applicationType'
})
export class ApplicationTypePipe implements PipeTransform {

    applicationTypeEnumsKeys = Object.entries(ApplicationTypeEnum).map(([key, value]) => {
        return key;
    });

    applicationTypeEnumsValues = Object.entries(ApplicationTypeEnum).map(([key, value]) => {
        return value;
    });

    transform(value: ApplicationTypeEnum, ...args: unknown[]): unknown {
        const index = this.applicationTypeEnumsValues.indexOf(value);

        if (index > -1) {
            return this.applicationTypeEnumsKeys[index];
        }
        return value;
    }

}
