import {User} from '../../settings/user/user.model';
import {AdvocateProfile} from '../../advocate/advocate-profile/advocate-profile.model';
import {ActionUserType} from '../../settings/action-user-type/action-user-type.model';
import {WorkFlowProcess} from '../../settings/work-flow-process/work-flow-process.model';
import {ApplicationDocument, Attachment} from '../../advocate/application-document/application-document.model';

export interface Application {
    id?: number;
    uid?: string;
    active?: boolean;
    resubmission?: boolean;
    unReviewed?: number;
    createdAt?: any;
    lastUpdatedAt?: any;
    submissionAt?: any;
    updatedAt?: any;
    applicationType?: ApplicationTypeEnum;
    approveStatus?: ApproveStatusEnum;
    createdBy?: User;
    currentStage?: ActionUserType;
    profile?: AdvocateProfile;
    workflowProcess?: WorkFlowProcess;
    approvalList?: Approval[];
    applicationDocuments?: ApplicationDocument[];
    lstExemptionStatus?: boolean;
    wavePenalty?: boolean;
}

export interface ResubmitApplicationDto {
    attachmentDto: Attachment[];
    newName?: string;
}

export interface Approval {
    actionUserType?: ActionUserType;
    active?: boolean;
    application?: Application;
    comment?: string;
    createdAt?: any;
    createdBy?: User;
    decision?: ApproveStatusEnum;
    deletedAt?: any;
    id?: number;
    resubmission?: boolean;
    uid?: string;
    updatedAt?: any;
    user?: User;
    wavePenalty?: boolean;
}

export enum ApplicationTypeEnum {
    LEGACY_FILES = 'LEGACY_FILES',
    'Application For Change Of Name' = 'PERMIT_NAMECHANGE',
    'Application For Practising' = 'PERMIT_PRACTISING',
    'Application For Non Practising' = 'PERMIT_NON_PRACTISING',
    'Application For Non Profit' = 'PERMIT_NON_PROFIT',
    'Application For Renewal Out Time' = 'PERMIT_RENEWAL',
    'Application For Retirement' = 'PERMIT_RETIRED',
    'Application For Suspension' = 'PERMIT_SUSPENDED',
    'Application For Petition' = 'PETITION',
    'Application For Junior Renewal' = 'RENEWAL_JR',
    'Application For Senior Renewal' = 'RENEWAL_SR'
}

export enum ApproveStatusEnum {
    APPROVE = 'APPROVE',
    NOT_SUBMITTED = 'NOT_SUBMITTED',
    PENDING = 'PENDING',
    RESUBMIT = 'RESUBMIT',
    ABANDONED = 'ABANDONED',
    ACCEPT = 'ACCEPT',
    ADMIT = 'ADMIT',
    BAR_EXAM = 'BAR_EXAM',
    CJ_INTERVIEW = 'CJ_INTERVIEW',
    DEFERRED = 'DEFERRED',
    ENRROL = 'ENRROL',
    OBJECTED = 'OBJECTED',
    POSTPONE = 'POSTPONE',
    REJECTED = 'REJECTED',
    EXEMPTION = 'EXEMPTION',
	GRANT_EXEMPTION = 'GRANT_EXEMPTION',
	REFUSE_EXEMPTION = 'REFUSE_EXEMPTION'
}
