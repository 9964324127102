import gql from 'graphql-tag';
import {UserGqlFields} from '../../settings/user/user.graphql';
import {petitionSessionsGqlFields} from '../../settings/petition-session/petition-session.graphql';

export const cleCoramGqlFields = `
    active
    chairman{
        ${UserGqlFields}
    }
    createdAt
    id
    uid
    workday
    secretary{
         ${UserGqlFields}
    }
    updatedAt
    createdBy{
         ${UserGqlFields}
    }
    petitionSession{
        ${petitionSessionsGqlFields}
    }
`;

export const LIST_ALL_CLE_CORAMS = gql`
    query getCleCoramBySession($sessionUid: String){
        getCleCoramBySession(sessionUid: $sessionUid){
            ${cleCoramGqlFields}
        }
    }
`;
