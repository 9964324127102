import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {
    applyVenueAppearance, changePetitionAppearance,
    changePetitionerSession,
    fetchAllPendingPetitionsBySessionUid,
    fetchAllPetitionsByBarUid,
    getLegalProfessionalViewByCurrentUser,
    loadPetitions,
    preAssignRollNumber,
    reArrangeRollNumber,
    refreshPendingAdmissionTable,
    upsertPetition,
    upsertPetitions
} from './petition.actions';
import {map, switchMap} from 'rxjs/operators';
import {
    APPLY_FOR_APPEARANCE_VENUE, CHANGE_PETITIONER_SESSION, CHANGE_PETITION_APPEARANCE,
    GET_LEGAL_VIEW_PETITIONERS_BY_CURRENT_USER,
    LIST_OF_BAR_EXAM_PETITIONERS,
    LIST_OF_PENDING_BAR_EXAM_PETITIONERS,
    PRE_ASSIGN_ROLL_NUMBER,
    RE_ARRANGE_ROLL_NUMBER
} from './petition.graphql';
import {Petition} from './petition.model';
import {ResponseCode} from '../../../../shared/enums/http-status-codes.enum';
import {getFullAdvocateProfileDetails} from '../full-profile-detail/full-profile-detail.actions';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {upsertAppearance} from '../appearance/appearance.actions';


@Injectable()
export class PetitionEffects {

    allPendingPetitionersForBar$ = createEffect(() => this.actions$.pipe(
        ofType(fetchAllPendingPetitionsBySessionUid),
        switchMap((action) => {
            return this.apollo.query({
                query: LIST_OF_PENDING_BAR_EXAM_PETITIONERS,
                fetchPolicy: 'network-only',
                variables: {
                    sessionUid: action.sessionUid
                }
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        this.store.dispatch(upsertPetitions({
                            petitions:
                                Object.values(data)[0] as Petition[]
                        }));
                    }
                })
            );
        })
    ), {dispatch: false});


    allPetitionersForBar$ = createEffect(() => this.actions$.pipe(
        ofType(fetchAllPetitionsByBarUid),
        switchMap((action) => {
            return this.apollo.query({
                query: LIST_OF_BAR_EXAM_PETITIONERS,
                fetchPolicy: 'network-only',
                variables: {
                    barExamUId: action.barUid
                }
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        this.store.dispatch(upsertPetitions({
                            petitions:
                                Object.values(data)[0] as Petition[]
                        }));
                    }
                })
            );
        })
    ), {dispatch: false});

    applyForVenueAppearance$ = createEffect(() => this.actions$.pipe(
            ofType(applyVenueAppearance),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: APPLY_FOR_APPEARANCE_VENUE,
                    variables: {
                        appearanceVenueUid: action.appearanceVenueUid,
                        petitionUid: action.petitionUid
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.applyForVenue?.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(upsertPetition({petition: data?.applyForVenue?.data}));
                                this.store.dispatch(getFullAdvocateProfileDetails({}));
                                return this.notificationService.successMessageSwalFire('Venue saved successfully');
                            } else {
                                return this.responseCodesService.errorMessageByMessage(data.applyForVenue);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    preAssignRollNumber$ = createEffect(() => this.actions$.pipe(
            ofType(preAssignRollNumber),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: PRE_ASSIGN_ROLL_NUMBER,
                    variables: {
                        sessionUid: action.sessionUid,
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.preAssignRollNumber?.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(refreshPendingAdmissionTable());
                                return this.notificationService.successMessageSwalFire(`${data?.preAssignRollNumber?.data} roll numbers pre assigned successfully.`);
                            } else {
                                return this.responseCodesService.errorMessageByMessage(data.preAssignRollNumber);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    reArrangeRollNumber$ = createEffect(() => this.actions$.pipe(
            ofType(reArrangeRollNumber),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: RE_ARRANGE_ROLL_NUMBER,
                    variables: {
                        petitionUid: action.petitionUid,
                        rollNumber: action.rollNumber
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.reArrangeRollNumber?.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(refreshPendingAdmissionTable());
                                return this.notificationService.successMessageSwalFire('Roll number re arranged successfully');
                            } else {
                                return this.responseCodesService.errorMessageByMessage(data.reArrangeRollNumber);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    allDoneViewPetitionersForLegalProfessional$ = createEffect(() => this.actions$.pipe(
        ofType(getLegalProfessionalViewByCurrentUser),
        switchMap((action) => {
            return this.apollo.query({
                query: GET_LEGAL_VIEW_PETITIONERS_BY_CURRENT_USER,
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        this.store.dispatch(loadPetitions({
                            petitions: (Object.values(data)[0] || []) as Petition[]
                        }));
                    }
                })
            );
        })
    ), {dispatch: false});

    chanePetitionAppearance$ = createEffect(() => this.actions$.pipe(
            ofType(changePetitionAppearance),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: CHANGE_PETITION_APPEARANCE,
                    variables: {
                        appearanceDto: action.changeAppearance
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.changeAppearance?.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(upsertAppearance({appearance: data?.changeAppearance?.data}));
                                // this.store.dispatch(getFullAdvocateProfileDetails({}));
                                return this.notificationService.successMessage('Appearance changed successfully');
                            } else {
                                return this.responseCodesService.errorMessageByMessage(data.changeAppearance);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    changePetitionerSession$ = createEffect(() => this.actions$.pipe(
        ofType(changePetitionerSession),
        switchMap((action) => {
            return this.apollo.mutate({
                mutation: CHANGE_PETITIONER_SESSION,
                variables: {
                    petitionUid: action.petitionUid,
                    petitionSessionUid : action.petitionSessionUid
                },
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        if (data?.changePetitionerSession?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(upsertPetition({petition: data?.changePetitionerSession?.data}));
                            //this.store.dispatch(getFullAdvocateProfileDetails({}));
                            return this.notificationService.successMessage('Session changed successfully');
                             //return this.notificationService.successMessageSwalFire('Session changed successfully');
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.changePetitionerSession);
                        }
                    }
                })
            );
        })
    ),
    {dispatch: false}
);


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService
    ) {
    }

}
