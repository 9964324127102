import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ProfileAddress } from './profile-address.model';
import * as ProfileAddressActions from './profile-address.actions';

export const profileAddressesFeatureKey = 'profileAddresses';

export interface State extends EntityState<ProfileAddress> {
  // additional entities state properties
}

export const adapter: EntityAdapter<ProfileAddress> = createEntityAdapter<ProfileAddress>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(ProfileAddressActions.addProfileAddress,
    (state, action) => adapter.addOne(action.profileAddress, state)
  ),
  on(ProfileAddressActions.upsertProfileAddress,
    (state, action) => adapter.upsertOne(action.profileAddress, state)
  ),
  on(ProfileAddressActions.addProfileAddresss,
    (state, action) => adapter.addMany(action.profileAddresss, state)
  ),
  on(ProfileAddressActions.upsertProfileAddresss,
    (state, action) => adapter.upsertMany(action.profileAddresss, state)
  ),
  on(ProfileAddressActions.updateProfileAddress,
    (state, action) => adapter.updateOne(action.profileAddress, state)
  ),
  on(ProfileAddressActions.updateProfileAddresss,
    (state, action) => adapter.updateMany(action.profileAddresss, state)
  ),
  on(ProfileAddressActions.deleteProfileAddress,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ProfileAddressActions.deleteProfileAddresss,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ProfileAddressActions.loadProfileAddresss,
    (state, action) => adapter.setAll(action.profileAddresss, state)
  ),
  on(ProfileAddressActions.clearProfileAddresss,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
