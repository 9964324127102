<div class="page-header">
    <div class="row align-items-end">
        <div class="col-8">
            <div class="page-header-title">
                <i class="{{icon}} bg-danger"></i>
                <div class="d-inline">
                    <h5 class="page-title">{{title}}</h5>
                    <span>{{pageInfo ? pageInfo : 'Page info'}}</span>
                </div>
            </div>
        </div>
        <div class="col-4" style="justify-content: right; align-items: center">
            <div class="nav-buttons">
                <ng-container *ngIf="useBackButton">
                    <div class="nav-control-button">
                        <span class="mr-2" matTooltip="Home" matTooltipPosition="left">
                            <mat-icon>home</mat-icon>
                        </span>
                        <span (click)="goBack()" matTooltip="Go Back" matTooltipPosition="left">
                            <mat-icon>chevron_left</mat-icon>
                        </span>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="button-container" *ngIf="useButtonPanel">
                <ng-container *ngTemplateOutlet="topButton"></ng-container>
            </div>
        </div>
    </div>
</div>
