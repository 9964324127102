import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import {Qualification} from './qualification.model';
import {AssignAttachment} from "../attachment-type/attachment-type.model";

export const loadQualifications = createAction(
  '[Qualification/API] Load Qualifications',
  props<{ qualifications: Qualification[] }>()
);

export const addQualification = createAction(
  '[Qualification/API] Add Qualification',
  props<{ qualification: Qualification }>()
);

export const upsertQualification = createAction(
  '[Qualification/API] Upsert Qualification',
  props<{ qualification: Qualification }>()
);

export const addQualifications = createAction(
  '[Qualification/API] Add Qualifications',
  props<{ qualifications: Qualification[] }>()
);

export const upsertQualifications = createAction(
  '[Qualification/API] Upsert Qualifications',
  props<{ qualifications: Qualification[] }>()
);

export const updateQualification = createAction(
  '[Qualification/API] Update Qualification',
  props<{ qualification: Update<Qualification> }>()
);

export const updateQualifications = createAction(
  '[Qualification/API] Update Qualifications',
  props<{ qualifications: Update<Qualification>[] }>()
);

export const deleteQualification = createAction(
  '[Qualification/API] Delete Qualification',
  props<{ id: string }>()
);

export const deleteQualifications = createAction(
  '[Qualification/API] Delete Qualifications',
  props<{ ids: string[] }>()
);

export const clearQualifications = createAction(
  '[Qualification/API] Clear Qualifications'
);


/*
*  Effect actions
* */

export const fetchAllQualifications = createAction(
    '[Qualification/API] fetch all qualifications from the server'
);

export const saveNewQualification = createAction(
    '[Qualification/API] save new qualification to the server',
    props<{ qualification: Qualification }>()
);

export const assignAttachmentTypeToQualification = createAction(
    '[Qualification/API] assign attachment types to qualification',
    props<{ attachment: AssignAttachment, qualificationUid?: string }>()
);

export const getOneQualificationByUid = createAction(
    '[Qualification/API] get one qualification by uid',
    props<{ uid: string }>()
);

export const editExistingQualification = createAction(
    '[Qualification/API] update exiting qualification to the server',
    props<{ qualification: Qualification, uid: string }>()
);

export const removerQualification = createAction(
    '[Qualification/API] remove existing qualification from server',
    props<{ uid: string }>()
);
