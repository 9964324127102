import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { AdvocateProfile } from './advocate-profile.model';
import * as AdvocateProfileActions from './advocate-profile.actions';

export const advocateProfilesFeatureKey = 'advocateProfiles';

export interface State extends EntityState<AdvocateProfile> {
  // additional entities state properties
}

export const adapter: EntityAdapter<AdvocateProfile> = createEntityAdapter<AdvocateProfile>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(AdvocateProfileActions.addAdvocateProfile,
    (state, action) => adapter.addOne(action.advocateProfile, state)
  ),
  on(AdvocateProfileActions.upsertAdvocateProfile,
    (state, action) => adapter.upsertOne(action.advocateProfile, state)
  ),
  on(AdvocateProfileActions.addAdvocateProfiles,
    (state, action) => adapter.addMany(action.advocateProfiles, state)
  ),
  on(AdvocateProfileActions.upsertAdvocateProfiles,
    (state, action) => adapter.upsertMany(action.advocateProfiles, state)
  ),
  on(AdvocateProfileActions.updateAdvocateProfile,
    (state, action) => adapter.updateOne(action.advocateProfile, state)
  ),
  on(AdvocateProfileActions.updateAdvocateProfiles,
    (state, action) => adapter.updateMany(action.advocateProfiles, state)
  ),
  on(AdvocateProfileActions.deleteAdvocateProfile,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(AdvocateProfileActions.deleteAdvocateProfiles,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(AdvocateProfileActions.loadAdvocateProfiles,
    (state, action) => adapter.setAll(action.advocateProfiles, state)
  ),
  on(AdvocateProfileActions.clearAdvocateProfiles,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
