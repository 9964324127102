import gql from 'graphql-tag';
import {UserGqlFields} from '../user/user.graphql';

export const appearanceVenueGqlFields = `
    id
    name
    active
    uid
    description
    createdBy{
        ${UserGqlFields}
    }
    createdAt
    updatedAt
`;

export const LIST_APPEARANCE_VENUES = gql `
    query getAllAppearanceVenues{
        getAllAppearanceVenue{
            ${appearanceVenueGqlFields}
        }
    }
`;

export const CREATE_APPEARANCE_VENUE = gql `
    mutation saveAppearanceVenue($venue: AppearanceVenueDtoInput){
        saveAppearanceVenue(appearanceVenueDto: $venue){
            code
            data{
                ${appearanceVenueGqlFields}
            }
            status
            errorDescription
        }
    }
`;

export const UPDATE_APPEARANCE_VENUE = gql `
    mutation updateAppVenue($venueUid: String, $venue: AppearanceVenueDtoInput){
        updateAppearanceVenue(uid: $venueUid, appearanceVenueDto: $venue){
            code
            data{
                ${appearanceVenueGqlFields}
            }
            status
            errorDescription
        }
    }
`;

export const REMOVE_APPEARANCE_VENUE = gql `
    mutation delAppVenue($venueUid: String){
        deleteAppearanceVenue(uid: $venueUid){
            code
            data{
                ${appearanceVenueGqlFields}
            }
            status
            errorDescription
        }
    }
`;
