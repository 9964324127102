import {Component, Input, OnInit} from '@angular/core';
import {Payment} from '../../../store/entities/bills/payment/payment.model';

@Component({
    selector: 'app-payment-details',
    templateUrl: './payment-details.component.html',
    styleUrls: ['./payment-details.component.scss']
})
export class PaymentDetailsComponent implements OnInit {

    @Input() payment: Payment;
    currentYear = new Date().getFullYear();

    constructor() {
    }

    ngOnInit(): void {
    }

}
