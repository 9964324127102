import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {AuthService} from '../../../../services/auth/auth.service';
import {HttpClient} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NotificationService} from "../../../../services/notifications/notification.service";

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

    currentYear: any;
    forgotPasswordForm: FormGroup;
    loading = false;
    loginView = false;
    subscription: Subscription = new Subscription();
    resetPasswordForm = this.fb.group({
        email: [null, [Validators.required, Validators.email]],
    });
    redirectUrl: string;

    constructor(
        private fb: FormBuilder,
        private route: Router,
        private auth: AuthService,
        private http: HttpClient,
        private snackbar: MatSnackBar,
        private notificationService: NotificationService
    ) {
        if (this.auth.alreadyLoggedIn()) {
            this.route.navigate(['', 'management']);
        }
    }

    ngOnInit(): void {
        this.currentYear = new Date().getFullYear();
        this.forgotPasswordForm = this.fb.group({
            email: [null, [Validators.required, Validators.email]]
        });
    }

    save(value: any) {
        this.loading = true
        this.subscription.add(
            this.auth.forgotPassword(value.email).subscribe(
                res => {
                    this.notificationService.successMessage(res.message);
                    this.loading = false;
                    return this.route.navigate(['', 'login']);
                },
                error => {
                    this.loading = false;
                    return this.notificationService.errorMessageSwalFire(error.error.message);
                }
            )
        );

    }

    getSliders() {
        const imagesArray = [];
        // this.http.get('http://admin.eganet.go.tz/api/sliders/default').subscribe((images) => {
        //     imagesArray = images._data;
        // });
        // return imagesArray;
    }

    changeView() {
        this.loginView = !this.loginView;
    }

    resetPassword(formData: any) {
        this.auth.openSnackbar('This Operation is Under Maintenance, Please contact helpdesk@ega.go.tz for Help');

        // this.auth.tempToken();

        // if (localStorage.getItem('tempClient')) {
        //   this.subscription.add(
        //     this.apollo.mutate({
        //       mutation: RESET_PASSWORD,
        //       variables: {
        //         email: formData.email
        //       }
        //     }).subscribe(({data}: any) => {
        //       if (data.resetPassword.code === 9000) {
        //         this.store.dispatch(logoutAction());
        //         this.loginView = false;
        //         this.auth.openSnackbar('Password Sent To Your E-mail');
        //       }
        //     })
        //   );
        // }
    }

}
