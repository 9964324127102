import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BarExam } from './bar-exam.model';
import * as BarExamActions from './bar-exam.actions';

export const barExamsFeatureKey = 'barExams';

export interface State extends EntityState<BarExam> {
  // additional entities state properties
}

export const adapter: EntityAdapter<BarExam> = createEntityAdapter<BarExam>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(BarExamActions.addBarExam,
    (state, action) => adapter.addOne(action.barExam, state)
  ),
  on(BarExamActions.upsertBarExam,
    (state, action) => adapter.upsertOne(action.barExam, state)
  ),
  on(BarExamActions.addBarExams,
    (state, action) => adapter.addMany(action.barExams, state)
  ),
  on(BarExamActions.upsertBarExams,
    (state, action) => adapter.upsertMany(action.barExams, state)
  ),
  on(BarExamActions.updateBarExam,
    (state, action) => adapter.updateOne(action.barExam, state)
  ),
  on(BarExamActions.updateBarExams,
    (state, action) => adapter.updateMany(action.barExams, state)
  ),
  on(BarExamActions.deleteBarExam,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(BarExamActions.deleteBarExams,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(BarExamActions.loadBarExams,
    (state, action) => adapter.setAll(action.barExams, state)
  ),
  on(BarExamActions.clearBarExams,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
