import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { FullProfileDetail } from './full-profile-detail.model';
import * as FullProfileDetailActions from './full-profile-detail.actions';

export const fullProfileDetailsFeatureKey = 'fullProfileDetails';

export interface State extends EntityState<FullProfileDetail> {
  // additional entities state properties
}

export const adapter: EntityAdapter<FullProfileDetail> = createEntityAdapter<FullProfileDetail>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(FullProfileDetailActions.addFullProfileDetail,
    (state, action) => adapter.addOne(action.fullProfileDetail, state)
  ),
  on(FullProfileDetailActions.upsertFullProfileDetail,
    (state, action) => adapter.upsertOne(action.fullProfileDetail, state)
  ),
  on(FullProfileDetailActions.addFullProfileDetails,
    (state, action) => adapter.addMany(action.fullProfileDetails, state)
  ),
  on(FullProfileDetailActions.upsertFullProfileDetails,
    (state, action) => adapter.upsertMany(action.fullProfileDetails, state)
  ),
  on(FullProfileDetailActions.updateFullProfileDetail,
    (state, action) => adapter.updateOne(action.fullProfileDetail, state)
  ),
  on(FullProfileDetailActions.updateFullProfileDetails,
    (state, action) => adapter.updateMany(action.fullProfileDetails, state)
  ),
  on(FullProfileDetailActions.deleteFullProfileDetail,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(FullProfileDetailActions.deleteFullProfileDetails,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(FullProfileDetailActions.loadFullProfileDetails,
    (state, action) => adapter.setAll(action.fullProfileDetails, state)
  ),
  on(FullProfileDetailActions.loadFullProfileDetail,
    (state, action) => adapter.setOne(action.fullProfileDetail, state)
  ),
  on(FullProfileDetailActions.clearFullProfileDetails,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
