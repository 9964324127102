import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {WorkFlowStage, WorkingStageInput} from './work-flow-stage.model';

export const loadWorkFlowStages = createAction(
    '[WorkFlowStage/API] Load WorkFlowStages',
    props<{ workFlowStages: WorkFlowStage[] }>()
);

export const addWorkFlowStage = createAction(
    '[WorkFlowStage/API] Add WorkFlowStage',
    props<{ workFlowStage: WorkFlowStage }>()
);

export const upsertWorkFlowStage = createAction(
    '[WorkFlowStage/API] Upsert WorkFlowStage',
    props<{ workFlowStage: WorkFlowStage }>()
);

export const addWorkFlowStages = createAction(
    '[WorkFlowStage/API] Add WorkFlowStages',
    props<{ workFlowStages: WorkFlowStage[] }>()
);

export const upsertWorkFlowStages = createAction(
    '[WorkFlowStage/API] Upsert WorkFlowStages',
    props<{ workFlowStages: WorkFlowStage[] }>()
);

export const updateWorkFlowStage = createAction(
    '[WorkFlowStage/API] Update WorkFlowStage',
    props<{ workFlowStage: Update<WorkFlowStage> }>()
);

export const updateWorkFlowStages = createAction(
    '[WorkFlowStage/API] Update WorkFlowStages',
    props<{ workFlowStages: Update<WorkFlowStage>[] }>()
);

export const deleteWorkFlowStage = createAction(
    '[WorkFlowStage/API] Delete WorkFlowStage',
    props<{ id: string }>()
);

export const deleteWorkFlowStages = createAction(
    '[WorkFlowStage/API] Delete WorkFlowStages',
    props<{ ids: string[] }>()
);

export const clearWorkFlowStages = createAction(
    '[WorkFlowStage/API] Clear WorkFlowStages'
);


//// Effect actions
export const fetchAllWorkFlowStageByProcess = createAction(
    '[WorkFlowStage/API] Load All User From Server',
    props<{ workProcessUid: string }>()
);

export const newWorkFlowStage = createAction(
    '[WorkFlowStage/API] create new work flow stage in the server',
    props<{ workFlowStage: WorkingStageInput }>()
);
