import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { marriageForms, marriageTypes } from '../../constants/marriages.constants';
import { maritalStatuses } from '../../constants/user.constants';
import { InputTypeEnum, SelectTypeEnum} from '../../enums/input.enum'

@Component({
  selector: 'app-custom-form-field',
  templateUrl: './custom-form-field.component.html',
  styleUrls: ['./custom-form-field.component.scss']
})
export class CustomFormFieldComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() control: string;
  @Input() inputType: string;
  @Input() selectType: SelectTypeEnum;
  @Input() label: string;
  @Input() maxDate: string;
  typeOfInputEnum = InputTypeEnum;
  typeOfSelectEnum = SelectTypeEnum;
  maritalStatusList = maritalStatuses;
  marriageFormsList = marriageForms;
  marriageTypesList = marriageTypes;
   
  constructor() { }

  ngOnInit(): void {
  }

}
