import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {ResponseCode} from '../../../../shared/enums/http-status-codes.enum';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {saveLegalProfessionalViewComment, upsertLegalProfessionalView} from './legal-professional-view.actions';
import {SAVE_LEGAL_PRO_VIEW} from './legal-professional-view.graphql';
import {getFullProfileDetails} from '../full-profile-detail/full-profile-detail.actions';


@Injectable()
export class LegalProfessionalViewEffects {

    saveLegalProView$ = createEffect(() => this.actions$.pipe(
            ofType(saveLegalProfessionalViewComment),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: SAVE_LEGAL_PRO_VIEW,
                    variables: {
                        legalProfessionalView: action.legalPro,
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.saveLegalProfessionalView?.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(upsertLegalProfessionalView({
                                    legalProfessionalView:
                                    data?.saveLegalProfessionalView?.data
                                }));
                                this.store.dispatch(getFullProfileDetails({applicationUid: action.applicationUid}));
                                return this.notificationService.successMessage('Your comment saved successfully');
                            } else {
                                return this.responseCodesService.errorMessageByMessage(data.saveLegalProfessionalView);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    /*  allDoneViewPetitionersForLegalProfessional$ = createEffect(() => this.actions$.pipe(
          ofType(getLegalProfessionalViewByCurrentUser),
          switchMap((action) => {
            return this.apollo.query({
              query: GET_LEGAL_VIEW_PETITIONERS_BY_CURRENT_USER,
              fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                  if (data) {
                    this.store.dispatch(loadPetitions({
                      petitions: Object.values(data)[0] as Petition[]
                    }));
                  }
                })
            );
          })
      ), {dispatch: false});
      */


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService
    ) {
    }

}
