export enum ResponseCode {
    ATLEAST_ONE_ATTACHMENT_REQUIRED = 'ATLEAST_ONE_ATTACHMENT_REQUIRED',
    CUSTOM = 'CUSTOM',
    DUPLICATE = 'DUPLICATE',
    FAILURE = 'FAILURE',
    INVALID_REQUEST = 'INVALID_REQUEST',
    NO_RECORD_FOUND = 'NO_RECORD_FOUND',
    SUCCESS = 'SUCCESS',
    UNAUTHORIZED = 'UNAUTHORIZED'
}
