<table class="table" *ngIf="attachments?.length">
    <tbody *ngIf="app?.attachments?.length">
        <tr *ngFor="let attachment of app?.attachments">
            <th>
                <button mat-raised-button color="accent" class="mr-2 mb-2"
                    (click)="openDocument(attachment?.uuid)">
                    Preview [{{attachment?.attachmentName}}]
                </button>
            </th>

        </tr>

    </tbody>

</table>
<div *ngIf="!attachments?.length" class="text-center no-attachments-section">
    <div class="svg-icon">
        <mat-icon mat-list-icon svgIcon="box"></mat-icon>
    </div>
    <i>There is no attachments in this application</i>
</div>