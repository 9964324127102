import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
    deleteAttachmentType,
    editExistingAttachmentType,
    fetchAllAttachmentTypes, fetchAllAttachmentTypesByUser, loadAttachmentTypes,
    removerAttachmentType,
    saveNewAttachmentType,
    upsertAttachmentType,
    upsertAttachmentTypes
} from './attachment-type.actions';
import {
    DELETE_ATTACHMENT_TYPE,
    GET_ALL_ATTACHMENT_TYPES,
    GET_ALL_ATTACHMENT_TYPES_BY_USER,
    SAVE_ATTACHMENT_TYPE,
    UPDATE_ATTACHMENT_TYPE
} from './attachment-type.graphql';
import {AttachmentType} from './attachment-type.model';
import {map, switchMap} from 'rxjs/operators';
import {ResponseCode} from '../../../../shared/enums/http-status-codes.enum';
import {Apollo} from 'apollo-angular';
import {Store} from '@ngrx/store';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';


@Injectable()
export class AttachmentTypeEffects {

    allAttachmentTypes$ = createEffect(() => this.actions$.pipe(
            ofType(fetchAllAttachmentTypes),
            switchMap((action) => {
                return this.apollo
                    .query({
                        query: GET_ALL_ATTACHMENT_TYPES,
                        fetchPolicy: 'network-only',
                    })
                    .pipe(
                        this.notificationService.catchError(),
                        map(({data}: any) => {
                            if (data) {
                                this.store.dispatch(upsertAttachmentTypes({attachmentTypes: Object.values(data)[0] as AttachmentType[]}));
                            }
                        })
                    );
            })
        ),
        {dispatch: false}
    );

    allAttachmentTypesByUser$ = createEffect(() => this.actions$.pipe(
            ofType(fetchAllAttachmentTypesByUser),
            switchMap((action) => {
                return this.apollo
                    .query({
                        query: GET_ALL_ATTACHMENT_TYPES_BY_USER,
                        fetchPolicy: 'network-only',
                    })
                    .pipe(
                        this.notificationService.catchError(),
                        map(({data}: any) => {
                            if (data) {
                                this.store.dispatch(loadAttachmentTypes({attachmentTypes: Object.values(data)[0] as AttachmentType[]}));
                            }
                        })
                    );
            })
        ),
        {dispatch: false}
    );

    newAttachmentType$ = createEffect(() => this.actions$.pipe(
            ofType(saveNewAttachmentType),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: SAVE_ATTACHMENT_TYPE,
                    variables: {
                        attchType: action.attachmentType
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.saveAttachmentType?.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(upsertAttachmentType({attachmentType: data?.saveAttachmentType?.data}));
                                return this.notificationService.successMessage('Saved successfully');
                            } else {
                                return this.responseCodesService.errorMessageByCode(data.saveAttachmentType.code);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    editAttachmentType$ = createEffect(() => this.actions$.pipe(
            ofType(editExistingAttachmentType),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: UPDATE_ATTACHMENT_TYPE,
                    variables: {
                        attchType: action.attachmentType,
                        uid: action.uid
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.updateAttachmentType?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(upsertAttachmentType({attachmentType: data.updateAttachmentType.data}));
                            return this.notificationService.successMessage('Edited Successfully');
                        } else {
                            return this.responseCodesService.errorMessageByCode(data.updateAttachmentType.code);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    deleteAttachmentType$ = createEffect(() => this.actions$.pipe(
            ofType(removerAttachmentType),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: DELETE_ATTACHMENT_TYPE,
                    variables: {
                        uid: action.uid
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.deleteAttachmentType?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(deleteAttachmentType({id: data.deleteAttachmentType.data.id}));
                            return this.notificationService.successMessage('Deleted Successfully');
                        } else {
                            return this.responseCodesService.errorMessageByCode(data.deleteAttachmentType.code);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService
    ) {
    }
}
