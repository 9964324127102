import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {fetchPaymentByUid, getApplicantPayments, loadPayments, upsertPayment} from './payment.actions';
import {FETCH_RECEIPT_BY_PAYMENT_ID, GET_APPLICANT_PAYMENTS} from './payment.graphql';
import {Payment} from './payment.model';
import {ResponseCode} from '../../../../shared/enums/http-status-codes.enum';


@Injectable()
export class PaymentEffects {

    getApplicantPayments$ = createEffect(() => this.actions$.pipe(
        ofType(getApplicantPayments),
        switchMap((action) => {
            return this.apollo.query({
                query: GET_APPLICANT_PAYMENTS,
                fetchPolicy: 'network-only',
            }).pipe(
                this.notificationService.catchError(),
                map(({data}: any) => {
                    if (data) {
                        this.store.dispatch(loadPayments({
                            payments: Object.values(data)[0] as Payment[]
                        }));
                    }
                })
            );
        })
    ), {dispatch: false});


    fetchPaymentByUid = createEffect(() => this.actions$.pipe(
            ofType(fetchPaymentByUid),
            switchMap((action) => {
                return this.apollo.query({
                    query: FETCH_RECEIPT_BY_PAYMENT_ID,
                    fetchPolicy: 'network-only',
                    variables: {
                        paymentUid: action.paymentUid
                    }
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data?.viewRecept?.code === ResponseCode.SUCCESS) {
                            this.store.dispatch(upsertPayment({payment: data.viewRecept.data as Payment}));
                        } else {
                            return this.responseCodesService.errorMessageByMessage(data.viewRecept);
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService
    ) {
    }

}
