import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { PetitionSession } from './petition-session.model';

export const loadPetitionSessions = createAction(
  '[PetitionSession/API] Load PetitionSessions',
  props<{ petitionSessions: PetitionSession[] }>()
);

export const addPetitionSession = createAction(
  '[PetitionSession/API] Add PetitionSession',
  props<{ petitionSession: PetitionSession }>()
);

export const upsertPetitionSession = createAction(
  '[PetitionSession/API] Upsert PetitionSession',
  props<{ petitionSession: PetitionSession }>()
);

export const addPetitionSessions = createAction(
  '[PetitionSession/API] Add PetitionSessions',
  props<{ petitionSessions: PetitionSession[] }>()
);

export const upsertPetitionSessions = createAction(
  '[PetitionSession/API] Upsert PetitionSessions',
  props<{ petitionSessions: PetitionSession[] }>()
);

export const updatePetitionSession = createAction(
  '[PetitionSession/API] Update PetitionSession',
  props<{ petitionSession: Update<PetitionSession> }>()
);

export const updatePetitionSessions = createAction(
  '[PetitionSession/API] Update PetitionSessions',
  props<{ petitionSessions: Update<PetitionSession>[] }>()
);

export const deletePetitionSession = createAction(
  '[PetitionSession/API] Delete PetitionSession',
  props<{ id: string }>()
);

export const deletePetitionSessions = createAction(
  '[PetitionSession/API] Delete PetitionSessions',
  props<{ ids: string[] }>()
);

export const clearPetitionSessions = createAction(
  '[PetitionSession/API] Clear PetitionSessions'
);



// Effect actions

export const fetchAllPetitionSessions = createAction(
    '[PetitionSession/API] fetch all petition sessions from the server'
);

export const getPetitionSessionByUid = createAction(
    '[PetitionSession/API] get single petition single session from the server',
    props<{sessionUid: string}>()
);

export const savePetitionSession = createAction(
    '[PetitionSession/API] save petition session to the server',
    props<{ petitionSession: PetitionSession }>()
);

export const editPetitionSession = createAction(
    '[PetitionSession/API] edit existing petition session to the server',
    props<{ petitionSession: PetitionSession, uid: string }>()
);

export const removePetitionSession = createAction(
    '[PetitionSession/API] remove existing petition session to the server',
    props<{ uid: string }>()
);

