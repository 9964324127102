import {FirmGrapqhlFields} from '../firm/firm.grapqhl';
import gql from 'graphql-tag';

export const FirmBranchGraphqlFields = `
    id
    uid
    active
    approvalStatus
    box
    branchName
    city
    code
    createdAt
    createdBy{
        id
        uid
        fullName
    }
    deletedAt
    district{
        id
        uid
    }
    email
    firm{
        ${FirmGrapqhlFields}
    }
    foreignAddr
    phone
    postcode
    preference
    region{
        id
        uid
    }
    street
    updatedAt
    ward
`;

export const GET_USER_FIRM_BRANCH = gql`
    query getUserFirmBranch{
        getUserFirmBranch{
            ${FirmBranchGraphqlFields}
        }
    }
`;

export const SAVE_FIRM_BRANCH = gql`
    mutation saveFirmBranch($firmBranch:FirmBranchDtoInput){
        saveFirmBranch(firmBranchDto:$firmBranch){
           data{
            ${FirmBranchGraphqlFields}
            }
           errorDescription
           status
           code
        }
    }
`;


export const APPLY_FOR_FIRM = gql`
    query applyForFirm($firmUid:String,$firmBranchUid:String){
        applyForFirm(firmUid:$firmUid,firmBranchUid:$firmBranchUid){
             data{
            ${FirmBranchGraphqlFields}
            }
           errorDescription
           status
           code
        }
    }
`;

