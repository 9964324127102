import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { CleCoram } from './cle-coram.model';
import {CleMemberDto} from "../../settings/cle-member/cle-member.model";

export const loadCleCorams = createAction(
  '[CleCoram/API] Load CleCorams', 
  props<{ cleCorams: CleCoram[] }>()
);

export const addCleCoram = createAction(
  '[CleCoram/API] Add CleCoram',
  props<{ cleCoram: CleCoram }>()
);

export const upsertCleCoram = createAction(
  '[CleCoram/API] Upsert CleCoram',
  props<{ cleCoram: CleCoram }>()
);

export const addCleCorams = createAction(
  '[CleCoram/API] Add CleCorams',
  props<{ cleCorams: CleCoram[] }>()
);

export const upsertCleCorams = createAction(
  '[CleCoram/API] Upsert CleCorams',
  props<{ cleCorams: CleCoram[] }>()
);

export const updateCleCoram = createAction(
  '[CleCoram/API] Update CleCoram',
  props<{ cleCoram: Update<CleCoram> }>()
);

export const updateCleCorams = createAction(
  '[CleCoram/API] Update CleCorams',
  props<{ cleCorams: Update<CleCoram>[] }>()
);

export const deleteCleCoram = createAction(
  '[CleCoram/API] Delete CleCoram',
  props<{ id: string }>()
);

export const deleteCleCorams = createAction(
  '[CleCoram/API] Delete CleCorams',
  props<{ ids: string[] }>()
);

export const clearCleCorams = createAction(
  '[CleCoram/API] Clear CleCorams'
);


/*
*  effect actions
* */
export const fetchAllCleCorams = createAction(
    '[CleCoram/API] fetch all cle corams from the server',
    props<{sessionUid: string}>()
);
