import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../../../../services/auth/auth.service';
import {Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    currentYear: any;
    staffLoginForm: FormGroup;
    loading = false;
    loginView = false;
    subscription: Subscription = new Subscription();
    resetPasswordForm = this.fb.group({
        email: [null, [Validators.required, Validators.email]],
    });
    redirectUrl: string;

    constructor(
        private fb: FormBuilder,
        private route: Router,
        private auth: AuthService,
        private http: HttpClient,
        private snackbar: MatSnackBar,
    ) {
        if (this.auth.alreadyLoggedIn()) {
            this.route.navigate(['', 'management']);
        }
    }

    ngOnInit(): void {
        this.currentYear = new Date().getFullYear();
        this.staffLoginForm = this.fb.group({
            username: [null, [Validators.required, Validators.email]],
            password: [null, [Validators.required]]
        });
    }

    async login() {
        this.loading = true;
        try {
            const tokenData = await this.auth.login(this.staffLoginForm.value);
            await this.auth.authRole(true);
        } catch (e) {
            if (e.status === 401) {
                this.snackbar.open('Sorry! we are having trouble logging you in, Please contact your administrator.');
     /*           if (e.error.error_description.split(' ').includes('locked')) {
                    this.snackbar.open('Sorry! your account is locked due to multiple login attempts, Please contact your administrator');
                }*/
            }
            // TODO: click more than once for login, need to be checked with serious minds and eyes: By Avity
            // console.log(e);
        }
        this.loading = false;
    }

    getSliders() {
        const imagesArray = [];
        // this.http.get('http://admin.eganet.go.tz/api/sliders/default').subscribe((images) => {
        //     imagesArray = images._data;
        // });
        // return imagesArray;
    }

    changeView() {
        this.loginView = !this.loginView;
    }

    resetPassword(formData: any) {
        this.auth.openSnackbar('This Operation is Under Maintenance, Please contact helpdesk@ega.go.tz for Help');

        // this.auth.tempToken();

        // if (localStorage.getItem('tempClient')) {
        //   this.subscription.add(
        //     this.apollo.mutate({
        //       mutation: RESET_PASSWORD,
        //       variables: {
        //         email: formData.email
        //       }
        //     }).subscribe(({data}: any) => {
        //       if (data.resetPassword.code === 9000) {
        //         this.store.dispatch(logoutAction());
        //         this.loginView = false;
        //         this.auth.openSnackbar('Password Sent To Your E-mail');
        //       }
        //     })
        //   );
        // }
    }
}
