<div class="auth-wrapper">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-5 col-md-7 my-auto p-0">
                <div class="authentication-form mx-auto">
                    <div class="logo-centered">
                        <img src="assets/images/Judiciary-Logo.png" style="height:60px; width: 60px;"
                             alt="logo icon">
                    </div>
                    <h3>Application for Petition for Admission</h3>

                    <form [formGroup]="registrationForm" (ngSubmit)="save(registrationForm.value)" method="POST">
                        <div class="form-group">
                            <input type="text" class="form-control is-valid" formControlName="fullName"
                                   placeholder="Full Name" required="">
                            <i class="ik ik-user"></i>
                        </div>
                        <div class="form-group">
                            <input type="text" formControlName="phone" required="" class="form-control is-valid"
                                   placeholder="Mobile Number eg. 07XXXXXXXX">
                            <i class="ik ik-phone"></i>
                        </div>
                        <div class="form-group">
                            <input type="text" required formControlName="email" class="form-control is-valid"
                                   placeholder="Valid Email eg. email@email.com">
                            <i class="ik ik-mail"></i>
                        </div>

                        <div class="form-group">
                            <textarea formControlName="address" class="form-control is-valid"
                                      placeholder="Address"></textarea>
                            <i class="fa fa-map-marker-alt"></i>
                        </div>

                        <div class="sign-btn text-center">
                            <button [disabled]="!registrationForm.valid" class="btn btn-theme"
                                    data-loading-text="Submiting request.....">Apply
                            </button>
                        </div>
                    </form>
                    <div class="register">
                        <p>Already Registered? <a style="color:blue;" routerLink="/login"> Sign In Here</a> | <i
                                class="ik ik-home"></i> <a href="">Home</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
