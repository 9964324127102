import gql from 'graphql-tag';

export const RESET_PASSWORD = gql`
    mutation passwordResetReq($email: String!) {
        requestPasswordReset(email: $email) {
            code
            status
            errorDescription
        }
    }
`;

export const DO_RESET_PASSWORD = gql`
    mutation resetPassword($pwds: PasswordResetInput!) {
        performPasswordReset(passwordResetInput: $pwds) {
            code
            data{
                status
                errorDescription
            }
        }
    }
`;

export const SHOW_RENEWAL_BUTTON = gql`
    query showRenewButton{
      showRenewButton
    }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($changePassword:PasswordDtoInput){
    changePassword(changePassword:$changePassword){
      code
      data
      status
      errorDescription
    }
  }
`;

export const RESEND_PASSWORD_RESET_MAIL = gql`
  mutation resendPasswordResetMail{
    resendPasswordResetMail{
      code
      data
      status
      errorDescription
    }
  }
`;

