import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import {ApplicationDocument, ApplicationDocumentsDto} from './application-document.model';

export const loadApplicationDocuments = createAction(
  '[ApplicationDocument/API] Load ApplicationDocuments',
  props<{ applicationDocuments: ApplicationDocument[] }>()
);

export const addApplicationDocument = createAction(
  '[ApplicationDocument/API] Add ApplicationDocument',
  props<{ applicationDocument: ApplicationDocument }>()
);

export const upsertApplicationDocument = createAction(
  '[ApplicationDocument/API] Upsert ApplicationDocument',
  props<{ applicationDocument: ApplicationDocument }>()
);

export const addApplicationDocuments = createAction(
  '[ApplicationDocument/API] Add ApplicationDocuments',
  props<{ applicationDocuments: ApplicationDocument[] }>()
);

export const upsertApplicationDocuments = createAction(
  '[ApplicationDocument/API] Upsert ApplicationDocuments',
  props<{ applicationDocuments: ApplicationDocument[] }>()
);

export const updateApplicationDocument = createAction(
  '[ApplicationDocument/API] Update ApplicationDocument',
  props<{ applicationDocument: Update<ApplicationDocument> }>()
);

export const updateApplicationDocuments = createAction(
  '[ApplicationDocument/API] Update ApplicationDocuments',
  props<{ applicationDocuments: Update<ApplicationDocument>[] }>()
);

export const deleteApplicationDocument = createAction(
  '[ApplicationDocument/API] Delete ApplicationDocument',
  props<{ id: string }>()
);

export const deleteApplicationDocuments = createAction(
  '[ApplicationDocument/API] Delete ApplicationDocuments',
  props<{ ids: string[] }>()
);

export const clearApplicationDocuments = createAction(
  '[ApplicationDocument/API] Clear ApplicationDocuments'
);


/*
*  Effect actions
* */
export const fetchAllAttachmentDocuments = createAction(
    '[ApplicationDocument/API] fetch all documents attachment from the server'
);

export const fetchAttachmentDocumentsByUser = createAction(
    '[ApplicationDocument/API] fetch all documents attachment by user from the server'
);

export const saveAttachmentDocuments = createAction(
    '[Attachment/API] save new documents attachment to the server',
    props<{ applicationDocument: ApplicationDocumentsDto }>()
);
