import {createSelector} from '@ngrx/store';
import * as fromAppState from '../../reducers/index';
import {AppState} from '../../reducers/index';
import * as fromAuthReducers from './auth-user.reducer';
import * as fromUser from './auth-user.reducer';

const currentState = (state: AppState) => (state[fromAuthReducers.authUsersFeatureKey]) as any;

export const getAuthUserState = createSelector(fromAppState.appState, (state) => state.authUsers);

// query to get all users
export const selectAuthUser = createSelector(getAuthUserState, fromUser.getAuthUser);

export const selectRenewalButton = createSelector(currentState, (state) => {
    return state.renewalButton;
});

export const selectLoggedInUser = createSelector(
    selectAuthUser,
    (user) => {
        if (user !== undefined && user !== null) {
            return user[0];
        }
    }
);
