import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../../services/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class NonStaffGuardGuard implements CanActivate {

    constructor(
        private auth: AuthService,
        private router: Router
    ) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.auth.alreadyLoggedIn() ?
            (
                this.auth.authUser.isExternalUser ? true : (
                    this.auth.authUser.isInternalUser ? this.router.navigate(['', 'management']) : this.auth.logout()
                )
            ) :
            this.auth.logout();
    }

}
