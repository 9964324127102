import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {WorkFlowProcess} from './work-flow-process.model';

export const loadWorkFlowProcesss = createAction(
    '[WorkFlowProcess/API] Load WorkFlowProcesss',
    props<{ workFlowProcesss: WorkFlowProcess[] }>()
);

export const addWorkFlowProcess = createAction(
    '[WorkFlowProcess/API] Add WorkFlowProcess',
    props<{ workFlowProcess: WorkFlowProcess }>()
);

export const upsertWorkFlowProcess = createAction(
    '[WorkFlowProcess/API] Upsert WorkFlowProcess',
    props<{ workFlowProcess: WorkFlowProcess }>()
);

export const addWorkFlowProcesss = createAction(
    '[WorkFlowProcess/API] Add WorkFlowProcesss',
    props<{ workFlowProcesss: WorkFlowProcess[] }>()
);

export const upsertWorkFlowProcesss = createAction(
    '[WorkFlowProcess/API] Upsert WorkFlowProcesss',
    props<{ workFlowProcesss: WorkFlowProcess[] }>()
);

export const updateWorkFlowProcess = createAction(
    '[WorkFlowProcess/API] Update WorkFlowProcess',
    props<{ workFlowProcess: Update<WorkFlowProcess> }>()
);

export const updateWorkFlowProcesss = createAction(
    '[WorkFlowProcess/API] Update WorkFlowProcesss',
    props<{ workFlowProcesss: Update<WorkFlowProcess>[] }>()
);

export const deleteWorkFlowProcess = createAction(
    '[WorkFlowProcess/API] Delete WorkFlowProcess',
    props<{ id: string }>()
);

export const deleteWorkFlowProcesss = createAction(
    '[WorkFlowProcess/API] Delete WorkFlowProcesss',
    props<{ ids: string[] }>()
);

export const clearWorkFlowProcesss = createAction(
    '[WorkFlowProcess/API] Clear WorkFlowProcesss'
);

// effect
export const fetchWorkFlowProcesses = createAction(
    '[WorkFlowProcess/API] fetch all WorkFlowProcesss from the server'
);

export const assignWorkflowAttachments = createAction(
    '[WorkFlowProcess/API] Assign workflow attachment',
    props<{ attachment: any}>()
);


