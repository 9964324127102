import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {PetitionEducation} from './petition-education.model';

export const loadPetitionEducations = createAction(
    '[PetitionEducation/API] Load PetitionEducations',
    props<{ petitionEducations: PetitionEducation[] }>()
);

export const addPetitionEducation = createAction(
    '[PetitionEducation/API] Add PetitionEducation',
    props<{ petitionEducation: PetitionEducation }>()
);

export const upsertPetitionEducation = createAction(
    '[PetitionEducation/API] Upsert PetitionEducation',
    props<{ petitionEducation: PetitionEducation }>()
);

export const addPetitionEducations = createAction(
    '[PetitionEducation/API] Add PetitionEducations',
    props<{ petitionEducations: PetitionEducation[] }>()
);

export const upsertPetitionEducations = createAction(
    '[PetitionEducation/API] Upsert PetitionEducations',
    props<{ petitionEducations: PetitionEducation[] }>()
);

export const updatePetitionEducation = createAction(
    '[PetitionEducation/API] Update PetitionEducation',
    props<{ petitionEducation: Update<PetitionEducation> }>()
);

export const updatePetitionEducations = createAction(
    '[PetitionEducation/API] Update PetitionEducations',
    props<{ petitionEducations: Update<PetitionEducation>[] }>()
);

export const deletePetitionEducation = createAction(
    '[PetitionEducation/API] Delete PetitionEducation',
    props<{ id: string }>()
);

export const deletePetitionEducations = createAction(
    '[PetitionEducation/API] Delete PetitionEducations',
    props<{ ids: string[] }>()
);

export const clearPetitionEducations = createAction(
    '[PetitionEducation/API] Clear PetitionEducations'
);


/*
* Effect actions
* */

export const savePetitionEducation = createAction(
    '[PetitionEducation/API] save advocate petition education to the server',
    props<{ petitionEducation: PetitionEducation }>()
);

export const getPetitionEducationByUser = createAction(
    '[PetitionEducation/API] get advocate petition education by user'
);

export const editPetitionEducation = createAction(
    '[PetitionEducation/API] edit existing advocate petition education in the server',
    props<{ petitionEducation: PetitionEducation, uid: string }>()
);
