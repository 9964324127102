import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { UserActionDecision } from './user-action-decision.model';
import * as UserActionDecisionActions from './user-action-decision.actions';

export const userActionDecisionsFeatureKey = 'userActionDecisions';

export interface State extends EntityState<UserActionDecision> {
  // additional entities state properties
}

export const adapter: EntityAdapter<UserActionDecision> = createEntityAdapter<UserActionDecision>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(UserActionDecisionActions.addUserActionDecision,
    (state, action) => adapter.addOne(action.userActionDecision, state)
  ),
  on(UserActionDecisionActions.upsertUserActionDecision,
    (state, action) => adapter.upsertOne(action.userActionDecision, state)
  ),
  on(UserActionDecisionActions.addUserActionDecisions,
    (state, action) => adapter.addMany(action.userActionDecisions, state)
  ),
  on(UserActionDecisionActions.upsertUserActionDecisions,
    (state, action) => adapter.upsertMany(action.userActionDecisions, state)
  ),
  on(UserActionDecisionActions.updateUserActionDecision,
    (state, action) => adapter.updateOne(action.userActionDecision, state)
  ),
  on(UserActionDecisionActions.updateUserActionDecisions,
    (state, action) => adapter.updateMany(action.userActionDecisions, state)
  ),
  on(UserActionDecisionActions.deleteUserActionDecision,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(UserActionDecisionActions.deleteUserActionDecisions,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(UserActionDecisionActions.loadUserActionDecisions,
    (state, action) => adapter.setAll(action.userActionDecisions, state)
  ),
  on(UserActionDecisionActions.clearUserActionDecisions,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
