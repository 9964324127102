import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { AdvocateChangeStatus } from './advocate-change-status.model';

export const loadAdvocateChangeStatuss = createAction(
  '[AdvocateChangeStatus/API] Load AdvocateChangeStatuss', 
  props<{ advocateChangeStatuss: AdvocateChangeStatus[] }>()
);

export const addAdvocateChangeStatus = createAction(
  '[AdvocateChangeStatus/API] Add AdvocateChangeStatus',
  props<{ advocateChangeStatus: AdvocateChangeStatus }>()
);

export const upsertAdvocateChangeStatus = createAction(
  '[AdvocateChangeStatus/API] Upsert AdvocateChangeStatus',
  props<{ advocateChangeStatus: AdvocateChangeStatus }>()
);

export const addAdvocateChangeStatuss = createAction(
  '[AdvocateChangeStatus/API] Add AdvocateChangeStatuss',
  props<{ advocateChangeStatuss: AdvocateChangeStatus[] }>()
);

export const upsertAdvocateChangeStatuss = createAction(
  '[AdvocateChangeStatus/API] Upsert AdvocateChangeStatuss',
  props<{ advocateChangeStatuss: AdvocateChangeStatus[] }>()
);

export const updateAdvocateChangeStatus = createAction(
  '[AdvocateChangeStatus/API] Update AdvocateChangeStatus',
  props<{ advocateChangeStatus: Update<AdvocateChangeStatus> }>()
);

export const updateAdvocateChangeStatuss = createAction(
  '[AdvocateChangeStatus/API] Update AdvocateChangeStatuss',
  props<{ advocateChangeStatuss: Update<AdvocateChangeStatus>[] }>()
);

export const deleteAdvocateChangeStatus = createAction(
  '[AdvocateChangeStatus/API] Delete AdvocateChangeStatus',
  props<{ id: string }>()
);

export const clearAdvocateChangeStatuss = createAction(
  '[AdvocateChangeStatus/API] Clear AdvocateChangeStatuss'
);

export const deleteAdvocateChangeStatuss = createAction(
    '[AdvocateChangeStatus/API] Delete AdvocateChangeStatuss',
    props<{ ids: string[] }>()
);


// Effect actions
export const newAdvocateStatus = createAction(
    '[AdvocateChangeStatus/API] save new AdvocateChangeStatuss',
    props<{ statusData: AdvocateChangeStatus }>()
);
