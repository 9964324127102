import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {map, switchMap} from 'rxjs/operators';
import {
    editRenewalBatch,
    getRenewalBatch,
    loadRenewalBatchs,
    saveRenewalBatch,
    upsertRenewalBatch
} from './renewal-batch.actions';
import {GET_RENEWAL_BATCH, SAVE_RENEWAL_BATCH, UPDATE_RENEWAL_BATCH} from './renewal-batch.graphql';
import {RenewalBatch} from './renewal-batch.model';
import {ResponseCode} from '../../../../shared/enums/http-status-codes.enum';


@Injectable()
export class RenewalBatchEffects {

    getRenewalBatch$ = createEffect(() => this.actions$.pipe(
            ofType(getRenewalBatch),
            switchMap((action) => {
                return this.apollo.query({
                    query: GET_RENEWAL_BATCH,
                    fetchPolicy: 'network-only',
                })
                    .pipe(
                        this.notificationService.catchError(),
                        map(({data}: any) => {
                            if (data) {
                                const renewalBatchs = data.getRenewalBatch as RenewalBatch [];
                                this.store.dispatch(loadRenewalBatchs({renewalBatchs}));
                            }
                        })
                    );
            })
        ),
        {dispatch: false}
    );


    saveRenewalBatch = createEffect(() => this.actions$.pipe(
            ofType(saveRenewalBatch),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: SAVE_RENEWAL_BATCH,
                    variables: {
                        renewalBatchDto: action.renewalBatch
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.saveRenewalBatch?.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(upsertRenewalBatch({renewalBatch: data?.saveRenewalBatch?.data}));
                                return this.notificationService.successMessage('Saved successfully');
                            } else {
                                return this.responseCodesService.errorMessageByCode(data.saveRenewalBatch.code);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    updateRenewalBatch = createEffect(() => this.actions$.pipe(
            ofType(editRenewalBatch),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: UPDATE_RENEWAL_BATCH,
                    variables: {
                        renewalBatchDto: action.renewalBatch,
                        uid: action.uid
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.updateRenewalBatch?.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(upsertRenewalBatch({renewalBatch: data?.updateRenewalBatch?.data}));
                                return this.notificationService.successMessage('Updated successfully');
                            } else {
                                return this.responseCodesService.errorMessageByCode(data.updateRenewalBatch.code);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );

    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService
    ) {
    }

}
