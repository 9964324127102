import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Role } from './role.model';
import * as RoleActions from './role.actions';

export const rolesFeatureKey = 'roles';

export interface State extends EntityState<Role> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Role> = createEntityAdapter<Role>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(RoleActions.addRole,
    (state, action) => adapter.addOne(action.role, state)
  ),
  on(RoleActions.upsertRole,
    (state, action) => adapter.upsertOne(action.role, state)
  ),
  on(RoleActions.addRoles,
    (state, action) => adapter.addMany(action.roles, state)
  ),
  on(RoleActions.upsertRoles,
    (state, action) => adapter.upsertMany(action.roles, state)
  ),
  on(RoleActions.updateRole,
    (state, action) => adapter.updateOne(action.role, state)
  ),
  on(RoleActions.updateRoles,
    (state, action) => adapter.updateMany(action.roles, state)
  ),
  on(RoleActions.deleteRole,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(RoleActions.deleteRoles,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(RoleActions.loadRoles,
    (state, action) => adapter.setAll(action.roles, state)
  ),
  on(RoleActions.clearRoles,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
