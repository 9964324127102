import gql from 'graphql-tag';
import {profileGqlFields} from '../../advocate/advocate-profile/advocate-profile.graphql';

export const applicationsGqlFields = `
  id
  uid
  active
  applicationType
  approveStatus
  unReviewed
  resubmission
  submissionAt
  updatedAt
  createdAt
  lastUpdatedAt
  lstExemptionStatus
  wavePenalty
  createdBy{
    id
    uid
    fullName
  }
  currentStage{
    id
    uid
    name
    displayName
  }
  workflowProcess{
    id
    uid
    displayName
    name
  }
  profile{
    ${profileGqlFields}
  }
  approvalList{
    id
    uid
    active
    resubmission
    decision
    comment
    createdAt
    updatedAt
    resubmission
    actionUserType{
        id
        uid
        name
        displayName
    }
    user{
      id
      uid
      fullName
    }
  }
`;

export const GET_APPLICATION_BY_ACTION_USER_DT = gql`
    query getApplicationByActionUser($status: ApproveStatusEnums, $appType:  ApplicationTypeEnums, $dtInput: DataTablesInputInput){
        getApplicationByActionUser(approveStatus: $status, applicationType: $appType, dtInput: $dtInput){
            data{
                ${applicationsGqlFields}
            }
            draw
            recordsTotal
            recordsFiltered
        }
    }
`;

export const SUBMIT_PETITION_APPLICATION = gql`
    mutation saveApplication{
        savePetitionApplication{
            code
            status
            data{
                ${applicationsGqlFields}
            }
            errorDescription
        }
    }
`;

export const APPROVE_PETITION_APPLICATION = gql`
    mutation approveApplication($approve: ApproveDtoInput){
        approveApplication(approveDto: $approve){
            code
            data{
                ${applicationsGqlFields}
            }
            dataList{
                ${applicationsGqlFields}
            }
            status
            errorDescription
        }
    }
`;

export const GET_APPLICATION_BY_UID = gql`
    query getApplicationByUid($uid: String){
        getApplicationByUid(uid: $uid){
            code
            data{
                ${applicationsGqlFields}
            }
            errorDescription
            status
        }
    }
`;

export const SAVE_PERMIT_APPLICATION = gql`
    mutation savePermitApplication($permitApplication:PermitApplicatiomDtoInput){
        savePermitApplication(permitApplicatiomDto:$permitApplication){
            code
            data{
                ${applicationsGqlFields}
            }
            errorDescription
            status
        }
    }
`;

export const UPDATE_PETITION_ADMIT_AS = gql`
    mutation updatePAdmitAs($admit:  AdvocateStatusEnums, $applicationUid: String) {
        updatePetitionAdmitAs(admitAs: $admit, applicationUid: $applicationUid){
            code
            data{
                ${applicationsGqlFields}
            }
            errorDescription
            status
        }
    }
`;

export const SAVE_RENEWAL_APPLICATION = gql`
    mutation saveRenewalApplication($renewalApplicationDto: RenewalApplicationDtoInput){
      saveRenewalApplication(renewalApplicationDto: $renewalApplicationDto){
        data{
          ${applicationsGqlFields}
        }
        status
        errorDescription
        code
      }
    }
`;

export const RESUBMIT_OTHER_APPLICATION = gql`
    mutation resubmitApplications($appUid: String, $applicationDto: ResubmitApplicatiomDtoInput){
        resubmitApplication(applicationUid: $appUid, resubmitApplicatiomDto: $applicationDto){
            code
            data{
                id
                applicationType
                resubmission
            }
            errorDescription
            status
        }
    }
`;

export const GET_APPLICATION_WITH_EXEMPTION_BY_ACTION_USER_DT = gql`
    query getApplicationWithExemptionByActionUser($status: ApproveStatusEnums, $appType:  ApplicationTypeEnums, $exemptionStatus: Boolean, $dtInput: DataTablesInputInput){
        getApplicationByActionUser(approveStatus: $status, applicationType: $appType, exemptionStatus: $exemptionStatus, dtInput: $dtInput){
            data{
                ${applicationsGqlFields}
            }
            draw
            recordsTotal
            recordsFiltered
        }
    }
`;
