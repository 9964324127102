import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Petition } from './petition.model';
import * as PetitionActions from './petition.actions';

export const petitionsFeatureKey = 'petitions';

export interface State extends EntityState<Petition> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Petition> = createEntityAdapter<Petition>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(PetitionActions.addPetition,
    (state, action) => adapter.addOne(action.petition, state)
  ),
  on(PetitionActions.upsertPetition,
    (state, action) => adapter.upsertOne(action.petition, state)
  ),
  on(PetitionActions.addPetitions,
    (state, action) => adapter.addMany(action.petitions, state)
  ),
  on(PetitionActions.upsertPetitions,
    (state, action) => adapter.upsertMany(action.petitions, state)
  ),
  on(PetitionActions.updatePetition,
    (state, action) => adapter.updateOne(action.petition, state)
  ),
  on(PetitionActions.updatePetitions,
    (state, action) => adapter.updateMany(action.petitions, state)
  ),
  on(PetitionActions.deletePetition,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(PetitionActions.deletePetitions,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(PetitionActions.loadPetitions,
    (state, action) => adapter.setAll(action.petitions, state)
  ),
  on(PetitionActions.clearPetitions,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
