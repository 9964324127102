import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {Firm} from './firm.model';

export const loadFirms = createAction(
    '[Firm/API] Load Firms',
    props<{ firms: Firm[] }>()
);

export const addFirm = createAction(
    '[Firm/API] Add Firm',
    props<{ firm: Firm }>()
);

export const upsertFirm = createAction(
    '[Firm/API] Upsert Firm',
    props<{ firm: Firm }>()
);

export const addFirms = createAction(
    '[Firm/API] Add Firms',
    props<{ firms: Firm[] }>()
);

export const upsertFirms = createAction(
    '[Firm/API] Upsert Firms',
    props<{ firms: Firm[] }>()
);

export const updateFirm = createAction(
    '[Firm/API] Update Firm',
    props<{ firm: Update<Firm> }>()
);

export const updateFirms = createAction(
    '[Firm/API] Update Firms',
    props<{ firms: Update<Firm>[] }>()
);

export const deleteFirm = createAction(
    '[Firm/API] Delete Firm',
    props<{ id: string }>()
);

export const deleteFirms = createAction(
    '[Firm/API] Delete Firms',
    props<{ ids: string[] }>()
);

export const clearFirms = createAction(
    '[Firm/API] Clear Firms'
);

export const saveFirm = createAction(
    '[Firm/API] Clear Firms',
    props<{ firm: string }>()
);


export const approveFirm = createAction(
    '[Firm/API] Approve Firms',
    props<{ firmApproval: any }>()
);

export const refreshFirmsTable = createAction(
    '[Firm/API] Refresh Firms Table'
);


export const getFirmByUid = createAction(
    '[Firm/API] Get Firms By Uid',
    props<{ firmUid: string }>()
);

