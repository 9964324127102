import {Component, OnInit, Input, ViewChild, ElementRef, TemplateRef, ContentChild} from '@angular/core';
import {Location} from '@angular/common';

@Component({
    selector: 'app-page-title',
    templateUrl: './page-title.component.html',
    styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit {

    @Input() title: string;
    @Input() pageInfo: string;
    @Input() useBackButton = false;
    @Input() useButtonPanel = false;
    @Input() icon = 'ik ik-info';
    @ContentChild(TemplateRef, {static: true}) topButton: TemplateRef<any>;

    constructor(
        private location: Location
    ) {

    }

    ngOnInit() {
    }

    goBack() {
        this.location.back();
    }

}
