import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Signature } from './signature.model';
import * as SignatureActions from './signature.actions';

export const signaturesFeatureKey = 'signatures';

export interface State extends EntityState<Signature> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Signature> = createEntityAdapter<Signature>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(SignatureActions.addSignature,
    (state, action) => adapter.addOne(action.signature, state)
  ),
  on(SignatureActions.upsertSignature,
    (state, action) => adapter.upsertOne(action.signature, state)
  ),
  on(SignatureActions.addSignatures,
    (state, action) => adapter.addMany(action.signatures, state)
  ),
  on(SignatureActions.upsertSignatures,
    (state, action) => adapter.upsertMany(action.signatures, state)
  ),
  on(SignatureActions.updateSignature,
    (state, action) => adapter.updateOne(action.signature, state)
  ),
  on(SignatureActions.updateSignatures,
    (state, action) => adapter.updateMany(action.signatures, state)
  ),
  on(SignatureActions.deleteSignature,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(SignatureActions.deleteSignatures,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(SignatureActions.loadSignatures,
    (state, action) => adapter.setAll(action.signatures, state)
  ),
  on(SignatureActions.clearSignatures,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
