import {Injectable} from '@angular/core';
import {NotificationService} from '../notifications/notification.service';
import * as DataModel from '../../common/interfaces/data.interfaces';
import {ResponseCode} from '../../shared/enums/http-status-codes.enum';

export enum ResponseCodes {
    CUSTOM = 'CUSTOM',
    UNAUTHORIZED = 'UNAUTHORIZED',
    FAILURE = 'FAILURE',
    NO_RECORD_FOUND = 'NO_RECORD_FOUND',
    DUPLICATE = 'DUPLICATE',
}

export interface GqlErrorResponse {
    code?: ResponseCodes;
    errorDescription?: string;
    status?: boolean;
}

export const GepgErrorCodes = {
    7101: 'Successful',
    7201: 'Failure',

    7202: 'Required header is not given',

    7203: 'Unauthorized',

    7204: 'Bill does not exist',

    7205: ' Invalid service provider',

    7206: 'Service provider is not active',

    7207: 'Duplicate payment',

    7208: 'Invalid business account',

    7209: 'Business account is not active',

    7210: 'Collection account balance limit reached',

    7211: 'Payment service provider code did not match Bill service provider code',

    7212: 'Payment currency did not match Bill currency',

    7213: ' Bill has expired',

    7214: 'Insufficient amount paid',

    7215: 'Invalid payment service provider',

    7216: 'Payment service provider is not active',

    7217: 'No payer email or phone number',

    7218: 'Wrong payer identity',

    7219: 'Wrong currency',

    7220: 'Sub Service provider is inactive',

    7221: 'Wrong bill equivalent amount',

    7222: ' Wrong bill miscellaneous amount',

    7223: 'Invalid or inactive gfs or service type',

    7224: 'Wrong bill amount',

    7225: ' Invalid bill reference number or code',

    7226: ' Duplicate bill information',

    7227: ' Blank bill identification number',

    7228: 'Invalid sub service provider code',

    7229: 'Wrong bill item gfs or payment type code',

    7230: ' Wrong bill generation date',

    7231: 'Wrong bill expiry date',

    7232: 'Consumer already started by another process',

    7233: 'Consumer already stopped by another process',

    7234: ' Wrong bill payment option',

    7235: 'Bill creation completed successfully',

    7236: 'Bill creation completed with errors',

    7237: 'Bill detail creation completed successfully',

    7238: 'Bill detail creation completed with errors',

    7239: 'No external bill system settings found',

    7240: 'Failed to save transaction',

    7241: 'Invalid session',

    7242: 'Invalid request data',

    7243: 'Invalid credit account',

    7244: 'Invalid transfer amount',

    7245: 'Invalid credit account name',

    7246: 'Invalid debit account',

    7247: 'Invalid transfer transaction description',

    7248: ' Invalid debitor bic',

    7249: 'Wrong transfer date',

    7250: 'Invalid value in transfer reserved field one',

    7251: 'Invalid transfer transaction number',

    7252: 'Transfer transaction created successfully',

    7253: 'Transfer transaction created with errors',

    7254: ' Invalid use payment reference, use Y or N',

    7255: 'Invalid item billed amount',

    7256: 'Invalid item equivalent amount',

    7257: 'Invalid item miscellaneous amount',

    7258: 'Total item billed amount mismatches the bill amount',

    7259: ' Total item equivalent amount mismatches the bill equivalent amount',

    7260: 'Total item miscellaneous amount mismatches the bill miscellaneous amount',

    7261: 'Defect bill saved successfully',

    7262: 'Defect bill saved with errors',

    7263: 'Defect bill items saved successfully',

    7264: 'Defect bill items saved with errors',

    7265: 'Bill items saved successfully',

    7266: 'Bill items saved with errors',

    7267: 'Invalid email address',

    7268: ' Invalid phone number',

    7269: ' Invalid or inactive Service Provider System Id',

    7270: 'Transfer transaction update completed successfully',

    7271: 'Transfer transaction update completed with errors',

    7272: ' Defect transfer transaction saved successfully',

    7273: ' Defect transfer transaction saved with errors',

    7274: 'Duplicate transfer transaction',

    7275: ' Invalid Service Provider Payer Id',

    7276: 'Invalid Service Provider Payer Name',

    7277: 'Invalid bill description',

    7278: 'Invalid bill approval user',

    7279: ' Bill already settled',

    7280: ' Bill expired and bill move process failed',

    7281: 'Invalid payment transaction date',

    7282: ' Invalid payer email or phone number',

    7283: 'Bill has been cancelled',

    7284: ' Payment currency did not match collection account currency',

    7285: ' Invalid bill generation user',

    7286: 'Bill cancellation process failed',

    7287: ' Bill reference number does not meet required bill control number specifications',

    7288: 'Disbursement request did not match signature',

    7289: 'Invalid batch generated date',

    7290: 'Total batch amount cannot be zero(0)',

    7291: 'Total batch amount is not equal to summation of items(transactions);',

    7292: ' Duplicate disbursement batch',

    7293: ' Invalid disbursement pay option',

    7294: 'Invalid disbursement batch scheduled date',

    7295: 'Invalid disbursement notification template',

    7296: 'Disbursement notification template is not active',

    7297: ' Inactive currency',

    7298: ' Invalid currency for disbursement',

    7299: ' Batch item(recipients) recipients should not exceed',

    7301: ' Bill has been paid partially',

    7302: ' Paid amount is not exact billed amount',

    7303: ' Invalid Signature',

    7304: 'Invalid Signature Configuration missing one of parameter(passphrase, keyalias, filename);',

    7305: 'Invalid batch start and end date',

    7306: ' Batch has no item(transaction);',

    7307: ' Inconsistency batch start, end and generated date',

    7308: ' Invalid value in transfer reserved field two',

    7309: ' Invalid value in transfer reserved field three',

    7310: 'Invalid transfer credit or debit account',

    7311: 'Invalid GePG configurations missing one of parameter(gepgKeyFilePath, gepgPassphrase, gepgAlias);',

    7312: ' Batch does not exist',

    7313: 'Cancel is only for auto pay batch',

    7314: ' Batch already on disbursement process, cancellation process failed',

    7315: ' Batch cancellation process failed',

    7316: ' Batch already canceled',

    7317: ' Error on processing request',

    7318: 'Invalid reconciliation request date',

    7319: 'Reconciliation request date is out of allowable range',

    7320: 'Invalid reconciliation request options',

    7321: 'Request can not completed at this time, try later',

    7322: 'Inactive communication protocol',

    7323: 'Invalid code, mismatch of supplied code on information and header',

    7324: 'No payment(s) found for specified bill control number',

    7325: 'Request to partner application composed',

    7326: '  Request sent to partner application(system);',

    7327: 'Request sent to partner application(system) with no content response',

    7328: 'Request not received successful with partner application(system);',

    7329: 'Processing or communication error on partner application(system);',

    7330: 'Inactive or Unavailable, bill push to pay for specified Payment service Provider',

    7331: ' Paid Online Waiting Bank Confirmation',

    7332: ' Too many request of the same content',

    7333: 'Invalid bill control number',

    7334: 'Bill has been updated',

    7335: 'Requested bill control number does not exist',

    7336: ' Requested bill amount has reached the maximum payable limit'
};


@Injectable({
    providedIn: 'root'
})
export class ResponseCodesService {

    private responseCodes: DataModel.ResponseCode[] = [
        {
            code: ResponseCode.SUCCESS,
            description: 'Success'
        },
        {
            code: ResponseCode.FAILURE,
            description: 'Your request failed'
        },
        {
            code: ResponseCode.CUSTOM,
            description: 'CUSTOM'
        },
        {
            code: ResponseCode.UNAUTHORIZED,
            description: 'You are not authorized to perform this action'
        },
        {
            code: ResponseCode.NO_RECORD_FOUND,
            description: 'Requested resource was not found.'
        },
        {
            code: ResponseCode.DUPLICATE,
            description: 'Record already exist'
        },
        {
            code: ResponseCode.INVALID_REQUEST,
            description: 'Invalid Request'
        },
    ];

    /*    private responseCodes: DataModel.ResponseCode[] = [
            {
                code: 9000,
                description: 'Success'
            },
            {
                code: 9001,
                description: 'Invalid Request'
            },
            {
                code: 9002,
                description: 'Failed, No Data Changed'
            },
            {
                code: 9003,
                description: 'Record Does Not Exist'
            },
            {
                code: 9004,
                description: 'Duplicate'
            },
            {
                code: 9005,
                description: 'Application Failed To Execute An Action'
            },
            {
                code: 9006,
                description: 'Previous Password Not Correct'
            },
            {
                code: 9007,
                description: 'Password Do Not Match'
            },
            {
                code: 9008,
                description: 'Unauthorized'
            },
            {
                code: 9009,
                description: 'Bad Request'
            },
            {
                code: 9010,
                description: 'Restricted Access, Licence Not Valid'
            },
            {
                code: 9011,
                description: 'Minimum Requirement Not Meet'
            },
            {
                code: 9012,
                description: 'Transfer Not Initialized'
            },
            {
                code: 9015,
                description: 'Constraint Violation'
            }
        ];*/

    constructor(
        private notificationService: NotificationService,
    ) {
    }

    getCodeDescription(code) {
        const result = [];
        for (let i = 0; i < this.responseCodes.length; i++) {
            for (const data in this.responseCodes[i]) {
                if ((this.responseCodes[i][data]) === code) {
                    result.push(this.responseCodes[i]);
                }
            }
        }
        return result;
    }

    errorMessageByCode(code) {
        const responseCode = this.getCodeDescription(code);
        const message = responseCode[0].code + ' : ' + responseCode[0].description;
        // const message = responseCode[0].description;
        return this.notificationService.errorMessage(message);
    }

    errorMessageByMessage(response: GqlErrorResponse) {
        switch (response.code) {
            case ResponseCodes.NO_RECORD_FOUND:
                return this.notificationService.errorMessageSwalFire('Requested resource was not found.');
            case ResponseCodes.CUSTOM:
                return this.notificationService.errorMessageSwalFire(response.errorDescription);
            case ResponseCodes.UNAUTHORIZED:
                return this.notificationService.errorMessageSwalFire('You are not authorized to perform the requested action.');
            case ResponseCodes.FAILURE:
                return this.notificationService.errorMessageSwalFire('Unable to perform the requested action. Please try again. If the problem persist, contact the system admin');
            case ResponseCodes.DUPLICATE:
                return this.notificationService.errorMessageSwalFire('Record is already exist.');
            default:
                break;
        }
    }

    errorMessage(message) {
        return this.notificationService.errorMessage(message);
    }
}
