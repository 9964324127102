<div class="page-wraper" id="main-public-content">
    <!-- start loader -->
    <!--<div id="pageloader-overlay" class="visible incoming">Loading....<div class="loader-wrapper-outer"><div class="loader-wrapper-inner"><div class="loader"></div></div></div></div>-->
    <!-- end loader -->
    <!-- Header Top ==== -->
    <app-public-layout-header>
    </app-public-layout-header>
    <!-- Header Top END ==== -->
    <!-- Content -->
    <router-outlet></router-outlet>
    <!-- Content END-->
    <!-- Footer ==== -->
    <app-public-layout-footer>
    </app-public-layout-footer>
    <!-- Footer END ==== -->
    <button class="back-to-top fa fa-chevron-up"></button>
</div>
