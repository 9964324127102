import gql from 'graphql-tag';
import {UserGqlFields} from '../user/user.graphql';

export const attachmentTypeGqlFields = `
    id
    uid
    createdAt
    name
    createdBy{
        ${UserGqlFields}
    }
`;

export const SAVE_ATTACHMENT_TYPE = gql`
    mutation saveAttachmentType($attchType: AttachmentTypeDtoInput){
        saveAttachmentType(attachmentTypeDto: $attchType){
            code
            data{
                ${attachmentTypeGqlFields}
            }
            errorDescription
            status
        }
    }
`;

export const GET_ALL_ATTACHMENT_TYPES = gql`
    query allAttachmentTypes{
        getAllAttachmentType{
            ${attachmentTypeGqlFields}
        }
    }
`;

export const GET_ALL_ATTACHMENT_TYPES_BY_USER = gql`
    query attachmentsByUser{
        getAttachmentsByUser{
            id
            uid
            name
        }
    }
`;

export const UPDATE_ATTACHMENT_TYPE = gql`
    mutation editAttachmentType($attchType: AttachmentTypeDtoInput, $uid: String){
        updateAttachmentType(attachmentTypeDto: $attchType, uid: $uid){
            code
            data{
                ${attachmentTypeGqlFields}
            }
            errorDescription
            status
        }
    }
`;

export const DELETE_ATTACHMENT_TYPE = gql`
    mutation deleteAttachmentType($uid: String){
        deleteAttachmentType(uid: $uid){
            code
            data{
                ${attachmentTypeGqlFields}
            }
            errorDescription
            status
        }
    }
`;
