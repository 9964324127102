import gql from 'graphql-tag';
import {appearanceVenueGqlFields} from '../../settings/appearance-venue/appearance-venue.graphql';
import {UserGqlFields} from '../../settings/user/user.graphql';
import {petitionSessionsGqlFields} from '../../settings/petition-session/petition-session.graphql';

export const appearanceGqlFields = `
        id
        appearDate
        appearanceVenue{
            ${appearanceVenueGqlFields}
        }
        createdAt
        createdBy{
            ${UserGqlFields}
        }
        uid
        petitionSession{
            ${petitionSessionsGqlFields}
        }
        petitioners
        reportingTime
        updatedAt
`;

export const LIST_APPEARANCES = gql `
    query getAppearanceBySession($sessionUid: String){
        getAppearanceBySession(petitionSessionUid: $sessionUid){
            ${appearanceGqlFields}
        }
    }
`;

export const CREATE_APPEARANCE = gql `
    mutation saveAppearance($appearance: AppearanceDtoInput) {
        saveAppearance(appearanceDto: $appearance){
            code
            data{
                ${appearanceGqlFields}
            }
            errorDescription
            status
        }
    }
`;

export const GET_APPEARANCE_BY_VENUE = gql `
    query getAppearanceByVenue($venueUid: String, $sessionUid: String){
        getAppearanceByVenue(venueUid: $venueUid, petitionSessionUid: $sessionUid){
            ${appearanceGqlFields}
        }
    }
`;

export const UPDATE_APPEARANCE = gql `
    mutation updateAppearance($appearanceUid: String, $appearance: AppearanceDtoInput){
        updateAppearance(appearanceUid: $appearanceUid, appearanceDto: $appearance){
            code
            data{
                ${appearanceGqlFields}
            }
            errorDescription
            status
        }
    }
`;
/*
export const REMOVE_APPEARANCE = gql `

`;*/
