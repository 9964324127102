import gql from 'graphql-tag';
import {petitionersGqlFields} from '../petition/petition.graphql';

export const legalProfessionalViewGqlFields = `
    id
    active
    uid
    comment
    createdAt
    updatedAt
    user{
        id
        fullName
        uid
    }
    petition{
        uid
        id
        petitionNo
        petitionSession{
            id
            uid
        }
    }
    approveStatus
    submissionAt
    currentStage{
        id
        uid
        name
        displayName
        actionDecisions{
            approveStatusEnums
            id
            name
        }
    }
    updatedBy{
        id
        uid
        fullName
    }
    createdBy{
        id
        uid
        fullName
    }
    workflowProcess{
        id
        uid
        active
        displayName
        name
        updatedAt
        createdAt
    }
`;

export const SAVE_LEGAL_PRO_VIEW = gql`
    mutation saveLegalProComment($legalProfessionalView: LegalProfessionalViewDtoInput){
        saveLegalProfessionalView(legalProfessionalViewDto: $legalProfessionalView){
            code
            data{
                ${legalProfessionalViewGqlFields}
            }
            errorDescription
            status
        }
    }
`;

export const GET_LEGAL_PRO_VIEW_BY_ACTION_USER = gql`
    query getLegalProViewByActionUser($appStatus: ApproveStatusEnums, $dtInput: DataTablesInputInput, $sessionUid: String){
        getLegalProfessionalViewByActionUser(approveStatus: $appStatus, dtInput: $dtInput, sessionUid: $sessionUid){
            data{
                ${legalProfessionalViewGqlFields}
                petition{
                    ${petitionersGqlFields}
                }
            }
            recordsFiltered
            recordsTotal
            error
            draw
        }
    }
`;

