import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ApplicationDocument } from './application-document.model';
import * as ApplicationDocumentActions from './application-document.actions';

export const applicationDocumentsFeatureKey = 'applicationDocuments';

export interface State extends EntityState<ApplicationDocument> {
  // additional entities state properties
}

export const adapter: EntityAdapter<ApplicationDocument> = createEntityAdapter<ApplicationDocument>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(ApplicationDocumentActions.addApplicationDocument,
    (state, action) => adapter.addOne(action.applicationDocument, state)
  ),
  on(ApplicationDocumentActions.upsertApplicationDocument,
    (state, action) => adapter.upsertOne(action.applicationDocument, state)
  ),
  on(ApplicationDocumentActions.addApplicationDocuments,
    (state, action) => adapter.addMany(action.applicationDocuments, state)
  ),
  on(ApplicationDocumentActions.upsertApplicationDocuments,
    (state, action) => adapter.upsertMany(action.applicationDocuments, state)
  ),
  on(ApplicationDocumentActions.updateApplicationDocument,
    (state, action) => adapter.updateOne(action.applicationDocument, state)
  ),
  on(ApplicationDocumentActions.updateApplicationDocuments,
    (state, action) => adapter.updateMany(action.applicationDocuments, state)
  ),
  on(ApplicationDocumentActions.deleteApplicationDocument,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ApplicationDocumentActions.deleteApplicationDocuments,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ApplicationDocumentActions.loadApplicationDocuments,
    (state, action) => adapter.setAll(action.applicationDocuments, state)
  ),
  on(ApplicationDocumentActions.clearApplicationDocuments,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
