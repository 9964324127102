import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {ResponseCode} from '../../../../shared/enums/http-status-codes.enum';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {
    clearApplicationDocuments,
    fetchAttachmentDocumentsByUser, loadApplicationDocuments,
    saveAttachmentDocuments,
    upsertApplicationDocuments
} from './application-document.actions';
import {GET_USER_ATTACHMENTS, SAVE_ATTACHMENTS} from './application-document.graphql';
import {ApplicationDocument} from './application-document.model';
import {refreshSubmitButton} from '../advocate-profile/advocate-profile.actions';
import {getFullAdvocateProfileDetails} from '../../management/full-profile-detail/full-profile-detail.actions';


@Injectable()
export class ApplicationDocumentEffects {

    fetchAllAttachmentsByUser$ = createEffect(() => this.actions$.pipe(
            ofType(fetchAttachmentDocumentsByUser),
            switchMap((action) => {
                return this.apollo
                    .query({
                        query: GET_USER_ATTACHMENTS,
                        fetchPolicy: 'network-only',
                    })
                    .pipe(
                        this.notificationService.catchError(),
                        map(({data}: any) => {
                            if (data) {
                                this.store.dispatch(clearApplicationDocuments());
                                this.store.dispatch(loadApplicationDocuments({applicationDocuments:
                                        Object.values(data)[0] as ApplicationDocument[] || []}));
                            }
                        })
                    );
            })
        ),
        {dispatch: false}
    );

    newAttachments$ = createEffect(() => this.actions$.pipe(
            ofType(saveAttachmentDocuments),
            switchMap((action) => {
                return this.apollo.mutate({
                    mutation: SAVE_ATTACHMENTS,
                    variables: {
                        attachments: action.applicationDocument
                    },
                }).pipe(
                    this.notificationService.catchError(),
                    map(({data}: any) => {
                        if (data) {
                            if (data?.saveApplicationAttachments?.code === ResponseCode.SUCCESS) {
                                this.store.dispatch(refreshSubmitButton());
                                this.store.dispatch(upsertApplicationDocuments({
                                    applicationDocuments:
                                    data?.saveApplicationAttachments?.dataList
                                }));
                                this.store.dispatch(getFullAdvocateProfileDetails({}));
                                return this.notificationService.successMessage('Saved successfully');
                            } else {
                                return this.responseCodesService.errorMessageByMessage(data.saveApplicationAttachments);
                            }
                        }
                    })
                );
            })
        ),
        {dispatch: false}
    );


    constructor(
        private actions$: Actions,
        private apollo: Apollo,
        private notificationService: NotificationService,
        private store: Store<AppState>,
        private responseCodesService: ResponseCodesService
    ) {
    }
}
