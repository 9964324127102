import gql from 'graphql-tag';
import { BillGqlFields } from '../bill/bill.graphql';
import { UserGqlFields } from '../../settings/user/user.graphql';

export const GroupBillGqlFields = `
    uid
    billedInstitution
    phone
    email
    year
    createdAt
    createdBy{
        ${UserGqlFields}
    }
    bill{
        ${BillGqlFields}
    }
    id
    groupBillItems{
        practicingAmount
        notaryAmount
        penaltyAmount
        bill{
            ${BillGqlFields}
        }
    }
`;

export const RenewBillDto = `
    billedInstitution
    year
    notFoundEngineers
    totalBillAmount
    email
    phone
    billedRenewFee{
        rollNo
        fullName
    }
    unPaidRenewFee{
        billUid
        rollNo
        fullName
        practicingFee
        notaryAmount
        penaltyAmount
        totalAmount
    }
`;

export const GET_GROUP_BILLS = gql`
    query getGroupBillList($dtInput:DataTablesInputInput,$dateTo:LocalDate,$dateFrom:LocalDate){
        getGroupBillList(dtInput:$dtInput,dateFrom:$dateFrom,dateTo:$dateTo){
            data{
                 ${GroupBillGqlFields}
            }
            draw
            recordsTotal
            recordsFiltered
        }
    }
`;

export const FETCH_GROUP_BILL_BY_UID = gql`
  query viewGroupBill($billUid:String){
    viewGroupBill(billUid:$billUid){
        data{
           ${GroupBillGqlFields}
        }
        code
        status
        errorDescription
     }
  }
`;

export const PREVIEW_GROUP_BILL = gql`
    query previewGroupBill($billDetails:PreviewGroupBillDtoInput){
        previewGroupBill(previewGroupBillDto:$billDetails){
            data{
                ${RenewBillDto}
            }
            status
            errorDescription
            code
        }
    }
`;

export const SAVE_GROUP_BILL = gql`
    mutation createGroupBill($billDetail: CreateGroupBillDtoInput){
        createGroupBill(createGroupBillDto: $billDetail){
            code
            data{
                ${GroupBillGqlFields}
            }
            errorDescription
            status
        }
    }
`;












