import gql from 'graphql-tag';
import {petitionSessionsGqlFields} from '../../settings/petition-session/petition-session.graphql';

export const advocateGqlFields = `
    active
    admission
    advocateCategory
    certificate
    createdAt
    id
    uid
    paidYear
    rollNo
    tin
    updatedAt
    petitionSession{
        ${petitionSessionsGqlFields}
    }
    profile{
        id
        uid
        fullname
        gender
        user{
            phone
            email
        }
    }
`;


export const SEARCH_ADVOCATE = gql`
    query searchAdvocate($name: String, $rollNo: String){
        searchAdvocate(fullName: $name, rollNo: $rollNo){
            ${advocateGqlFields}
        }
    }
`;


export const GET_ADVOCATE_BY_UID = gql`
    query getAdvocateByUid($advocateUid: String){
        getAdvocateByUid(advocateUid: $advocateUid){
            code
            data{
                ${advocateGqlFields}
            }
            status
            errorDescription
        }
    }
`;


export const GET_ADVOCATE_BY_STATUS_DT = gql`
    query getAdvocateByStatus($dtInput:DataTablesInputInput,$status:AdvocateStatusEnums){
        getAdvocateByStatus(dtInput:$dtInput,status:$status){
            data{
                ${advocateGqlFields}
            }
            draw
            recordsTotal
            recordsFiltered
        }
    }
`;


export const GET_ADVOCATE_COUNT_FOR_DASHBOARD = gql`
    query getAdvocateDashboardCounts{
        getAdvocateDashboardCounts
    }
`;
