import {Component, OnDestroy, OnInit} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable, Subscription} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {AppState} from '../../../../store/reducers';
import {AuthService} from '../../../../services/auth/auth.service';
import {Router, RouterOutlet} from '@angular/router';
import {Store} from '@ngrx/store';
import {AuthUser} from '../../../../store/entities/auth-user/auth-user.model';
import {selectAuthUser} from '../../../../store/entities/auth-user/auth-user.selector';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {SettingsService} from '../../../../services/settings/settings.service';
import {ChangePasswordFormComponent} from '../../auth/change-password-form/change-password-form.component';
import {NavMenuInfo} from '../../../constants/modules-menu.constants';
import {TranslateService} from '@ngx-translate/core';
import {SideBarMenuService} from '../../../../services/side-bar-menu/side-bar-menu.service';
import {AdvocateProfile} from '../../../../store/entities/advocate/advocate-profile/advocate-profile.model';
import {
    AppNotification,
    NotificationType
} from '../../../../store/entities/management/app-notification/app-notification.model';
import {
    selectAllAppNotifications,
    selectAppNotificationCounts
} from '../../../../store/entities/management/app-notification/app-notification.selectors';
import {Fader, Slider} from '../../../animations/route-animations';

@Component({
    selector: 'app-main-layout',
    templateUrl: './main-layout.component.html',
    styleUrls: ['./main-layout.component.scss'],
    animations: [
        Fader
        // Slider
    ]
})
export class MainLayoutComponent implements OnInit, OnDestroy {
    mainMenus: NavMenuInfo[];

    appNotifications$: Observable<AppNotification[]>;
    totalAppNotifications$: Observable<number>;
    type = NotificationType;

    isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
        .pipe(
            map(result => result.matches),
            shareReplay()
        );

    title = 'main.app-title-short';
    subscription: Subscription = new Subscription();
    authUser$: Observable<AuthUser>;
    currentMenu: any;
    currentYear: number;
    currentUserProfile$: Observable<AdvocateProfile>;

    constructor(
        private breakpointObserver: BreakpointObserver,
        private auth: AuthService,
        private router: Router,
        private store: Store<AppState>,
        private dialog: MatDialog,
        public translate: TranslateService,
        private settingsService: SettingsService,
        private sideBarMenuService: SideBarMenuService
    ) {
    }

    ngOnInit(): void {
        this.authUser$ = this.store.select(selectAuthUser).pipe(
            map((response) => {
                return response[0];
            })
        );

        this.totalAppNotifications$ = this.store.select(selectAppNotificationCounts);
        this.appNotifications$ = this.store.select(selectAllAppNotifications);

        this.currentYear = new Date().getFullYear();

        this.currentUserProfile$ = this.settingsService.getCurrentUserProfilePicture();

        this.subscription.add(
            this.authUser$.subscribe(authUser => {
                if (authUser !== null && authUser !== undefined) {
                    if (authUser?.isInternalUser) {
                        this.mainMenus = this.sideBarMenuService.sideMenu;
                        if (authUser?.isExternalUser) {
                            this.mainMenus.push(this.sideBarMenuService.getPetitionerMenu());
                        }
                    } else {
                        this.mainMenus = this.sideBarMenuService.advocateSideMenu;
                    }
                }
            })
        );
    }

    getFullNameWithTitle(title: string, name: string): string {
        return this.settingsService.getFullNameWithTitle(title, name);
    }

    logout() {
        this.auth.logout();
    }

    changePassword(data: AuthUser) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '50%';
        dialogConfig.data = data;
        this.dialog.open(ChangePasswordFormComponent, dialogConfig);
    }

    switchLang(language: string) {
        this.settingsService.switchLang(language);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    // For Fader Imported animation
    prepareRoute(outlet: RouterOutlet) {
        return outlet && outlet.activatedRouteData;
    }

    // For slide Imported animation
/*    prepareRoute(outlet: RouterOutlet) {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
    }*/

}
