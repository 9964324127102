<div class="container bill-wrapper">
    <div class="bill-header px-1 py-3">
        <img src="assets/images/Judiciary-Logo.png" alt="">
        <h4 class="sub-title">
            Jamuhuri ya Muungano wa Tanzania
        </h4>
        <h4 class="sub-title">
            United Republic of Tanzania
        </h4>
        <h2 class="main-title">
            Judiciary of Tanzania
        </h2>
        <h4 class="sub-title dark-color">
            Exchequer Receipts
        </h4>
        <h2 class="sub-title dark-color fw-700">
            Stakabadhi ya Malipo ya Serikali
        </h2>
    </div>

    <div class="bill-details">
        <div class="bill-sub-details pb-0">
            <table class="table">
                <tbody>
                <tr>
                    <th style="width: 300px">Receipt No</th>
                    <td class="fw-700" colspan="2">: {{payment?.payrefid}}</td>
                </tr>
                <tr>
                    <th>Received from</th>
                    <td class="fw-700" colspan="2">: {{payment?.bill?.profile?.fullname | uppercase}}</td>
                </tr>
                <tr>
                    <th>Amount</th>
                    <td colspan="2">: {{payment?.paidamt | currency: '':''}} ({{payment?.bill?.currency}})</td>
                </tr>
                <tr>
                    <th>Amount in Words</th>
                    <td colspan="2">: {{payment?.amountInWords | titlecase}}.</td>
                </tr>
                <tr>
                    <th>Outstanding Balance</th>
                    <td colspan="2">: 0.00 (TZS)</td>
                </tr>
                <tr class="border-bottom border-top">
                    <th>In respect of</th>
                    <td class="fw-700">Item Description(s)</td>
                    <td class="fw-700">Item Amount</td>
                </tr>
                <tr class="border-bottom border-top" *ngFor="let billItem of payment?.bill?.billItems">
                    <th></th>
                    <td>{{billItem?.fee?.description}}</td>
                    <td>{{billItem?.amount | currency: '':''}}</td>
                </tr>
                <tr>
                    <th></th>
                    <td class="fw-700">Total Billed Amount</td>
                    <td class="fw-700">{{payment?.billamt | currency: '':''}} ({{payment?.bill?.currency}})</td>
                </tr>

                </tbody>
            </table>
        </div>
    </div>
    <div class="bill-details">
        <div class="bill-sub-details py-0">
            <table class="table">
                <tbody>
                <tr>
                    <th style="width: 300px">Bill Reference</th>
                    <td class="fw-700">: {{payment?.bill.billId}}</td>
                </tr>
                <tr>
                    <th>Payment Control Number</th>
                    <td class="fw-700">: {{payment?.payctrnum}}</td>
                </tr>
                <tr>
                    <th>Payment Date</th>
                    <td>: {{payment?.createdAt| date: 'medium'}}</td>
                </tr>
                <tr>
                    <th>Issued By</th>
                    <td class="fw-700">: {{payment?.bill?.createdBy?.fullName}}</td>
                </tr>
                <tr>
                    <th>Date Issued</th>
                    <td>: {{payment?.bill?.createdAt | date : 'medium'}}</td>
                </tr>
                <tr>
                    <th>Signature</th>
                    <td>: ...........................................................................</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

    <p class="footer-copyright">Copyright &copy; {{currentYear}}. Judiciary of Tanzania</p>
</div>
