import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {NotificationService} from '../../../../services/notifications/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {ResponseCodesService} from '../../../../services/response-codes/response-codes.service';
import {AuthService} from '../../../../services/auth/auth.service';
import {LIST_ALL_CLE_CORAMS} from './cle-coram-graphql';
import {fetchAllCleCorams, loadCleCorams} from './cle-coram.actions';
import {CleCoram} from './cle-coram.model';


@Injectable()
export class CleCoramEffects {

  allCleCorams$ = createEffect(() => this.actions$.pipe(
      ofType(fetchAllCleCorams),
      switchMap((action) => {
        return this.apollo.query({
          query: LIST_ALL_CLE_CORAMS,
          fetchPolicy: 'network-only',
          variables: {
            sessionUid: action.sessionUid
          }
        }).pipe(
            this.notificationService.catchError(),
            map(({data}: any) => {
              if (data) {
                this.store.dispatch(loadCleCorams({cleCorams:
                      Object.values(data)[0] as CleCoram[]}));
              }
            })
        );
      })
  ), {dispatch: false});


  constructor(
      private actions$: Actions,
      private apollo: Apollo,
      private notificationService: NotificationService,
      private store: Store<AppState>,
      private responseCodesService: ResponseCodesService,
      private auth: AuthService,
  ) {
  }

}
